import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  updateOnlyMessageCountService,
  changeBodyService,
} from "../redux/slices/userSMSMMSSlice.js";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { lighten, linearGradient } from "polished";
import SmsMmsForm from "./forms/SmsMmsForm";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Controls from "./controls";
import CloseIcon from "@material-ui/icons/Close";
import AddIcCallIcon from "@material-ui/icons/AddIcCall";
import CallIcon from "@material-ui/icons/Call";
import InfiniteScroll from "react-infinite-scroll-component";
import { debounce, isEmpty, findIndex, findLastIndex } from "lodash";
import { updatePbxInfo } from "../redux/slices/auth";
import { useInterval } from "beautiful-react-hooks";
import { saveAs } from "file-saver";
import { v4 as uuidv4 } from "uuid";
import ContactDetails from "../pages/contacts/details2";
import RecordDetails from "../pages/records/details";
import Popup from "./Popup";
import {
  blue,
  red,
  green,
  yellow,
  grey,
  blueGrey,
} from "@material-ui/core/colors";
import SendSmsOrMmsForm from "./forms/SendSmsOrMmsForm";
import { getMessaging, onMessage } from "firebase/messaging";
import { useSelector, useDispatch } from "react-redux";
import SearchIcon from "@material-ui/icons/Search";
import ConfirmDialog from "./ConfirmDialog";

import useTable from "./useTable";
import moment from "moment";
import {
  Paper,
  Box,
  InputBase,
  Typography,
  List,
  ListItem,
  CircularProgress,
  Tooltip,
  IconButton,
  Grid,
  Button,
} from "@material-ui/core";
//import Popup from "./Popup";
import CHAT from "../assets/img/icons/CHAT.jpg";
import CHAT2 from "../assets/img/icons/CHAT2.jpg";

import ChatRoomLeftItem from "./ChatRoomLeftItem";
import ChatRoomRightItem from "./ChatRoomRightItem";
import Api from "../lib/api";

import { fetchDocumentTypesService } from "../redux/slices/documenttypes";

import { fetchEntitiesService as fetchUserContactsSMSMMSByGroupsService } from "../redux/slices/userSMSMMSByGroupsSlice.js";

import ClickToCallPopup from "./ClickToCallPopup";
import contacts from "../redux/slices/contacts.js";
import Iconify from "./Iconify.js";
import CRMUtils from "../utils/index.js";
import Notification from "./Notification.js";

var dataSliceUserContactsSMSMMSByGroups = {
  stateListName: "userContactsSMSMMSByGroups",
  dataUrl: {},
  orgId: 1,
  userId: 0,

  getFilters: {
    include: [],

    page: 1,
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },

  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },
  errorHandle: null,
};

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  flex-direction: column;
  display: flex;
  height: 100%;
`;

const useStyles = makeStyles((theme) => ({
  form: {
    justifySelf: "self-end",
  },
  grow: {
    flexGrow: 1,
  },
  inputRoot: {
    borderRadius: "35px",
  },
  spinner: {
    position: "absolute",
    zIndex: 10,
    top: "65px",
    left: "50%",
  },
  more: {
    position: "absolute",
    zIndex: 1200,
    top: 0,
    right: 5,
    width: 25,
    height: 25,
    color: "transparent",

    backgroundColor: "transparent",

    "&:hover, &.Mui-focusVisible": {
      boxShadow:
        "rgba(0, 0, 0, 0.2) 0px 8px 10px -5px, rgba(0, 0, 0, 0.14) 0px 16px 24px 2px, rgba(0, 0, 0, 0.12) 0px 6px 30px 5px",
      backgroundColor: "white",
      color: "black",
    },
  },
  moreMenu: {
    position: "absolute",
    zIndex: 1200,
    top: 0,
    right: 5,
    width: 25,
    height: 25,
    color: "black",

    backgroundColor: "white",
    boxShadow:
      "rgba(0, 0, 0, 0.2) 0px 8px 10px -5px, rgba(0, 0, 0, 0.14) 0px 16px 24px 2px, rgba(0, 0, 0, 0.12) 0px 6px 30px 5px",
  },

  chatHistory: {
    height: "93%",
    display: "flex",
    flexDirection: "column",
  },
  chatWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "10px",
  },
  chatHistoryItems: {
    width: "100%",
    maxWidth: "100%",
    overflow: "auto",
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },

  rightItem: {
    flexDirection: "row-reverse",
    textAlign: "right",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: lighten("0.2", "rgba(137,132,128,0.2)"),
    "&:hover": {
      backgroundColor: "#E4DDD6",
    },
    alignSelf: "center",

    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 1),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),

    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "15ch",
      "&:focus": {
        width: "60ch",
      },
    },
  },

  boxBackground: {
    backgroundImage: "url(" + CHAT + ")",
    backgroundColor: "#E4DDD6",
    height: "80%",
    width: "96%",
    outline: "none",
    paddingBottom: 15,
    paddingTop: 15,
    border: "1px solid #ddd",
    borderRadius: "4px",
    boxShadow: "0 0 2px 1px rgba(0, 140, 186, 0.5)",
  },

  paper2: {
    position: "absolute",
    zIndex: "1000",
    height: "89%",
    width: "75%",
    borderRadius: "4px",
    padding: "5px",
  },
  paper3: {
    position: "absolute",
    zIndex: "1000",
    height: "65%",
    width: "87%",
    borderRadius: "4px",
    padding: "5px",
  },
  paper4: {
    position: "absolute",
    zIndex: "1000",
    height: "93%",
    width: "68%",
    borderRadius: "4px",
    padding: "5px",
  },
  paper: {
    position: "absolute",
    zIndex: "1000",
    height: "64%",
    width: "46%",
    borderRadius: "4px",
    padding: "5px",
  },
  image: {
    border: "1px solid #ddd",
    borderRadius: "4px",
    padding: "5px",
    width: "77px",
    "&:hover": {
      boxShadow: "0 0 2px 1px rgba(0, 140, 186, 0.5)",
    },
  },
  popover: {
    //margin: 5,
    position: "absolute",
    top: 0,
    right: 5,
    width: 25,
    height: 25,
    color: "black",
    backgroundColor: lighten("0.2", "rgba(120,120,120,0.2)"),
    "&:hover": {
      backgroundColor: "#E4DDD6",
    },
  },
  phoneWarningContainer: {
    padding: "2px 8px",
    backgroundColor: "rgb(251 246 202)",
    color: "#252525",
    display: "flex",
    alignItems: "center",
    fontSize: 12,
    borderRadius: 10,
    marginTop: 5,
    maxWidth: "fit-content",
    marginBottom: 6,
  },
  phoneWarningIcon: {
    marginRight: 5,
    color: "#bfb100",
    fontSize: 19,
  },
  phoneWarningText: {
    fontSize: 12,
    fontWeight: 500,
  },
}));

var limit = 10;
var total = 0;
var currentPage = 1;

function SmsMms(props) {
  const { authUser, pbxConfig } = useSelector((state) => state.auth);
  const { newSMS } = useSelector((state) => state.newSMS);
  //const flatList = useRef();
  const uuid = uuidv4();
  const classes = useStyles();
  const [searchText, setSearchText] = useState(null);
  const [openData, setOpenData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(null);
  const [confirmDialogLoading, setConfirmDialogLoading] = useState(false);
  const downloadImage = () => {
    saveAs(
      "https://image.shutterstock.com/image-photo/concept-words-live-demo-on-260nw-1855958839.jpg",
      "image.jpg"
    ); // Put your image url here.
  };
  const setSearch = (search) => {
    setSearchText(search);
  };
  const dispatch = useDispatch();
  const debouncedSearch = useCallback(
    debounce((newValue) => setSearch(newValue), 1500),
    []
  );
  const userCanUnmarkPhoneAsInvalid =
    CRMUtils.userHasPermission(authUser, ["user_unmarkphoneasinvalid"]) ||
    CRMUtils.userHasPermission(authUser, ["user_unmarkphoneasinvalidsuper"]);

  const {
    contact,
    fromIndex = false,
    fromSMSPanel = false,
    phone = null,
    setOrder = null,
    setUnread = null,
    fromRecord = false,
    userCanSend,
    phoneF,
    selectedGroup = null,
    setSelectedGroup = null,
    selectedGroupContact = null,
    setSelectedGroupContact = null,
    tabValueExternal = null,
    setTabValueExternal = null,
    setSelectedGroupPhone = null,
    selectedGroupPhone = null,
    mergeDataByGroups = null,
    setContact = null,
  } = props;

  const getContactGroupInfo = () => {
    let tmp;
    tmp = mergeDataByGroups.filter((data) => {
      return (
        data.id == selectedGroupContact &&
        data.group_id == selectedGroup &&
        data.id
      );
    });
    if (tmp.length === 0) {
      tmp = [
        {
          //full_name: selectedGroupPhone,
          phone: selectedGroupPhone,
        },
      ];
    }
    //console.log("selectedGroupPhone, tmp:", selectedGroupPhone,tmp);
    if (tmp.length > 0)
      if (tmp[0].building)
        return (
          <Button
            onClick={() => {
              if (authUser.is_coordinator == 1 && tmp[0] && tmp[0].record_id) {
                openRecord(tmp[0].record_id);
              } else {
                openContact(tmp[0].id);
              }
            }}
            style={{
              borderRadius: "10%",
            }}
            color="primary"
          >
            <Typography
              variant="subtitle2"
              style={{ borderColor: "#000", m: 1, border: 1 }}
            >
              {"Contact " +
                tmp[0].full_name +
                " - Phone " +
                tmp[0].phone +
                " at " +
                tmp[0].building}
            </Typography>
          </Button>
        );
      else return "Phone " + tmp[0].phone;
    else return "";
  };

  const getContactGroupClickToCall = (mergeDataByGroups, authUser) => {
    let tmp;
    tmp = mergeDataByGroups
      ? mergeDataByGroups.filter((data) => {
          return (
            data.id == selectedGroupContact && data.group_id == selectedGroup
          );
        })
      : [];

    //console.log("getContactGroupClickToCall",mergeDataByGroups?mergeDataByGroups:[],selectedGroupContact?selectedGroupContact:"nogroupcontact",selectedGroup?selectedGroup:"nogroup",tmp,authUser.communications_groups);

    let tmpComunicationGroup =
      authUser.communications_groups.length > 0
        ? authUser.communications_groups.filter((group) => {
            return (
              group.type === "message_group" && group.group_id == selectedGroup
            );
          })
        : [];
    let result = [];

    //console.log("tmpComunicationGroup",tmpComunicationGroup?tmpComunicationGroup:[]);

    if (
      tmp.length === 0 &&
      tmpComunicationGroup.length > 0 &&
      tmpComunicationGroup[0].outbound_call_code &&
      tmpComunicationGroup[0].pbx_domain === authUser.pbx_domain
    ) {
      result.name = selectedGroupPhone;
      result.phone =
        tmpComunicationGroup[0].outbound_call_code + selectedGroupPhone;
    }
    if (
      tmp.length > 0 &&
      tmpComunicationGroup.length > 0 &&
      tmpComunicationGroup[0].outbound_call_code &&
      tmpComunicationGroup[0].pbx_domain === authUser.pbx_domain
    ) {
      result.name = tmp[0].full_name;
      result.phone = tmpComunicationGroup[0].outbound_call_code + tmp[0].phone;
    }

    return result;
  };

  const [smsMmsData, setSmsMmsData] = useState([]);
  const [invalidPhoneNumber, setInvalidPhoneNumber] = useState(null);
  const { documentTypes } = useSelector((state) => state.documenttypes);
  const [selected, setSelected] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);

  const [openClickToCallMenu, setOpenClickToCallMenu] = useState(false);
  const objRefRowForClickToCall = useRef();

  const saveToContact = async (media_id, type) => {
    const payload = {
      docType: type,
    };

    if (contact && media_id) {
      const { data } = await Api.saveToContactImage(
        contact.id,
        media_id,
        payload
      );
    }
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const refreshByGroups = (group = null, phone = null, contact = null) => {
    if (
      /*!selectedGroupContact && !selectedGroupPhone*/ tabValueExternal === 0 &&
      ((group && group != -5) || !group)
    ) {
      dataSliceUserContactsSMSMMSByGroups.orgId = authUser.id;
      dispatch(
        fetchUserContactsSMSMMSByGroupsService(
          dataSliceUserContactsSMSMMSByGroups
        )
      );

      setTabValueExternal(1);
      phone ? setSelectedGroupPhone(phone) : "";
      contact ? setSelectedGroupContact(contact.id) : "";
      group && group != -5 ? setSelectedGroup(group) : "";
    }
  };

  const getInvalidPhoneNumberForMessaging = async (phone) => {
    const { data } = await Api.getInvalidPhoneNumberForMessaging(phone);

    setInvalidPhoneNumber(data ? data : null);
  };

  const sendMessage = async (payload) => {
    if (Array.isArray(payload)) {
      let newData = [...smsMmsData];
      payload.map(async (item) => {
        if (!item["attch"]) item["attch"] = "";
        if (item["attchURL"] && item["attchURL"] != "") {
          let formData = new FormData();
          Object.keys(item).forEach((key) => formData.append(key, item[key]));
          if (phone) {
            formData.append("phone", phone);
          } else if (selectedGroupPhone)
            formData.append("phone", selectedGroupPhone);

          if (selectedGroup && !authUser.default_cg) {
            formData.set("group", selectedGroup);
            //setSelectedGroup(null);
          } else if (
            selectedGroup &&
            payload.setDefault == 0 &&
            tabValueExternal == 1 //From group chat
          ) {
            formData.set("group", selectedGroup);
            //setSelectedGroup(null);
          } else if (
            authUser.default_cg &&
            payload.setDefault == 0 &&
            tabValueExternal == 0 //From user chat
          ) {
            formData.set("group", authUser.default_cg);
            //setSelectedGroup(null);
          } else if (payload.setDefault == 1)
            formData.set("group", payload.group);

          var object = {};
          formData.forEach((value, key) => (object[key] = value));
          var json = JSON.stringify(object);
          console.log("onSubmit -> sendMessage", payload, json);

          const newMessage = {
            created_by: authUser.dropdown_name,
            body: item.body,
            building_id: authUser.building_id,
            created_at: moment().utc().format(),
            failed: null,
            file_name: "picture",
            from: authUser.phone,
            fromUser: authUser.dropdown_name,
            from_id: 142,
            from_type: selectedGroup ? "group" : "user",
            id: -1,
            media_id: null,
            message_date: moment().format("YYYY-MM-DD hh:mm:ss"),
            organization_id: authUser.organization_id,
            response: null,
            sms_mms_contact_color_id: null,
            tempUrl: item["attchURL"],
            toUser: null,
            mediaType: item["mediaType"],
            to: contact
              ? contact.phone
              : selectedGroupPhone
              ? selectedGroupPhone
              : phone,
            to_id: contact
              ? contact.id
              : selectedGroupContact
              ? selectedGroupContact
              : null,
            to_type: "contact",
            type: "mms",
            url_attch: null,
            view: 1,
          };
          newData = [newMessage, ...newData];
          if (
            (newMessage.to_id &&
              ((contact && newMessage.to_id == contact.id && phone == null) ||
                (selectedGroupContact &&
                  newMessage.to_id == selectedGroupContact))) ||
            (newMessage.to &&
              ((phone && newMessage.to == phone) ||
                (selectedGroupPhone && newMessage.to == selectedGroupPhone)) &&
              contact == null)
          ) {
            if ((payload.group && payload.group != -5) || !payload.group)
              setSmsMmsData([newMessage, ...smsMmsData]);
          }
          if (fromIndex || fromSMSPanel) {
            setOrder(newMessage);
          }

          let localpayload = {
            data: {
              contact_id: newMessage.to_id ? newMessage.to_id : "",
              phone: newMessage.to_id ? "" : newMessage.to ? newMessage.to : "",
            },
            lastMessageBody: item["body"] ? item["body"] : "picture",
            lastMessageUser: 1,
          };

          dispatch(changeBodyService(localpayload));

          Promise.resolve(
            !phone && !contact && selectedGroupContact
              ? Api.sendContactSmsOrMms(selectedGroupContact, formData)
              : !phone && contact
              ? Api.sendContactSmsOrMms(contact.id, formData)
              : Api.SendSMSMMSByPhone(authUser.id, formData)
          ).then((result) => {
            if (result.data.to && result.data.to == phoneF.current) {
              const index = findLastIndex(newData, ["id", -1]);
              if (index >= 0) {
                //if(result && result.data) console.log("result.data 1",result.data); //result.data 1
                let newSmsMmsData = [...newData];
                newMessage.id = result.data.id;
                newMessage.failed = result.data.failed;
                newMessage.response = result.data.response;
                if (
                  result &&
                  result.data &&
                  result.data.body &&
                  newMessage.body != result.data.body
                )
                  newMessage.body = result.data.body;
                newSmsMmsData[index] = newMessage;
                setSmsMmsData(newSmsMmsData);
              }
            } else if (result.data.to == null) {
              const index = findLastIndex(newData, ["id", -1]);
              if (index >= 0) {
                let newSmsMmsData = [...newData];
                newMessage.failed = true;
                newMessage.response = "none";
                newSmsMmsData[index] = newMessage;
                setSmsMmsData(newSmsMmsData);
              }
            }
          });
        } else {
          let formData = new FormData();
          Object.keys(item).forEach((key) => formData.append(key, item[key]));
          if (phone) {
            formData.append("phone", phone);
          } else if (selectedGroupPhone)
            formData.append("phone", selectedGroupPhone);

          if (selectedGroup && !authUser.default_cg) {
            formData.set("group", selectedGroup);
            //setSelectedGroup(null);
          } else if (
            selectedGroup &&
            payload.setDefault == 0 &&
            tabValueExternal == 1 //From group chat
          ) {
            formData.set("group", selectedGroup);
            //setSelectedGroup(null);
          } else if (
            authUser.default_cg &&
            payload.setDefault == 0 &&
            tabValueExternal == 0 //From user chat
          ) {
            formData.set("group", authUser.default_cg);
            //setSelectedGroup(null);
          } else if (payload.setDefault == 1)
            formData.set("group", payload.group);

          var object = {};
          formData.forEach((value, key) => (object[key] = value));
          var json = JSON.stringify(object);
          console.log("onSubmit -> sendMessage", payload, json);

          const newMessage = {
            created_by: authUser.dropdown_name,
            body: item.body,
            building_id: authUser.building_id,
            created_at: moment().utc().format(),
            failed: null,
            file_name: "picture",
            from: authUser.phone,
            fromUser: authUser.dropdown_name,
            from_id: 142,
            from_type: selectedGroup ? "group" : "user",
            id: -1,
            media_id: null,
            message_date: moment().format("YYYY-MM-DD hh:mm:ss"),
            organization_id: authUser.organization_id,
            response: null,
            sms_mms_contact_color_id: null,
            tempUrl: null,
            toUser: null,
            to: contact
              ? contact.phone
              : selectedGroupPhone
              ? selectedGroupPhone
              : phone,
            to_id: contact
              ? contact.id
              : selectedGroupContact
              ? selectedGroupContact
              : null,
            to_type: "contact",
            type: "sms",
            url_attch: null,
            view: 1,
          };
          newData = [newMessage, ...newData];

          if (
            (newMessage.to_id &&
              ((contact && newMessage.to_id == contact.id && phone == null) ||
                (selectedGroupContact &&
                  newMessage.to_id == selectedGroupContact))) ||
            (newMessage.to &&
              ((phone && newMessage.to == phone) ||
                (selectedGroupPhone && newMessage.to == selectedGroupPhone)) &&
              contact == null)
          ) {
            if ((payload.group && payload.group != -5) || !payload.group)
              setSmsMmsData([newMessage, ...smsMmsData]);
          }
          if (fromIndex || fromSMSPanel) {
            setOrder(newMessage);
          }

          let localpayload = {
            data: {
              contact_id: newMessage.to_id ? newMessage.to_id : "",
              phone: newMessage.to_id ? "" : newMessage.to ? newMessage.to : "",
            },
            lastMessageBody: item["body"] ? item["body"] : "picture",
            lastMessageUser: 1,
          };

          dispatch(changeBodyService(localpayload));

          Promise.resolve(
            !phone && !contact && selectedGroupContact
              ? Api.sendContactSmsOrMms(selectedGroupContact, formData)
              : !phone && contact
              ? Api.sendContactSmsOrMms(contact.id, formData)
              : Api.SendSMSMMSByPhone(authUser.id, formData)
          ).then((result) => {
            if (result.data.to && result.data.to == phoneF.current) {
              const index = findLastIndex(newData, ["id", -1]);

              if (index >= 0) {
                //if(result && result.data) console.log("result.data 2",result.data); //result.data 2
                let newSmsMmsData = [...newData];
                newMessage.id = result.data.id;
                newMessage.failed = result.data.failed;
                newMessage.response = result.data.response;
                if (
                  result &&
                  result.data &&
                  result.data.body &&
                  newMessage.body != result.data.body
                )
                  newMessage.body = result.data.body;
                newSmsMmsData[index] = newMessage;
                setSmsMmsData(newSmsMmsData);
              }
            } else if (result.data.to == null) {
              const index = findLastIndex(newData, ["id", -1]);
              if (index >= 0) {
                let newSmsMmsData = [...newData];
                newMessage.failed = true;
                newMessage.response = "none";
                newSmsMmsData[index] = newMessage;
                setSmsMmsData(newSmsMmsData);
              }
            }
          });
        }

        const div = document.getElementById("scrollableDiv" + uuid);
        if (div) {
          div.scrollTo(0, 0);
        } /*dispatch(
    
        /*if(phone && selectedGroup)*/
        /*fetchUserContactsSMSMMSByGroupsService(
            dataSliceUserContactsSMSMMSByGroups
          )
        );*/

        if (item["group"])
          refreshByGroups(
            item["group"],
            selectedGroupPhone ? selectedGroupPhone : phone,
            contact
          );
        //setSelectedGroup(null);
      });

      setSmsMmsData(newData);
    } else {
      if (!payload["attch"]) payload["attch"] = "";
      let newData = [...smsMmsData];
      let localpayload = null;
      if (payload["attch"] && Array.isArray(payload["attch"])) {
        payload["attch"].forEach(async (attch) => {
          let formData = new FormData();

          formData.append("attch", attch);

          if (payload["body"]) formData.append("body", payload["body"]);
          if (phone) {
            formData.append("phone", phone);
          } else if (selectedGroupPhone)
            formData.append("phone", selectedGroupPhone);

          if (selectedGroup && !authUser.default_cg) {
            formData.set("group", selectedGroup);
            //setSelectedGroup(null);
          } else if (
            selectedGroup &&
            payload.setDefault == 0 &&
            tabValueExternal == 1 //From group chat
          ) {
            formData.set("group", selectedGroup);
            //setSelectedGroup(null);
          } else if (
            authUser.default_cg &&
            payload.setDefault == 0 &&
            tabValueExternal == 0 //From user chat
          ) {
            formData.set("group", authUser.default_cg);
            //setSelectedGroup(null);
          } else if (payload.setDefault == 1)
            formData.set("group", payload.group);

          var object = {};
          formData.forEach((value, key) => (object[key] = value));
          var json = JSON.stringify(object);
          console.log("onSubmit -> sendMessage", payload, json);

          const newMessage = {
            created_by: authUser.dropdown_name,
            body: payload.body,
            building_id: authUser.building_id,
            created_at: moment().utc().format(),
            failed: null,
            file_name: "picture",
            from: authUser.phone,
            fromUser: authUser.dropdown_name,
            from_id: 142,
            from_type: selectedGroup ? "group" : "user",
            id: -1,
            media_id: null,
            message_date: moment().format("YYYY-MM-DD hh:mm:ss"),
            organization_id: authUser.organization_id,
            response: null,
            sms_mms_contact_color_id: null,
            tempUrl: window.URL.createObjectURL(attch),
            toUser: null,
            to: contact
              ? contact.phone
              : selectedGroupPhone
              ? selectedGroupPhone
              : phone,
            to_id: contact
              ? contact.id
              : selectedGroupContact
              ? selectedGroupContact
              : null,
            to_type: "contact",
            type: "mms",
            url_attch: null,
            view: 1,
          };

          newData = [newMessage, ...newData];
          if (
            (newMessage.to_id &&
              ((contact && newMessage.to_id == contact.id && phone == null) ||
                (selectedGroupContact &&
                  newMessage.to_id == selectedGroupContact))) ||
            (newMessage.to &&
              ((phone && newMessage.to == phone) ||
                (selectedGroupPhone && newMessage.to == selectedGroupPhone)) &&
              contact == null)
          ) {
            if ((payload.group && payload.group != -5) || !payload.group)
              setSmsMmsData([newMessage, ...smsMmsData]);
          }
          if (fromIndex || fromSMSPanel) {
            setOrder(newMessage);
          }

          localpayload = {
            data: {
              contact_id: newMessage.to_id ? newMessage.to_id : "",
              phone: newMessage.to_id ? "" : newMessage.to ? newMessage.to : "",
            },
            lastMessageBody: payload["body"] ? payload["body"] : "picture",
            lastMessageUser: 1,
          };

          Promise.resolve(
            !phone && !contact && selectedGroupContact
              ? Api.sendContactSmsOrMms(selectedGroupContact, formData)
              : !phone && contact
              ? Api.sendContactSmsOrMms(contact.id, formData)
              : Api.SendSMSMMSByPhone(authUser.id, formData)
          ).then((result) => {
            if (result.data.to && result.data.to == phoneF.current) {
              const index = findLastIndex(newData, ["id", -1]);
              if (index >= 0) {
                //if(result && result.data) console.log("result.data 3",result.data); //result.data 3
                let newSmsMmsData = [...newData];
                newMessage.id = result.data.id;
                newMessage.failed = result.data.failed;
                newMessage.response = result.data.response;
                if (
                  result &&
                  result.data &&
                  result.data.body &&
                  newMessage.body != result.data.body
                )
                  newMessage.body = result.data.body;
                newSmsMmsData[index] = newMessage;
                setSmsMmsData(newSmsMmsData);
              }
            } else if (result.data.to == null) {
              const index = findLastIndex(newData, ["id", -1]);
              if (index >= 0) {
                let newSmsMmsData = [...newData];
                newMessage.failed = true;
                newMessage.response = "none";
                newSmsMmsData[index] = newMessage;
                setSmsMmsData(newSmsMmsData);
              }
            }
          });
        });
        dispatch(changeBodyService(localpayload));
        setSmsMmsData(newData);
      } else {
        let formData = new FormData();
        Object.keys(payload).forEach((key) =>
          formData.append(key, payload[key])
        );
        if (phone) {
          formData.append("phone", phone);
        } else if (selectedGroupPhone)
          formData.append("phone", selectedGroupPhone);

        if (selectedGroup && !authUser.default_cg) {
          formData.set("group", selectedGroup);
          //setSelectedGroup(null);
        } else if (
          selectedGroup &&
          payload.setDefault == 0 &&
          tabValueExternal == 1 //From group chat
        ) {
          formData.set("group", selectedGroup);
          //setSelectedGroup(null);
        } else if (
          authUser.default_cg &&
          payload.setDefault == 0 &&
          tabValueExternal == 0 //From user chat
        ) {
          formData.set("group", authUser.default_cg);
          //setSelectedGroup(null);
        } else if (payload.setDefault == 1)
          formData.set("group", payload.group);

        var object = {};
        formData.forEach((value, key) => (object[key] = value));
        var json = JSON.stringify(object);
        console.log("onSubmit -> sendMessage", payload, json);

        const newMessage = {
          created_by: authUser.dropdown_name,
          body: payload.body,
          building_id: authUser.building_id,
          created_at: moment().utc().format(),
          failed: null,
          file_name: "picture",
          from: authUser.phone,
          fromUser: authUser.dropdown_name,
          from_id: 142,
          from_type: selectedGroup ? "group" : "user",
          id: -1,
          media_id: null,
          message_date: moment().format("YYYY-MM-DD hh:mm:ss"),
          organization_id: authUser.organization_id,
          response: null,
          sms_mms_contact_color_id: null,
          tempUrl: null,
          toUser: null,
          to: contact
            ? contact.phone
            : selectedGroupPhone
            ? selectedGroupPhone
            : phone,
          to_id: contact
            ? contact.id
            : selectedGroupContact
            ? selectedGroupContact
            : null,
          to_type: "contact",
          type: "sms",
          url_attch: null,
          view: 1,
        };
        let newData = [newMessage, ...smsMmsData];

        if (
          (newMessage.to_id &&
            ((contact && newMessage.to_id == contact.id && phone == null) ||
              (selectedGroupContact &&
                newMessage.to_id == selectedGroupContact))) ||
          (newMessage.to &&
            ((phone && newMessage.to == phone) ||
              (selectedGroupPhone && newMessage.to == selectedGroupPhone)) &&
            contact == null)
        ) {
          setSmsMmsData(newData);
        }
        if (fromIndex || fromSMSPanel) {
          setOrder(newMessage);
        }

        let localpayload = {
          data: {
            contact_id: newMessage.to_id ? newMessage.to_id : "",
            phone: newMessage.to_id ? "" : newMessage.to ? newMessage.to : "",
          },
          lastMessageBody: payload["body"] ? payload["body"] : "picture",
          lastMessageUser: 1,
        };

        dispatch(changeBodyService(localpayload));

        const { data } =
          !phone && !contact && selectedGroupContact
            ? await Api.sendContactSmsOrMms(selectedGroupContact, formData)
            : !phone && contact
            ? await Api.sendContactSmsOrMms(contact.id, formData)
            : await Api.SendSMSMMSByPhone(authUser.id, formData);

        /*const { data } = !phone
          ? await Api.sendContactSmsOrMms(contact.id, formData)
          : await Api.SendSMSMMSByPhone(authUser.id, formData);*/

        if (data.to && data.to == phoneF.current) {
          const index = findIndex(newData, ["id", -1]);

          if (index >= 0) {
            //if(data) console.log("result.data 4",data); //result.data 4
            let newSmsMmsData = [...newData];
            newMessage.id = data.id;
            newMessage.failed = data.failed;
            newMessage.response = data.response;
            if (data && data.body && newMessage.body != data.body)
              newMessage.body = data.body;
            newSmsMmsData[index] = newMessage;

            setSmsMmsData(newSmsMmsData);
          }
        } else if (data.to == null) {
          const index = findIndex(newData, ["id", -1]);
          if (index >= 0) {
            let newSmsMmsData = [...newData];
            newMessage.failed = true;
            newMessage.response = "none";
            newSmsMmsData[index] = newMessage;
            setSmsMmsData(newSmsMmsData);
          }
        }
      }

      const div = document.getElementById("scrollableDiv" + uuid);
      if (div) {
        div.scrollTo(0, 0);
      } /*dispatch(
  
      /*if(phone && selectedGroup)*/
      /*fetchUserContactsSMSMMSByGroupsService(
          dataSliceUserContactsSMSMMSByGroups
        )
      );*/

      if (payload["group"])
        refreshByGroups(
          payload["group"],
          selectedGroupPhone ? selectedGroupPhone : phone,
          contact
        );
      //setSelectedGroup(null);
    }
  };

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const isSelected = (id) => selected.indexOf(id) !== -1;

  const { TblContainer } = useTable();

  const openInPopup = (item) => {
    setOpenPopup(true);
  };

  const onClose = () => {
    setOpenPopup(false);
  };

  const onDelete = async (id) => {
    const orgId = authUser.organization_id;
    onDeleteDocument(orgId, id);

    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    setNotify({
      isOpen: true,
      message: "Deleted Successfully",
      type: "error",
    });
  };

  const getNewSMSMMS = async () => {
    setLoading(true);
    let params = "";
    if (contact || selectedGroup) {
      params = new URLSearchParams(
        "page=" + parseInt(currentPage + 1) + "&limit=" + limit
      );
    }
    if (phone) {
      params = new URLSearchParams(
        "page=" +
          parseInt(currentPage + 1) +
          "&limit=" +
          limit +
          "&phone=" +
          phone
      );
    }
    //console.log("getNewSMSMMS: fromIndex,fromSMSPanel,phone,selectedGroupContact,selectedGroup,selectedGroupPhone",fromIndex,fromSMSPanel,phone,selectedGroupContact,selectedGroup,selectedGroupPhone);
    const { data } =
      fromIndex || fromSMSPanel
        ? phone
          ? await Api.getContactSMSMMSByPhone(
              authUser.id,
              `?1=1&${params.toString()}`
            )
          : selectedGroup && selectedGroupContact
          ? await Api.getContactSMSMMSByGroups(
              selectedGroupContact,
              selectedGroup,
              `?1=1&${params.toString()}`
            )
          : selectedGroup && selectedGroupPhone && !selectedGroupContact
          ? await Api.getContactSMSMMSByGroupsPhone(
              selectedGroupPhone,
              selectedGroup,
              `?1=1&${params.toString()}`
            )
          : await Api.getContactSMSMMS(contact.id, `?1=1&${params.toString()}`)
        : await Api.getContactSMSMMSHistory(
            contact.id,
            `?1=1&${params.toString()}`
          );

    setSmsMmsData(smsMmsData.concat(data.data));
    currentPage = data.meta.pagination.current_page;
    if (fromIndex || fromSMSPanel) {
    }
    setLoading(false);
  };

  const getSMSMMS = async () => {
    const params = new URLSearchParams("page=1&limit=" + limit);
    setLoading(true);

    const { data } =
      fromIndex || fromSMSPanel
        ? await Api.getContactSMSMMS(contact.id, `?1=1&${params.toString()}`)
        : await Api.getContactSMSMMSHistory(
            contact.id,
            `?1=1&${params.toString()}`
          );

    setSmsMmsData(data.data);

    if (fromIndex || fromSMSPanel) {
    }

    total = data.meta.pagination.total;
    currentPage = data.meta.pagination.current_page;

    setLoading(false);
  };

  const getSMSMMSByGroups = async () => {
    const params = new URLSearchParams("page=1&limit=" + limit);
    setLoading(true);

    const { data } =
      (fromIndex || fromSMSPanel) && selectedGroupContact && selectedGroup
        ? await Api.getContactSMSMMSByGroups(
            selectedGroupContact,
            selectedGroup,
            `?1=1&${params.toString()}`
          )
        : !selectedGroupContact && selectedGroup && selectedGroupPhone
        ? await Api.getContactSMSMMSByGroupsPhone(
            selectedGroupPhone,
            selectedGroup,
            `?1=1&${params.toString()}`
          )
        : await Api.getContactSMSMMSHistoryByGroups(
            selectedGroup,
            `?1=1&${params.toString()}`
          );
    //getContactSMSMMSByGroupsPhone
    setSmsMmsData(data.data);

    if (fromIndex || fromSMSPanel) {
    }

    total = data.meta.pagination.total;
    currentPage = data.meta.pagination.current_page;

    setLoading(false);
  };

  const getSMSMMSByPhone = async () => {
    const params = new URLSearchParams(
      "page=1&limit=" + limit + "&phone=" + phone
    );
    setLoading(true);

    const { data } = await Api.getContactSMSMMSByPhone(
      authUser.id,
      `?1=1&${params.toString()}`
    );

    setSmsMmsData(data.data);

    total = data.meta.pagination.total;
    currentPage = data.meta.pagination.current_page;

    setLoading(false);
  };
  const [openContactPopup, setOpenContactPopup] = useState(false);
  const [contactDetail, setContactDetail] = useState(null);

  const [openRecordPopup, setOpenRecordPopup] = useState(false);
  const [recordDetail, setRecordDetail] = useState(null);

  const openContact = async (id) => {
    const { data } = await Api.getContactPlain(id);

    setContactDetail(data.data);
    setOpenContactPopup(true);
  };

  const oncloseContact = () => {
    setOpenContactPopup(false);
  };

  const openRecord = async (id) => {
    setIsLoading(true);
    const { data } = await Api.getRecord(id);

    setRecordDetail(data.data);
    setOpenRecordPopup(true);
    setIsLoading(false);
  };
  const onCloseRecord = () => {
    setOpenRecordPopup(false);
  };

  useEffect(() => {
    const orgId = authUser.organization_id;
    if (isEmpty(documentTypes)) dispatch(fetchDocumentTypesService(orgId));
  }, []);

  useEffect(() => {
    if (!isEmpty(newSMS)) {
      let localpayload = {
        data: { contact_id: null, phone: null },
        addsubtract: 0,
      };

      if (phone) {
      }
      if (contact || phone) {
        if (contact && contact.id == newSMS[0].from_id) {
          const index = findIndex(smsMmsData, ["id", newSMS[0].id]);

          if (index < 0) {
            setSmsMmsData([newSMS[0], ...smsMmsData]);
            localpayload.data.contact_id = contact.id;
            //dispatch(updateOnlyMessageCountService(localpayload));
          }
        } else if (phone && phone === newSMS[0].from) {
          const index = findIndex(smsMmsData, ["id", newSMS[0].id]);

          if (index < 0) {
            setSmsMmsData([newSMS[0], ...smsMmsData]);
            localpayload.data.phone = phone;
            //dispatch(updateOnlyMessageCountService(localpayload));
          }
        }
      } else {
        if (selectedGroupContact && selectedGroupContact == newSMS[0].from_id) {
          const index = findIndex(smsMmsData, ["id", newSMS[0].id]);

          if (index < 0) {
            setSmsMmsData([newSMS[0], ...smsMmsData]);
            localpayload.data.contact_id = selectedGroupContact;
            //dispatch(updateOnlyMessageCountService(localpayload));
          }
        } else if (
          selectedGroupPhone &&
          selectedGroupPhone === newSMS[0].from
        ) {
          const index = findIndex(smsMmsData, ["id", newSMS[0].id]);

          if (index < 0) {
            setSmsMmsData([newSMS[0], ...smsMmsData]);
            localpayload.data.phone = selectedGroupPhone;
            //dispatch(updateOnlyMessageCountService(localpayload));
          }
        }
      }
    }
  }, [newSMS]);

  useEffect(() => {
    if (
      (selectedGroupContact && selectedGroup && tabValueExternal == 1) ||
      (selectedGroupPhone && selectedGroup && tabValueExternal == 1)
    ) {
      setInvalidPhoneNumber(null);
      getSMSMMSByGroups();
    } else if (!phone) {
      setSmsMmsData([]);
    }
  }, [
    selectedGroupContact,
    selectedGroup,
    selectedGroupPhone,
    tabValueExternal,
  ]);

  useEffect(() => {
    if (contact && tabValueExternal == 0) {
      setInvalidPhoneNumber(null);
      getInvalidPhoneNumberForMessaging(contact.phone);
      getSMSMMS();
    } else if (!phone) {
      setSmsMmsData([]);
    }
  }, [contact, tabValueExternal]);

  useEffect(() => {
    if (phone && tabValueExternal == 0) {
      setInvalidPhoneNumber(null);
      getInvalidPhoneNumberForMessaging(phone);
      getSMSMMSByPhone();
    } else if (!contact) {
      setSmsMmsData([]);
    }
  }, [phone, tabValueExternal]);

  /*useEffect(() => {
    console.error("smsMmsData", smsMmsData);
  }, [smsMmsData]);*/

  const makeACall = () => {
    console.log("makeACall", contact, phone);
    let tmp = {};

    tmp = {
      ...pbxConfig,
      makeACall: true,
      callToPhone: contact ? contact.phone : phone ? phone : "",
      callToName: contact
        ? contact.fname + contact.lname + " (contact)"
        : "Unknown",
    };

    dispatch(updatePbxInfo(tmp));
  };

  const clickToCallExternal = async (destination) => {
    const { data } = await Api.originateCallTo(
      authUser.pbx_domain,
      authUser.ext,
      authUser.phone,
      destination
    );
  };

  const beforeClickToCall = async () => {
    if (!selectedGroup && (contact || phone)) {
      const { data } = await Api.validatePhoneNumberForVoice(
        contact ? contact.phone : phone
      );
      if (data) {
        setConfirmDialog({
          isOpen: true,
          title:
            "The phone " +
            (contact ? contact.phone : phone) +
            " was marked as not valid for calls ",
          subTitle: "Are you sure you want to call it anyway?",
          onConfirm: () => {
            runClickToCall2();
          },
        });
      } else {
        runClickToCall2();
      }
    } else {
      runClickToCall2();
    }
  };

  const runClickToCall2 = () => {
    //console.log("runClickToCall2",contact?contact:"no contact",phone?phone:"no phone",selectedGroup?selectedGroup:"no group",selectedGroupContact?selectedGroupContact:"groupcontact",selectedGroupPhone?selectedGroupPhone:"groupphone",getContactGroupClickToCall(mergeDataByGroups,authUser),mergeDataByGroups,authUser.communications_groups);
    //console.log("callToFrogetContactGroupClickToCall()",getContactGroupClickToCall());

    if (!selectedGroup && (contact || phone)) {
      if (authUser.click_to_call == "web_phone") {
        makeACallMenu(
          contact ? contact.phone : phone,
          contact ? contact.fname + " " + contact.lname : phone
        );
      } else if (authUser.click_to_call == "external_phone") {
        clickToCallExternal(contact ? contact.phone : phone);
      }
    } else if (selectedGroup && (selectedGroupContact || selectedGroupPhone)) {
      let callToFromGroup = getContactGroupClickToCall(
        mergeDataByGroups,
        authUser
      );
      //console.log("callToFromGroup",callToFromGroup);

      if (authUser.click_to_call == "web_phone") {
        makeACallMenu(
          callToFromGroup ? callToFromGroup.phone : "",
          callToFromGroup ? callToFromGroup.name : ""
        );
      } else if (authUser.click_to_call == "external_phone") {
        clickToCallExternal(callToFromGroup ? callToFromGroup.phone : "");
      }
    }
  };

  const makeACallMenu = (phone, name) => {
    let tmp = {};

    tmp = {
      ...pbxConfig,
      makeACall: true,
    };

    tmp.callToPhone = phone;
    tmp.callToName = name;

    dispatch(updatePbxInfo(tmp));
  };

  const GroupOptions = () => {
    if (props.groups)
      return props.groups.map((group) => {
        return {
          id: group.group_id,
          title: group.name,
        };
      });
    else return [{ id: 0, title: "" }];
  };

  const runClickToCallMenu = () => {
    if (authUser.click_to_call_external) {
      setOpenClickToCallMenu(true);
    } else makeACall();
  };

  if (!contact && !selectedGroupPhone && !phone) {
    return (
      <Box p={8}>
        <div style={{ display: "flex", position: "relative" }}>
          <img
            src={CHAT2}
            alt={"Default"}
            style={{
              position: "absolute",
              zIndex: 100,
              top: -12,
              left: 0,
              borderRadius: 25,
              height: fromIndex ? "77vh" : fromSMSPanel ? "50vh" : "0vh",
              width: fromIndex ? "59vw" : fromSMSPanel ? "36vw" : "0vw",
              boxShadow:
                "rgba(145, 158, 171, 0.2) 0px 0px 10px 4px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
            }}
          />
        </div>
      </Box>
    );
  }

  const onUnmarkPhoneAsInvalid = async () => {
    try {
      setConfirmDialogLoading(true);
      await Api.unmarkPhoneNumberAsInvalidForMessaging(
        invalidPhoneNumber.phone_number
      );

      setNotify({
        isOpen: true,
        message: "Successfully unmarked!",
        type: "success",
      });

      setInvalidPhoneNumber(null);
    } catch (err) {
      if (
        (err.status == 401 || err.status == 422) &&
        err.data &&
        err.data.error
      ) {
        setNotify({
          isOpen: true,
          message: err.data.error,
          type: "error",
        });
      } else {
        setNotify({
          isOpen: true,
          message: "Something went wrong!",
          type: "error",
        });
      }
    } finally {
      setConfirmDialogLoading(false);
      setConfirmDialog({ isOpen: false, title: "", subTitle: "" });
    }
  };

  return (
    <Wrapper>
      <div className={classes.grow}>
        <Paper
          elevation={1}
          className={
            fromIndex
              ? classes.paper2
              : fromRecord
              ? classes.paper3
              : fromSMSPanel
              ? classes.paper4
              : classes.paper
          }
        >
          <Box mx={5}>
            <Toolbar>
              <Grid container>
                <Grid item xs={2}>
                  <Typography className={classes.title} variant="subtitle2">
                    Messages
                  </Typography>
                </Grid>

                <Grid item xs={8}>
                  {contact ? (
                    authUser.is_salesman == 0 ||
                    (authUser.is_salesman == 1 &&
                      contact.user_id &&
                      contact.user_id == authUser.id) ? (
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs={8}>
                          <Button
                            onClick={() => {
                              if (
                                authUser.is_coordinator == 1 &&
                                contact &&
                                contact.record_id
                              ) {
                                openRecord(contact.record_id);
                              } else {
                                openContact(contact.id);
                              }
                            }}
                            style={{
                              borderRadius: "10%",
                            }}
                            color="primary"
                          >
                            <Typography
                              variant="subtitle2"
                              style={{ borderColor: "#000", m: 1, border: 1 }}
                            >
                              {`Contact: ${contact.full_name}`}
                            </Typography>
                          </Button>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="subtitle2">
                            {pbxConfig && pbxConfig.aor ? (
                              <Button
                                onClick={beforeClickToCall}
                                style={{ borderRadius: "10%" }}
                                color="primary"
                              >
                                <Typography variant="subtitle2">
                                  {`Phone: ${contact.phone}`}
                                </Typography>
                              </Button>
                            ) : (
                              `Phone: ${contact.phone}`
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs={6}>
                          <Typography variant="subtitle2">
                            {`Contact: ${contact.full_name}`}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="subtitle2">
                            {pbxConfig && pbxConfig.aor ? (
                              <Button
                                onClick={
                                  //makeACall
                                  //runClickToCallMenu
                                  beforeClickToCall
                                }
                                style={{ borderRadius: "10%" }}
                                color="primary"
                              >
                                <Typography variant="subtitle2">
                                  {`Phone: ${contact.phone}`}
                                </Typography>
                              </Button>
                            ) : (
                              <Typography variant="subtitle2">
                                {`Phone: ${contact.phone}`}
                              </Typography>
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    )
                  ) : phone ? (
                    <Typography variant="subtitle2">
                      {pbxConfig && pbxConfig.aor ? (
                        <Button
                          onClick={beforeClickToCall}
                          style={{ borderRadius: "10%" }}
                          color="primary"
                        >
                          <Typography variant="subtitle2">
                            {`Phone: ${phone}`}
                          </Typography>
                        </Button>
                      ) : (
                        `Phone: ${phone}`
                      )}
                    </Typography>
                  ) : selectedGroupContact || selectedGroupPhone ? (
                    getContactGroupInfo()
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={2}>
                  {!userCanSend ? (
                    <Typography
                      style={{ color: "red" }}
                      variant="subtitle2"
                      noWrap
                    >
                      ***You don't have permission to send Messages***
                    </Typography>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>

              {/*
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <SearchIcon fontSize="small" />
                  </div>
                  <InputBase
                    placeholder="Search…"
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    onChange={(event) => debouncedSearch(event.target.value)}
                    inputProps={{ "aria-label": "search" }}
                  ></InputBase>
                </div>
                  */}
              {/*JSON.stringify(getContactGroupClickToCall(mergeDataByGroups,authUser))*/}
              {/*getContactGroupClickToCall(mergeDataByGroups,authUser).phone*/}
              {pbxConfig &&
              pbxConfig.aor &&
              (contact ||
                phone ||
                ((selectedGroup || selectedGroupPhone) &&
                  getContactGroupClickToCall(mergeDataByGroups, authUser)
                    .phone)) ? (
                <Tooltip
                  title={`Call ${contact ? contact.fname : ""} ${
                    contact ? contact.lname : ""
                  } (${
                    contact
                      ? contact.phone
                      : phone
                      ? phone.phone
                      : selectedGroup || selectedGroupPhone
                      ? getContactGroupClickToCall(mergeDataByGroups, authUser)
                          .phone
                      : ""
                  })`}
                >
                  <IconButton color="inherit" onClick={beforeClickToCall}>
                    <AddIcCallIcon
                      fontSize="large"
                      style={{ color: blue[500] }}
                    />
                  </IconButton>
                </Tooltip>
              ) : (
                ""
              )}
            </Toolbar>
            {invalidPhoneNumber ? (
              <div
                style={{ display: "flex", alignItems: "center", marginTop: 4 }}
              >
                <div className={classes.phoneWarningContainer}>
                  <Iconify
                    icon="ic:round-warning"
                    className={classes.phoneWarningIcon}
                  />
                  <div>
                    <Typography className={classes.phoneWarningText}>
                      This number is not valid for messaging, messages sent are
                      not guaranteed to be delivered
                    </Typography>
                    <Typography className={classes.phoneWarningText}>
                      *If a message is received from this number, it will be
                      automatically validated.
                    </Typography>
                  </div>
                </div>
                {userCanUnmarkPhoneAsInvalid ? (
                  <Tooltip title="Unmark phone as invalid">
                    <span>
                      <IconButton
                        onClick={() => {
                          setConfirmDialog({
                            isOpen: true,
                            title:
                              "Are you sure to unmark the phone " +
                              invalidPhoneNumber.phone_number +
                              " as invalid?",
                            subTitle: "You can't undo this operation",
                            onConfirm: () => onUnmarkPhoneAsInvalid(),
                          });
                        }}
                        style={{ padding: 4, marginLeft: 2 }}
                      >
                        <Iconify
                          style={{ color: "#527493", width: 20, height: 20 }}
                          icon="basil:phone-off-solid"
                        />
                      </IconButton>{" "}
                    </span>
                  </Tooltip>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
          </Box>

          <div style={{ display: "flex", position: "relative" }}>
            {loading && (
              <CircularProgress
                classes={{ root: classes.spinner }}
                size={40}
                color="secondary"
              />
            )}
          </div>
          <Box mx={5} className={classes.boxBackground}>
            {
              <div
                id={"scrollableDiv" + uuid}
                style={{
                  height: "100%",
                  width: "99%",
                  overflowY: "auto",
                  overflowX: "hidden",
                  display: "flex",
                  flexDirection: "column-reverse",
                }}
              >
                {/* <p>SmsMms.js</p> */}
                <InfiniteScroll
                  dataLength={smsMmsData.length}
                  next={() => {
                    getNewSMSMMS();
                  }}
                  style={{
                    display: "flex",
                    flexDirection: "column-reverse",
                    overflowY: "auto",
                    overflowX: "hidden",
                  }}
                  inverse={true} //
                  hasMore={true}
                  scrollableTarget={"scrollableDiv" + uuid}
                  initialScrollY={0}
                >
                  {smsMmsData
                    ? smsMmsData.map((item, index) => {
                        if (item && item.from_type === "contact") {
                          return (
                            <ListItem alignItems="flex-start">
                              <ChatRoomLeftItem
                                item={item}
                                classes={classes}
                                saveToContact={saveToContact}
                                saveToContactD={contact ? true : false}
                                documentTypes={documentTypes}
                              />{" "}
                            </ListItem>
                          );
                        } else {
                          return (
                            <ListItem alignItems="flex-start">
                              <ChatRoomRightItem
                                item={item}
                                classes={classes}
                                saveToContact={saveToContact}
                                saveToContactD={contact ? true : false}
                                documentTypes={documentTypes}
                              />
                            </ListItem>
                          );
                        }
                      })
                    : ""}
                </InfiniteScroll>
              </div>
            }
          </Box>
          <Box mt={4}>
            {" "}
            <SmsMmsForm
              sendSmsMms={(text) => {
                sendMessage(text);
              }}
              userCanSend={userCanSend}
              setOpenData={setOpenData}
              groups={props.groups}
              selectedGroup={selectedGroup}
              tabValueExternal={tabValueExternal}
              refreshByGroups={refreshByGroups}
              contact={contact}
              setSelectedGroup={setSelectedGroup}
            />{" "}
          </Box>
        </Paper>
      </div>
      <div className={classes.grow} />
      {/* <Popup
        title={"Send Message"}
        fullWidth={true}
        openPopup={openPopup}
        onClose={onClose}
      >
        <SendSmsOrMmsForm
          sendMessage={sendMessage}
          //documentTypes={documentTypes}
          className={classes.form}
        />
     </Popup>*/}
      {/*  <Button title="download" onClick={()=>downloadImage()}>Download</Button>*/}
      <ConfirmDialog
        confirmLoading={confirmDialogLoading}
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />

      {contactDetail && (
        <Popup
          fullScreen
          title={`Contact ${contactDetail.fname} ${contactDetail.lname}`}
          openPopup={openContactPopup}
          onClose={oncloseContact}
        >
          <ContactDetails
            contact={contactDetail}
            onDetailClose={oncloseContact}
          />
        </Popup>
      )}

      {recordDetail && (
        <Popup
          fullScreen
          title={`Patient ${recordDetail.fname} ${recordDetail.lname}`}
          openPopup={openRecordPopup}
          onClose={onCloseRecord}
        >
          <RecordDetails
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            record={recordDetail}
            onClose={onCloseRecord}
          />
        </Popup>
      )}

      {openClickToCallMenu && (
        <ClickToCallPopup
          domain={authUser.pbx_domain}
          sourceExt={authUser.ext}
          originationNum={authUser.phone}
          destination={contact ? contact.phone : phone}
          makeACall={makeACallMenu}
          destinationName={
            contact ? contact.fname + " " + contact.lname : phone
          }
          setOpenClickToCallMenu={setOpenClickToCallMenu}
        />
      )}

      <Notification notify={notify} setNotify={setNotify} />
    </Wrapper>
  );
}

export default SmsMms;
