import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Grid,
  makeStyles,
  TextField,
  List,
  ListItemText,
  ListItem,
  ListItemIcon,
  ListItemAvatar,
  Divider,
  Card,
  CardContent,
  Typography,
  FormControl,
  Paper,
  CardHeader,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  FormControlLabel,
  Switch,
  Tooltip,
} from "@material-ui/core";
import Iconify from "../Iconify";
import { useForm, Controller } from "react-hook-form";
import Controls from "../../components/controls";
import { useDispatch, useSelector } from "react-redux";
import { fetchLanguagesService } from "../../redux/slices/languages";
import { isEmpty } from "lodash";
import Api from "../../lib/api";
import BMI2 from "../BMI2";
import CRMUtils from "../../utils";

import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { debounce } from "lodash";
import moment from "moment";
import { withStyles } from "@material-ui/styles";
import ConfirmDialog from "../ConfirmDialog";
import Notification from "../Notification";

const googleMapAPI = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "100%",
      marginTop: 1,
    },
  },
  card: { width: "98%" },
  extraWrapper: {
    backgroundColor: "#f2f2f2",
    padding: "2px 4px",
  },
  error: {
    color: "#f44336",
  },

  cardPlaces: {
    backgroundColor: "white",
    zIndex: "999999",
    position: "fixed",
    boxShadow: "rgba(53, 64, 82, 0.25) 0px 0px 14px 0px",
  },

  phoneWarningContainer: {
    padding: "2px 8px",
    backgroundColor: "rgb(251 246 202)",
    color: "#252525",
    display: "flex",
    alignItems: "center",
    fontSize: 12,
    borderRadius: 10,
    maxWidth: "fit-content",
  },
  phoneWarningIcon: {
    marginRight: 3,
    color: "#bfb100",
    fontSize: 17,
  },
  phoneWarningText: {
    fontSize: 12,
    fontWeight: 500,
  },
}));

const SSwitch = withStyles((theme) => ({
  switchBase: {
    color: "#90a4ae",
    "&$checked": {
      transform: "translateX(16px)",
      color: "#66bb6a",
      "& + $track": {
        backgroundColor: "#cfd8dc",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    border: `1px solid #bdbdbd`,
    backgroundColor: "#eceff1",
    opacity: 1,

    transition: theme.transitions.create(["background-color", "border"]),
  },
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        thumb: classes.thumb,
        track: classes.track,
        switchBase: classes.switchBase,
      }}
      {...props}
    />
  );
});

function DuplicateStatus(props) {
  const classes = useStyles();
  const { phone, ...other } = props;

  const checkDuplicatePhone = async (value) => {
    const { data } = await Api.checkDuplicate("phone", value);

    if (data === 1) {
      return "duplicated";
    }
    return null;
  };

  return <div></div>;
}

export default function ContactForm2(props) {
  const { authUser } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const {
    addOrEdit,
    contactForEdit,
    setContactForEdit,
    tags,
    customFields,
    coordinators,
    buildings,
    saveLoading,
    userCanEdit = true,
    user,
    setOpenSendFormTemplate,
    getContact = null,
    userTeamLeader = false,
    onSaveExtraField,
    error,
  } = props;
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "success",
  });
  const [confirmDialogLoading, setConfirmDialogLoading] = useState(false);
  const { handleSubmit, setValue, reset, watch, control, errors, register } =
    useForm();
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const { control: control2, setValue: setValue2 } = useForm();

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: googleMapAPI,
    options: {
      types: ["address"],
    },
    language: "en-US",
  });
  const [placePredictionsLocal, setPlacePredictionsLocal] = useState([]);

  const [valueP, setValueP] = useState("");

  const [extraPhones, setExtraPhones] = useState([]);

  const [extraEmail, setExtraEmail] = useState([]);

  const [extraPhonesLabel, setExtraPhonesLabel] = useState(null);

  const [extraEmailLabel, setExtraEmailLabel] = useState(null);

  const debouncedSearch = useCallback(
    debounce((newValue) => setSearch(newValue), 1500),
    []
  );

  const setSearch = (search) => {
    getPlacePredictions({ input: search });
    setValueP(search);
  };

  const watchWeight = watch("weight");
  const watchHeight = watch("height");
  const [bmi, setBmi] = useState(null);
  const [phone, setPhone] = useState(null);
  const { contactsnewErrors } = useSelector((state) => state.contactsnew);
  const [errorFlag, setErrorFlag] = useState(false);
  const { languages } = useSelector((state) => state.languages);
  const [consultants, setConsultants] = useState([]);

  const [sources, setSources] = useState([]);
  const [idTypes, setIdTypes] = useState([]);
  const [completeAddress, setCompleteAddress] = useState(null);
  const [description, setDescription] = useState(
    contactForEdit?.description || null
  );
  const [openExtraPopup, setOpenExtraPopup] = useState(false);
  const [extraType, setExtraType] = useState(null);
  const closeExtraPopup = () => {
    setOpenExtraPopup(false);
    setExtraType(null);
  };

  const userCanEditAll = CRMUtils.userHasPermission(authUser, [
    "contacts_editall",
  ]);
  const userCanchangeSource = CRMUtils.userHasPermission(authUser, [
    "contacts_changesource",
  ]);
  const userCanDeleteLockedTags = CRMUtils.userHasPermission(authUser, [
    "contacts_deleteLockedTags",
  ]);

  const userIsSuperAdmin = CRMUtils.userHasRole(authUser, ["super-admin"]);


  const userIsSalesManager =
    contactForEdit &&
    CRMUtils.userHasPermission(authUser, ["user_salesGroupManager"])
      ? true
      : false;

  const userIsCallCenterBasic =
    contactForEdit &&
    CRMUtils.userHasPermission(authUser, ["dashboard_callCenterBasic"])
      ? true
      : false;

  const userIsFrontDeskBasic =
    contactForEdit &&
    CRMUtils.userHasPermission(authUser, ["dashboard_frontDeskBasic"])
      ? true
      : false;

  const userIsSocialMediaBasic =
    contactForEdit &&
    CRMUtils.userHasPermission(authUser, ["dashboard_socialMediaBasic"])
      ? true
      : false;

  const userIsCallCenterManager =
    contactForEdit &&
    CRMUtils.userHasPermission(authUser, ["dashboard_callCenterManager"])
      ? true
      : false;

  const userIsFrontDeskManager =
    contactForEdit &&
    CRMUtils.userHasPermission(authUser, ["dashboard_frontDeskManager"])
      ? true
      : false;

  const userIsSocialMediaManager =
    contactForEdit &&
    CRMUtils.userHasPermission(authUser, ["dashboard_socialMediaManager"])
      ? true
      : false;

  const userCanBillingVerification = CRMUtils.userHasPermission(authUser, [
    "contacts_billing_verification",
  ]);

  const readOnlyComponent =
    !userCanEdit ||
    (authUser.is_coordinator &&
      contactForEdit &&
      !contactForEdit.editable &&
      !userCanEditAll) ||
    (userIsSalesManager &&
      contactForEdit &&
      !contactForEdit.editable &&
      !userCanEditAll);

  const userBuilding =
    (contactForEdit &&
      CRMUtils.userHasPermission(authUser, ["contacts_assignbuilding"])) ||
    userIsCallCenterManager ||
    userIsFrontDeskManager ||
    userIsSocialMediaManager ||
    (!contactForEdit &&
      (CRMUtils.userHasPermission(authUser, ["contacts_createwithbuilding"]) ||
        CRMUtils.userHasPermission(authUser, ["contacts_assignbuilding"])))
      ? true
      : false;
  const userCanUnmarkPhoneAsInvalid =
    CRMUtils.userHasPermission(authUser, ["user_unmarkphoneasinvalid"]) ||
    CRMUtils.userHasPermission(authUser, ["user_unmarkphoneasinvalidsuper"]);

  const languageOptionsPlain = (languages) => {
    return languages.map((language) => language.id);
  };
  const languageOptions = (languages) => {
    return languages.map((language) => {
      return {
        id: language.id,
        title: language.name.replace(/^\w/, (c) => c.toUpperCase()),
      };
    });
  };
  const updateUserData = async (building_id) => {
    const { data } = await Api.getUsersBuilding(
      authUser.organization_id,
      building_id
    );

    let consult = data.data
      .filter((user) => {
        return user.is_salesman === 1 || user.is_fake_salesman === 1;
      })
      .map((user) => {
        return {
          id: user.id,
          title: !isEmpty(user.groups.data)
            ? user.title + "  (" + user.groups?.data[0]?.name + ")"
            : user.title,
        };
      });

    if (!isEmpty(contactForEdit?.owner?.data)) {
      setConsultants([
        {
          id: contactForEdit?.owner?.data?.id,
          title: `${contactForEdit?.owner?.data?.first_name} ${contactForEdit?.owner?.data?.last_name}`,
        },
        ...consult,
      ]);
    } else {
      setConsultants(consult);
    }
  };

  const setAddressValues = (item) => {
    placesService?.getDetails(
      {
        placeId: item.place_id,
      },
      (placeDetails) => {
        setCompleteAddress(placeDetails);
      }
    );
  };

  const priorityOptions = () => {
    return [
      {
        id: 1,
        title: "Very High Priority",
      },
      {
        id: 2,
        title: "High Priority",
      },
      {
        id: 3,
        title: "Medium Priority",
      },
      {
        id: 4,
        title: "Low Priority",
      },
      {
        id: 5,
        title: "Very Low Priority",
      },
      {
        id: 21,
        title: "Default Priority",
      },
    ];
  };

  const financingOptions = () => {
    return [
      {
        id: 0,
        title: "No",
      },
      {
        id: 1,
        title: "Yes",
      },
    ];
  };

  const classes = useStyles();
  const userCanAssignLead = () => {
    if (
      contactForEdit &&
      !CRMUtils.userHasPermission(user, ["contacts_assign"])
    ) {
      return false;
    }

    return true;
  };

  const getConsult = () => {
    return contactForEdit && !isEmpty(contactForEdit?.owner?.data)
      ? [
          {
            id: contactForEdit?.owner?.data?.id,
            title: `${contactForEdit?.owner?.data?.first_name} ${contactForEdit?.owner?.data?.last_name}`,
          },
        ]
      : [];
  };

  const SourceOptions = () => {
    if (isEmpty(sources)) {
      return [
        {
          id: "Call Center",
          title: "Call Center",
        },
      ];
    }
    return sources.map((source) => {
      return {
        id: source.id_name,
        title: source.name,
      };
    });
  };

  const maritalStatusOptions = () => {
    return [
      { id: "single", title: "Single" },
      { id: "married", title: "Married" },
      { id: "widowed", title: "Widowed" },
      { id: "divorced", title: "Divorced" },
      { id: "domestic-partnership", title: "Domestic Partnership" },
    ];
  };
  const [age, setAge] = useState(null);
  const [anchorElNew, setAnchorElNew] = useState(null);

  const [anchorElNewEmail, setAnchorElNewEmail] = useState(null);

  const handleClickNew = (event) => {
    setAnchorElNew(event.currentTarget);
  };

  const handleClickNewEmail = (event) => {
    setAnchorElNewEmail(event.currentTarget);
  };

  const handleCloseEmailNew = () => {
    setAnchorElNewEmail(null);
  };

  const handleCloseNew = () => {
    setAnchorElNew(null);
  };

  const [anchorElNewEmail2, setAnchorElNewEmail2] = useState(null);

  const handleClickNewEmail2 = (event) => {
    setAnchorElNewEmail2(event.currentTarget);
  };

  const handleCloseNewEmail2 = () => {
    setAnchorElNewEmail2(null);
  };

  const [anchorElNew2, setAnchorElNew2] = useState(null);

  const handleClickNew2 = (event) => {
    setAnchorElNew2(event.currentTarget);
  };

  const handleCloseNew2 = () => {
    setAnchorElNew2(null);
  };

  const saveExtraPhoneLabel = (phone) => {
    const payload = {
      value: phone.value,
      type_label: phone.type_label,
    };
    //  console.log("extraPhonesextraPhones", payload);
    const { data } = Api.updateContactExtraPhone(contactForEdit.id, payload);
  };
  const deleteExtraPhone = () => {
    const itemPhone = extraPhones[parseInt(anchorElNew.id)];
    if (itemPhone) {
      // console.log("itemPhoneitemPhone", itemPhone);
      if (itemPhone.id !== null) {
        const payload = {
          value: itemPhone.value,
          type_label: itemPhone.type_label,
        };
        //  console.log("extraPhonesextraPhones", payload);
        const { data } = Api.deleteContactExtraPhone(
          contactForEdit.id,
          payload
        );
      }
      setExtraPhones(
        extraPhones.filter((item) => {
          return item.type_label !== itemPhone.type_label;
        })
      );
    }
  };

  const saveExtraEmailLabel = (email) => {
    const payload = {
      value: email.value,
      type_label: email.type_label,
    };
    //  console.log("extraPhonesextraPhones", payload);
    const { data } = Api.updateContactExtraEmail(contactForEdit.id, payload);
  };
  const deleteExtraEmail = () => {
    const itemEmail = extraEmail[parseInt(anchorElNewEmail.id)];
    if (itemEmail) {
      if (itemEmail.id !== null) {
        const payload = {
          value: itemEmail.value,
          type_label: itemEmail.type_label,
        };

        const { data } = Api.deleteContactExtraEmail(
          contactForEdit.id,
          payload
        );
      }
      setExtraEmail(
        extraEmail.filter((item) => {
          return item.type_label !== itemEmail.type_label;
        })
      );
    }
  };

  const onSubmit = async (data) => {
    setErrorFlag(true);
    const contactData = formatContactData(data);
    await addOrEdit(contactData);
    getContact ? getContact() : "";
  };

  const formatContactData = (data) => {
    let contactData = {};
    let contactProperties = {};
    let extraPhonesA = {};
    let extraEmailA = {};
    Object.keys(data).forEach((key) => {
      const xk = key.slice(0, 3);
      if (xk !== "cf_" && xk !== "ep_" && xk !== "em_") {
        contactData[key] = data[key];
      } else if (xk == "ep_") {
        const fixedKey = key.slice(3, key.length);
        extraPhonesA[fixedKey] = data[key];
      } else if (xk == "em_") {
        const fixedKey = key.slice(3, key.length);
        extraEmailA[fixedKey] = data[key];
      } else {
        const fixedKey = key.slice(3, key.length);
        contactProperties[fixedKey] = data[key];
      }
    });
    contactData.properties = JSON.stringify(contactProperties);
    if (bmi) {
      contactData.bmi = bmi;
    }

    contactData.extraPhones = JSON.stringify(extraPhonesA);
    contactData.extraEmail = JSON.stringify(extraEmailA);

    return contactData;
  };

  const tagOptions = (tags) => {
    return tags.map((tag) => tag.tagname);
  };
  const renderCustomFields = () => {
    return customFields.map((field) => {
      return <div key={field.key}>{renderCustomField(field)}</div>;
    });
  };

  const getSources = async (orgID) => {
    const { data } = contactForEdit
      ? await Api.getAllSourcesDropdown(orgID)
      : await Api.getManualSourcesDropdown(orgID);

    setSources(data.data);
  };

  const getIDTypes = async () => {
    const { data } = await Api.getIDTypes();

    setIdTypes([...data]);
  };

  const getIdTypesOptions = () => {
    if (!idTypes) return [];
    return idTypes.map((type) => {
      return {
        id: type.id,
        title: type.name,
      };
    });
  };

  useEffect(() => {
    const orgID = authUser.organization_id;
    if (isEmpty(languages)) {
      dispatch(fetchLanguagesService(orgID));
    }
    getIDTypes();
    getSources(orgID);

    if (contactForEdit && contactForEdit.dob) {
      getAgeByDOB(moment(contactForEdit.dob).format("YYYY-MM-DD"));
    }
  }, []);

  useEffect(() => {
    if (Boolean(anchorElNew)) {
      setExtraPhonesLabel(extraPhones[parseInt(anchorElNew.id)].type_label);
    }
  }, [anchorElNew]);

  useEffect(() => {
    if (Boolean(anchorElNewEmail)) {
      setExtraEmailLabel(extraEmail[parseInt(anchorElNewEmail.id)].type_label);
    }
  }, [anchorElNewEmail]);

  useEffect(() => {
    if (!isEmpty(placePredictions)) {
      setPlacePredictionsLocal(placePredictions);
    }
  }, [placePredictions]);

  useEffect(() => {
    if (!isEmpty(contactForEdit?.owner?.data)) {
      setConsultants([
        {
          id: contactForEdit?.owner?.data?.id,
          title: `${contactForEdit?.owner?.data?.first_name} ${contactForEdit?.owner?.data?.last_name}`,
        },
        ...coordinators,
      ]);
    } else {
      setConsultants(coordinators);
    }
  }, [coordinators, contactForEdit]);

  useEffect(() => {
    try {
      if (completeAddress) {
        for (const component of completeAddress.address_components) {
          const componentType = component.types[0];

          switch (componentType) {
            case "postal_code": {
              setValue("zip", component.long_name, {
                shouldValidate: true,
              });
              break;
            }
            case "locality":
              setValue("city_field", component.long_name, {
                shouldValidate: true,
              });
              break;
            case "administrative_area_level_1": {
              setValue("state_field", component.long_name, {
                shouldValidate: true,
              });

              break;
            }
            case "country":
              setValue("country_field", component.long_name, {
                shouldValidate: true,
              });

              break;
          }
        }
        setValue("address", completeAddress.name, {
          shouldValidate: true,
        });

        setPlacePredictionsLocal([]);
      }
    } catch {
      console.error("completeAddress error");
    }
  }, [completeAddress]);

  const renderCustomField = (field) => {
    let defaultValue = "";
    if (!isEmpty(contactForEdit) && !isEmpty(contactForEdit.properties)) {
      const properties = JSON.parse(contactForEdit.properties);
      defaultValue = properties[field.key];
    }
    switch (field.type) {
      case "string":
        return (
          <>
            <Controller
              name={`cf_${field.key}`}
              control={control}
              defaultValue={defaultValue}
              as={<Controls.Input label={field.title} />}
              rules={{ required: field.required === 1 ? true : false }}
            />
            {errors[`cf_${field.key}`] && (
              <p className={classes.error}>{`${field.title} is required`}</p>
            )}
          </>
        );
        break;
      case "yes/no":
        return (
          <>
            <Controller
              name={`cf_${field.key}`}
              control={control}
              defaultValue={defaultValue}
              as={
                <Controls.RadioGroup
                  label={field.title}
                  items={[
                    {
                      id: "yes",
                      title: "YES",
                    },
                    {
                      id: "no",
                      title: "NO",
                    },
                  ]}
                />
              }
              rules={{ required: field.required === 1 ? true : false }}
            />
            {errors[`cf_${field.key}`] && (
              <p className={classes.error}>{`${field.title} is required`}</p>
            )}
          </>
        );
        break;
      case "money":
        return (
          <>
            <Controller
              name={`cf_${field.key}`}
              control={control}
              defaultValue={defaultValue}
              as={<Controls.InputMoney label={field.title} />}
              rules={{ required: field.required === 1 ? true : false }}
            />
            {errors[`cf_${field.key}`] && (
              <p className={classes.error}>{`${field.title} is required`}</p>
            )}
          </>
        );
        break;
      case "date/time":
        return (
          <>
            <Controller
              name={`cf_${field.key}`}
              control={control}
              defaultValue={defaultValue}
              as={<Controls.DateTimePicker label={field.title} />}
              rules={{ required: field.required === 1 ? true : false }}
            />
            {errors[`cf_${field.key}`] && (
              <p className={classes.error}>{`${field.title} is required`}</p>
            )}
          </>
        );
        break;
      case "date":
        return (
          <>
            <Controller
              name={`cf_${field.key}`}
              control={control}
              defaultValue={defaultValue}
              as={<Controls.DatePicker label={field.title} />}
              rules={{ required: field.required === 1 ? true : false }}
            />
            {errors[`cf_${field.key}`] && (
              <p className={classes.error}>{`${field.title} is required`}</p>
            )}
          </>
        );
        break;
      case "list":
        return (
          <>
            <Controller
              name={`cf_${field.key}`}
              control={control}
              defaultValue={defaultValue}
              as={({ onChange, value }) => (
                <Controls.Select
                  label={field.title}
                  options={getFieldSource(field.source)}
                  onChange={(value) => onChange(value)}
                  value={value}
                />
              )}
              rules={{ required: "field is required" }}
            />
            {errors[`cf_${field.key}`] && (
              <p className={classes.error}>{`${field.title} is required`}</p>
            )}
          </>
        );
        break;
      case "relation":
        return (
          <>
            <Controller
              name={`cf_${field.key}`}
              control={control}
              defaultValue={defaultValue}
              as={({ onChange, value }) => (
                <Controls.SelectModel
                  label={field.title}
                  modelid={field.source_model_id}
                  onChange={(value) => onChange(value)}
                  value={value}
                />
              )}
              rules={{ required: "field is required" }}
            />
            {errors[`cf_${field.key}`] && (
              <p className={classes.error}>{`${field.title} is required`}</p>
            )}
          </>
        );
        break;
      default:
        return (
          <>
            <Controller
              name={`cf_${field.key}`}
              control={control}
              defaultValue={defaultValue}
              as={<Controls.Input label={field.title} />}
              rules={{ required: field.required === 1 ? true : false }}
            />
            {errors[`cf_${field.key}`] && (
              <p className={classes.error}>{`${field.title} is required`}</p>
            )}
          </>
        );
    }
  };

  const getFieldSource = (source) => {
    const list = source ? JSON.parse(source) : [];
    return list.map((value) => {
      return {
        id: value.id,
        title: value.value,
      };
    });
  };
  const renderExtraEmail = () => {
    if (!contactForEdit) return null;
    return (
      <Box mb={1} mr={2}>
        <Grid container spacing={3}>
          <Grid item xs={10}>
            <Controller
              name="email"
              control={control}
              type="email"
              defaultValue={contactForEdit?.email || ""}
              as={
                <Controls.Input
                  label="Email"
                  disabled={
                    // (contactForEdit &&
                    //   !isEmpty(contactForEdit.fraudolent.data)) ||
                    readOnlyComponent ||
                    ((userIsCallCenterBasic ||
                      userIsCallCenterManager ||
                      userIsFrontDeskBasic ||
                      userIsFrontDeskManager ||
                      userIsSocialMediaBasic ||
                      userIsSocialMediaManager) &&
                      contactForEdit &&
                      !contactForEdit.editable &&
                      !userCanEditAll)
                  }
                />
              }
              rules={{
                pattern: {
                  value:
                    /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z]{2,8})+$/i,
                  message: "invalid email address",
                },
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <IconButton
              aria-label="addEmail"
              color="primary"
              disabled={
                // (contactForEdit && !isEmpty(contactForEdit.fraudolent.data)) ||
                extraEmail.length == 1 ||
                contactForEdit.email == null ||
                contactForEdit.email == "" ||
                readOnlyComponent ||
                ((userIsCallCenterBasic ||
                  userIsCallCenterManager ||
                  userIsFrontDeskBasic ||
                  userIsFrontDeskManager ||
                  userIsSocialMediaBasic ||
                  userIsSocialMediaManager) &&
                  contactForEdit &&
                  !contactForEdit.editable &&
                  !userCanEditAll)
              }
              onClick={() => {
                setExtraEmail([
                  ...extraEmail,
                  {
                    id: null,
                    type: "email",
                    type_label:
                      extraEmail.length == 0 ? "Second Email" : "Third Email",
                    value: "",
                  },
                ]);
              }}
            >
              <Iconify
                icon={"fluent:add-circle-32-filled"}
                width={25}
                height={25}
                style={{
                  color:
                    extraEmail.length < 1 &&
                    contactForEdit.email !== null &&
                    contactForEdit.email !== ""
                      ? "#009688"
                      : "#e0e0e0",
                }}
              />
            </IconButton>
          </Grid>
        </Grid>
        {extraEmail.length > 0 && (
          <Grid container spacing={3}>
            <Grid item xs={10}>
              {
                <Controller
                  name={`em_${extraEmail[0].type_label}`}
                  control={control}
                  type="email"
                  defaultValue={extraEmail[0]?.value || ""}
                  as={({ onChange, value, onBlur }) => (
                    <Controls.Input
                      label={extraEmail[0].type_label}
                      onChange={onChange}
                      onBlur={(e) => {
                        const email = {
                          ...extraEmail[0],
                          value: e.target.value,
                        };

                        let extraEmailsLocal = [...extraEmail];
                        extraEmailsLocal[0] = email;
                        setExtraEmail(extraEmailsLocal);
                      }}
                      value={value}
                      disabled={
                        // (contactForEdit &&
                        //   !isEmpty(contactForEdit.fraudolent.data)) ||
                        readOnlyComponent ||
                        ((userIsCallCenterBasic ||
                          userIsCallCenterManager ||
                          userIsFrontDeskBasic ||
                          userIsFrontDeskManager ||
                          userIsSocialMediaBasic ||
                          userIsSocialMediaManager) &&
                          contactForEdit &&
                          !contactForEdit.editable &&
                          !userCanEditAll)
                      }
                      rules={{
                        pattern: {
                          value:
                            /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z]{2,8})+$/i,
                          message: "invalid email address",
                        },
                      }}
                      helperText={
                        <div className={classes.error}>
                          {errors.email ? errors.email.message : ""}
                        </div>
                      }
                    />
                  )}
                />
              }
            </Grid>
            <Grid item xs={2}>
              <IconButton
                aria-label="menu"
                id={0}
                color="primary"
                onClick={(event) => {
                  handleClickNewEmail(event);
                }}
                disabled={
                  // (contactForEdit &&
                  //   !isEmpty(contactForEdit.fraudolent.data)) ||
                  readOnlyComponent ||
                  ((userIsCallCenterBasic ||
                    userIsCallCenterManager ||
                    userIsFrontDeskBasic ||
                    userIsFrontDeskManager ||
                    userIsSocialMediaBasic ||
                    userIsSocialMediaManager) &&
                    contactForEdit &&
                    !contactForEdit.editable &&
                    !userCanEditAll)
                }
              >
                <Iconify
                  icon={"charm:menu-kebab"}
                  width={25}
                  height={25}
                  style={{ color: "#009688" }}
                />
              </IconButton>
            </Grid>
          </Grid>
        )}
      </Box>
    );
  };

  const onUnmarkPhoneAsInvalid = async () => {
    try {
      setConfirmDialogLoading(true);
      await Api.unmarkPhoneNumberAsInvalidForMessaging(contactForEdit.phone);

      setNotify({
        isOpen: true,
        message: "Successfully unmarked!",
        type: "success",
      });

      if (setContactForEdit) {
        setContactForEdit({
          ...contactForEdit,
          ...{ invalid_phone_number_for_messaging: false },
        });
      }
    } catch (err) {
      if (
        (err.status == 401 || err.status == 422) &&
        err.data &&
        err.data.error
      ) {
        setNotify({
          isOpen: true,
          message: err.data.error,
          type: "error",
        });
      } else {
        setNotify({
          isOpen: true,
          message: "Something went wrong!",
          type: "error",
        });
      }
    } finally {
      setConfirmDialogLoading(false);
      setConfirmDialog({ isOpen: false, title: "", subTitle: "" });
    }
  };

  const renderExtraPhones = () => {
    if (!contactForEdit) return null;

    return (
      <Box mb={1} mr={2}>
        <Grid container spacing={3}>
          <Grid item xs={10}>
            <Controller
              name="phone"
              control={control}
              defaultValue={contactForEdit?.phone || ""}
              as={({ onChange, value, onBlur }) => (
                <Controls.Input
                  label="Main Phone"
                  type="number"
                  onChange={onChange}
                  value={value}
                  disabled={
                    // (contactForEdit &&
                    //   !isEmpty(contactForEdit.fraudolent.data)) ||
                    readOnlyComponent ||
                    ((userIsCallCenterBasic ||
                      userIsCallCenterManager ||
                      userIsFrontDeskBasic ||
                      userIsFrontDeskManager ||
                      userIsSocialMediaBasic ||
                      userIsSocialMediaManager) &&
                      contactForEdit &&
                      !contactForEdit.editable &&
                      !userCanEditAll)
                  }
                  onBlur={(e) => {
                    setPhone(e.target.value);
                  }}
                />
              )}
            />
            {contactForEdit &&
            contactForEdit.invalid_phone_number_for_messaging ? (
              <div
                style={{ display: "flex", alignItems: "center", marginTop: 4 }}
              >
                <div className={classes.phoneWarningContainer}>
                  <Iconify
                    icon="ic:round-warning"
                    className={classes.phoneWarningIcon}
                  />
                  <Typography className={classes.phoneWarningText}>
                    Phone not valid for messaging
                  </Typography>
                </div>
                {userCanUnmarkPhoneAsInvalid ? (
                  <Tooltip title="Unmark phone as invalid">
                    <span>
                      <IconButton
                        onClick={() => {
                          setConfirmDialog({
                            isOpen: true,
                            title:
                              "Are you sure to unmark the phone " +
                              contactForEdit.phone +
                              " as invalid?",
                            subTitle: "You can't undo this operation",
                            onConfirm: () => onUnmarkPhoneAsInvalid(),
                          });
                        }}
                        style={{ padding: 4, marginLeft: 2 }}
                      >
                        <Iconify
                          style={{ color: "#527493", width: 20, height: 20 }}
                          icon="basil:phone-off-solid"
                        />
                      </IconButton>{" "}
                    </span>
                  </Tooltip>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
            {contactForEdit && contactForEdit.is_phone_invalid_for_voice ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: 4,
                }}
              >
                <div className={classes.phoneWarningContainer}>
                  <Iconify
                    icon="ic:round-warning"
                    className={classes.phoneWarningIcon}
                  />
                  <Typography className={classes.phoneWarningText}>
                    Phone not valid for calls
                  </Typography>
                </div>
              </div>
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={2}>
            <IconButton
              aria-label="addPhone"
              color="primary"
              disabled={
                // (contactForEdit && !isEmpty(contactForEdit.fraudolent.data)) ||
                extraPhones.length > 1 ||
                contactForEdit.phone == null ||
                contactForEdit.phone == "" ||
                readOnlyComponent ||
                ((userIsCallCenterBasic ||
                  userIsCallCenterManager ||
                  userIsFrontDeskBasic ||
                  userIsFrontDeskManager ||
                  userIsSocialMediaBasic ||
                  userIsSocialMediaManager) &&
                  contactForEdit &&
                  !contactForEdit.editable &&
                  !userCanEditAll)
              }
              onClick={() => {
                setExtraPhones([
                  ...extraPhones,
                  {
                    id: null,
                    type: "phone",
                    type_label:
                      extraPhones.length == 0 ? "Second Phone" : "Third Phone",
                    value: "",
                  },
                ]);
              }}
            >
              <Iconify
                icon={"fluent:add-circle-32-filled"}
                width={25}
                height={25}
                style={{
                  color:
                    extraPhones.length < 2 &&
                    contactForEdit.phone !== null &&
                    contactForEdit.phone !== ""
                      ? "#009688"
                      : "#e0e0e0",
                }}
              />
            </IconButton>
          </Grid>
        </Grid>
        {extraPhones.length > 0 && (
          <Grid container spacing={3}>
            <Grid item xs={10}>
              {
                <Controller
                  name={`ep_${extraPhones[0].type_label}`}
                  control={control}
                  defaultValue={extraPhones[0].value || ""}
                  as={({ onChange, value, onBlur }) => (
                    <Controls.Input
                      label={extraPhones[0].type_label}
                      type="number"
                      onChange={onChange}
                      onBlur={(e) => {
                        const phone = {
                          ...extraPhones[0],
                          value: e.target.value,
                        };

                        let extraPhonesLocal = [...extraPhones];
                        extraPhonesLocal[0] = phone;
                        setExtraPhones(extraPhonesLocal);
                      }}
                      value={value}
                      disabled={
                        // (contactForEdit &&
                        //   !isEmpty(contactForEdit.fraudolent.data)) ||
                        readOnlyComponent ||
                        ((userIsCallCenterBasic ||
                          userIsCallCenterManager ||
                          userIsFrontDeskBasic ||
                          userIsFrontDeskManager ||
                          userIsSocialMediaBasic ||
                          userIsSocialMediaManager) &&
                          contactForEdit &&
                          !contactForEdit.editable &&
                          !userCanEditAll)
                      }
                    />
                  )}
                />
              }
            </Grid>
            <Grid item xs={2}>
              <IconButton
                aria-label="menu"
                id={0}
                color="primary"
                onClick={(event) => {
                  handleClickNew(event);
                }}
                disabled={
                  // (contactForEdit &&
                  //   !isEmpty(contactForEdit.fraudolent.data)) ||
                  readOnlyComponent ||
                  ((userIsCallCenterBasic ||
                    userIsCallCenterManager ||
                    userIsFrontDeskBasic ||
                    userIsFrontDeskManager ||
                    userIsSocialMediaBasic ||
                    userIsSocialMediaManager) &&
                    contactForEdit &&
                    !contactForEdit.editable &&
                    !userCanEditAll)
                }
              >
                <Iconify
                  icon={"charm:menu-kebab"}
                  width={25}
                  height={25}
                  style={{ color: "#009688" }}
                />
              </IconButton>
            </Grid>
          </Grid>
        )}
        {extraPhones.length > 1 && (
          <Grid container spacing={3}>
            <Grid item xs={10}>
              {
                <Controller
                  name={`ep_${extraPhones[1].type_label}`}
                  control={control}
                  defaultValue={extraPhones[1].value || ""}
                  as={({ onChange, value, onBlur }) => (
                    <Controls.Input
                      label={extraPhones[1].type_label}
                      type="number"
                      onChange={onChange}
                      onBlur={(e) => {
                        // setPhone(e.target.value);
                        const phone = {
                          ...extraPhones[1],
                          value: e.target.value,
                        };

                        let extraPhonesLocal = [...extraPhones];
                        extraPhonesLocal[1] = phone;
                        setExtraPhones(extraPhonesLocal);
                      }}
                      value={value}
                      disabled={
                        // (contactForEdit &&
                        //   !isEmpty(contactForEdit.fraudolent.data)) ||
                        readOnlyComponent ||
                        ((userIsCallCenterBasic ||
                          userIsCallCenterManager ||
                          userIsFrontDeskBasic ||
                          userIsFrontDeskManager ||
                          userIsSocialMediaBasic ||
                          userIsSocialMediaManager) &&
                          contactForEdit &&
                          !contactForEdit.editable &&
                          !userCanEditAll)
                      }
                    />
                  )}
                />
              }
            </Grid>
            <Grid item xs={2}>
              <IconButton
                aria-label="menu"
                id={1}
                color="primary"
                onClick={(event) => {
                  handleClickNew(event);
                }}
                disabled={
                  // (contactForEdit &&
                  //   !isEmpty(contactForEdit.fraudolent.data)) ||
                  readOnlyComponent ||
                  ((userIsCallCenterBasic ||
                    userIsCallCenterManager ||
                    userIsFrontDeskBasic ||
                    userIsFrontDeskManager ||
                    userIsSocialMediaBasic ||
                    userIsSocialMediaManager) &&
                    contactForEdit &&
                    !contactForEdit.editable &&
                    !userCanEditAll)
                }
              >
                <Iconify
                  icon={"charm:menu-kebab"}
                  width={25}
                  height={25}
                  style={{ color: "#009688" }}
                />
              </IconButton>
            </Grid>
          </Grid>
        )}

        <Menu
          id="menu-item1"
          anchorEl={anchorElNew}
          keepMounted
          open={Boolean(anchorElNew)}
          onClose={handleCloseNew}
          PaperProps={{
            style: {
              marginLeft: 10,
              backgroundColor: "#e0f2f1",
            },
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem
            key={"EditLabel"}
            onClick={(event) => {
              handleClickNew2(event);
            }}
            style={{
              marginLeft: 10,
            }}
          >
            <ListItemIcon>
              <Iconify
                icon={"fluent:text-edit-style-20-filled"}
                style={{ color: "#00695c" }}
                width={20}
                height={20}
              />
            </ListItemIcon>
            <Typography
              variant="body2"
              style={{
                color: "#00695c",
                fontWeight: 600,
              }}
            >
              {"Edit Label"}
            </Typography>
          </MenuItem>
          <MenuItem
            key={"Delete"}
            onClick={() => {
              deleteExtraPhone();
              handleCloseNew();
            }}
            style={{
              marginLeft: 10,
            }}
          >
            <ListItemIcon>
              <Iconify
                icon={"carbon:phone-block-filled"}
                style={{ color: "#00695c" }}
                width={20}
                height={20}
              />
            </ListItemIcon>
            <Typography
              variant="body2"
              style={{
                color: "#00695c",
                fontWeight: 600,
              }}
            >
              {"Delete"}
            </Typography>
          </MenuItem>
        </Menu>

        <Menu
          id="menu-item"
          anchorEl={anchorElNew2}
          keepMounted
          open={Boolean(anchorElNew2)}
          onClose={handleCloseNew2}
          PaperProps={{
            style: {
              marginLeft: 3,
              backgroundColor: "#e0f2f1",
            },
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
        >
          <List>
            <ListItemAvatar style={{ margin: 15 }}>
              <TextField
                variant="outlined"
                label={"Label"}
                value={extraPhonesLabel}
                onChange={(e) => {
                  setExtraPhonesLabel(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                onBlur={(e) => {
                  const phone = {
                    ...extraPhones[anchorElNew.id],
                    type_label: e.target.value,
                  };
                  if (phone.id) {
                    saveExtraPhoneLabel(phone);
                  }
                  let extraPhonesLocal = [...extraPhones];
                  extraPhonesLocal[anchorElNew.id] = phone;
                  setExtraPhones(extraPhonesLocal);
                  handleCloseNew2();
                  handleCloseNew();
                  setExtraPhonesLabel(null);
                }}
              />
            </ListItemAvatar>
          </List>
        </Menu>

        <Menu
          id="menu-item-email1"
          anchorEl={anchorElNewEmail}
          keepMounted
          open={Boolean(anchorElNewEmail)}
          onClose={handleCloseEmailNew}
          PaperProps={{
            style: {
              marginLeft: 10,
              backgroundColor: "#e0f2f1",
            },
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem
            key={"EditLabelEmail"}
            onClick={(event) => {
              handleClickNewEmail2(event);
            }}
            style={{
              marginLeft: 10,
            }}
          >
            <ListItemIcon>
              <Iconify
                icon={"fluent:text-edit-style-20-filled"}
                style={{ color: "#00695c" }}
                width={20}
                height={20}
              />
            </ListItemIcon>
            <Typography
              variant="body2"
              style={{
                color: "#00695c",
                fontWeight: 600,
              }}
            >
              {"Edit Label"}
            </Typography>
          </MenuItem>
          <MenuItem
            key={"Delete"}
            onClick={() => {
              deleteExtraEmail();
              handleCloseEmailNew();
            }}
            style={{
              marginLeft: 10,
            }}
          >
            <ListItemIcon>
              <Iconify
                icon={"carbon:phone-block-filled"}
                style={{ color: "#00695c" }}
                width={20}
                height={20}
              />
            </ListItemIcon>
            <Typography
              variant="body2"
              style={{
                color: "#00695c",
                fontWeight: 600,
              }}
            >
              {"Delete"}
            </Typography>
          </MenuItem>
        </Menu>

        <Menu
          id="menu-item-emailNew"
          anchorEl={anchorElNewEmail2}
          keepMounted
          open={Boolean(anchorElNewEmail2)}
          onClose={handleCloseNewEmail2}
          PaperProps={{
            style: {
              marginLeft: 3,
              backgroundColor: "#e0f2f1",
            },
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
        >
          <List>
            <ListItemAvatar style={{ margin: 15 }}>
              <TextField
                variant="outlined"
                label={"Label"}
                value={extraEmailLabel}
                onChange={(e) => {
                  setExtraEmailLabel(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                onBlur={(e) => {
                  const email = {
                    ...extraEmail[anchorElNewEmail.id],
                    type_label: e.target.value,
                  };
                  if (email.id) {
                    saveExtraEmailLabel(email);
                  }
                  let extraEmailLocal = [...extraEmail];
                  extraEmailLocal[anchorElNewEmail.id] = email;
                  setExtraEmail(extraEmailLocal);
                  handleCloseEmailNew();
                  handleCloseNewEmail2();
                  setExtraEmailLabel(null);
                }}
              />
            </ListItemAvatar>
          </List>
        </Menu>
      </Box>
    );

    // return contactForEdit.multifields.data
    //   .filter((field) => {
    //     return field.type == "phone";
    //   })
    //   .map((field) => {
    //     // return `${field.value}, `;
    //     return (
    //       <Controller
    //         name={field.type}
    //         control={control}
    //         defaultValue={field.value || ""}
    //         as={({ onChange, value, onBlur }) => (
    //           <Controls.Input
    //             label="Main Phone"
    //             type="number"
    //             onChange={onChange}
    //             value={value}
    //             disabled={
    //               readOnlyComponent ||
    //               ((userIsCallCenterBasic ||
    //                 userIsCallCenterManager ||
    //                 userIsFrontDeskBasic ||
    //                 userIsFrontDeskManager ||
    //                 userIsSocialMediaBasic ||
    //                 userIsSocialMediaManager) &&
    //                 contactForEdit &&
    //                       !contactForEdit.editable &&
    //                 !userCanEditAll)
    //             }
    //             onBlur={(e) => {
    //               setPhone(e.target.value);
    //             }}
    //           />
    //         )}
    //       />
    //     );
    //   });
  };
  const renderExtraEmails = () => {
    if (!contactForEdit) return null;
    return contactForEdit.multifields.data
      .filter((field) => {
        return field.type == "email";
      })
      .map((field) => {
        return `${field.value}, `;
      });
  };

  const openExtraPopupType = (type) => {
    setExtraType(type);
    setOpenExtraPopup(true);
  };

  const addOrEditExtra = async (payload) => {
    payload.type = extraType;
    onSaveExtraField(payload);
    setOpenExtraPopup(false);
  };

  useEffect(() => {
    if (errorFlag)
      setTimeout(() => {
        setErrorFlag(false);
      }, 20000);
  }, [errorFlag]);

  useEffect(() => {
    if (contactForEdit) {
      setExtraPhones(
        contactForEdit.multifields.data.filter((field) => {
          return field.type == "phone";
        })
      );
      setExtraEmail(
        contactForEdit.multifields.data.filter((field) => {
          return field.type == "email";
        })
      );
    }
  }, [contactForEdit]);

  const getAgeByDOB = (dob) => {
    if (dob) {
      const current_year = moment(Date.now()).year();
      const dob_age = moment(dob).year();

      var age_number = current_year - dob_age;

      if (
        !moment(moment(Date.now()).format("MM-DD")).isAfter(
          moment(dob).format("MM-DD")
        ) &&
        !moment(moment(Date.now()).format("MM-DD")).isSame(
          moment(dob).format("MM-DD")
        )
      ) {
        age_number = age_number - 1;
      }

      setAge(age_number);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <FormControl>
        <Paper className={classes.card}>
          <Grid
            container
            spacing={2}
            style={{
              justifyContent: "center",
              alignItems: "center",
              marginLeft: 25,
            }}
          >
            <Grid item xs={6}>
              <ListItemText
                primary="* Required field: New contact"
                style={{ fontStyle: "italic" }}
              />
            </Grid>
            <Grid item xs={6}>
              <ListItemText
                primary="** Required field: Quote / Invoice"
                style={{ fontStyle: "italic" }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={4} style={{ marginTop: 10 }}>
            <Grid item xs={!authUser.organization.data.contact_simple ? 6 : 12}>
              <ListItem style={{ padding: 0 }}>
                <ListItemIcon style={{ margin: 0, minWidth: 45 }}>
                  <Avatar style={{ backgroundColor: "#e0f2f1" }}>
                    <Iconify
                      icon={"fluent:person-48-filled"}
                      width={20}
                      height={20}
                      style={{ color: "#009688" }}
                    />
                  </Avatar>
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Box
                      sx={{
                        display: "grid",
                        columnGap: 10,
                        rowGap: 10,
                        gridTemplateColumns: {
                          xs: "repeat(1, 1fr)",
                          sm: "repeat(2, 1fr)",
                        },
                      }}
                      mb={1}
                      mr={2}
                    >
                      <Controller
                        name="fname"
                        type="text"
                        control={control}
                        defaultValue={contactForEdit?.fname || ""}
                        as={
                          <Controls.Input
                            label="* First name"
                            disabled={
                              // (contactForEdit &&
                              //   !isEmpty(contactForEdit.fraudolent.data)) ||
                              !userCanEdit ||
                              (authUser.is_coordinator &&
                                contactForEdit &&
                                !contactForEdit.editable &&
                                !userCanEditAll) ||
                              (userIsSalesManager &&
                                contactForEdit &&
                                !contactForEdit.editable &&
                                !userCanEditAll) ||
                              ((userIsCallCenterBasic ||
                                userIsCallCenterManager ||
                                userIsFrontDeskBasic ||
                                userIsFrontDeskManager ||
                                userIsSocialMediaBasic ||
                                userIsSocialMediaManager) &&
                                contactForEdit &&
                                contactForEdit.building_id !=
                                  authUser.building_id &&
                                !userCanEditAll)
                            }
                          />
                        }
                        rules={{
                          required: "Contact first name is required",
                          pattern: {
                            value: /^[a-zA-Z][a-zA-Z\s]*$/i,
                            message:
                              "Contact's first name can only accept characters",
                          },
                        }}
                        helperText={
                          <div className={classes.error}>
                            {errors.fname ? errors.fname.message : ""}
                          </div>
                        }
                      />

                      <Controller
                        name="lname"
                        type="text"
                        control={control}
                        defaultValue={contactForEdit?.lname || ""}
                        as={
                          <Controls.Input
                            label="* Last name"
                            disabled={
                              // (contactForEdit &&
                              //   !isEmpty(contactForEdit.fraudolent.data)) ||
                              !userCanEdit ||
                              (authUser.is_coordinator &&
                                contactForEdit &&
                                !contactForEdit.editable &&
                                !userCanEditAll) ||
                              (userIsSalesManager &&
                                contactForEdit &&
                                !contactForEdit.editable &&
                                !userCanEditAll) ||
                              ((userIsCallCenterBasic ||
                                userIsCallCenterManager ||
                                userIsFrontDeskBasic ||
                                userIsFrontDeskManager ||
                                userIsSocialMediaBasic ||
                                userIsSocialMediaManager) &&
                                contactForEdit &&
                                contactForEdit.building_id !=
                                  authUser.building_id &&
                                !userCanEditAll)
                            }
                          />
                        }
                        rules={{
                          required: "Contact last name is required",
                          pattern: {
                            value: /^[a-zA-Z][a-zA-Z\s]*$/i,
                            message:
                              "Contact's last name can only accept characters",
                          },
                        }}
                        helperText={
                          <div className={classes.error}>
                            {errors.lname ? errors.lname.message : ""}
                          </div>
                        }
                      />
                    </Box>
                  }
                />
              </ListItem>
              <ListItem style={{ padding: 0 }}>
                <ListItemIcon style={{ margin: 0, minWidth: 45 }}>
                  <Avatar style={{ backgroundColor: "#e0f2f1" }}>
                    <Iconify
                      icon={"ic:round-email"}
                      width={25}
                      height={25}
                      style={{ color: "#009688" }}
                    />
                  </Avatar>
                </ListItemIcon>
                <ListItemText primary={renderExtraEmail()} />
              </ListItem>
              <div
                style={{ margin: 3, marginLeft: "12%" }}
                className={classes.error}
              >
                {errors.email
                  ? errors.email.message
                  : contactForEdit && !contactForEdit.email
                  ? "***Please contact the patient to ask for email***"
                  : ""}
              </div>
              <ListItem style={{ padding: 0 }}>
                <ListItemIcon style={{ margin: 0, minWidth: 45 }}>
                  <Avatar style={{ backgroundColor: "#e0f2f1" }}>
                    <Iconify
                      icon={"fa:phone"}
                      width={25}
                      height={25}
                      style={{ color: "#009688" }}
                    />
                  </Avatar>
                </ListItemIcon>
                <ListItemText primary={renderExtraPhones()} />
              </ListItem>
              <ListItem style={{ padding: 0 }}>
                <ListItemIcon style={{ margin: 0, minWidth: 45 }}>
                  <Avatar style={{ backgroundColor: "#e0f2f1" }}>
                    <Iconify
                      icon={"vaadin:date-input"}
                      width={25}
                      height={25}
                      style={{ color: "#009688" }}
                    />
                  </Avatar>
                </ListItemIcon>
                <Grid container>
                  <Grid item xs={age != null ? 8 : 12}>
                    <ListItemText
                      primary={
                        <Controller
                          name="dob"
                          control={control}
                          defaultValue={contactForEdit?.dob || null}
                          as={({ onChange, value }) => (
                            <Controls.DatePicker
                              label="** Date Of Birth"
                              disabled={
                                // (contactForEdit &&
                                //   !isEmpty(contactForEdit.fraudolent.data)) ||
                                readOnlyComponent ||
                                userIsCallCenterBasic ||
                                userIsCallCenterManager ||
                                userIsFrontDeskBasic ||
                                userIsFrontDeskManager ||
                                userIsSocialMediaBasic ||
                                userIsSocialMediaManager
                              }
                              onChange={(date) => {
                                getAgeByDOB(
                                  date && date != "" && moment(date).isValid()
                                    ? date.format("YYYY-MM-DD")
                                    : null
                                );
                                onChange(date ? date.format("YYYY-MM-DD") : "");
                              }}
                              value={value}
                            />
                          )}
                        />
                      }
                    />
                  </Grid>

                  {age != null ? (
                    <Grid item xs={4}>
                      <Typography
                        style={{
                          padding: "5px 10px",
                          background: "#bcdbf5",
                          borderRadius: "20px",
                          fontSize: 15,
                          fontWeight: 500,
                          maxWidth: "fit-content",
                          margin: 10,
                          marginInlineStart: "auto",
                        }}
                      >
                        {" "}
                        {age != null ? `Age: ${age}` : ""}
                      </Typography>
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
              </ListItem>
              {!authUser.organization.data.contact_simple && (
                <ListItem style={{ padding: 0 }}>
                  <ListItemIcon style={{ margin: 0, minWidth: 45 }}>
                    <Avatar style={{ backgroundColor: "#e0f2f1" }}>
                      <Iconify
                        icon={"healthicons:weight"}
                        width={25}
                        height={25}
                        style={{ color: "#009688" }}
                      />
                    </Avatar>
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Box
                        sx={{
                          display: "grid",
                          columnGap: 10,
                          rowGap: 10,
                          gridTemplateColumns: {
                            xs: "repeat(1, 1fr)",
                            sm: "repeat(2, 1fr)",
                          },
                        }}
                        mb={1}
                        mr={2}
                      >
                        <Controller
                          name="weight"
                          type="number"
                          control={control}
                          defaultValue={contactForEdit?.weight || ""}
                          as={
                            <Controls.Input
                              label="** Weight - lb"
                              disabled={
                                // (contactForEdit &&
                                //   !isEmpty(contactForEdit.fraudolent.data)) ||
                                readOnlyComponent ||
                                userIsCallCenterBasic ||
                                userIsCallCenterManager ||
                                userIsFrontDeskBasic ||
                                userIsFrontDeskManager ||
                                userIsSocialMediaBasic ||
                                userIsSocialMediaManager
                              }
                            />
                          }
                        />
                        <Controller
                          name="height"
                          type="number"
                          control={control}
                          defaultValue={contactForEdit?.height || ""}
                          as={
                            <Controls.Input
                              label="** Height - ft.in"
                              disabled={
                                // (contactForEdit &&
                                //   !isEmpty(contactForEdit.fraudolent.data)) ||
                                readOnlyComponent ||
                                userIsCallCenterBasic ||
                                userIsCallCenterManager ||
                                userIsFrontDeskBasic ||
                                userIsFrontDeskManager ||
                                userIsSocialMediaBasic ||
                                userIsSocialMediaManager
                              }
                            />
                          }
                        />
                      </Box>
                    }
                  />
                </ListItem>
              )}
              {!authUser.organization.data.contact_simple && (
                <ListItem style={{ padding: 0 }}>
                  <ListItemIcon style={{ margin: 0, minWidth: 45 }}>
                    <Avatar style={{ backgroundColor: "#e0f2f1" }}>
                      <Iconify
                        icon={"fa-solid:id-card-alt"}
                        width={25}
                        height={25}
                        style={{ color: "#009688" }}
                      />
                    </Avatar>
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Box
                        sx={{
                          display: "grid",
                          columnGap: 10,
                          rowGap: 10,
                          gridTemplateColumns: {
                            xs: "repeat(1, 1fr)",
                            sm: "repeat(2, 1fr)",
                          },
                        }}
                        mb={1}
                        mr={2}
                      >
                        <Controller
                          name="id_number"
                          control={control}
                          defaultValue={contactForEdit?.id_number || ""}
                          as={
                            <Controls.Input
                              label="ID Number"
                              disabled={
                                // (contactForEdit &&
                                //   !isEmpty(contactForEdit.fraudolent.data)) ||
                                readOnlyComponent ||
                                userIsCallCenterBasic ||
                                userIsCallCenterManager ||
                                userIsFrontDeskBasic ||
                                userIsFrontDeskManager ||
                                userIsSocialMediaBasic ||
                                userIsSocialMediaManager
                              }
                            />
                          }
                        />
                        <Controller
                          name="id_type"
                          control={control}
                          defaultValue={contactForEdit?.id_type || ""}
                          as={({ onChange, value }) => (
                            <Controls.Select
                              label="ID Type"
                              disabled={
                                // (contactForEdit &&
                                //   !isEmpty(contactForEdit.fraudolent.data)) ||
                                readOnlyComponent ||
                                userIsCallCenterBasic ||
                                userIsCallCenterManager ||
                                userIsFrontDeskBasic ||
                                userIsFrontDeskManager ||
                                userIsSocialMediaBasic ||
                                userIsSocialMediaManager
                              }
                              options={getIdTypesOptions()}
                              value={value}
                              onChange={(value) => onChange(value)}
                            />
                          )}
                        />
                      </Box>
                    }
                  />
                </ListItem>
              )}
              <Box
                sx={{
                  display: "grid",
                  columnGap: 10,
                  rowGap: 10,
                  gridTemplateColumns: {
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(1, 1fr)",
                  },
                }}
                mb={1}
                mr={2}
              >
                {!authUser.organization.data.contact_simple && (
                  <ListItem style={{ padding: 0 }}>
                    <ListItemIcon style={{ margin: 0, minWidth: 45 }}>
                      <Avatar style={{ backgroundColor: "#e0f2f1" }}>
                        <Iconify
                          icon={"fluent:form-48-filled"}
                          width={25}
                          height={25}
                          style={{ color: "#009688" }}
                        />
                      </Avatar>
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Controller
                          name="description"
                          control={control}
                          defaultValue={contactForEdit?.description || ""}
                          as={
                            <Controls.Textarea
                              value={description}
                              disabled={
                                // (contactForEdit &&
                                //   !isEmpty(contactForEdit.fraudolent.data)) ||
                                readOnlyComponent ||
                                userIsCallCenterBasic ||
                                userIsCallCenterManager ||
                                userIsFrontDeskBasic ||
                                userIsFrontDeskManager ||
                                userIsSocialMediaBasic ||
                                userIsSocialMediaManager
                              }
                              label="Description"
                              rows={
                                extraPhones.length + extraEmail.length == 1
                                  ? 4
                                  : extraPhones.length + extraEmail.length == 2
                                  ? 3
                                  : extraPhones.length + extraEmail.length > 2
                                  ? 1
                                  : 5
                              }
                              onChange={(value) => {
                                setDescription(value.target.value);
                              }}
                            />
                          }
                        />
                      }
                    />
                  </ListItem>
                )}
                {!authUser.organization.data.contact_simple && (
                  <ListItem style={{ padding: 0 }}>
                    <ListItemIcon style={{ margin: 0, minWidth: 45 }}>
                      <Avatar style={{ backgroundColor: "#e0f2f1" }}>
                        <Iconify
                          icon={"pajamas:status-health"}
                          width={25}
                          height={25}
                          style={{ color: "#009688" }}
                        />
                      </Avatar>
                    </ListItemIcon>

                    <ListItemText
                      primary={
                        <Controller
                          name="medical_conditions"
                          control={control}
                          defaultValue={
                            contactForEdit?.medical_conditions || ""
                          }
                          as={
                            <Controls.Textarea
                              label="** Medical Conditions"
                              rows={
                                extraPhones.length + extraEmail.length == 1
                                  ? 2
                                  : extraPhones.length + extraEmail.length >= 2
                                  ? 2
                                  : 4
                              }
                              disabled={
                                // (contactForEdit &&
                                //   !isEmpty(contactForEdit.fraudolent.data)) ||
                                readOnlyComponent ||
                                userIsCallCenterBasic ||
                                userIsCallCenterManager ||
                                userIsFrontDeskBasic ||
                                userIsFrontDeskManager ||
                                userIsSocialMediaBasic ||
                                userIsSocialMediaManager
                              }
                            />
                          }
                        />
                      }
                    />
                  </ListItem>
                )}
                {!authUser.organization.data.contact_simple && (
                  <ListItem style={{ padding: 0, marginTop: 0 }}>
                    <ListItemIcon style={{ margin: 0, minWidth: 45 }}>
                      <Avatar style={{ backgroundColor: "#e0f2f1" }}>
                        <Iconify
                          icon={"healthicons:health-worker-form"}
                          width={25}
                          height={25}
                          style={{ color: "#009688" }}
                        />
                      </Avatar>
                    </ListItemIcon>
                    <ListItemText
                      style={{}}
                      primary={
                        <Controller
                          name="surgical_history"
                          control={control}
                          defaultValue={contactForEdit?.surgical_history || ""}
                          as={
                            <Controls.Textarea
                              label="** Surgical History"
                              rows={
                                extraPhones.length + extraEmail.length == 1
                                  ? 2
                                  : extraPhones.length + extraEmail.length >= 2
                                  ? 1
                                  : 4
                              }
                              disabled={
                                // (contactForEdit &&
                                //   !isEmpty(contactForEdit.fraudolent.data)) ||
                                readOnlyComponent ||
                                userIsCallCenterBasic ||
                                userIsCallCenterManager ||
                                userIsFrontDeskBasic ||
                                userIsFrontDeskManager ||
                                userIsSocialMediaBasic ||
                                userIsSocialMediaManager
                              }
                            />
                          }
                        />
                      }
                    />
                  </ListItem>
                )}
              </Box>
            </Grid>

            <Divider
              orientation="vertical"
              flexItem
              style={{ marginRight: "-2px" }}
            />
            {!authUser.organization.data.contact_simple && (
              <Grid
                item
                xs={!authUser.organization.data.contact_simple ? 6 : 12}
                // style={{ marginLeft: 1 }}
              >
                <Box
                  sx={{
                    display: "grid",
                    columnGap: 5,
                    rowGap: 5,
                    gridTemplateColumns: {
                      xs: "repeat(1, 1fr)",
                      sm: "repeat(1, 1fr)",
                    },
                  }}
                  mb={2}
                  mr={2}
                >
                  {userBuilding &&
                    !authUser.organization.data.contact_simple && (
                      <ListItem style={{ padding: 0 }}>
                        <ListItemIcon style={{ margin: 0, minWidth: 45 }}>
                          <Avatar style={{ backgroundColor: "#e0f2f1" }}>
                            <Iconify
                              icon={"fa6-solid:building"}
                              width={25}
                              height={25}
                              style={{ color: "#009688" }}
                            />
                          </Avatar>
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Controller
                              name="building_id"
                              control={control}
                              defaultValue={contactForEdit?.building_id || ""}
                              as={({ onChange, value }) => (
                                <Controls.Select
                                  label="Building"
                                  disabled={
                                    // (contactForEdit &&
                                    //   !isEmpty(
                                    //     contactForEdit.fraudolent.data
                                    //   )) ||
                                    readOnlyComponent ||
                                    (contactForEdit &&
                                      (userIsCallCenterBasic ||
                                        userIsFrontDeskBasic ||
                                        userIsSocialMediaBasic ||
                                        userIsSocialMediaManager))
                                  }
                                  options={buildings || []}
                                  onChange={(value) => {
                                    onChange(value);
                                    updateUserData(value.target.value);
                                  }}
                                  value={value}
                                />
                              )}
                            />
                          }
                        />
                      </ListItem>
                    )}
                  {userCanAssignLead() ||
                  userIsCallCenterBasic ||
                  userIsCallCenterManager ||
                  userIsFrontDeskBasic ||
                  userIsFrontDeskManager ||
                  userIsSocialMediaBasic ||
                  userIsSocialMediaManager ? (
                    <ListItem style={{ padding: 0 }}>
                      <ListItemIcon style={{ margin: 0, minWidth: 45 }}>
                        <Avatar style={{ backgroundColor: "#e0f2f1" }}>
                          <Iconify
                            icon={"bi:person-badge-fill"}
                            width={25}
                            height={25}
                            style={{ color: "#009688" }}
                          />
                        </Avatar>
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Controller
                            name="user_id"
                            control={control}
                            defaultValue={contactForEdit?.user_id || ""}
                            as={({ onChange, value }) => (
                              <Controls.Select
                                label="Consultant"
                                disabled={
                                  !userCanEdit ||
                                  (authUser.is_coordinator &&
                                    contactForEdit &&
                                    !contactForEdit.editable &&
                                    !userCanEditAll) ||
                                  (userIsSalesManager &&
                                    contactForEdit &&
                                    !contactForEdit.editable &&
                                    !userCanEditAll) ||
                                  ((userIsCallCenterBasic ||
                                    userIsCallCenterManager ||
                                    userIsFrontDeskBasic ||
                                    userIsFrontDeskManager ||
                                    userIsSocialMediaBasic ||
                                    userIsSocialMediaManager) &&
                                    contactForEdit &&
                                    !contactForEdit.editable &&
                                    !userCanEditAll)
                                }
                                options={consultants}
                                onChange={(value) => onChange(value)}
                                value={value}
                              />
                            )}
                          />
                        }
                      />
                    </ListItem>
                  ) : (
                    ""
                  )}

                  <ListItem style={{ padding: 0 }}>
                    <ListItemIcon style={{ margin: 0, minWidth: 45 }}>
                      <Avatar style={{ backgroundColor: "#e0f2f1" }}>
                        <Iconify
                          icon={"fa6-solid:share-from-square"}
                          width={25}
                          height={25}
                          style={{ color: "#009688" }}
                        />
                      </Avatar>
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Controller
                          name="source"
                          control={control}
                          defaultValue={contactForEdit?.source || "Call Center"}
                          as={({ onChange, value }) => (
                            <Controls.Select
                              label="Source"
                              disabled={
                                // (contactForEdit &&
                                //   !isEmpty(contactForEdit.fraudolent.data)) ||
                                contactForEdit &&
                                !userCanEditAll &&
                                !userCanchangeSource
                              }
                              options={SourceOptions()}
                              onChange={(value) => onChange(value)}
                              value={value}
                            />
                          )}
                        />
                      }
                    />
                  </ListItem>

                  <ListItem style={{ padding: 0 }}>
                    <ListItemIcon style={{ margin: 0, minWidth: 45 }}>
                      <Avatar style={{ backgroundColor: "#e0f2f1" }}>
                        <Iconify
                          icon={"eva:people-fill"}
                          width={25}
                          height={25}
                          style={{ color: "#009688" }}
                        />
                      </Avatar>
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Box
                          sx={{
                            display: "grid",
                            columnGap: 10,
                            rowGap: 10,
                            gridTemplateColumns: {
                              xs: "repeat(1, 1fr)",
                              sm: "repeat(2, 1fr)",
                            },
                          }}
                          mb={1}
                          mr={2}
                        >
                          <Controller
                            name="marital_status"
                            control={control}
                            defaultValue={
                              contactForEdit?.marital_status || "single"
                            }
                            as={({ onChange, value }) => (
                              <Controls.Select
                                label="Marital Status"
                                disabled={
                                  // (contactForEdit &&
                                  //   !isEmpty(contactForEdit.fraudolent.data)) ||
                                  readOnlyComponent ||
                                  userIsCallCenterBasic ||
                                  userIsCallCenterManager ||
                                  userIsFrontDeskBasic ||
                                  userIsFrontDeskManager ||
                                  userIsSocialMediaBasic ||
                                  userIsSocialMediaManager
                                }
                                options={maritalStatusOptions()}
                                onChange={(value) => onChange(value)}
                                value={value}
                              />
                            )}
                          />

                          <Controller
                            name="languages"
                            control={control}
                            defaultValue={languageOptionsPlain(
                              contactForEdit?.languages?.data || []
                            )}
                            as={({ onChange, value }) => (
                              <Controls.MultipleSelect
                                label="Languages"
                                disabled={
                                  // (contactForEdit &&
                                  //   !isEmpty(contactForEdit.fraudolent.data)) ||
                                  readOnlyComponent ||
                                  userIsCallCenterBasic ||
                                  userIsCallCenterManager ||
                                  userIsFrontDeskBasic ||
                                  userIsFrontDeskManager ||
                                  userIsSocialMediaBasic ||
                                  userIsSocialMediaManager
                                }
                                options={languageOptions(languages)}
                                value={languageOptionsPlain(
                                  contactForEdit?.languages?.data || []
                                )}
                                onChange={(value) => onChange(value)}
                              />
                            )}
                          />
                        </Box>
                      }
                    />
                  </ListItem>
                  <ListItem style={{ padding: 0, marginTop: 5 }}>
                    <ListItemIcon style={{ margin: 0, minWidth: 45 }}>
                      <Avatar style={{ backgroundColor: "#e0f2f1" }}>
                        <Iconify
                          icon={"iconoir:high-priority"}
                          width={25}
                          height={25}
                          style={{ color: "#009688" }}
                        />
                      </Avatar>
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Box
                          sx={{
                            display: "grid",
                            columnGap: 10,
                            rowGap: 10,
                            gridTemplateColumns: {
                              xs: "repeat(1, 1fr)",
                              sm: "repeat(2, 1fr)",
                            },
                          }}
                          mb={1}
                          mr={2}
                        >
                          <Controller
                            name="priority"
                            control={control}
                            defaultValue={contactForEdit?.priority || ""}
                            as={({ onChange, value }) => (
                              <Controls.Select
                                label="Priority"
                                disabled={
                                  // (contactForEdit &&
                                  //   !isEmpty(contactForEdit.fraudolent.data)) ||
                                  readOnlyComponent ||
                                  userIsCallCenterBasic ||
                                  userIsCallCenterManager ||
                                  userIsFrontDeskBasic ||
                                  userIsFrontDeskManager ||
                                  userIsSocialMediaBasic ||
                                  userIsSocialMediaManager
                                }
                                options={priorityOptions()}
                                value={value}
                                onChange={(value) => onChange(value)}
                              />
                            )}
                          />
                          <Controller
                            name="financing"
                            control={control}
                            defaultValue={contactForEdit?.financing || ""}
                            as={({ onChange, value }) => (
                              <Controls.Select
                                label={
                                  authUser.organization.data
                                    .contact_financing_required
                                    ? "** Financing"
                                    : "Financing"
                                }
                                disabled={
                                  // (contactForEdit &&
                                  //   !isEmpty(contactForEdit.fraudolent.data)) ||
                                  readOnlyComponent ||
                                  userIsCallCenterBasic ||
                                  userIsCallCenterManager ||
                                  userIsFrontDeskBasic ||
                                  userIsFrontDeskManager ||
                                  userIsSocialMediaBasic ||
                                  userIsSocialMediaManager
                                }
                                options={financingOptions()}
                                value={value}
                                onChange={(value) => onChange(value)}
                              />
                            )}
                          />
                        </Box>
                      }
                    />
                  </ListItem>
                </Box>

                <Card
                  style={{
                    backgroundColor: "#f7ffff",
                    paddingBottom: 0,
                    paddingTop: 0,
                    borderRadius: "5%",
                    marginBottom: 0,
                    paddingLeft: 3,
                  }}
                >
                  <CardHeader
                    style={{ paddingTop: 3, paddingLeft: 0 }}
                    avatar={
                      <Avatar
                        style={{ backgroundColor: "#e0f2f1", paddingLeft: 0 }}
                      >
                        <Iconify
                          icon={"fa-solid:address-card"}
                          width={25}
                          height={25}
                          style={{ color: "#009688", paddingLeft: 0 }}
                        />
                      </Avatar>
                    }
                    title={
                      authUser &&
                      authUser.organization &&
                      authUser.organization.data &&
                      authUser.organization.data.contact_validation
                        ? "Address **"
                        : "Address"
                    }
                  />
                  <CardContent
                    style={{
                      paddingTop: 3,
                      paddingBottom: 0,
                      marginBottom: 10,
                    }}
                  >
                    <Grid container alignItems="center" spacing={3}>
                      <Grid item xs={9}>
                        <Controller
                          name="address"
                          control={control}
                          defaultValue={contactForEdit?.address || ""}
                          as={({ onChange, value }) => (
                            <TextField
                              variant="outlined"
                              value={value}
                              onChange={(data) => {
                                debouncedSearch(data.target.value);
                                onChange(data.target.value);
                              }}
                              label="Line 1"
                              disabled={
                                // (contactForEdit &&
                                //   !isEmpty(contactForEdit.fraudolent.data)) ||
                                readOnlyComponent ||
                                userIsCallCenterBasic ||
                                userIsCallCenterManager ||
                                userIsFrontDeskBasic ||
                                userIsFrontDeskManager ||
                                userIsSocialMediaBasic ||
                                userIsSocialMediaManager
                              }
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={3}>
                        <Controller
                          name="apt"
                          control={control}
                          defaultValue={contactForEdit?.apt || ""}
                          as={
                            <Controls.Input
                              label="APT"
                              disabled={
                                // (contactForEdit &&
                                //   !isEmpty(contactForEdit.fraudolent.data)) ||
                                readOnlyComponent ||
                                userIsCallCenterBasic ||
                                userIsCallCenterManager ||
                                userIsFrontDeskBasic ||
                                userIsFrontDeskManager ||
                                userIsSocialMediaBasic ||
                                userIsSocialMediaManager
                              }
                            />
                          }
                        />
                      </Grid>
                    </Grid>
                    {
                      <Grid item xs={12}>
                        {!isPlacePredictionsLoading &&
                        !isEmpty(placePredictionsLocal) ? (
                          <Card className={classes.cardPlaces}>
                            <Box m={1}>
                              <List>
                                <Divider component="li" />
                                {placePredictionsLocal.map((item, index) => {
                                  return (
                                    <React.Fragment key={item.id}>
                                      <ListItem
                                        role={undefined}
                                        dense
                                        button
                                        onClick={() => {
                                          setAddressValues(item);
                                        }}
                                      >
                                        <ListItemText
                                          id={item.index}
                                          primary={item.description}
                                        />
                                      </ListItem>
                                      <Divider component="li" />
                                    </React.Fragment>
                                  );
                                })}
                              </List>
                            </Box>
                          </Card>
                        ) : (
                          ""
                        )}
                      </Grid>
                    }

                    <Grid container spacing={3} style={{ paddingTop: 7 }}>
                      <Grid item xs={6}>
                        <Controller
                          name="city_field"
                          control={control}
                          disabled
                          defaultValue={contactForEdit?.city || ""}
                          as={({ onChange, value }) => (
                            <TextField
                              variant="outlined"
                              value={value}
                              onChange={(data) => onChange(data.target.value)}
                              label="City"
                              inputProps={{
                                maxLength: 5,
                              }}
                              disabled={true}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Controller
                          name="state_field"
                          control={control}
                          defaultValue={contactForEdit?.state || ""}
                          as={({ onChange, value }) => (
                            <TextField
                              variant="outlined"
                              value={value}
                              onChange={(data) => onChange(data.target.value)}
                              label="State"
                              inputProps={{
                                maxLength: 5,
                              }}
                              disabled={true}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Controller
                          name="country_field"
                          control={control}
                          defaultValue={contactForEdit?.country || ""}
                          as={({ onChange, value }) => (
                            <TextField
                              variant="outlined"
                              value={value}
                              onChange={(data) => onChange(data.target.value)}
                              label="Country"
                              inputProps={{
                                maxLength: 5,
                              }}
                              disabled={true}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <Controller
                          name="zip"
                          control={control}
                          // type="number"
                          defaultValue={contactForEdit?.zip || ""}
                          as={({ onChange, value }) => (
                            <TextField
                              variant="outlined"
                              value={value}
                              onChange={(data) => onChange(data.target.value)}
                              label="Zipcode"
                              inputProps={{
                                maxLength: 5,
                              }}
                              disabled={true}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
                {authUser &&
                authUser.organization &&
                authUser.organization.data &&
                authUser.organization.data.contact_validation ? (
                  <FormControlLabel
                    style={{ marginLeft: 0, marginTop: 8 }}
                    labelPlacement="end"
                    control={
                      <Controller
                        name="validated"
                        control={control}
                        defaultValue={
                          contactForEdit && contactForEdit.validated ? 1 : 0
                        }
                       
                        render={({ onChange, value }) => (
                          <SSwitch
                          disabled={contactForEdit && !contactForEdit.address && !userIsSuperAdmin}
                            checked={value === 1 ? true : false}
                            onChange={(value) =>
                              onChange(value.target.checked ? 1 : 0)
                            }
                          />
                        )}
                      />
                    }
                    label={
                      <ListItemText
                        primaryTypographyProps={{ style: { fontWeight: 500 } }}
                        primary="Validate information**"
                      />
                    }
                  />
                ) : (
                  ""
                )}
                <Box mr={1} ml={1}>
                  {
                    <BMI2
                      height={watchHeight}
                      weight={watchWeight}
                      setBmiform={setBmi}
                      cardHeight={
                        extraPhones.length + extraEmail.length > 2
                          ? "130px"
                          : "110px"
                      }
                      pTop={
                        extraPhones.length + extraEmail.length <= 2
                          ? "0px"
                          : "6px"
                      }
                    />
                  }
                </Box>
              </Grid>
            )}
          </Grid>
          <Grid container style={{ paddingTop: 0 }}>
            <Grid item xs={12} style={{ paddingTop: 0, paddingRight: 20 }}>
              <ListItem style={{ padding: 0 }}>
                <ListItemIcon style={{ margin: 0, minWidth: 45 }}>
                  <Avatar style={{ backgroundColor: "#e0f2f1" }}>
                    <Iconify
                      icon={"gis:tags"}
                      width={25}
                      height={25}
                      style={{ color: "#009688" }}
                    />
                  </Avatar>
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Controller
                      name="tags"
                      defaultValue={tagOptions(contactForEdit?.tags.data || [])}
                      control={control}
                      as={({ onChange, value }) => (
                        <Controls.AutocompleteTagsD
                          label="Tags"
                          disabled={
                            // (contactForEdit &&
                            //   !isEmpty(contactForEdit.fraudolent.data)) ||
                            readOnlyComponent &&
                            !userIsCallCenterBasic &&
                            !userIsCallCenterManager &&
                            !userIsFrontDeskBasic &&
                            !userIsFrontDeskManager &&
                            !userIsSocialMediaBasic &&
                            !userIsSocialMediaManager
                          }
                          defaultValue={tagOptions(
                            contactForEdit?.tags.data || []
                          )}
                          options={tagOptions(tags)}
                          value={value}
                          onChange={(value) => onChange(value)}
                          tags={tags}
                          deleteLocked={userCanDeleteLockedTags}
                        />
                      )}
                    />
                  }
                />
              </ListItem>
            </Grid>
          </Grid>

          {!authUser.organization.data.contact_simple && (
            <Grid
              style={{
                width: "100%",
                justifyContent: "space-around",
                alignItems: "center",
              }}
              container
              direction="row"
              spacing="3"
            >
              <Grid item>
                <FormControlLabel
                  style={{ marginLeft: 0, marginTop: 8 }}
                  labelPlacement="end"
                  control={
                    <Controller
                      name="Influencer"
                      control={control}
                      defaultValue={contactForEdit?.Influencer || 0}
                      render={({ onChange, value }) => (
                        <SSwitch
                          checked={value === 1 ? true : false}
                          onChange={(value) => {
                            onChange(value.target.checked ? 1 : 0);
                          }}
                          disabled={
                            (contactForEdit &&
                              !isEmpty(contactForEdit.fraudolent.data)) ||
                            readOnlyComponent
                          }
                        />
                      )}
                    />
                  }
                  label={<ListItemText primary="Influencer" />}
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  style={{ marginLeft: 0, marginTop: 8 }}
                  labelPlacement="end"
                  control={
                    <Controller
                      name="VIP"
                      control={control}
                      defaultValue={contactForEdit?.VIP || 0}
                      render={({ onChange, value }) => (
                        <SSwitch
                          checked={value === 1 ? true : false}
                          onChange={(value) =>
                            onChange(value.target.checked ? 1 : 0)
                          }
                          disabled={
                            // (contactForEdit &&
                            //   !isEmpty(contactForEdit.fraudolent.data)) ||
                            readOnlyComponent ||
                            userIsCallCenterBasic ||
                            userIsCallCenterManager ||
                            userIsFrontDeskBasic ||
                            userIsFrontDeskManager ||
                            userIsSocialMediaBasic ||
                            userIsSocialMediaManager
                          }
                        />
                      )}
                    />
                  }
                  label={<ListItemText primary="VIP" />}
                />
              </Grid>

              <Grid item>
                <FormControlLabel
                  style={{ marginLeft: 0, marginTop: 8 }}
                  labelPlacement="end"
                  control={
                    <Controller
                      name="SuperVIP"
                      control={control}
                      defaultValue={contactForEdit?.SuperVIP || 0}
                      render={({ onChange, value }) => (
                        <SSwitch
                          checked={value === 1 ? true : false}
                          onChange={(value) =>
                            onChange(value.target.checked ? 1 : 0)
                          }
                          disabled={
                            // (contactForEdit &&
                            //   !isEmpty(contactForEdit.fraudolent.data)) ||
                            readOnlyComponent ||
                            userIsCallCenterBasic ||
                            userIsCallCenterManager ||
                            userIsFrontDeskBasic ||
                            userIsFrontDeskManager ||
                            userIsSocialMediaBasic ||
                            userIsSocialMediaManager
                          }
                        />
                      )}
                    />
                  }
                  label={<ListItemText primary="SuperVIP" />}
                />
              </Grid>
              {userCanBillingVerification && (
                <Grid item>
                  <FormControlLabel
                    style={{ marginLeft: 0, marginTop: 8 }}
                    labelPlacement="end"
                    control={
                      <Controller
                        name="billing_verification_disabled"
                        control={control}
                        defaultValue={
                          contactForEdit?.billing_verification_disabled || 0
                        }
                        render={({ onChange, value }) => (
                          <SSwitch
                            checked={value === 1 ? true : false}
                            onChange={(value) =>
                              onChange(value.target.checked ? 1 : 0)
                            }
                            disabled={
                              readOnlyComponent ||
                              userIsCallCenterBasic ||
                              userIsCallCenterManager ||
                              userIsFrontDeskBasic ||
                              userIsFrontDeskManager ||
                              userIsSocialMediaBasic ||
                              userIsSocialMediaManager
                            }
                          />
                        )}
                      />
                    }
                    label={
                      <ListItemText primary="Billing Verification Disabled" />
                    }
                  />
                </Grid>
              )}
            </Grid>
          )}

          <Grid
            style={{
              marginTop: 3,
              justifyContent: "flex-end",
              alignItems: "center",
            }}
            container
            direction="row"
            spacing={1}
          >
            <Grid item xs={6}>
              <div>
                {contactForEdit && (
                  <Controls.Button
                    text="Send Form"
                    variant="outlined"
                    style={{
                      borderRadius: "30px",
                      height: "40px",
                      width: "120px",
                      marginRight: 15,
                    }}
                    onClick={() => setOpenSendFormTemplate(true)}
                    disabled={
                      // (contactForEdit &&
                      //   !isEmpty(contactForEdit.fraudolent.data)) ||
                      readOnlyComponent
                    }
                  />
                )}
                <Controls.AdornedButton
                  type="submit"
                  style={{
                    borderRadius: "30px",
                    height: "40px",
                    width: "120px",
                    marginRight: 15,
                  }}
                  variant="outlined"
                  color="primary"
                  loading={saveLoading}
                  disabled={
                    // (contactForEdit &&
                    //   !isEmpty(contactForEdit.fraudolent.data)) ||
                    !userCanEdit ||
                    (authUser.is_coordinator &&
                      contactForEdit &&
                      !contactForEdit.editable &&
                      !userCanEditAll) ||
                    (userIsSalesManager &&
                      contactForEdit &&
                      !contactForEdit.editable &&
                      !userCanEditAll) ||
                    (!isEmpty(contactsnewErrors) && errorFlag)
                  }
                >
                  Submit
                </Controls.AdornedButton>
                {error && errorFlag && !saveLoading && (
                  <p className={classes.error}>
                    {contactsnewErrors[contactsnewErrors.length - 1]}
                  </p>
                )}
              </div>{" "}
            </Grid>
          </Grid>
        </Paper>
      </FormControl>
      <ConfirmDialog
        confirmLoading={confirmDialogLoading}
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <Notification notify={notify} setNotify={setNotify} />
    </form>
  );
}
