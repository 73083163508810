import Toolbar from "@material-ui/core/Toolbar";
import { blue, blueGrey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import PrintIcon from "@material-ui/icons/Print";
import SyncIcon from "@material-ui/icons/Sync";
import { useInterval } from "beautiful-react-hooks";
import { debounce, find, isEmpty } from "lodash";
import { lighten } from "polished";
import React, { useCallback, useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import ReactToPrint from "react-to-print";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { updatePbxInfo } from "../redux/slices/auth";
import { fetchDocumentTypesService } from "../redux/slices/documenttypes";
import SmsMmsForm from "./forms/SmsMmsForm";

import { useDispatch, useSelector } from "react-redux";
import ConfirmDialog from "./ConfirmDialog";

import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  ListItem,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import CHAT from "../assets/img/icons/CHAT.jpg";
import Api from "../lib/api";
import CRMUtils from "../utils";
import ChatRoomLeftItem from "./ChatRoomLeftItem";
import ChatRoomRightItem from "./ChatRoomRightItem";
import Iconify from "./Iconify";
import Notification from "./Notification";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  flex-direction: column;
  display: flex;
  height: 100%;
`;

const useStyles = makeStyles((theme) => ({
  form: {
    justifySelf: "self-end",
  },
  grow: {
    flexGrow: 1,
  },
  inputRoot: {
    borderRadius: "35px",
  },
  spinner: {
    position: "absolute",
    zIndex: 10,
    top: "65px",
    left: "50%",
  },
  more: {
    //margin: 5,
    position: "absolute",
    zIndex: 1200,
    top: 0,
    right: 5,
    width: 25,
    height: 25,
    color: "white",
    overflow: "hidden",

    "&:hover, &.Mui-focusVisible": {
      boxShadow:
        "rgba(0, 0, 0, 0.2) 0px 8px 10px -5px, rgba(0, 0, 0, 0.14) 0px 16px 24px 2px, rgba(0, 0, 0, 0.12) 0px 6px 30px 5px",
    },
  },

  chatHistory: {
    height: "93%",
    display: "flex",
    flexDirection: "column",
  },
  chatWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "10px",
  },
  chatHistoryItems: {
    width: "100%",
    maxWidth: "100%",
    overflow: "auto",
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  name: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  newButton: {
    position: "absolute",
    right: "10px",
  },

  rightItem: {
    flexDirection: "row-reverse",
    textAlign: "right",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: lighten("0.2", "rgba(137,132,128,0.2)"),
    "&:hover": {
      backgroundColor: "#E4DDD6",
    },
    alignSelf: "center",

    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 1),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "15ch",
      "&:focus": {
        width: "60ch",
      },
    },
  },

  boxBackground: {
    backgroundImage: "url(" + CHAT + ")",
    backgroundColor: "#E4DDD6",
    height: "80%",
    width: "96%",
    outline: "none",
    paddingBottom: 15,
    paddingTop: 15,
    border: "1px solid #ddd",
    borderRadius: "4px",
    boxShadow: "0 0 2px 1px rgba(0, 140, 186, 0.5)",
  },

  paper2: {
    zIndex: "1000",
    height: "600px",
    width: "100%",
    borderRadius: "4px",
    padding: "5px",
  },
  paper3: {
    zIndex: "1000",
    height: "600px",
    width: "100%",
    borderRadius: "4px",
    padding: "5px",
  },
  paper4: {
    zIndex: "1000",
    height: "93%",
    width: "70%",
    borderRadius: "4px",
    padding: "5px",
  },
  paper: {
    zIndex: "1000",
    height: "600px",
    width: "100%",
    borderRadius: "4px",
    padding: "5px",
  },
  image: {
    border: "1px solid #ddd",
    borderRadius: "4px",
    padding: "5px",
    width: "77px",
    "&:hover": {
      boxShadow: "0 0 2px 1px rgba(0, 140, 186, 0.5)",
    },
  },
  popover: {
    //margin: 5,
    position: "absolute",
    top: 0,
    right: 5,
    width: 25,
    height: 25,
    color: "black",
    backgroundColor: lighten("0.2", "rgba(120,120,120,0.2)"),
    "&:hover": {
      backgroundColor: "#E4DDD6",
    },
  },
  phoneWarningContainer: {
    padding: "2px 8px",
    backgroundColor: "rgb(251 246 202)",
    color: "#252525",
    display: "flex",
    alignItems: "center",
    fontSize: 12,
    borderRadius: 10,
    marginTop: 5,
    maxWidth: "fit-content",
  },
  phoneWarningIcon: {
    marginRight: 5,
    color: "#bfb100",
    fontSize: 19,
  },
  phoneWarningText: {
    fontSize: 12,
    fontWeight: 500,
  },
}));

var limit = 10;
var total = 0;
var currentPage = 1;

function SmsMmsHistory(props) {
  const { authUser, pbxConfig } = useSelector((state) => state.auth);
  const [confirmDialogLoading, setConfirmDialogLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const classes = useStyles();
  const [searchText, setSearchText] = useState(null);
  const [autoRefresh, setAutoRefresh] = useState(true);
  const [scrollLocation, setScrollLocation] = useState(0);
  const [loading, setLoading] = useState(null);
  const { documentTypes } = useSelector((state) => state.documenttypes);
  const chatToPrintRef = useRef();
  const chatToPrintAllRef = useRef();
  const [smsMmsDataToPrint, setSmsMmsDataToPrint] = useState([]);
  const userIdRef = useRef();
  const [smsMmsDataToPrintAll, setSmsMmsDataToPrintAll] = useState([]);
  const setSearch = (search) => {
    setSearchText(search);
  };
  const dispatch = useDispatch();
  const debouncedSearch = useCallback(
    debounce((newValue) => setSearch(newValue), 1500),
    []
  );
  const uuid = uuidv4();
  const {
    contact,
    fromIndex = false,
    fromSMSPanel = false,
    phone = null,
    setOrder = null,
    setUnread = null,
    fromRecord = false,
    userID,
    setSelectedUserSMSMMS,
    consultantErrorHandle = null,
    userCanSend,
    contactUsersDetails,
    setContact = null,
    setSelectedUserSMSMMSByGroup = null,
    selectedUserSMSMMSByGroup = null,
  } = props;

  let lastChatPartner = null;

  const userCanPrintChat = CRMUtils.userHasPermission(authUser, [
    "contact_PrintChat",
  ]);
  const userCanPrintChatAll = CRMUtils.userHasPermission(authUser, [
    "contact_PrintChatAll",
  ]);
  const userCanUnmarkPhoneAsInvalid =
    CRMUtils.userHasPermission(authUser, ["user_unmarkphoneasinvalid"]) ||
    CRMUtils.userHasPermission(authUser, ["user_unmarkphoneasinvalidsuper"]);
  useInterval(async () => {
    if (contact && autoRefresh && parseFloat(scrollLocation) >= -600) {
      console.error("onScroll33333");
      getSMSMMS();
    }
  }, 20000);

  const [smsMmsData, setSmsMmsData] = useState([]);

  const sendMessage = async (payload) => {
    //console.log("sendMessage payload history",payload);
    setSelectedUserSMSMMS(authUser.id);

    if (!payload["attch"]) payload["attch"] = "";

    if (payload["attch"] && Array.isArray(payload["attch"])) {
      payload["attch"].forEach(async (attch) => {
        let formData = new FormData();

        formData.append("attch", attch);
        if (payload["body"]) formData.append("body", payload["body"]);
        if (phone) {
          formData.append("phone", phone);
        }

        const { data } = !phone
          ? await Api.sendContactSmsOrMms(contact.id, formData)
          : await Api.SendSMSMMSByPhone(authUser.id, formData);

        let newData = [data, ...smsMmsData];

        setSmsMmsData(newData);
      });
    } else {
      if (Array.isArray(payload)) {
        payload.forEach(async (payloadItem) => {
          if (!payloadItem["attch"]) payloadItem["attch"] = "";
          let formData = new FormData();
          Object.keys(payloadItem).forEach((key) =>
            formData.append(key, payloadItem[key])
          );
          if (phone) {
            formData.append("phone", phone);
          }
          const { data } = !phone
            ? await Api.sendContactSmsOrMms(contact.id, formData)
            : await Api.SendSMSMMSByPhone(authUser.id, formData);
          let newData = [data, ...smsMmsData];

          if (fromIndex || fromSMSPanel) {
            setOrder(data);
          }
          setSmsMmsData(newData);
        });
      } else {
        if (!payload["attch"]) payload["attch"] = "";
        let formData = new FormData();
        Object.keys(payload).forEach((key) =>
          formData.append(key, payload[key])
        );
        if (phone) {
          formData.append("phone", phone);
        }
        const { data } = !phone
          ? await Api.sendContactSmsOrMms(contact.id, formData)
          : await Api.SendSMSMMSByPhone(authUser.id, formData);
        let newData = [data, ...smsMmsData];

        if (fromIndex || fromSMSPanel) {
          setOrder(data);
        }
        setSmsMmsData(newData);
      }
    }

    const div = document.getElementById("scrollableDiv" + uuid);
    if (div) {
      div.scrollTo(0, 0);
    }
  };

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const getNewSMSMMS = async () => {
    setLoading(true);
    let params = "";
    if (contact) {
      params = new URLSearchParams(
        "page=" + parseInt(currentPage + 1) + "&limit=" + limit
      );
    }
    if (phone) {
      params = new URLSearchParams(
        "page=" +
          parseInt(currentPage + 1) +
          "&limit=" +
          limit +
          "&phone=" +
          phone
      );
    }
    try {
      if (userID) {
        const { data } =
          userID >= 1
            ? await Api.getContactSMSMMSHistoryUser(
                contact.id,
                userID,
                `?1=1&${params.toString()}`
              )
            : userID == -1
            ? await Api.getContactSMSMMSHistory(
                contact.id,
                `?1=1&${params.toString()}`
              )
            : "";

        setSmsMmsData(smsMmsData.concat(data.data));

        total = data.meta.pagination.total;
        currentPage = data.meta.pagination.current_page;
      }

      if (selectedUserSMSMMSByGroup) {
        const { data } =
          selectedUserSMSMMSByGroup >= 1
            ? await Api.getContactSMSMMSHistoryUserByGroups(
                contact.id,
                selectedUserSMSMMSByGroup,
                `?1=1&${params.toString()}`
              )
            : "";

        setSmsMmsData(smsMmsData.concat(data.data));

        total = data.meta.pagination.total;
        currentPage = data.meta.pagination.current_page;
      }

      setLoading(false);
    } catch (error) {
      if (
        typeof consultantErrorHandle === "function" &&
        error?.data?.data?.message
      ) {
        consultantErrorHandle(error?.data?.data?.message);
      }
    }
  };

  const getSMSMMS = async () => {
    try {
      const params = new URLSearchParams("page=1&limit=" + limit);
      setLoading(true);

      if (userID) {
        const { data } =
          userID >= 1
            ? await Api.getContactSMSMMSHistoryUser(
                contact.id,
                userID,
                `?1=1&${params.toString()}`
              )
            : userID == -1
            ? await Api.getContactSMSMMSHistory(
                contact.id,
                `?1=1&${params.toString()}`
              )
            : "";

        setSmsMmsData(data.data);

        total = data.meta.pagination.total;
        currentPage = data.meta.pagination.current_page;
      }

      if (selectedUserSMSMMSByGroup) {
        const { data } =
          selectedUserSMSMMSByGroup >= 1
            ? await Api.getContactSMSMMSHistoryUserByGroups(
                contact.id,
                selectedUserSMSMMSByGroup,
                `?1=1&${params.toString()}`
              )
            : "";

        setSmsMmsData(smsMmsData.concat(data.data));

        total = data.meta.pagination.total;
        currentPage = data.meta.pagination.current_page;
      }

      setLoading(false);
    } catch (error) {
      if (
        typeof consultantErrorHandle === "function" &&
        error?.data?.data?.message
      ) {
        consultantErrorHandle(error?.data?.data?.message);
      }
    }
  };

  const getSMSMMSByGroup = async () => {
    const params = new URLSearchParams("page=1&limit=" + limit);
    setLoading(true);

    if (selectedUserSMSMMSByGroup) {
      const { data } =
        selectedUserSMSMMSByGroup > 0
          ? await Api.getContactSMSMMSHistoryUserByGroups(
              contact.id,
              selectedUserSMSMMSByGroup,
              `?1=1&${params.toString()}`
            )
          : "";

      setSmsMmsData(data.data);
      //setSelectedUserSMSMMSByGroup(null);

      total = data.meta.pagination.total;
      currentPage = data.meta.pagination.current_page;
    }

    setLoading(false);
  };

  useEffect(() => {
    if (contact) {
      setSmsMmsData([]);
      getSMSMMS();
    }
  }, [userID]);

  useEffect(() => {
    //if (contact) {
    //setSmsMmsData([]);
    getSMSMMSByGroup();
    //}
  }, [selectedUserSMSMMSByGroup]);

  useEffect(() => {
    if (userID) {
      userIdRef.current = userID;
    }
  }, [userID]);

  useEffect(() => {
    const orgId = authUser.organization_id;
    if (isEmpty(documentTypes)) dispatch(fetchDocumentTypesService(orgId));
  }, []);

  const makeACall = () => {
    console.log("makeACall", contact);
    let tmp = {};

    tmp = {
      ...pbxConfig,
      makeACall: true,
      callToPhone: contact ? contact.phone : phone ? phone : "",
      callToName: contact
        ? contact.fname + contact.lname + " (contact)"
        : "Unknown",
    };

    dispatch(updatePbxInfo(tmp));
  };

  const saveToContact = async (media_id, type) => {
    const payload = {
      docType: type,
    };

    if (contact && media_id) {
      const { data } = await Api.saveToContactImage(
        contact.id,
        media_id,
        payload
      );
    }
  };

  const onUnmarkPhoneAsInvalid = async () => {
    try {
      setConfirmDialogLoading(true);
      await Api.unmarkPhoneNumberAsInvalidForMessaging(contact.phone);

      setNotify({
        isOpen: true,
        message: "Successfully unmarked!",
        type: "success",
      });

      if (setContact) {
        setContact({
          ...contact,
          ...{ invalid_phone_number_for_messaging: false },
        });
      }
    } catch (err) {
      if (
        (err.status == 401 || err.status == 422) &&
        err.data &&
        err.data.error
      ) {
        setNotify({
          isOpen: true,
          message: err.data.error,
          type: "error",
        });
      } else {
        setNotify({
          isOpen: true,
          message: "Something went wrong!",
          type: "error",
        });
      }
    } finally {
      setConfirmDialogLoading(false);
      setConfirmDialog({ isOpen: false, title: "", subTitle: "" });
    }
  };

  return (
    <Wrapper>
      <div className={classes.grow}>
        <Paper
          elevation={1}
          className={
            fromIndex
              ? classes.paper2
              : fromRecord
              ? classes.paper3
              : fromSMSPanel
              ? classes.paper4
              : classes.paper
          }
        >
          <Box mx={5}>
            <Toolbar style={{ display: "block", padding: 8 }}>
              <Grid container spacing={2} style={{ height: "auto" }}>
                <Grid item xs={3}>
                  {userCanPrintChatAll && (
                    <div>
                      <ReactToPrint
                        trigger={() => (
                          <Tooltip title="Print all Chat Conversations">
                            <PrintIcon
                              style={{
                                color: blue[700],
                                float: "right",
                                cursor: "pointer",
                                marginRight: 15,
                              }}
                            />
                          </Tooltip>
                        )}
                        content={() => chatToPrintAllRef.current}
                        onBeforeGetContent={async () => {
                          const { data } = await Api.getContactSMSMMSHistory(
                            contact.id,
                            `?order=asc`
                          );
                          setSmsMmsDataToPrintAll(data.data);
                        }}
                      />
                    </div>
                  )}
                  {
                    //PrintALL
                    /*
                    userCanPrintChatAll && (
                    <ReactToPrint
                      trigger={() => (
                        <Tooltip title="Print all the conversations of this contact">
                          <Iconify
                            icon={"foundation:print"}
                            width={25}
                            height={25}
                            style={{
                              color: blue[700],
                              float: "right",
                              cursor: "pointer",
                              marginRight: 15,
                            }}
                          />
                        </Tooltip>
                      )}
                      content={() => chatToPrintAllRef.current}
                      onBeforeGetContent={async () => {
                        const { data } = await Api.getContactSMSMMSHistory(
                          contact.id,
                          `?`
                        );
                        setSmsMmsDataToPrintAll(data.data);
                      }}
                    />
                  )
                  */
                  }
                  <Typography className={classes.title} variant="h6" noWrap>
                    Messages
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  {" "}
                  {!userCanSend ? (
                    <Typography style={{ color: "red" }} variant="h6" noWrap>
                      ***You don't have permission to send Messages***
                    </Typography>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={2}>
                  <SyncIcon
                    style={{
                      color: autoRefresh ? blue[700] : blueGrey[700],
                      float: "right",
                      cursor: "pointer",
                    }}
                    onClick={() => setAutoRefresh(!autoRefresh)}
                  />
                  <>
                    {(userCanPrintChat || userCanPrintChatAll) && (
                      <div>
                        <ReactToPrint
                          trigger={() => (
                            <Tooltip title="Print this Chat Conversation">
                              <PrintIcon
                                style={{
                                  color: blue[700],
                                  float: "right",
                                  cursor: "pointer",
                                  marginRight: 15,
                                }}
                              />
                            </Tooltip>
                          )}
                          content={() => chatToPrintRef.current}
                          onBeforeGetContent={async () => {
                            if (userIdRef.current) {
                              const { data } =
                                userIdRef.current >= 1
                                  ? await Api.getContactSMSMMSHistoryUser(
                                      contact.id,
                                      userIdRef.current,
                                      `?order=asc`
                                    )
                                  : userIdRef.current == -1
                                  ? await Api.getContactSMSMMSHistory(
                                      contact.id,
                                      `?order=asc`
                                    )
                                  : "";
                              setSmsMmsDataToPrint(data.data);
                            }
                          }}
                        />
                      </div>
                    )}
                  </>
                </Grid>
              </Grid>
              {contact && contact.invalid_phone_number_for_messaging ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 4,
                  }}
                >
                  <div className={classes.phoneWarningContainer}>
                    <Iconify
                      icon="ic:round-warning"
                      className={classes.phoneWarningIcon}
                    />
                    <div>
                      <Typography className={classes.phoneWarningText}>
                        This number is not valid for messaging, messages sent
                        are not guaranteed to be delivered
                      </Typography>
                      <Typography className={classes.phoneWarningText}>
                        *If a message is received from this number, it will be
                        automatically validated.
                      </Typography>
                    </div>
                  </div>
                  {userCanUnmarkPhoneAsInvalid ? (
                    <Tooltip title="Unmark phone as invalid">
                      <span>
                        <IconButton
                          onClick={() => {
                            setConfirmDialog({
                              isOpen: true,
                              title:
                                "Are you sure to unmark the phone " +
                                contact.phone +
                                " as invalid?",
                              subTitle: "You can't undo this operation",
                              onConfirm: () => onUnmarkPhoneAsInvalid(),
                            });
                          }}
                          style={{ padding: 4, marginLeft: 2 }}
                        >
                          <Iconify
                            style={{ color: "#527493", width: 20, height: 20 }}
                            icon="basil:phone-off-solid"
                          />
                        </IconButton>{" "}
                      </span>
                    </Tooltip>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
              {/*
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <SearchIcon fontSize="small" />
                  </div>
                  <InputBase
                    placeholder="Search…"
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    onChange={(event) => debouncedSearch(event.target.value)}
                    inputProps={{ "aria-label": "search" }}
                  ></InputBase>
                </div>
                  */}
            </Toolbar>
          </Box>

          <div style={{ display: "flex", position: "relative" }}>
            {loading && (
              <CircularProgress
                classes={{ root: classes.spinner }}
                size={40}
                color="secondary"
              />
            )}
          </div>
          <Box mx={5} className={classes.boxBackground}>
            {
              <div
                id={"scrollableDiv" + uuid}
                style={{
                  height: "100%",
                  width: "99%",
                  overflow: "auto",
                  display: "flex",
                  flexDirection: "column-reverse",
                }}
              >
                <InfiniteScroll
                  dataLength={smsMmsData.length}
                  next={() => {
                    getNewSMSMMS();
                  }}
                  style={{ display: "flex", flexDirection: "column-reverse" }}
                  inverse={true} //
                  hasMore={true}
                  onScroll={(e) => {
                    setScrollLocation(e.target.scrollTop);
                    console.error("onScroll", e.target.scrollTop);
                  }}
                  scrollableTarget={"scrollableDiv" + uuid}
                  initialScrollY={0}
                >
                  {smsMmsData.map((item, index) => {
                    const user = find(contactUsersDetails, {
                      id: item.from_id,
                    });

                    if (item && item.from_type === "contact") {
                      return (
                        <ListItem alignItems="flex-start">
                          <ChatRoomLeftItem
                            item={item}
                            classes={classes}
                            fromUser={item.fromUser}
                            fromUserColor={user ? user.color : "000000"}
                            saveToContact={saveToContact}
                            saveToContactD={contact ? true : false}
                            documentTypes={documentTypes}
                          />
                        </ListItem>
                      );
                    } else {
                      return (
                        <>
                          <ListItem alignItems="flex-start">
                            <ChatRoomRightItem
                              item={item}
                              classes={classes}
                              saveToContact={saveToContact}
                              saveToContactD={contact ? true : false}
                              documentTypes={documentTypes}
                            />
                          </ListItem>
                        </>
                      );
                    }
                  })}
                </InfiniteScroll>
              </div>
            }
          </Box>
          {(userID == null || userID == authUser.id) && (
            <Box mt={4}>
              {" "}
              <SmsMmsForm
                contactSmsMms={contact}
                sendSmsMms={(text) => sendMessage(text)}
                userCanSend={userCanSend}
                groups={authUser.communications_groups}
                selectedGroup={selectedUserSMSMMSByGroup}
                tabValueExternal={0}
                contact={contact}
              />{" "}
            </Box>
          )}
        </Paper>
      </div>
      <div className={classes.grow} />
      {/* <Popup
        title={"Send Message"}
        fullWidth={true}
        openPopup={openPopup}
        onClose={onClose}
      >
        <SendSmsOrMmsForm
          sendMessage={sendMessage}
          //documentTypes={documentTypes}
          className={classes.form}
        />
     </Popup>*/}

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        confirmLoading={confirmDialogLoading}
      />
      {smsMmsDataToPrint.length > 0 && (
        <div style={{ display: "none" }}>
          <div ref={chatToPrintRef}>
            <div style={{ textAlign: "center" }}>
              <h3>
                Conversation between phone numbers{" "}
                <strong>{smsMmsDataToPrint[0].from}</strong> and{" "}
                <strong>{smsMmsDataToPrint[0].to}</strong>
              </h3>
            </div>
            {smsMmsDataToPrint.map((item, index) => {
              return (
                <div>
                  {item && item.from_type === "contact" ? (
                    <ListItem alignItems="flex-start">
                      <ChatRoomLeftItem
                        item={item}
                        classes={classes}
                        fromUser={item.fromUser}
                        fromUserColor={"lightblue"}
                        isPrinting={true}
                      />
                    </ListItem>
                  ) : (
                    <ListItem alignItems="flex-start">
                      <ChatRoomRightItem
                        item={item}
                        classes={classes}
                        isPrinting={true}
                      />
                    </ListItem>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
      {
        //PrintALL
        smsMmsDataToPrintAll.length > 0 && (
          <div style={{ display: "none" }}>
            <div ref={chatToPrintAllRef}>
              {smsMmsDataToPrintAll.map((item, index) => {
                let chatPartner = "";
                if (item && item.from_type === "contact") {
                  chatPartner = item?.toUser?.data?.users_fname || '';

                  return (
                    <div>
                      {chatPartner !== lastChatPartner && (
                        <ListItem
                          style={{
                            justifyContent: "center",
                            alignSelf: "center",
                            alignItems: "center",
                            textAlign: "center",
                          }}
                        >
                          <Typography
                            variant="h3"
                            style={{
                              justifyContent: "center",
                              alignSelf: "center",
                              alignItems: "center",
                              textAlign: "center",
                            }}
                          >
                            Conversation with {chatPartner}
                          </Typography>
                        </ListItem>
                      )}
                      <div style={{ display: "none" }}>
                        {(lastChatPartner = chatPartner)}
                      </div>
                      <ListItem alignItems="flex-start">
                        <ChatRoomLeftItem
                          item={item}
                          classes={classes}
                          fromUser={item.fromUser}
                          fromUserColor={"lightblue"}
                          isPrinting={true}
                        />
                      </ListItem>
                    </div>
                  );
                } else {
                  chatPartner = item.fromUser;
                  console.log("chatPartner: ",chatPartner);
                  console.log("lastChatPartner: ",lastChatPartner);
                  return (
                    <div>
                      {chatPartner !== lastChatPartner && (
                        <ListItem
                          style={{
                            justifyContent: "center",
                            alignSelf: "center",
                            alignItems: "center",
                            textAlign: "center",
                          }}
                        >
                          <Typography
                            variant="h3"
                            style={{
                              justifyContent: "center",
                              alignSelf: "center",
                              alignItems: "center",
                              textAlign: "center",
                            }}
                          >
                            Conversation with {chatPartner}
                          </Typography>
                        </ListItem>
                      )}
                      <div style={{ display: "none" }}>
                        {(lastChatPartner = chatPartner)}
                      </div>
                      <ListItem alignItems="flex-start">
                        <ChatRoomRightItem
                          item={item}
                          classes={classes}
                          isPrinting={true}
                        />
                      </ListItem>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        )
      }
      <Notification notify={notify} setNotify={setNotify} />
    </Wrapper>
  );
}

export default SmsMmsHistory;
