import axios from "axios";
import { merge } from "lodash";
import { Exception } from "sip.js/lib/core";

axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

var baseURL =
  process.env.REACT_APP_API_ENDPOINT || `http://crm305.com:8000/api`;

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT || `http://crm305.com:8000/api`,
});

instance.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

instance.interceptors.response.use(
  (response) => {
    //Intercepta las respuestas
    return response;
  },
  function (error) {
    let json = error.response.data;
    let message = json.message;

    //If error 401 redirect to login
    if (error.response.status === 401) {
    } else if (
      error.response.status === 403 &&
      message == "Invalid scope(s) provided."
    ) {
      const portal = localStorage.getItem("portal");

      removeLocalStorage();
      if (portal) {
        window.location.href = "/auth/sign-in";
        localStorage.removeItem("portal");
        window.location.href = "/auth/sign-in";
      } else {
        window.location.href = "/auth/sign-in";
      }
    } else if (error.response.status === 404) {
      message = "El recurso no existe";
    }
    return Promise.reject(error.response);
  }
);
function removeLocalStorage() {
  localStorage.removeItem("token");
}
function apiGet(url_code, getFilters = null, oid = null, contact_id = null) {
  var url = url_code != null ? apiUrl[url_code] : "";

  if (url == null || url.length == 0) {
    console.log("!!! GET ERROR : No url code found");
    return "";
  }

  if (oid && url.indexOf("{oid}") > 0) {
    url = url.replace("$", "");
    var temp = url.split("{oid}");
    url = temp[0] + oid + temp[1];
  }

  if (contact_id && url.indexOf("{contact_id}") > 0) {
    url = url.replace("$", "");
    var temp = url.split("{contact_id}");
    url = temp[0] + contact_id + temp[1];
  }

  var include,
    params,
    filters = [];
  var page,
    limit = 0;
  var search = null;
  var sort = null;

  if (getFilters != null) {
    include = getFilters.include != null ? getFilters.include : include;
    page = getFilters.page != null ? getFilters.page : page;
    limit = getFilters.limit != null ? getFilters.limit : limit;
    params = getFilters.params != null ? getFilters.params : params;
    filters = getFilters.filters != null ? getFilters.filters : filters;
    search = getFilters.search != null ? getFilters.search : search;
    sort = getFilters.sort != null ? getFilters.sort : sort;
  }
  var urlPart = "";
  var operator = "";
  if (include != null && include.length > 0) {
    include.forEach((element) => {
      operator = operator == "" ? "?" : "&";
      urlPart += operator == "?" ? "?include=" : ",";
      urlPart += element;
    });
  }
  if (page != null && page > 0) {
    operator = operator == "" ? "?" : "&";
    //urlPart += urlPart == "" ? "?" : "&";
    urlPart += operator + "page=" + page;
  }
  if (limit != null && limit > 0) {
    operator = operator == "" ? "?" : "&";
    //urlPart += urlPart == "" ? "?" : "&";
    urlPart += operator + "limit=" + limit;
  }
  /*if (params != null && params.length > 0) {
    params.forEach((element) => {
      urlPart += urlPart == "" ? "?" : "&";
      urlPart += element.code + "=" + element.value;
    });
  }*/
  if (search != null && search.length > 0) {
    operator = operator == "" ? "?" : "&";
    //urlPart += urlPart == "" ? "?" : "&";
    urlPart += operator + "search=" + search;
  }

  if (sort != null && sort.length > 0) {
    operator = operator == "" ? "?" : "&";
    //urlPart += urlPart == "" ? "?" : "&";
    urlPart += operator + "sort=" + sort;
  }
  url += urlPart;

  url = setParamsAndFiltersUrl(url, params, operator, filters);

  return instance.get(url);
  //return instance.get(`/accounting/expenseitems?include=accExpenseCategory`);
}

function apiPost(url_code, payload, getFilters = null, op = "post") {
  var url = url_code != null ? apiUrl[url_code] : "";

  if (url == null || url.length == 0) {
    console.log("!!! POST ERROR : No url code found");
    return "";
  }

  var params = [];
  if (getFilters != null) {
    params = getFilters.params != null ? getFilters.params : params;
  }

  url = setParamsAndFiltersUrl(url, params, "");

  return op == "post"
    ? instance.post(url, payload)
    : instance.put(url, payload);
}

function apiDelete(url_code, getFilters = null) {
  var url = url_code != null ? apiUrl[url_code] : "";

  if (url == null || url.length == 0) {
    console.log("!!! DELETE ERROR : No url code found");
    return "";
  }

  var params = [];
  if (getFilters != null) {
    params = getFilters.params != null ? getFilters.params : params;
  }

  url = setParamsAndFiltersUrl(url, params, "");

  return instance.delete(url);
}

function setParamsAndFiltersUrl(url, params, operator, filters = null) {
  //var params = _params!=null?_params.entries():null;
  if (params != null) {
    for (var key in params) {
      var value = params[key];
      var find = "${" + key + "}";
      if (url.includes(find)) {
        url = url.replace(find, value);
      } else {
        operator = operator == "" ? "?" : "&";
        url += operator + key + "=" + value;
      }
    }
  }
  if (filters != null) {
    for (var key in filters) {
      var value = filters[key];
      key = key.replace("___", ".");
      var str = "filter[" + key + "]=" + value;
      operator = operator == "" ? "?" : "&";
      url += operator + str;
    }
  }
  return url;
}

const apiUrl = {
  GETPAYMENTSACTIVITYREPORTSPRINT:
  "organizations/${oid}/getpaymentsactivityreportsprint",
  GETPAYMENTSACTIVITYREPORTS:
    "organizations/${oid}/getpaymentsactivityreports",

  GET_WORKWAREHOUSESOUTOFSTOCKITEMS:
    "/inventorynew/work/warehouses/${oid}/outofstockitems",
  GET_WORKWAREHOUSESSTOCKITEMS:
    "/inventorynew/work/warehouses/${oid}/stockitems",
  GET_WORKWAREHOUSES: "/inventorynew/work/warehouses",
  GET_POINTOFSALEWAREHOUSES: "/organizations/${oid}/pointofsalewarehouses",
  GET_INVENTORYWAREHOUSES: "/organizations/${oid}/warehouses",
  GET_PROVIDERS: "/organizations/${oid}/providers",
  GET_MOVEMENTTYPES: "/organizations/${oid}/movementtypes",
  GET_MOVEMENTREASONS: "/organizations/${oid}/movementreasons",
  GET_CONTACTFORMCATEGORIES: "/contactformtemplatecategories",
  GET_CONTACTFORMCATEGORY: "/organizations/${oid}/getcallshistoryorgnew",
  GET_CALLSORGNEW: "/organizations/${oid}/getcallshistoryorgnew",
  GET_CALLSORGNEWEXPORT: "/organizations/${oid}/getcallshistoryorgnewexport",

  GET_PAIDCOORDINATIONMANAGERPAYROLLREPORTPRINT:
    "organizations/${oid}/paidcoordinatorspayrollmanagerprint",
  GET_PAIDCOORDINATIONMANAGERPAYROLLREPORT:
    "organizations/${oid}/paidcoordinatorsmanagerpayroll",
  GET_COORDINATIONMANAGERPAYROLLREPORT:
    "organizations/${oid}/coordinatorsmanagerpayroll",
  GET_PAIDCOORDINATIONPAYROLLREPORTPRINT:
    "organizations/${oid}/paidcoordinatorspayrollprint",
  GET_PAIDCOORDINATIONPAYROLLREPORT:
    "organizations/${oid}/paidcoordinatorspayroll",
  GET_COORDINATIONPAYROLLREPORT: "organizations/${oid}/coordinatorspayroll",
  GET_PROCEDURESIMPLANTDONELISTREPORT:
    "organizations/${oid}/procedureanalyticsimplantdonereport",
  GET_PROCEDURESIMPLANTLISTREPORTPRINT:
    "organizations/${oid}/procedureanalyticsimplantreportprint",
  GET_PROCEDURESIMPLANTLISTREPORT:
    "organizations/${oid}/procedureanalyticsimplantreport",
  GET_PROCEDURESLISTREPORTPRINT:
    "organizations/${oid}/procedureanalyticsreportprint",
  GET_PROCEDURESLISTREPORT: "organizations/${oid}/procedureanalyticsreport",

  GET_PROCEDURESANALYTICSREPORT:
    "organizations/${oid}/procedureanalyticsreportnew",
  GET_SERVICESANALYTICSREPORT: "organizations/${oid}/servicesanalyticsreport",

  GET_PRODUCTSANALYTICSREPORT: "organizations/${oid}/productsanalyticsreport",

  GET_PAIDSALESMANPAYROLLPRINT:
    "organizations/${oid}/paidsalesmanpayrollreportprint",

  GET_PAIDSALESMANPAYROLL: "organizations/${oid}/paidsalesmanpayrollreport",

  GET_PAIDSALESMANAGERPAYROLLPRINT:
    "organizations/${oid}/paidsalesmanagerpayrollreportprint",

  GET_PAIDSALESMANAGERPAYROLL:
    "organizations/${oid}/paidsalesmanagerpayrollreport",

  GET_PENDINGSALESMANAGERPAYROLLREPORT:
    "organizations/${oid}/salesmanagerpayroll",

  GET_PAIDCOMMISSIONPAYROLLPRINT:
    "organizations/${oid}/paidcommissionpayrollreportprint",

  GET_PAIDCOMMISSIONPAYROLL: "organizations/${oid}/paidcommissionpayrollreport",

  GET_ANESTHESIOLOGISTSPAYROLL: "organizations/${oid}/anesthesiologistsreport",

  GET_ANESTHESIOLOGISTSPAYROLL_PRINT:
    "organizations/${oid}/anesthesiologistsreportprint",

  //commissionPayrollReport
  GET_PENDINGCOMMISSIONPAYROLLREPORT:
    "organizations/${oid}/pendingcommissionpayrollreport2",
  //commissionPayrollReportExport
  GET_PENDINGCOMMISSIONPAYROLLREPORTEXPORT:
    "organizations/${oid}/pendingcommissionpayrollreport2print",

  //paymentReconciliationReportExport
  PAYMENTRECONCILIATIONREPORTEXPORT:
    "organizations/${oid}/paymentreconciliationreportexport",

  //paymentReconciliationReport
  GET_PAYMENTRECONCILIATIONREPORT:
    "organizations/${oid}/paymentreconciliationreport",

  //productsServicesReport
  GET_CONTACTACTIVITYREPORT: "organizations/${oid}/contactactivityreport",

  //productsServicesReport
  GET_PRODUCTSSERVICESREPORT: "organizations/${oid}/productsServicesReport",
  GET_PRODUCTSSERVICESREPORTNEW:
    "organizations/${oid}/productsServicesReportnew",

  //payrollSalesExport
  GET_PAYROLLSALESEXPORT: "organizations/${oid}/payrollsellsorgexport",

  //payrollSalesPrint
  GET_PAYROLLSALESPRINT: "organizations/${oid}/payrollsellsorgprint",

  //payrollSales
  GET_PAYROLLSALES: "organizations/${oid}/payrollsellsorg",

  //payrollSalesTotals
  GET_PAYROLLSALESREPORTTOTALS: "organizations/${oid}/getuserpayrollsorgtotal",

  //payrollSalesReportPrint
  GET_PAYROLLSALESREPORTPRINT: "organizations/${oid}/getuserpayrollsorgprint",

  //payrollSalesReport
  GET_PAYROLLSALESREPORT: "organizations/${oid}/getuserpayrollsorg",

  //payrollConfigurationRules
  GET_PAYROLLCONFIGURATIONRULES:
    "organizations/${oid}/payrollconfigurationrules",

  //payrollConfigurations
  GET_PAYROLLCONFIGURATIONS: "organizations/${oid}/payrollconfigurations",

  //dataSliceMyQuotesnewReports2
  GET_GENERALCONTRACTSREPORTEXPORT:
    "organizations/${oid}/generalContractReportExport",

  //dataSliceMyQuotesnewReports2
  GET_GENERALCONTRACTSREPORT: "organizations/${oid}/generalContractReport",

  //InvoiceDiscountReport
  GET_INVOICEDISCOUNTREPORT: "organizations/${oid}/invoiceDiscountReport",

  //QuoteDiscountReport
  GET_QUOTEDISCOUNTREPORT: "organizations/${oid}/quoteDiscountReport",

  /* CONTACTMETADATAREPORT EXPORT*/
  GET_CONTACTMETADATAREPORTEXPORT:
    "organizations/${oid}/contactmetadatareportsExport",

  /* CONTACTMETADATAREPORT */
  GET_CONTACTMETADATAREPORT: "/organizations/${oid}/contactmetadatareports",

  /* CONTACTMETADATACATEGORIES */
  GET_CONTACTMETADATACATEGORIES:
    "/organizations/${oid}/contactmetadatacategories",

  /* WhatsAppGroups */
  GET_WHATSAPPGROUPS: "/organizations/${oid}/whatsappgroups",

  /* WhatsAppApplications */
  GET_WHATSAPPAPPLICATION: "/organizations/${oid}/whatsappapplication",

  /* WhatsAppConfigurations */
  GET_WHATSAPPCONFIGURATIONS: "/organizations/${oid}/whatsAppConfiguration",

  //recordProductTransfer
  GET_RECORDPRODUCTTRANSFER: "/organizations/${oid}/coordRecordProductTransfer",
  // procedureReportDashboard
  GET_PROCEDUREREPORTDASHBOARD:
    "/organizations/${oid}/recordproducts_report_bydoctor",

  // invoicesForfeitureReport
  GET_INVOICESFORFEITUREREPORT:
    "/organizations/${oid}/getInvoicePaymentHistoryForfeiture",

  // userChangeRolesReasons
  GET_USERCHANGEROLESHISTORIES:
    "/organizations/${oid}/userchangeroleshistories",

  // userChangeRolesReasons
  GET_USERCHANGEROLESREASONS: "/userchangerolesreasons",

  //documentTypes
  GET_DOCUMENTTYPES: "organizations/${oid}/doctypes",

  //cancellationMotives
  GET_CANCELLATIONMOTIVES: "organizations/${oid}/cancellationmotives",

  //recordProductsChangesLogsbyHistory
  GET_RECORDPRODUCTLOGSREPORTBYHISTORY:
    "organizations/${oid}/recordproductschangesbyhistory",

  //recordProductsChangesLogsReport
  GET_RECORDPRODUCTLOGSREPORT:
    "organizations/${oid}/getrecordproductschangeslogs",

  //RecordProductLogsGrouped
  GET_RECORDPRODUCTLOGSGROUPED:
    "organizations/${oid}/getrecordproductschangeslogsgrouped",

  //procedureCanceledReportsExport
  GET_PROCEDURECANCELEDREPORTSEXPORT:
    "/organizations/${oid}/procedureCanceledReportsExport",

  //recordProductsPendingExport
  GET_RECORDPRODUCTSPENDINGEXPORT:
    "/organizations/${oid}/recordproductsPendingToRescheduleExport",

  //pendingToScheduleReportsExport
  GET_PENDINGTOSCHEDULEREPORTSEXPORT:
    "/organizations/${oid}/recordproductsPendingToScheduleReportsgroupedExport",

  //chargebackReport
  GET_CHARGEBACKREPORT: "organizations/${oid}/refundsChargeback",

  GET_RECORDEXPORT: "organizations/${oid}/recordsExport",

  GET_CONTACTEXPORT: "organizations/${oid}/contactsExport",

  //invoiceLogsReport
  GET_INVOICELOGSREPORT: "organizations/${oid}/getinvoiceschangeslogs",

  //whiteBlackList

  GET_BLACKWHITELIST: "organizations/${oid}/whiteblackrules",
  GET_WHITELIST: "organizations/${oid}/whiterules",
  GET_BLACKLIST: "organizations/${oid}/blackrules",

  //firstDepositReport
  GET_FIRSTDEPOSITREPORT: "organizations/${oid}/getdepositcontacts",

  //Email client
  GET_EMAIL_ACCOUNTS: "/emailclient",
  POST_EMAIL_ACCOUNTS: "/emailclient",
  POST_EMAIL_ACCOUNTS_OAUTH_CODE_GMAIL: "/emailclient/oauth/gmail",
  GET_EMAIL_KNOWN_CONF: "/emailclient/known_conf",
  GET_EMAIL_FOLDERS: "/emailclient/folders/${account}",
  GET_EMAIL_FOLDERS_MESSAGES: "/emailclient/folders/${account}/messages",
  GET_EMAIL_FOLDERS_MESSAGE: "/emailclient/folders/${account}/message",
  POST_EMAIL_MESSAGE: "/emailclient/${account}/send",
  DELETE_EMAIL_MESSAGE: "/emailclient/${account}",
  // GET_EMAIL_MESSAGE_BODY: '/emailclient/${account}/body',

  // recordsTransferredReport
  GET_RECORDSTRANSFERREDREPORT:
    "organizations/${oid}/transferpatientsdoctorreport",

  // recordContactMarked
  GET_RECORDMARKED: "organizations/${oid}/records_plaincontactmarked",

  // creditMemoReasons
  GET_CREDITMEMOREASONS: "organizations/${oid}/creditmemoreasons",
  // doctorsConfiguration
  GET_DOCTORSCONFIGURATION: "organizations/${oid}/doctorconfig",

  // comparativeSalesAnalysis
  GET_RECORDSDEPOSITREPORT: "organizations/${oid}/recordsDeposit3",

  GETCOMPARATIVESALESANALYSIS: "organizations/${oid}/compsanalysis2",

  // payrollGroupedReport
  GET_PRODUCTSNOPROCEDUREPAYROLL:
    "organizations/${oid}/productsnoprocedurepayroll",

  // financingContactReport
  GET_FINANCINGCONTACTS: "/organizations/${oid}/financingContacts",

  // accountingSalesReport

  GETACCOUNTINGSALESREPORTWSALESDATA1:
    "/organizations/${oid}/getaccountingsalesreportwsalesdata1",
  GETACCOUNTINGSALESREPORTWSALESDATA2:
    "/organizations/${oid}/getaccountingsalesreportwsalesdata2",
  GETACCOUNTINGSALESREPORTWSALESDATA3:
    "/organizations/${oid}/getaccountingsalesreportwsalesdata3",

  GET_ACCOUNTINGSALESREPORT: "/organizations/${oid}/accountingreport3",
  GET_ACCOUNTINGSALESREPORT1: "/organizations/${oid}/accountingreportnew1a/1",
  GET_ACCOUNTINGSALESREPORT2: "/organizations/${oid}/accountingreportnew2b/1",
  GET_ACCOUNTINGSALESREPORT3: "/organizations/${oid}/accountingreportnew3b/1",

  GET_ACCOUNTINGSALESREPORT1New:
    "/organizations/${oid}/accountingreportprocedures/1",
  GET_ACCOUNTINGSALESREPORT2New:
    "/organizations/${oid}/accountingreportservices/1",
  GET_ACCOUNTINGSALESREPORT3New:
    "/organizations/${oid}/accountingreportproducts/1",

  GET_ACCOUNTINGSALESREPORT4: "/organizations/${oid}/accountingreportnew1a/2",
  GET_ACCOUNTINGSALESREPORT5: "/organizations/${oid}/accountingreportnew2b/2",
  GET_ACCOUNTINGSALESREPORT6: "/organizations/${oid}/accountingreportnew3b/2",

  //contactsRecordsByZip
  GET_CONTACTSRECORDSBYZIP: "/organizations/${oid}/getcontactsrecordsbyzip",

  //financedPatientsReport
  GET_FINANCEDPATIENTSREPORT: "/organizations/${oid}/financedpatientsreport",

  GET_DEPARTMENTSDROPDOWN: "/organizations/${oid}/departmentsDropdown",
  /* liveNotifications */
  GET_LIVENOTIFICATIONS: "/users/${oid}/userNotifications",

  /* commentCategories */
  GET_COMMENTCATEGORIES: "/organizations/${oid}/commentcategories",
  POST_COMMENTCATEGORY: "/commentcategories",
  PUT_COMMENTCATEGORY: "/commentcategories/${id}",
  DELETE_COMMENTCATEGORY: "/commentcategories/${id}",

  //badContactReport
  GET_BADCONTACTSREPORT: "organizations/${oid}/badcontacts",
  //payrollGroupedReport
  GET_PAYROLLGROUPREPORT: "organizations/${oid}/recordproducts_payrollGrouped",

  //tagsEdit
  GET_TAGSEDIT: "/organizations/${oid}/tags2",
  POST_TAGEDIT: "/tags",
  PUT_TAGEDIT: "/tags/${id}",
  DELETE_TAGEDIT: "/tags/${id}",

  //contactAppointments
  GET_CONTACT_APPOINTMENTS: "/contacts/${oid}/appointments",
  POST_CONTACT_APPOINTMENTS: "/appointments",
  PUT_CONTACT_APPOINTMENTS: "/appointments/${id}",
  DELETE_CONTACT_APPOINTMENTS: "/appointments/${id}",

  //contactsNewSearch
  GET_CONTACTSNEWSEARCH: "/organizations/${oid}/contacts_new_search2",
  POST_CONTACTSNEWSEARCH: "/contacts",
  PUT_CONTACTSNEWSEARCH: "/contacts/${id}",
  DELETE_CONTACTSNEWSEARCH: "/contacts/${id}",

  //agentList
  GET_AGENTLIST: "/organizations/${oid}/agentlist",
  PUT_AGENTLIST: "/users/${id}",

  //procedureRefundedReports
  GET_RECORDPRODUCTSREFUNDREPORTS:
    "/organizations/${oid}/recordproductsRefundReports",

  //procedureRefundedReports
  GET_RECORDPRODUCTSREFUNDREPORTS:
    "/organizations/${oid}/recordproductsRefundReports",

  //procedureNoDoctorReports
  GET_PROCEDURENODOCTORREPORTS: "/organizations/${oid}/recordproductsNoDoctors",

  //doctorRemarksTemplates
  GET_DOCTORREMARKSTEMPLATES: "/organizations/${oid}/doctorremarkstemplate",
  POST_DOCTORREMARKSTEMPLATES: "/doctorremarkstemplate",
  PUT_DOCTORREMARKSTEMPLATES: "/doctorremarkstemplate/${id}",
  DELETE_DOCTORREMARKSTEMPLATES: "/doctorremarkstemplate/${id}",

  //creditReport
  GET_CREDITREPORT: "/organizations/${oid}/creditbuildingreport",
  //coordinatorsList
  GET_COORDINATORSLIST: "/organizations/${oid}/coordinatorslist",
  PUT_COORDINATORSLIST: "/users/${id}",

  //unassignedContacts
  GET_UNASSIGNEDCONTACTS: "/organizations/${oid}/unassigned_contacts2",

  //callsOrg
  GET_CALLSORG: "/organizations/${oid}/getcallshistoryorg",

  //productGiftReport

  GET_PRODUCTREPORT: "/organizations/${oid}/giftreport",

  //socialPlatforms
  GET_SOCIALPLATFORMS: "/organizations/${oid}/socialplatforms",
  POST_SOCIALPLATFORMS: "/socialplatforms",
  PUT_SOCIALPLATFORMS: "/socialplatforms/${id}",
  DELETE_SOCIALPLATFORMS: "/socialplatforms/${id}",

  //usersGeneralDropdown
  GET_USERSGENERALDROPDOWN: "/organizations/${oid}/usersgeneraldropdown",
  //userLogin
  GET_USERLOGIN: "/organizations/${oid}/userslogin",

  //groupsDropdown
  GET_GROUPSDROPDOWN: "/organizations/${oid}/groupsdropdown",

  //assistantsDropdown
  GET_ASSISTANTSDROPDOWN: "/organizations/${oid}/assistantsdropdown",
  //salesGroupsDropdown
  GET_SALESGROUPSDROPDOWN: "/organizations/${oid}/salesgroupsdropdown",

  //salesGroupBuildingDropdown
  GET_SALESGROUPBUILDINGDROPDOWN:
    "/organizations/${oid}/salesgroupbuildingdropdown",

  //noprocedureDropdown
  GET_NOPROCEDUREDROPDOWN: "/organizations/${oid}/noproceduredropdown",

  //proceduresDropdown
  GET_PROCEDURESDROPDOWN: "/organizations/${oid}/proceduresdropdown",

  //coordinatorsDropdown
  GET_COORDINATORSDROPDOWN: "/organizations/${oid}/coordinatorsdropdown",

  //coordinatorsDropdown
  GET_COORDINATORSGENERALDROPDOWN:
    "/organizations/${oid}/coordinatorsgeneraldropdown",

  //surgeonDropdown
  GET_SURGEONDROPDOWN: "/organizations/${oid}/surgeondropdown",
  GET_SURGEONDROPDOWNRESTRICTED:
    "/organizations/${oid}/surgeondropdown-restricted",

  //sourcesNewDropdown
  GET_SOURCESNEWDROPDOWN: "/organizations/${oid}/contactssourcedropdown",

  //sourcesNew
  GET_SOURCESNEW: "/organizations/${oid}/contactssource",
  POST_SOURCESNEW: "/contactssource",
  PUT_SOURCESNEW: "/contactssource/${id}",
  DELETE_SOURCESNEW: "/contactssource/${id}",

  //consultantBuildingDropdown

  GET_CONSULTANTBUILDINGDROPDOWN:
    "/organizations/${oid}/consultantbuildingdropdown",

  //consultantGeneralDropdown

  GET_CONSULTANTGENERALDROPDOWN:
    "/organizations/${oid}/consultantgeneralgropdown",

  //buildingsDropdown

  GET_BUILDINGSDROPDOWN: "/organizations/${oid}/buildings_dropdown",
  GET_BUILDINGSDROPDOWNRESTRICTED:
    "/organizations/${oid}/buildings_dropdown_restricted",

  //contactsBySource
  GET_CONTACTSBYSOURCE: "/organizations/${oid}/contactsBySource",

  //recordProductsReportPA
  GET_RECORDPRODUCTSREPORTPA: "/organizations/${oid}/recordproducts_report_pa",

  //records
  GET_RECORDSTOREMARKETING: "/remarketingcampaigns/records",
  GET_REMARKETINGRECORDS: "/remarketingcampaigns/report",

  //Directory
  GET_USERSDIRECTORY: "/organizations/${oid}/usersdirectory",

  /* Records Personal Assistant */
  GET_RECORDPA: "/organizations/${oid}/records_plain4",

  /*callsReports*/
  GET_CALLSREPORT: "/organizations/${oid}/callsReport",

  //salesBySourceReport

  GET_SALESBYSOURCE: "/organizations/${oid}/salesBySources",

  //productGiftReason
  GET_PRODUCTGIFTREASON: "/organizations/${oid}/productgiftreason",
  POST_PRODUCTGIFTREASON: "/productgiftreason",
  PUT_PRODUCTGIFTREASON: "/productgiftreason/${id}",
  DELETE_PRODUCTGIFTREASON: "/productgiftreason/${id}",

  //products
  GET_PRODUCTSTOFDIRECTORY: "/organizations/${oid}/directoryactiveproducts",

  GET_PRODUCTSTOFDIRECTORYPRINT:
    "/organizations/${oid}/directoryactiveproductsprint",

  /*productsNoProcedure*/

  GET_PRODUCTSNOPROCEDURE: "/organizations/${oid}/productsNoProcedureReport3",
  GET_PRODUCTSNOPROCEDURENEW:
    "/organizations/${oid}/productsNoProcedureReport4",
  POST_PRODUCTSNOPROCEDURE: "/contacts/${id}/productscontrol",

  /*smsReports*/
  GET_SMSREPORT: "/organizations/${oid}/smsMMSReport2",

  /*ticketReports*/
  GET_TICKETREPORTS: "/organizations/${oid}/ticketsReport",

  /*salesReportNew*/

  GET_SALESREPORT: "/organizations/${oid}/salesReportbyuser",
  /*userCallsNew*/

  GET_SALESREPORT2: "/organizations/${oid}/salesReportbyuser2",
  /*userCallsNew*/

  GET_SALESREPORTWITHDOCTOR:
    "/organizations/${oid}/salesReportbyuserWithDoctor2",
  /*userCallsNew*/

  GET_USERCALLSNEW: "/users/${oid}/getUserCallsHistory",

  /*contactCalls*/

  GET_CONTACTCALLS: "/contacts/${oid}/getContactCallsHistory",

  /*userContactsSMSMMS*/

  GET_USERCONTACTSMSMMS: "/users/${oid}/getUserContactSMSMMS2",
  GET_USERCONTACTSMSMMSBYGROUPS: "/users/${oid}/getUserContactSMSMMS2ByGroups",

  /*refundChargeBackReport*/

  GET_REFUNDCHARGEBACKREPORT: "/organizations/${oid}/refundsChargeBackReport",
  GET_REFUNDCHARGEBACKRISK: "/organizations/${oid}/refundsChargeBackRiskReport",

  /*recordProductsPending*/

  GET_RECORDPRODUCTSPENDINGTOSCHEDULE:
    "/organizations/${oid}/recordproductsPendingToScheduleReportsgrouped",
  PUT_RECORDPRODUCTSPENDINGTOSCHEDULE: "/recordproducts",

  GET_RECORDPRODUCTSCANCELED:
    "/organizations/${oid}/recordproductsCanceledReports",

  /*consultantsSalesReport*/

  GET_SURGEONQUOTESRECORDPRODUCTREPORTS:
    "/organizations/${oid}/surgeonContracts",

  /*consultantsSalesReport*/

  GET_CONSULTANTSSALESREPORT: "/organizations/${oid}/consultantssalesreport",

  /*groupsNew*/

  GET_GROUPSNEW: "/organizations/${oid}/groups2",
  POST_GROUPNEW: "/groups",
  PUT_GROUPNEW: "/groups/${id}",
  DELETE_GROUPNEW: "/groups/${id}",

  /*buildingsNew*/

  GET_BUILDINGSNEW: "/organizations/${oid}/buildings2",
  POST_BUILDINGNEW: "/buildings",
  PUT_BUILDINGNEW: "/buildings/${id}",
  DELETE_BUILDINGNEW: "/buildings/${id}",

  /*departmentsNew*/

  GET_DEPARTMENTSNEW: "/organizations/${oid}/departments2",
  POST_DEPARTMENTNEW: "/departments",
  PUT_DEPARTMENTNEW: "/departments/${id}",
  DELETE_DEPARTMENTNEW: "/departments/${id}",

  //deactivateHistory
  GET_DEACTIVATEHISTORY: "/usersdeactivatehistory",

  /*categoriesNew*/
  GET_CATEGORIESNEW: "/organizations/${oid}/categoriesnew",
  POST_CATEGORYNEW: "/categories",
  PUT_CATEGORYNEW: "/categories/${id}",
  DELETE_CATEGORYNEW: "/categories/${id}",

  /*SMSMMSTemplatesCategories*/
  GET_SMSMMSTEMPLATESCATEGORIES: "/smsmmstemplatescategories",
  POST_SMSMMSTEMPLATESCATEGORIES: "/smsmmstemplatescategories",
  PUT_SMSMMSTEMPLATESCATEGORIES: "/smsmmstemplatescategories/${id}",
  DELETE_SMSMMSTEMPLATESCATEGORIES: "/smsmmstemplatescategories/${id}",

  //SMSMMSTemplates
  GET_SMSMMSTEMPLATES: "/smsmmstemplates",
  POST_SMSMMSTEMPLATES: "/smsmmstemplates",
  PUT_SMSMMSTEMPLATES: "/smsmmstemplates/${id}",
  DELETE_SMSMMSTEMPLATES: "/smsmmstemplates/${id}",

  //appointmentsnew
  GET_APPOINTMENTSREPORTDATA: "appointmentsreport",
  GET_PENDINGAPPOINTMENTSREPORTDATA: "pendingappointmentsreport",
  GET_APPOINTMENTSDAILYMASSAGERECORD: "/appointmentsnew/dailymassagerecord",

  //fraudolent contacts
  GET_FRAUDOLENTCONTACTS: "/contacts/fraudolentscontacts",

  /*communicationGroups*/
  GET_COMMUNICATIONGROUP: "/users/fororg/${oid}/show/comunication/group",
  POST_COMMUNICATIONGROUP: "/users/create/comunication/group",
  PUT_COMMUNICATIONGROUP: "/users/update/${id}/comunication/group",
  DELETE_COMMUNICATIONGROUP: "/users/delete/${id}/comunication/group",

  //contactsMassagesControl
  GET_CONTACTSMASSAGESCONTROL: "/organizations/${oid}/massagescontrol",
  POST_CONTACTSMASSAGESCONTROL: "/contacts/${id}/massagescontrol",

  //templatesCategoriesNew
  GET_TEMPLATESCATEGORIES: "/organizations/${oid}/templatescategories",
  POST_TEMPLATESCATEGORY: "/templatescategories",
  PUT_TEMPLATESCATEGORY: "/templatescategories/${id}",
  DELETE_TEMPLATESCATEGORY: "/templatescategories/${id}",

  //refundRequestseNew
  GET_REFUNDREQUESTSPRINT: "/organizations/${oid}/refundrequests2print",
  GET_REFUNDREQUESTS: "/organizations/${oid}/refundrequests2",
  POST_REFUNDREQUEST: "/refundrequests",
  PUT_REFUNDREQUEST: "/refundrequests/${id}",
  DELETE_REFUNDREQUEST: "/refundrequests/${id}",

  //appointmentsNew
  GET_APPOINTMENTS: "/organizations/${oid}/appointments",
  GET_APPOINTMENTSNODATE: "/organizations/${oid}/appointmentsnotdate",
  POST_APPOINTMENT: "/appointments",
  PUT_APPOINTMENT: "/appointments/${id}",
  DELETE_APPOINTMENT: "/appointments/${id}",

  //templatesNew
  GET_TEMPLATES: "/organizations/${oid}/templates2",
  POST_TEMPLATE: "/templates",
  PUT_TEMPLATE: "/templates/${id}",
  DELETE_TEMPLATE: "/templates/${id}",

  /*users2*/

  GET_USERS2: "/organizations/${oid}/users2",

  POST_USER2: "/users",
  PUT_USER2: "/users/${id}",
  PUT_USER2PLAIN: "/usersPlain/${id}",
  DELETE_USER2: "/users/${id}",
  POST_USER2: "/users",

  /*usersNew*/

  GET_USERSNEW: "/organizations/${oid}/consultantslist",
  // POST_USERNEW: "/usersnew",
  PUT_USERNEW: "/users/${id}",
  // DELETE_USERNEW: "/usersnew/${id}",

  /*doctorsNew*/

  GET_DOCTORSNEW: "/organizations/${oid}/doctorslist",
  // POST_USERNEW: "/usersnew",
  PUT_DOCTORSNEW: "/users/${id}",
  // DELETE_USERNEW: "/usersnew/${id}",

  /* ContractReport*/
  GET_CONTRACT_REPORT: "/organizations/${oid}/quotes_plain5",

  /*payrollNew*/

  GET_PAYROLLS: "/organizations/${oid}/recordproducts_payroll",
  GET_PAYROLLS2: "/organizations/${oid}/recordproducts_payroll_new",

  GET_PAYROLLS_ASSISTANTS: "/organizations/${oid}/assistantreport2",

  /* Activity Log Report for invoices Payments*/
  GET_INVOICES_PAYMENTS_ACTIVITY_REPORT: "/invoices/activity/log/report",

  /*productsNew*/

  // GET_PRODUCTSNEW: "/organizations/${oid}/products2",
  GET_PRODUCTSNEW: "/organizations/${oid}/productsplain",
  POST_PRODUCTSNEW: "/products",
  PUT_PRODUCTSNEW: "/products/${id}",
  DELETE_PRODUCTSNEW: "/products/${id}",

  /*recordProductsPending*/

  GET_RECORDPRODUCTSPENDING:
    "/organizations/${oid}/recordproductsPendingToRescheduleReports",
  POST_RECORDPRODUCTPENDING: "/recordproducts",
  PUT_RECORDPRODUCTPENDING: "/recordproducts/${id}",
  DELETE_RECORDPRODUCTPENDING: "/recordproducts/${id}",

  GET_RECORDPRODUCTSPENDINGTORESCHEDULE:
    "/organizations/${oid}/recordproductsPendingToReschedule",

  /*recordProductsTransfer*/

  GET_RECORDPRODUCTSTRANFER: "/organizations/${oid}/recordproducttransfers",
  GET_RECORDPRODUCTSTRANFEREXPORT:
    "/organizations/${oid}/recordproducttransfersexport",
  POST_RECORDPRODUCTSTRANFER: "/recordproducttransfers",
  PUT_RECORDPRODUCTSTRANFER: "/recordproducttransfers/${id}",
  DELETE_RECORDPRODUCTSTRANFER: "/recordproducttransfers/${id}",

  /*recordProductsDateTransfer*/
  GET_RECORDPRODUCTSDATETRANFER: "/requestdatetransfers_report",
  /*recordProductCosts*/

  GET_RECORDPRODUCTSCOSTS: "/organizations/${oid}/recordproductcosts",
  POST_RECORDPRODUCTSCOST: "/recordproductcosts",
  PUT_RECORDPRODUCTSCOST: "/recordproductcosts/${id}",
  DELETE_RECORDPRODUCTSCOST: "/recordproductcosts/${id}",

  /* Invoicesnew */
  GET_INVOICESNEW: "/organizations/${oid}/invoices_plain",
  POST_INVOICENEW: "/invoices",
  PUT_INVOICENEW: "/invoices/${id}",
  DELETE_INVOICENEW: "/invoices/${id}",

  /* Records */
  //GET_RECORD: "/organizations/${oid}/records_plain3",
  GET_RECORD: "/organizations/${oid}/records_plain_fromindex",
  GETALL_RECORD: "/records/all",
  POST_RECORD: "/records",
  PUT_RECORD: "/records/${id}",
  DELETE_RECORD: "/records/${id}",
  GET_RECORD_SEARCH: "/organizations/${oid}/record_search",
  /* RecordProducts */
  GET_RECORDPRODUCTS: "/organizations/${oid}/recordproducts_new",
  GET_RECORDPRODUCTSREPORT: "/organizations/${oid}/recordproducts_report",
  GET_RECORDPRODUCTSREPORTEXPORT:
    "/organizations/${oid}/recordproducts_reportprint",
  GET_RECORDPRODUCTSREPORTEXPORTPROCEDURE:
    "/organizations/${oid}/recordproducts_reportprintprocedure",
  POST_RECORDPRODUCT: "/recordproducts",
  PUT_RECORDPRODUCT: "/recordproducts/${id}",
  DELETE_RECORDPRODUCT: "/recordproducts/${id}",

  /* GET RECORDS WITH PRODUCTS FOR NEW PROCEDURE REPORT */
  GET_RECORD_WITH_PRODUCTS: "/organizations/${oid}/recordproducts_new_grouped3",

  GET_RECORD_WITH_PRODUCTS_NOBALANCE:
    "/organizations/${oid}/recordproducts_new_grouped",
  /* quotesNew */
  GET_QUOTES: "/organizations/${oid}/quotes_plain2",
  GET_QUOTES2: "/organizations/${oid}/quotes_plain2A",
  POST_QUOTE: "/quotes",
  PUT_QUOTE: "/quotes/${id}",
  DELETE_QUOTE: "/quotes/${id}",

  /* invoicesNew */
  GET_INVOICES: "/organizations/${oid}/invoices_plain2",
  GET_INVOICES3: "/organizations/${oid}/invoices_plain3",
  POST_INVOICE: "/invoices",
  PUT_INVOICE: "/invoices/${id}",
  DELETE_INVOICE: "/invoices/${id}",
  GET_INVOICESTOAPPLYDISCOUNT: "/organizations/${oid}/invoicestoapplydiscount",

  /* APPOINTMENTSMOTIVES */
  GET_APPOINTMENTSMOTIVES: "/organizations/${oid}/appointmentsmotives",

  GETALL_APPOINTMENTSMOTIVES: "/appointmentsmotives/all",
  POST_APPOINTMENTSMOTIVE: "/appointmentsmotives",
  PUT_APPOINTMENTSMOTIVE: "/appointmentsmotives/${id}",
  DELETE_APPOINTMENTSMOTIVE: "/appointmentsmotives/${id}",

  /* Contacts */
  GET_CONTACT: "/organizations/${oid}/contacts_plain_new",
  GET_CONTACTP: "/organizations/${oid}/contacts_plain_new2",
  //GET_CONTACTN: "/organizations/${oid}/contacts_plain_new5",
  GET_CONTACTN: "/organizations/${oid}/contacts_plain_fromindex",
  GET_CONTACTPLAIN: "/organizations/${oid}/contacts_plain2",
  GETALL_CONTACT: "/contacts/all",
  GET_SOURCES: "contacts/sources",
  POST_CONTACT: "/contacts",
  PUT_CONTACT: "/contacts/${id}",
  PUT_CONTACT_PLAIN: "/contacts/${id}/updateplain",
  DELETE_CONTACT: "/contacts/${id}",

  /* Rooms */
  GET_ROOMS: "/organizations/${oid}/rooms",
  // GETALL_ROOMS: "/rooms/all",
  POST_ROOM: "/rooms",
  PUT_ROOM: "/rooms/${id}",
  DELETE_ROOM: "/rooms/${id}",

  /* ROOM SERVICES */
  GET_ROOMSERVICES: "/organizations/${oid}/rooms_services",
  // GETALL_ROOMSERVICES: "/rooms_services/all",
  POST_ROOMSERVICE: "/roomsservices",
  PUT_ROOMSERVICE: "/roomsservices/${id}",
  DELETE_ROOMSERVICE: "/roomsservices/${id}",

  /* ROOM STATES */
  GET_ROOMSTATES: "/organizations/${oid}/rooms_states",
  // GETALL_ROOMSTATES: "/rooms_states/all",
  POST_ROOMSTATE: "/roomsstates",
  PUT_ROOMSTATE: "/roomsstates/${id}",
  DELETE_ROOMSTATE: "/roomsstates/${id}",

  /* FINANCING */
  GET_FINANCING: "/organizations/${oid}/financing",
  POST_FINANCING: "/organizations/financing/store",
  PUT_FINANCING: "/organizations/financing/${id}",
  DELETE_FINANCING: "/organizations/financing/${id}",

  /* ExpenseCategory */
  GET_ACC_EXPENSECATEGORY: "/accounting/expensecategories",
  GETALL_ACC_EXPENSECATEGORY: "/accounting/expensecategories/all",
  POST_ACC_EXPENSECATEGORY: "/accounting/expensecategory",
  PUT_ACC_EXPENSECATEGORY: "/accounting/expensecategory/${id}",
  DELETE_ACC_EXPENSECATEGORY: "/accounting/expensecategory/${id}",

  /* ExpenseItem */
  GET_ACC_EXPENSEITEM: "/accounting/expenseitems",
  POST_ACC_EXPENSEITEM: "/accounting/expenseitem",
  PUT_ACC_EXPENSEITEM: "/accounting/expenseitem/${id}",
  DELETE_ACC_EXPENSEITEM: "/accounting/expenseitem/${id}",

  /* Expense */
  GET_ACC_EXPENSE: "/accounting/expenses",
  POST_ACC_EXPENSE: "/accounting/expense",
  PUT_ACC_EXPENSE: "/accounting/expense/${id}",
  DELETE_ACC_EXPENSE: "/accounting/expense/${id}",

  /* Invoices2 */
  GET_INVOICES2: "/organizations/${oid}/invoices2",
  POST_INVOICE2: "/invoices2",
  PUT_INVOICE2: "/invoices2/${id}",
  DELETE_INVOICE2: "/invoices2/${id}",

  /* Acc_Cash_Box */
  GET_ACC_CASHBOX: "/accounting/cashBoxes",
  POST_ACC_CASHBOX: "/accounting/cashBox",
  PUT_ACC_CASHBOX: "/accounting/cashBox/${id}",
  DELETE_ACC_CASHBOX: "/accounting/cashBox/${id}",
  POST_ACC_CASHBOXOPERATION: "/accounting/cashBox/operation",
  POST_ACC_CASHBOXTRANSFER: "/accounting/cashBox/transfer",

  /* CustomFields */
  GET_CUSTOMFIELDS: "/organizations/${oid}/customfields?include=modeltype",
  GET_CUSTOMFIELDS_CONTACT: "/organizations/${oid}/customfields/contact",
  POST_CUSTOMFIELDS: "/customfields",
  PUT_CUSTOMFIELDS: "/customfields/${id}",
  DELETE_CUSTOMFIELDS: "/customfields/${id}",

  /* CashFlow */
  GET_ACC_CASHFLOW: "/accounting/cashflow",

  /* AccFlowTypes */
  GET_ACC_FLOWTYPES: "/accounting/flowtypes",

  /* InventoryCategory */
  GET_ACC_INVCATEGORY: "/inventory/invcategories",
  GETALL_ACC_INVCATEGORY: "/inventory/invcategories/all",
  POST_ACC_INVCATEGORY: "/inventory/invcategory",
  PUT_ACC_INVCATEGORY: "/inventory/invcategory/${id}",
  DELETE_ACC_INVCATEGORY: "/inventory/invcategory/${id}",

  /* InventoryProduct */
  GET_ACC_INVPRODUCT: "/inventory/invproducts",
  POST_ACC_INVPRODUCT: "/inventory/invproduct",
  PUT_ACC_INVPRODUCT: "/inventory/invproductupd/${id}",
  DELETE_ACC_INVPRODUCT: "/inventory/invproduct/${id}",

  /* InventoryMeasure */
  GET_ACC_INVMEASURE: "/inventory/invmeasures",
  GETALL_ACC_INVMEASURE: "/inventory/invmeasures/all",
  POST_ACC_INVMEASURE: "/inventory/invmeasure",
  PUT_ACC_INVMEASURE: "/inventory/invmeasure/${id}",
  DELETE_ACC_INVMEASURE: "/inventory/invmeasure/${id}",

  /* InventoryLocalization */
  GET_ACC_INVLOCALIZATION: "/inventory/invlocalizations",
  POST_ACC_INVLOCALIZATION: "/inventory/invlocalization",
  PUT_ACC_INVLOCALIZATION: "/inventory/invlocalization/${id}",
  DELETE_ACC_INVLOCALIZATION: "/inventory/invlocalization/${id}",

  /* InventoryWarehouseType */
  GET_ACC_INVWAREHOUSETYPE: "/inventory/invwhtypes",
  POST_ACC_INVWAREHOUSETYPE: "/inventory/invwhtype",
  PUT_ACC_INVWAREHOUSETYPE: "/inventory/invwhtype/${id}",
  DELETE_ACC_INVWAREHOUSETYPE: "/inventory/invwhtype/${id}",

  /* InventoryWarehouse */
  GET_ACC_INVWAREHOUSE: "/inventory/invwarehouses",
  GET_ACC_INVWAREHOUSE_ALLOW: "/inventory/invwarehousesallow",
  POST_ACC_INVWAREHOUSE: "/inventory/invwarehouse",
  PUT_ACC_INVWAREHOUSE: "/inventory/invwarehouse/${id}",
  DELETE_ACC_INVWAREHOUSE: "/inventory/invwarehouse/${id}",

  /* InventoryProvider */
  GET_ACC_INVPROVIDER: "/inventory/invproviders",
  POST_ACC_INVPROVIDER: "/inventory/invprovider",
  PUT_ACC_INVPROVIDER: "/inventory/invprovider/${id}",
  DELETE_ACC_INVPROVIDER: "/inventory/invprovider/${id}",

  /* InventoryWhMovement */
  GET_ACC_INVWHMOVEMENT: "/inventory/invwhmovements",
  POST_ACC_INVWHMOVEMENT: "/inventory/invwhmovement",
  PUT_ACC_INVWHMOVEMENT: "/inventory/invwhmovementupd/${id}",
  DELETE_ACC_INVWHMOVEMENT: "/inventory/invwhmovement/${id}",
  CLOSE_ACC_INVWHMOVEMENT: "/inventory/invwhmovement/close/${id}",
  GET_ACC_INVWHMOVEMENTS_BY_WH: "/inventory/invwhmovementbywh/${idWh}",
  APPROVE_ACC_INVWHMOVEMENT: "/inventory/invwhmovement/approve/${id}",
  GET_ACC_REPORTPURCHASE_BY_WH: "/inventory/reportPurchases/${idWh}",

  /* InventoryWhMovementType */
  GET_ACC_INVWHMOVEMENTTYPE: "/inventory/invwhmovementtypes",

  /* InventoryWhMovementDetails */
  GET_ACC_INVWHMOVEMENTDETAIL: "/inventory/invwhmovementdetails",
  POST_ACC_INVWHMOVEMENTDETAIL: "/inventory/invwhmovementdetail",
  PUT_ACC_INVWHMOVEMENTDETAIL: "/inventory/invwhmovementdetail/${id}",
  DELETE_ACC_INVWHMOVEMENTDETAIL: "/inventory/invwhmovementdetail/${id}",

  /* InventoryWhMovementStates */
  GET_ACC_INVWHMOVEMENTSTATE: "/inventory/invwhmovementStates",

  /* InventoryWhProduct */
  GET_ACC_INVWHPRODUCTS: "/inventory/InvWhProducts",
  GET_ACC_INVWHPRODUCTS_BY_WH: "/inventory/InvWhProductByWh/${idWh}",
  //PUT_ACC_INVWHPRODUCT: "/inventory/InvWhProduct/${id}",
  PUT_ACC_INVWHPRODUCT: "/inventory/InvWhProduct/${id}",
  DELETE_ACC_INVWHPRODUCT: "/inventory/InvWhProduct/${id}",

  /* INVWHUSERPERMISSION */
  GET_ACC_INVWHUSERPERMISSION: "/inventory/invWhUserPermissions",
  POST_ACC_INVWHUSERPERMISSION: "/inventory/invWhUserPermission",
  PUT_ACC_INVWHUSERPERMISSION: "/inventory/invWhUserPermission/${id}",
  DELETE_ACC_INVWHUSERPERMISSION: "/inventory/invWhUserPermission/${id}",

  /* INVWHUSER */
  GET_ACC_INVWHUSER: "/inventory/invWhUsers",
  POST_ACC_INVWHUSER: "/inventory/invWhUser",
  PUT_ACC_INVWHUSER: "/inventory/invWhUser/${id}",
  DELETE_ACC_INVWHUSER: "/inventory/invWhUser/${id}",

  /* INVPERMISSION */
  GET_ACC_INVPERMISSION: "/inventory/invPermissions",

  // SMSMMS CAMPAIGN
  GET_SMSMMSCAMPAIGN: "/smsmmscampaign",

  /* TICKETS CATEGORIES*/
  GET_TICKETCATEGORIES:
    "/organizations/${oid}/ticketcategories?filter[is_active]=1",
  GET_TICKETCATEGORIESALL: "/organizations/${oid}/ticketcategories",
  POST_TICKETCATEGORIES: "/ticketcategories",
  PUT_TICKETCATEGORIES: "/ticketcategories/${id}",
  DELETE_TICKETCATEGORIES: "/ticketcategories/${id}",

  /* TICKETS STATUS*/
  GET_TICKETSTATUS: "/organizations/${oid}/ticketstatuses",
  POST_TICKETSTATUS: "/ticketstatuses",
  PUT_TICKETSTATUS: "/ticketstatuses/${id}",
  DELETE_TICKETSTATUS: "/ticketstatuses/${id}",

  /* TICKETS PRIORITIES*/
  GET_TICKETPRIORITIES: "/organizations/${oid}/ticketpriorities",
  POST_TICKETPRIORITY: "/ticketpriorities",
  PUT_TICKETPRIORITY: "/ticketpriorities/${id}",
  DELETE_TICKETPRIORITY: "/ticketpriorities/${id}",

  /* TICKETS*/
  GET_TICKETS: "/organizations/${oid}/tickets",
  GET_TICKETS2: "/organizations/${oid}/tickets2",
  GET_TICKETS_USER: "/organizations/${oid}/index_org_ticketsuser",
  GET_TICKETS_AGENT: "/organizations/${oid}/index_org_ticketsagent",
  GET_CONTACTTICKETS: "/contacts/${contact_id}/tickets",
  POST_TICKET: "/tickets",
  PUT_TICKET: "/tickets/${id}",
  DELETE_TICKET: "/tickets/${id}",

  /* AdmCommissionRules */
  GET_ADM_COMMISSIONRULE: "/administration/admCommissionRule",
  GET_ADM_COMMISSIONRULETYPES: "/administration/admCommissionRuleTypes",
  POST_ADM_COMMISSIONRULE: "/administration/admCommissionRule",
  PUT_ADM_COMMISSIONRULE: "/administration/admCommissionRule/${id}",
  DELETE_ADM_COMMISSIONRULE: "/administration/admCommissionRule/${id}",
  GET_ADM_COMMISSIONRULEREPORT: "/administration/reportRule",

  GET_ADM_COMMISSIONRULEGROUP: "/administration/admCommissionRuleGroup",
  POST_ADM_COMMISSIONRULEGROUP: "/administration/admCommissionRuleGroup",
  DELETE_ADM_COMMISSIONRULEGROUP:
    "/administration/admCommissionRuleGroup/${id}",

  GET_PATIENT_REPORT: "/portal/dashboard",
  POST_PATIENT_LOGIN: "/auth/plogin",
  GET_PORTAL_FAQ: "portal/faqs",
  GET_PORTAL_FAQ_BY_PROCEDURE: "portal/faqsByProcedure/${products_id}",

  GET_ADM_FAQ: "/administration/admFaq",
  POST_ADM_FAQ: "/administration/admFaq",
  PUT_ADM_FAQ: "/administration/admFaq/${id}",
  DELETE_ADM_FAQ: "/administration/admFaq/${id}",

  GET_ADM_FAQ_PRODUCT: "/administration/admFaqProduct",
  POST_ADM_FAQ_PRODUCT: "/administration/admFaqProduct",
  PUT_ADM_FAQ_PRODUCT: "/administration/admFaqProduct/${id}",
  DELETE_ADM_FAQ_PRODUCT: "/administration/admFaqProduct/${id}",

  POST_ADM_PORTAL_FORGET: "/auth/pforgetpsw",
  POST_ADM_PORTAL_CHANGEPSW: "/auth/pchangepsw",

  GET_MSG: "/messages",
  SHOW_MSG: "/messages/${id}",
  POST_MSG: "/messages",

  GET_PORTAL_MSG: "/portal/messages",
  POST_PORTAL_MSG: "/portal/messages",
  SHOW_PORTAL_MSG: "portal/messages/${id}",

  //Poll Main
  GET_ACC_POLLMAIN: "/polls",
  POST_ACC_POLLMAIN: "/polls",
  PUT_ACC_POLLMAIN: "/polls/${id}",
  DELETE_ACC_POLLMAIN: "/polls/${id}",

  //Poll Questions
  POST_ACC_POLLQUESTION: "/polls/questions",
  PUT_ACC_POLLQUESTION: "/polls/questions/${id}",
  DELETE_ACC_POLLQUESTION: "/polls/questions/${id}",
  GET_ACC_POLLQUESTION_BY_POLL: "/polls/questions/${id}",
  GET_ACC_POLLQUESTION_BY_POLL_BY_CONTACT: "/polls/questions/bycontact/${id}",

  //Poll Questions Options
  POST_ACC_POLLQUESTIONOPTION: "/polls/questions/options",
  PUT_ACC_POLLQUESTIONOPTION: "/polls/questions/options/${id}",
  DELETE_ACC_POLLQUESTIONOPTION: "/polls/questions/options/${id}",
  GET_ACC_POLLQUESTIONOPTION_BY_QUESTION: "/polls/questions/options/${id}",

  //Poll Contacts
  POST_POLLCONTACT: "/polls/contacts/",
  PUT_POLLCONTACT: "/polls/contacts/${poll_id}/${contact_id}",
  DELETE_POLLCONTACT: "/polls/contacts/${poll_id}",
  GET_POLLCONTACT: "/polls/contacts/bypoll/${poll_id}",
  GET_POLL_BYCONTACT: "/polls/contacts/bycontact/${contact_id}",

  //Poll Puntuation
  POST_POLL_PUNTUATION: "/portal/pollPuntuation'",

  //Commons
  GET_CONTACTS: "/organizations/${orgId}/contacts_plain",

  //Model States
  GET_MODELSTATES: "/states/models",
  POST_MODELSTATES: "/states/models",
  PUT_MODELSTATES: "/states/models/${id}",
  DELETE_MODELSTATES: "/states/models/${id}",

  //WORKFLOW
  GET_WORKFLOWBYMODEL: "/states/workflows/${id}",
  POST_WORKFLOW: "/states/workflows",
  PUT_WORKFLOW: "/states/workflows/${id}",
  DELETE_WORKFLOW: "/states/workflows/${id}",

  //STATES
  GET_STATESBYWORKFLOW: "/states/models/states/byworkflow/${id}",
  GET_STATESBYMODEL: "/states/models/states/bymodel/${id}",
  POST_STATES: "/states/models/states/",
  PUT_STATES: "/states/models/states/${id}",
  DELETE_STATES: "/states/models/states/${id}",
  SHOW_STATES: "states/models/states/${workflow}/${id}/",
  SHOW_STATES_HISTORY: "states/history/${model_id}/${state_id}",
  POST_STATES_MOVE: "states/models/states/move",

  //TRANSITIONS
  GET_TRANSITIONSBYSTATE: "/states/transitions/${id}",
  GET_TRANSITIONSBYWORKFLOW: "/states/transitions/bywf/${id}",
  PUT_TRANSITIONSBYWORKFLOW: "/states/transitions/${id}",
  PUT_BULKTRANSITIONACTIONS: "/states/transitions/bulkactions",
  PUT_BULKTRANSITIONROLES: "/states/transitions/bulkroles",

  //ACTIONS
  GET_ACTIONS: "states/actions/${id}",

  GET_POLL_REPORT_PUNTUATION: "/polls/getUserPuntutation",
  GET_POLL_CONTACT_REPORT_PUNTUATION: "/polls/getContactPuntuation",

  //apointmentsTypes
  GET_APPOINTMENT_TYPES: "/appointmentstypes",
  POST_APPOINTMENT_TYPES: "/appointmentstypes",
  PUT_APPOINTMENT_TYPES: "/appointmentstypes/${id}",
  DELETE_APPOINTMENT_TYPES: "/appointmentstypes/${id}",

  //appointmentsnew_cancelation
  GET_APPOINTMENT_CANCELATION_REASON: "/appointmentscancelationreason",
  POST_APPOINTMENT_CANCELATION_REASON: "/appointmentscancelationreason",
  PUT_APPOINTMENT_CANCELATION_REASON: "/appointmentscancelationreason/${id}",
  DELETE_APPOINTMENT_CANCELATION_REASON: "/appointmentscancelationreason/${id}",

  //appointmentsnew_cancelation
  GET_CONTACTSFRAUDOLENT_REASONS: "/fraudolentcontactsreasons",
  POST_CONTACTSFRAUDOLENT_REASONS: "/fraudolentcontactsreasons",
  PUT_CONTACTSFRAUDOLENT_REASONS: "/fraudolentcontactsreasons/${id}",
  DELETE_CONTACTSFRAUDOLENT_REASONS: "/fraudolentcontactsreasons/${id}",

  //appointmentsnew_movements
  GET_APPOINTMENT_MOVEMENTS_REASON: "/appointmentsmotivereason",
  POST_APPOINTMENT_MOVEMENTS_REASON: "/appointmentsmotivereason",
  PUT_APPOINTMENT_MOVEMENTS_REASON: "/appointmentsmotivereason/${id}",
  DELETE_APPOINTMENT_MOVEMENTS_REASON: "/appointmentsmotivereason/${id}",

  //appointmentsnew
  GET_APPOINTMENT_NEW: "/appointmentsnew",
  POST_APPOINTMENT_NEW: "/appointmentsnew",
  PUT_APPOINTMENT_NEW: "/appointmentsnew/${id}",
  DELETE_APPOINTMENT_NEW: "/appointmentsnew/${id}",

  //campaigns
  GET_CONTACTLIST_FOR_CAMPAIGNS: "/campaigns/contact_list",
  GET_CAMPAIGNS: "/campaigns",
  GET_ASSIGNEDCAMPAIGNS: "/assignedcampaigns",

  //campaign_templates
  GET_CAMPAIGNTEMPLATES: "/campaign_templates",

  //assigned_campaign_priorities
  GET_ASSIGNEDCAMPAIGNPRIORITIES: "/assigned_campaign_priorities",

  //internal_chat
  GET_BLOCKEDCHATUSERS: "/chat/get_blocked_users_report",

  /*
  //Model States
  GET_MODELSTATES: "/states/models",
  POST_MODELSTATES: "/states/models",
  PUT_MODELSTATES: "/states/models/${id}",
  DELETE_MODELSTATES: "/states/models/${id}",

  //STATES
  GET_STATESBYMODEL:'/states/models/states/${id}',
  POST_STATES:'/states/models/states/',
  PUT_STATES:'/states/models/states/${id}',
  DELETE_STATES:'/states/models/states/${id}',*/

  //user_activities
  GET_USERACTIVITIESREPORT: "/users/activities/report",

  // Merchants
  GET_MERCHANTS: "merchants",
  GET_PAYMENTSCONCILIATION: "/consolidation-report",
  // Merchants Configurations
  GET_MERCHANTCONFIGURATIONS: "/merchant-configurations",
  // Merchants Consolidation Report Groups
  GET_MERCHANTCONSOLIDATIONREPORTGROUPS: "/consolidation-report-groups",

  //invalid_phone_numbers_for_messaging:
  GET_INVALIDPHONENUMBERSFORMESSAGING: "/invalid-phone-number-for-messaging",

  //invalid_phone_numbers_for_voice:
  GET_INVALIDPHONENUMBERSFORVOICE: "/invalid-phone-number-for-voice",

  //influencers
  GET_INFLUENCERS: "/influencers",

  //contracts
  GET_INFLUENCERCONTRACTS: "/organizations/${oid}/influencer-contracts",

  //contracts
  GET_CAMPAIGNCREDITS: "/organizations/${oid}/campaign-credits",

  //patient-welcome-templates
  GET_PATIENTWELCOMETEMPLATES: "/patient-welcome-templates",

  //white-list-numbers-for-campaigns
  GET_WHITELISTNUMBERSFORCAMPAIGNS: "/white-list-numbers-for-campaigns",

  //payment-link-requests
  GET_PAYMENTLINKREQUESTS: "/payment-link-requests",

  //unanswered-sms-mms-by-user
  GET_UNANSWEREDSMSMMSBYUSER: "/unanswered-sms-mms-by-user",

  //coupons
  GET_COUPONS: "/organizations/${oid}/coupons",

  //paymentReconciliationReport
  GET_MANUALTRANSACTIONRECONCILIATION:
    "organizations/${oid}/manual-transaction-reconciliation",
  //paymentReconciliationReport
  GET_MANUALTRANSACTIONRECONCILIATIONEXPORT:
    "organizations/${oid}/manual-transaction-reconciliation-export",

  //Auto Calls Queue
  GET_AUTOCALLS: "/hooks/get/auto/call/queue/information/aksjdhcbaksjhbwakvjhdbkavjhd",
  DELETE_AUTOCALLS: "/hooks/delete/auto/call/queue/${id}/aksjdhcbaksjhbwakvjhdbkavjhd",

  GET_AUTOCALLSHISTORY: "/hooks/get/auto/call/queue/history/information/aksjdhcbaksjhbwakvjhdbkavjhd",
};

const Api = {
  onSplitInvoice: function (id, payload) {
    return instance.post(`/invoices/${id}/splitinvoice`, payload);
  }, 
  createPointOfSaleWarehouse: function (payload) {
    return instance.post(`/inventorynew/pointofsalewarehouses`, payload);
  },
  updatePointOfSaleWarehouse: function (id, payload) {
    return instance.put(`/inventorynew/pointofsalewarehouses/${id}`, payload);
  },
  deletePointOfSaleWarehouse: function (id) {
    return instance.delete(`/inventorynew/pointofsalewarehouses/${id}`);
  },
  getPointOfSaleWarehouseLogs: function (id, page, limit) {
    return instance.get(
      `/inventorynew/pointofsalewarehouses/${id}/getlogs?page=${
        page ? page : 0
      }&limit=${limit ? limit : 10}`
    );
  },

  getPointOfSaleWarehouseDropdown: function (id) {
    return instance.get(`/organizations/${id}/pointofsalewarehousesdropdown`);
  },
 
  getPointOfSaleWarehouseUsers: function (id) {
    return instance.get(`/inventorynew/pointofsalewarehouses/${id}/getusers`);
  },

  addPointOfSaleWarehouseUser: function (id, payload) {
    return instance.post(
      `/inventorynew/pointofsalewarehouses/${id}/adduser`,
      payload
    );
  },
  deletePointOfSaleWarehouseUser: function (id, user_id) {
    return instance.delete(
      `/inventorynew/pointofsalewarehouses/${id}/deleteuser/${user_id}`
    );
  },

  getPointOfSaleWarehouseMinMaxs: function (id) {
    return instance.get(`/inventorynew/pointofsalewarehouses/${id}/getminmaxs`);
  },
  addPointOfSaleWarehouseMinMax: function (id, payload) {
    return instance.post(
      `/inventorynew/pointofsalewarehouses/${id}/addminmax`,
      payload
    );
  },
  editPointOfSaleWarehouseMinMax: function (id, minmax_id, payload) {
    return instance.post(
      `/inventorynew/pointofsalewarehouses/${id}/editminmax/${minmax_id}`,
      payload
    );
  },
  deletePointOfSaleWarehouseMinMax: function (id, user_id) {
    return instance.delete(
      `/inventorynew/pointofsalewarehouses/${id}/deleteminmax/${user_id}`
    );
  },

  createWarehouse: function (payload) {
    return instance.post(`/inventorynew/warehouses`, payload);
  },
  updateWarehouse: function (id, payload) {
    return instance.put(`/inventorynew/warehouses/${id}`, payload);
  },
  deleteWarehouse: function (id) {
    return instance.delete(`/inventorynew/warehouses/${id}`);
  },
  getWarehouseLogs: function (id, page, limit) {
    return instance.get(
      `/inventorynew/warehouses/${id}/getlogs?page=${page ? page : 0}&limit=${
        limit ? limit : 10
      }`
    );
  },
  getWarehousesDropdown: function (id) {
    return instance.get(`/organizations/${id}/warehousesdropdown`);
  },
  getWarehouseUsers: function (id) {
    return instance.get(`/inventorynew/warehouses/${id}/getusers`);
  },
  addWarehouseUser: function (id, payload) {
    return instance.post(`/inventorynew/warehouses/${id}/adduser`, payload);
  },
  deleteWarehouseUser: function (id, user_id) {
    return instance.delete(
      `/inventorynew/warehouses/${id}/deleteuser/${user_id}`
    );
  },
  getWarehouseMinMaxs: function (id) {
    return instance.get(`/inventorynew/warehouses/${id}/getminmaxs`);
  },
  addWarehouseMinMax: function (id, payload) {
    return instance.post(`/inventorynew/warehouses/${id}/addminmax`, payload);
  },
  editWarehouseMinMax: function (id, minmax_id, payload) {
    return instance.post(
      `/inventorynew/warehouses/${id}/editminmax/${minmax_id}`,
      payload
    );
  },
  deleteWarehouseMinMax: function (id, minmax_id) {
    return instance.delete(
      `/inventorynew/warehouses/${id}/deleteminmax/${minmax_id}`
    );
  },
  getPointOfSaleWarehouses: function (id) {
    return instance.get(`/inventorynew/warehouses/${id}/pointofsalewarehouses`);
  },

  getWarehouseStockItems: function (id) {
    return instance.get(`/inventorynew/work/warehouses/${id}/stockitems`);
  },

  addInventoryProductWarehouse: function (id, payload) {
    return instance.post(`/inventorynew/work/warehouses/${id}/addstockitem`, payload);
  },

  transferRequest: function (id, payload) {
    return instance.post(`/inventorynew/work/warehouses/${id}/transferRequest`, payload);
  },

  createProvider: function (payload) {
    return instance.post(`/inventorynew/providers`, payload);
  },
  updateProvider: function (id, payload) {
    return instance.put(`/inventorynew/providers/${id}`, payload);
  },
  deleteProvider: function (id) {
    return instance.delete(`/inventorynew/providers/${id}`);
  },
  getProviderLogs: function (id, page, limit) {
    return instance.get(
      `/inventorynew/providers/${id}/getlogs?page=${page ? page : 0}&limit=${
        limit ? limit : 10
      }`
    );
  },

  getProvidersDropdown: function (id) {
    return instance.get(`/inventorynew/providers/dropdownname`);
  },

  getProvidersDropdown: function (id) {
    return instance.get(`/organizations/${id}/providersdropdown`);
  },

  createMovementType: function (payload) {
    return instance.post(`/inventorynew/movementtypes`, payload);
  },
  updateMovementType: function (id, payload) {
    return instance.put(`/inventorynew/movementtypes/${id}`, payload);
  },
  deleteMovementType: function (id) {
    return instance.delete(`/inventorynew/movementtypes/${id}`);
  },
  getMovementTypeLogs: function (id, page, limit) {
    return instance.get(
      `/inventorynew/movementtypes/${id}/getlogs?page=${
        page ? page : 0
      }&limit=${limit ? limit : 10}`
    );
  },
  getMovementTypesDropdown: function (id) {
    return instance.get(`/organizations/${id}/movementtypesdropdown`);
  },

  createMovementReason: function (payload) {
    return instance.post(`/inventorynew/movementreasons`, payload);
  },
  updateMovementReason: function (id, payload) {
    return instance.put(`/inventorynew/movementreasons/${id}`, payload);
  },
  deleteMovementReason: function (id) {
    return instance.delete(`/inventorynew/movementreasons/${id}`);
  },
  getMovementReasonLogs: function (id, page, limit) {
    return instance.get(
      `/inventorynew/movementreasons/${id}/getlogs?page=${
        page ? page : 0
      }&limit=${limit ? limit : 10}`
    );
  },

  getMovementReasonsDropdown: function (id) {
    return instance.get(`/inventorynew/movementreasons/dropdownname`);
  },

  // contactTemplatesFormsCategory
  saveContactTemplatesFormsCategory: function (payload) {
    return instance.post(`/contactformtemplatecategories`, payload);
  },
  updateContactTemplatesFormsCategory: function (id, payload) {
    return instance.put(`/contactformtemplatecategories/${id}`, payload);
  },
  deleteContactTemplatesFormsCategory: function (id) {
    return instance.delete(`/contactformtemplatecategories/${id}`);
  },

  getContactTemplate: function (id) {
    return instance.get(`/getcontacttemplateform/${id}`);
  },
  getContactTemplateFormsCategoriesWT: function (id) {
    return instance.get(
      `/organizations/${id}/getcontacttemplateformscategories`
    );
  },

  getAllCountriesIso2: function () {
    return instance.get(`/merchant/getallcountriesiso2`);
  },

  getCampaignsDashboardStatisticsByType: function (id, type, start, end) {
    return instance.get(
      `/organizations/${id}/campaigns/dashboardstatisticsbytype?filter[date_between_from_to]=${start},${end}&filter[type]=${type}`
    );
  },
  getCampaignsDashboard: function (id, start, end) {
    return instance.get(
      `/organizations/${id}/campaigns/dashboard?filter[date_between_from_to]=${start},${end}`
    );
  },

  sendCustomContactSMS: function (payload) {
    return instance.post(`/contacts/sendcustomsms`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  changePayrollSale: function (id, payload) {
    return instance.post(
      `/commissionpayrolls/${id}/changepayrollsale`,
      payload
    );
  },
  getDataToDateChange: function (id) {
    return instance.get(`/commissionpayrolls/${id}/getdatatodatechange`);
  },
  updatePayrollBuildingSettings: function (id, payload) {
    return instance.post(
      `/buildings/${id}/updatepayrollbuildingsettings`,
      payload
    );
  },
  getUsersOnGroup: function (id) {
    return instance.get(`/groups/${id}/usersongroupplain`);
  },
  payCommissionPayroll: function (id, payload) {
    return instance.post(`/organizations/${id}/paycommissionpayroll`, payload);
  },
  paySalesManagerPayroll: function (id, payload) {
    return instance.post(
      `/organizations/${id}/paysalesmanagerpayroll`,
      payload
    );
  },
  payCoordinationPayroll: function (id, payload) {
    return instance.post(
      `/organizations/${id}/paycoordinationpayroll`,
      payload
    );
  },
  payCoordinationManagerPayroll: function (id, payload) {
    return instance.post(
      `/organizations/${id}/paycoordinationmanagerpayroll`,
      payload
    );
  },
  paySalesmanPayroll: function (id, payload) {
    return instance.post(`/organizations/${id}/paysalesmanpayroll`, payload);
  },
  getRecordsLabelDataProcedureReport: function (payload) {
    return instance.post(`/records/recordslabeldataprocedurereport`, payload);
  },
  getRecordsLabelData: function (payload) {
    return instance.post(`/records/recordslabeldata`, payload);
  },
  //AUTH
  getCurrentUser: function (payload) {
    return instance.post(`/auth/user`, payload);
  },
  getCurrentUser2: function (payload) {
    return instance.post(`/auth/usert`, payload);
  },
  getCurrentPortalUser: function () {
    return instance.get(`/auth/portalUser`);
  },
  getCurrentUserByEmail: function (access_token, payload) {
    return instance.post(`/auth/glogin2/${access_token}`, payload);
  },
  authUser: function (payload) {
    return instance.post("/auth/login", payload);
  },
  authUserPatient: function (payload) {
    return instance.post("/auth/plogin", payload);
  },
  updateProfile: function (payload) {
    return instance.post("/profile", payload);
  },

  unregisterToken: function (id, token, os) {
    return instance.delete(`/users/${id}/tokens/${token}/os/${os}`);
  },
  revokeAccesToken: function (id) {
    return instance.post(`/users/${id}/tokens/revoke`);
  },
  //ORGANIZATIONS
  getOrganizations: function () {
    return instance.get(`/organizations`);
  },
  saveOrganization: function (payload) {
    return instance.post("/organizations", payload);
  },
  updateOrganization: function (id, payload) {
    return instance.post(`/organizations/${id}`, payload);
  },

  deleteOrganization: function (id) {
    return instance.delete(`/organizations/${id}`);
  },
  getOrgRecordProducts: function (orgId) {
    return instance.get(
      `/organizations/${orgId}/recordproducts?include=record,invoiceItem,responsible,recordfollowup,invoice,recordProductsStatusHistory`
    );
  },

  getRecordProductsPendingToScheduleprint: function (orgId, args) {
    return instance.get(
      `/organizations/${orgId}/recordProductsPendingToScheduleprint${args}`
    );
  },
  //get procedure 6 print
  getRecordProductsPrint: function (orgId, args) {
    return instance.get(
      `/organizations/${orgId}/recordproducts_reportprint${args}`
    );
  },

  getRecordProductsPrint2: function (orgId, args) {
    return instance.get(
      `/organizations/${orgId}/recordproducts_reportprint_pa${args}`
    );
  },

  getRecordproductsCanceledReportsprint: function (orgId, args) {
    return instance.get(
      `/organizations/${orgId}/recordproductsCanceledReportsPrint${args}`
    );
  },

  getRecordproductsPendingToRescheduleCoordinationPrint: function (
    orgId,
    args
  ) {
    return instance.get(
      `/organizations/${orgId}/recordproductsPendingToReschedulePrint${args}`
    );
  },

  getRecordproductsRefundReportsPrint: function (orgId, args) {
    return instance.get(
      `/organizations/${orgId}/recordproductsRefundReportsPrint${args}`
    );
  },
  getRecordproductsNoDoctorsPrint: function (orgId, args) {
    return instance.get(
      `/organizations/${orgId}/recordproductsNoDoctorsPrint${args}`
    );
  },

  //smsmmstemplatesCategories
  getSMSMMSTemplatesCategories: function (payload) {
    return instance.get(`/smsmmstemplatescategories`, payload);
  },

  //smsmmstemplates
  shareSMSMMSTemplates: function (payload) {
    return instance.post(`/smsmmstemplates/share`, payload);
  },

  createSMSMMSTemplate: function (payload) {
    return instance.post(`/smsmmstemplates`, payload);
  },

  updateSMSMMSTemplate: function (id, payload) {
    return instance.put(`/smsmmstemplates/${id}`, payload);
  },

  deleteSMSMMSTemplate: function (id) {
    return instance.delete(`/smsmmstemplates/${id}`);
  },

  getSMSMMSTemplatesByCategory: function (category_id, payload) {
    return instance.post(`/smsmmstemplatesbycategory/${category_id}`, payload);
  },

  getSMSMMSTemplatesByCategoryGroupBy: function (category_id, payload) {
    return instance.post(
      `/smsmmstemplatesbycategorygroupby/${category_id}`,
      payload
    );
  },

  getSMSMMSTemplate: function (id) {
    return instance.get(`/smsmmstemplates/${id}`);
  },

  attachElementToSMSMMSTemplate: function (payload, id) {
    return instance.post(`/smsmmstemplates/attachElement/${id}`, payload);
  },

  //buildings
  getBuildingsWithDoctors: function () {
    return instance.get(`/buildingswithdoctors`);
  },
  getBuildingTypeforms: function (id) {
    return instance.get(`/building/${id}/typeforms`);
  },
  createBuildingTypeform: function (payload) {
    return instance.post(`/building_typeform`, payload);
  },
  updateBuildingTypeform: function (id, payload) {
    return instance.post(`/building_typeform/${id}`, payload);
  },
  deleteBuildingTypeform: function (id) {
    return instance.delete(`/building_typeform/${id}`);
  },
  getBuildingTypeformCategories: function () {
    return instance.get(`/building_typeform_categories`);
  },
  getBuildingsWithTypeforms: function () {
    return instance.get(`/buildings_with_typeforms`);
  },
  getTypeformCategoriesTokens: function () {
    return instance.get(`/typeform_categories_tokens`);
  },
  getBuildingsDropdown: function (oid) {
    return instance.get(`/organizations/${oid}/buildings_dropdown`);
  },

  //holidaysConfiguration
  getMySellersConfiguration: function (payload) {
    return instance.post(`/holidaysconfiguration`, payload);
  },
  addSellersConfiguration: function (payload) {
    return instance.post(`/holidaysconfiguration/addconfiguration`, payload);
  },
  getMySellerstoHolidaysConfiguration: function (payload) {
    return instance.post(`/holidaysconfiguration/mysellers`, payload);
  },
  getMySellers: function () {
    return instance.get(`/users/mysellers`);
  },
  removeSellerConfiguration: function (id) {
    return instance.delete(
      `/holidaysconfiguration/${id}/removesellerconfiguration`
    );
  },

  getMyGroupsUsers: function (id) {
    return instance.get(`/users/${id}/mygroups`);
  },

  updateHolidayConfiguration: function (payload) {
    return instance.post(`/holidaysconfiguration/update`, payload);
  },

  //DeactivateReasons

  deleteDeactivateReason: function (id) {
    return instance.delete(`/deactivatereasons/${id}`);
  },

  uploadDeactivateReason: function (payload, id) {
    return instance.post(`/deactivatereasons/${id}`, payload);
  },

  saveDeactivateReason: function (payload) {
    return instance.post(`/deactivatereasons`, payload);
  },

  getDeactivateReason: function (id) {
    return instance.get(`/deactivatereasons/${id}`);
  },

  getDeactivateReasons: function (payload) {
    return instance.get(`/deactivatereasons`, payload);
  },

  getDeactivateHistory: function () {
    return instance.get(`/usersdeactivatehistory`);
  },

  //Clients
  getClients: function () {
    return instance.get(`/clients`);
  },

  saveClient: function (payload) {
    return instance.post(`/clients`, payload);
  },

  //USERS
  getMinPriceAvailableQtyToUse: function (building_id, contact_id, user_id) {
    return instance.get(
      `/buildings/${building_id}/contacts/${contact_id}/users/${user_id}/min_price_available_qty_to_use`
    );
  },

  getUserFavoriteSites: function (id) {
    return instance.get(`/users/${id}/favorite-sites`);
  },

  createUserFavoriteSite: function (id, payload) {
    return instance.post(`/users/${id}/favorite-sites`, payload);
  },

  removeUserFavoriteSite: function (id, path) {
    return instance.delete(`/users/${id}/favorite-sites?path=${path}`);
  },
  syncUserContactSources: function (id, payload) {
    return instance.put(`/users/${id}/contacts-sources`, payload);
  },

  sendMassiveEmailsToPatients: function (id, payload) {
    return instance.post(
      `/users/${id}/send_massive_emails_to_patients`,
      payload
    );
  },
  sendMassiveMessagesToPatients: function (id, payload) {
    return instance.post(
      `/users/${id}/send_massive_messages_to_patients`,
      payload
    );
  },
  getAvailableMassiveMessages: function (id) {
    return instance.get(`/users/${id}/available_massive_messages`);
  },

  getAppointmentTypeConfiguration: function (id) {
    return instance.get(`/users/${id}/appointmentstypesconfig`);
  },

  getAppointmentOwnerConfiguration: function (id) {
    return instance.get(`/users/${id}/appointmentsownerconfig`);
  },

  updateUserDaysOff: function (payload, id) {
    return instance.post(`/users/${id}/set_days_off`, payload);
  },
  updateStatusUser: function (id, status) {
    return instance.post(`/users/updatestatus/${id}/${status}`);
  },
  updateClickToCall: function (id, payload) {
    return instance.post(`/users/${id}/updateclicktocall`, payload);
  },

  getUsers: function (orgId) {
    return instance.get(
      `/organizations/${orgId}/users?include=groups,buildings`
    );
  },
  getUserPhoneBook: function () {
    return instance.get(`/users/phone/book`);
  },

  getUserCallHistory: function (page, limit = 50) {
    return instance.get(`/users/${page}/call/${limit}/history`);
  },
  getUserCallHistory2: function (id, params) {
    return instance.get(`/users/${id}/getUserCallsHistory${params}`);
  },
  getCountOfMissedCalls: function () {
    return instance.get(`/users/count/missed/calls`);
  },

  getCountOfNewVoiceMails: function () {
    return instance.get(`/users/count/new/voicemails/count`);
  },

  setViewMissedCall: function (id) {
    return instance.get(`/users/set/view/to/${id}/calls`);
  },

  unreadLast: function (id, payload) {
    return instance.put(`/users/${id}/unreadlast`, payload);
  },

  unreadLastByGroups: function (id, payload) {
    return instance.put(`/users/${id}/unreadlastbygroups`, payload);
  },

  getUserWhiteList: function (id) {
    return instance.get(`/users/${id}/whiteList`);
  },
  getUserBlackList: function (id) {
    return instance.get(`/users/${id}/blackList`);
  },
  updateUserBlackList: function (id, payload) {
    return instance.post(`/users/${id}/updateuserblacklist`, payload);
  },

  deleteUserIPList: function (id, userIP) {
    return instance.delete(`/users/${id}/deleteip/${userIP}`);
  },

  deleteIPList: function (id) {
    return instance.delete(`/whiteblackrules/${id}`);
  },

  deleteIPListDate: function (id, dateID) {
    return instance.delete(`/whiteblackrules/${id}/deletedate/${dateID}`);
  },

  deleteUserIPListDate: function (id, dateID) {
    return instance.delete(`/users/${id}/deleteipdate/${dateID}`);
  },

  getUsersDropdown: function (orgId) {
    return instance.get(`/organizations/${orgId}/users_dropdown`);
  },

  getInvoiceUsersDropdown: function (orgId) {
    return instance.get(`/organizations/${orgId}/invoices_users_dropdown`);
  },

  getHistoryUsersDropdown: function (orgId) {
    return instance.get(`/organizations/${orgId}/history_users_dropdown`);
  },

  getUsersDropdownPlain: function (orgId) {
    return instance.get(`/organizations/${orgId}/usersDropdownPlain`);
  },
  getPASurgeon: function (orgId) {
    return instance.get(`/organizations/${orgId}/getPASurgeon`);
  },

  getActiveUsersDropdown: function (orgId) {
    return instance.get(`/organizations/${orgId}/active_users_dropdown`);
  },

  getUsersWithPhone: function (orgId) {
    return instance.get(`/organizations/${orgId}/userswithphone`);
  },

  getActiveUsers: function (orgId) {
    return instance.get(`/organizations/${orgId}/activeusers`);
  },

  getUsersBuilding: function (orgId, building_id) {
    return instance.get(
      `/organizations/${orgId}/users_building/${building_id}`
    );
  },

  getCountries: function (orgId) {
    return instance.get(`/organizations/${orgId}/getCountries`);
  },

  getStates: function (orgId, country_id) {
    return instance.get(
      `/organizations/${orgId}/country/${country_id}/getStates`
    );
  },
  getCities: function (orgId, country_id, state_id) {
    return instance.get(
      `/organizations/${orgId}/country/${country_id}/state/${state_id}/getCities`
    );
  },

  getUsersPhone: function (orgId, phone) {
    return instance.get(`/organizations/${orgId}/users_phone/${phone}`);
  },

  getDoctors: function (orgId) {
    return instance.get(`/organizations/${orgId}/doctorsdropdown`);
  },

  getUser: function (id) {
    //    console.log("id: ", id);
    return instance.get(`/users/${id}`);
  },

  getUserTasks: function (id) {
    return instance.get(`/users/${id}?include=tasks`);
  },
  saveUser: function (payload) {
    return instance.post("/users", payload);
  },
  updateUser: function (id, payload) {
    // console.log(payload);
    return instance.post(`/users/${id}`, payload);
  },
  deleteUser: function (id) {
    return instance.delete(`/users/${id}`);
  },
  getUserCustomFields: function (orgId) {
    return instance.get(`/organizations/${orgId}/customfields/user`);
  },
  getUserLogs: function (id) {
    return instance.get(`/users/${id}/logs`);
  },
  getUsersActivityLogs: function (id, page, limit) {
    return instance.get(
      `/users/${id}/activitiesLogs?page=${page ? page : 0}&limit=${
        limit ? limit : 10
      }`
    );
  },

  deleteUserPersonalAssistant: function (id, personalAssistantId) {
    return instance.delete(
      `/users/${id}/deletePersonalAssistant/${personalAssistantId}`
    );
  },
  deleteUserDoctorSurgicalAssistant: function (id, personalAssistantId) {
    return instance.delete(
      `/users/${id}/deleteSurgicalAssistant/${personalAssistantId}`
    );
  },
  deleteUserDoctorSurgicalAnesthesiologist: function (id, anesthesiologistId) {
    return instance.delete(
      `/users/${id}/deleteSurgicalAnesthesiologist/${anesthesiologistId}`
    );
  },

  getInvoiceItem: function (id) {
    return instance.get(`/invoice_item/${id}`);
  },

  getAppointmentDateMovements: function (id) {
    return instance.get(`/appointmentsnew/${id}/movements`);
  },

  CheckIn: function (payload) {
    return instance.post(`/checkIn`, payload);
  },

  getAppointmentToPortal: function (id, payload) {
    return instance.post(`/appointmentsnew/${id}/toportal`, payload);
  },

  setAppointmentStatusWithConfirmation: function (id, payload) {
    return instance.post(`/appointmentsnew/${id}/confirmation`, payload);
  },

  getAppointmentContactInformation: function (payload) {
    return instance.post(`/contactinformationtocheckin`, payload);
  },

  getUserPriceslogs: function (id) {
    return instance.get(`/users/${id}/userPriceslogs`);
  },
  getContactsLogs: function (id) {
    return instance.get(`/contacts/${id}/logs`);
  },
  getContactsActivityLogs: function (id, page, limit) {
    return instance.get(
      `/contacts/${id}/activitiesLogs?page=${page ? page : 0}&limit=${
        limit ? limit : 10
      }`
    );
  },
  unsuscribeContact: function (subscription_token) {
    return instance.post(`/contacts/unsubscribe`, subscription_token);
  },

  getManualSourcesDropdown: function (orgId) {
    return instance.get(`/organizations/${orgId}/getmanualsourcesdropdown`);
  },
  getAllSourcesDropdown: function (orgId) {
    return instance.get(`/organizations/${orgId}/contactssource`);
  },

  getBuildingLogs: function (id) {
    return instance.get(`/building/${id}/logs`);
  },
  getCategoriesLogs: function (id) {
    return instance.get(`/categories/${id}/logs`);
  },
  getContractsLogs: function (id) {
    return instance.get(`/contracts/${id}/logs`);
  },
  getProductLogs: function (id) {
    return instance.get(`/products/${id}/logs`);
  },

  getInvoicePaymentTransferHistories: function (id) {
    return instance.get(`/invoices/${id}/transferredpaymenthistories`);
  },

  getInvoiceLogs: function (id) {
    return instance.get(`/invoices/${id}/logs`);
  },
  getInvoiceLogsNew: function (id) {
    return instance.get(`/invoices/${id}/showInvoiceChangesLogsReport`);
  },
  showInvoiceLogs: function (id) {
    return instance.get(`/invoices/${id}/showInvoiceLogs`);
  },
  getQuoteLogs: function (id) {
    return instance.get(`/quotes/${id}/logs`);
  },
  getQuoteActivityLogs: function (id, page, limit) {
    return instance.get(
      `/quotes/${id}/activitiesLogs?page=${page ? page : 0}&limit=${
        limit ? limit : 10
      }`
    );
  },

  getRecordfollowupCustomFields: function (orgId) {
    return instance.get(
      `/organizations/${orgId}/customfields/category/recordfollowup?sort=category_id,order&include=category`
    );
  },
  getUserProducts: function (id) {
    return instance.get(`/users/${id}/products`);
  },

  getUserProductPrices: function (id) {
    return instance.get(`/organizations/${id}/getuserproductpriceslist`);
  },

  getUserBuildings: function (id) {
    return instance.get(`/users/${id}/buildings`);
  },
  getUserRecordProductsGroupByDate: function (id) {
    return instance.get(`/users/${id}/recordproduct_count_by_date`);
  },
  saveUserProduct: function (id, payload) {
    return instance.post(`/users/${id}/products`, payload);
  },

  updateUserProduct: function (id, productId, payload) {
    return instance.put(`/users/${id}/products/${productId}`, payload);
  },

  deleteUserProduct: function (id, productId) {
    return instance.delete(`/users/${id}/products/${productId}`);
  },

  getUserCoordinators: function (id) {
    return instance.get(`/users/${id}/coordinators`);
  },

  getUserCoordinatorsPlain: function (id) {
    return instance.get(`/users/${id}/coordinatorsplain`);
  },

  changeCoordinatorAfterClose: function (id, coordinator_id) {
    return instance.post(
      `/recordproducts/${id}/changecoordinatorafterclose/${coordinator_id}`
    );
  },

  getDoctorsBuildings: function (id, building_id) {
    return instance.get(
      `/organizations/${id}/getdoctorsbybuildings/${building_id}`
    );
  },

  getUserDoctors: function (id) {
    return instance.get(`/users/${id}/doctors`);
  },

  getAmountSMSMMSforBuilding: function (payload) {
    return instance.post(`/qtysmsmmscampaignforbuilding`, payload);
  },

  getAmountSMSMMSforBuilding: function (payload) {
    return instance.post(`/qtysmsmmscampaignforbuilding`, payload);
  },

  getMessagesByCategories: function (payload) {
    return instance.post(`/messagesbycategories`, payload);
  },

  getContactSMSMMSByPhone: function (id, params) {
    return instance.get(`/users/${id}/getSMSMMSByPhone${params}`);
  },

  getSMSMMSINCount: function (id, params) {
    return instance.get(`/users/${id}/getSMSMMSINCount${params}`);
  },

  getCallsINCount: function (id, params) {
    return instance.get(`/users/${id}/getCallsINCount${params}`);
  },

  SendSMSMMSByPhone: function (id, payload) {
    return instance.post(`/users/${id}/sendSmsOrMmsByNumber`, payload);
  },

  getNewSMS: function (id, payload) {
    return instance.post(`/users/${id}/getNewSMS`, payload);
  },

  getNewSMS: function (id, payload) {
    return instance.post(`/users/${id}/getNewSMS`, payload);
  },

  getNewWhatsApp: function (id, payload) {
    return instance.post(`/users/${id}/getNewWhatsApp`, payload);
  },

  getSMSMMSByKeyWord: function (id) {
    return instance.get(`/users/${id}/getSMSMMSByKeyWord`);
  },

  mergeContacts: function (id, payload) {
    return instance.post(`/users/${id}/mergeUserContactSMSMMS`, payload);
  },

  mergeContactsByGroups: function (id, payload) {
    return instance.post(
      `/users/${id}/mergeUserContactSMSMMSByGroups`,
      payload
    );
  },

  getUnreadMessages: function (id) {
    return instance.get(`/users/${id}/getUserSMSMMSNoRead`);
  },
  getUserSMSMMSNoReadCount: function (id) {
    return instance.get(`/users/${id}/getUserSMSMMSNoReadCount`);
  },

  //Users Notifications
  getNotificationById: function (id) {
    return instance.get(`/notification/${id}`);
  },
  getAllNotificationsByUser: function (id, params = null) {
    return instance.get(`/users/${id}/allnotifications?${params}`);
  },
  getUserNotificationsTypes: function (id) {
    return instance.get(`/users/${id}/getNotificationsTypes`);
  },
  getUserNotifications: function (id, notification_type) {
    return instance.get(`/users/${id}/userNotifications`);
  },
  getUserNotificationsNoRead: function (id) {
    return instance.get(`/users/${id}/noreadnotification`);
  },
  setUserViewNotifications: function (id, idNot) {
    return instance.get(`/users/${id}/notification/${idNot}/view`);
  },
  setUserViewNotificationsGroup: function (id) {
    return instance.get(`/users/${id}/notifications/view`);
  },
  addUserNotification: function (id, payload) {
    return instance.post(`/users/${id}/notification`, payload);
  },

  getCallContactData: function (id, params) {
    return instance.get(`/users/${id}/getCallContactData?${params}`);
  },

  getRecordDocuments: function (id) {
    return instance.get(`/records/${id}/getAllDocuments`);
  },

  getDoctorPerformanceByProcedure: function (id, params) {
    return instance.get(
      `/organizations/${id}/count_compled_procedure_by_doctor${params}`
    );
  },

  getDoctorProjectionByProcedure: function (id, params) {
    return instance.get(
      `/organizations/${id}/count_nocompleted_procedure_by_doctor${params}`
    );
  },
  //DEPARTMENTS
  getDepartments: function (orgId) {
    return instance.get(`/organizations/${orgId}/departments`);
  },
  deleteDepartment: function (id) {
    return instance.delete(`/departments/${id}`);
  },

  saveDepartment: function (payload) {
    return instance.post("/departments", payload);
  },
  updateDepartment: function (id, payload) {
    return instance.put(`/departments/${id}`, payload);
  },

  getDepartmentUsers: function (id) {
    return instance.get(`/departments/${id}/users`);
  },

  //SmsMmsContactColor

  setAsView: function (id) {
    return instance.post(`/messages/view/${id}`);
  },

  getUsersSmsMss: function (id) {
    return instance.get(`/contacts/${id}/getuserschat`);
  },

  getGroupsSmsMss: function (id) {
    return instance.get(`/contacts/${id}/getgroupschat`);
  },

  saveSmsMmsContactColor: function (payload) {
    return instance.post("/smsmmscontactcolor", payload);
  },
  updateSmsMmsContactColor: function (id, payload) {
    return instance.put(`/smsmmscontactcolor/${id}`, payload);
  },

  deleteSmsMmsContactColor: function (id) {
    return instance.delete(`/smsmmscontactcolor/${id}`);
  },

  handleIsImportant: function (id, payload) {
    return instance.put(`/smsmmscontactcolor/${id}/is_important`, payload);
  },

  setSmsMmsContactColor: function (id, payload) {
    return instance.post(`/users/${id}/smsmmscontactcolor`, payload);
  },
  setSmsMmsContactColorByGroups: function (id, payload) {
    return instance.post(`/users/${id}/smsmmscontactcolorbygroups`, payload);
  },
  getSmsMmsContactColor: function (id) {
    return instance.get(`/users/${id}/getsmsmmscontactcolor`);
  },

  //GROUPS
  getGroups: function (orgId) {
    return instance.get(`/organizations/${orgId}/groups`);
  },
  saveGroup: function (payload) {
    return instance.post("/groups", payload);
  },
  updateGroup: function (id, payload) {
    return instance.put(`/groups/${id}`, payload);
  },
  deleteGroup: function (id) {
    return instance.delete(`/groups/${id}`);
  },

  getGroupUsers: function (id) {
    return instance.get(`/groups/${id}/users`);
  },

  getSalesGroups: function (department) {
    return instance.get(`/groups/bydepartment/${department}`);
  },

  getSalesGroups2: function (department) {
    return instance.get(`/groups/bydepartment2/${department}`);
  },

  //RecordProductTransfer
  /* getRecordProductTransfer: function (orgId) {
      return instance.get(`/organizations/${orgId}/recordproducttransfers`);
    },*/
  saveRecordProductTransfer: function (payload) {
    return instance.post("/recordproducttransfers", payload);
  },
  updateRecordProductTransfer: function (id, payload) {
    return instance.put(`/recordproducttransfers/${id}`, payload);
  },
  deleteRecordProductTransfer: function (id) {
    return instance.delete(`/recordproducttransfers/${id}`);
  },
  createMultiRecordProductTransfer: function (payload) {
    return instance.post("/recordproduct-multi-transfer", payload);
  },
  //ROLES
  getRolesWithPermissions: function (orgId) {
    return instance.get(`/organizations/${orgId}/roles?include=permissions`);
  },
  getRoles: function (orgId) {
    return instance.get(`/organizations/${orgId}/roles2`);
  },
  getRolesDropdown: function (orgId) {
    return instance.get(`/organizations/${orgId}/roles/dropdown`);
  },
  saveRole: function (payload) {
    return instance.post("/roles", payload);
  },
  updateRole: function (id, payload) {
    return instance.put(`/roles/${id}`, payload);
  },
  deleteRole: function (id) {
    return instance.delete(`/roles/${id}`);
  },
  getRoleActivityLogs: function (id, page, limit) {
    return instance.get(
      `/roles/${id}/activitiesLogs?page=${page ? page : 0}&limit=${
        limit ? limit : 10
      }`
    );
  },
  //PERMISSIONS
  getPermissions: function (orgId) {
    return instance.get(`/organizations/${orgId}/permissions`);
  },
  getPermissionsInventory: function () {
    return instance.get(`/inventory/invPermissions`);
  },
  //Custom Fields
  getCustomFields: function (orgId) {
    return instance.get(
      `/organizations/${orgId}/customfields?include=modeltype`
    );
  },
  getCustomFieldsRecordsbyModel: function (orgId, modelid) {
    return instance.get(`/organizations/${orgId}/modelitems/${modelid}`);
  },
  deleteCustomField: function (id) {
    return instance.delete(`/customfields/${id}`);
  },
  saveCustomField: function (payload) {
    return instance.post(`/customfields`, payload);
  },
  updateCustomField: function (id, payload) {
    return instance.put(`/customfields/${id}`, payload);
  },
  //Custom Field Models
  getModels: function () {
    return instance.get(`/customfieldmodels`);
  },
  getCustomfieldCategories: function (orgId) {
    return instance.get(`/organizations/${orgId}/customfieldcategories`);
  },
  //PRODUCTS
  getProductsToUsersCosts: function (orgId) {
    return instance.get(`/organizations/${orgId}/producttodoctorsconfig`);
  },

  getProductsToUsersConfig: function (orgId) {
    return instance.get(
      `/organizations/${orgId}/proceduresdropdowntodoctorconfig`
    );
  },
  getProducts2: function (orgId) {
    return instance.get(`/organizations/${orgId}/products2`);
  },
  getProductsPlain: function (orgId) {
    return instance.get(`/organizations/${orgId}/productsplain`);
  },
  getPhysicalActiveProducts: function (orgId) {
    return instance.get(`/organizations/${orgId}/physicalactiveproducts`);
  },
  getProducts: function (orgId) {
    return instance.get(`/organizations/${orgId}/products`);
  },
  saveProduct: function (payload) {
    return instance.post("/products", payload);
  },
  updateProduct: function (id, payload) {
    return instance.put(`/products/${id}`, payload);
  },
  deleteProduct: function (id) {
    return instance.delete(`/products/${id}`);
  },
  getUserPrices: function (id) {
    return instance.get(`/products/${id}/userprices`);
  },

  getActiveProducts: function (id, params) {
    return instance.get(`/organizations/${id}/activeproducts${params}`);
  },

  //PAYMENT GATEWAY
  getPaymentGateways: function (orgId, params = "") {
    return instance.get(`/organizations/${orgId}/paymentgateways${params}`);
  },
  getPaymentGatewaysAll: function (orgId) {
    return instance.get(`/organizations/${orgId}/paymentgatewaysAll`);
  },
  savePaymentGateway: function (payload) {
    return instance.post("/paymentgateways", payload);
  },
  updatePaymentGateway: function (id, payload) {
    return instance.put(`/paymentgateways/${id}`, payload);
  },
  deletePaymentGateway: function (id) {
    return instance.delete(`/paymentgateways/${id}`);
  },

  //CATEGORIES
  getCategories: function (orgId) {
    return instance.get(`/organizations/${orgId}/categories`);
  },
  saveCategory: function (payload) {
    return instance.post("/categories", payload);
  },
  updateCategory: function (id, payload) {
    return instance.put(`/categories/${id}`, payload);
  },
  deleteCategory: function (id) {
    return instance.delete(`/categories/${id}`);
  },

  //CONTACTS New
  getIDTypes: function () {
    return instance.get(`/contacts/idtypes`);
  },
  getContactsnew: function (orgId, params = null) {
    return apiGet(`/organizations/${orgId}/contacts_plain${params}`);
  },

  possibleFraudolent: function (id) {
    return instance.get(`/contacts/${id}/possiblefraudolent`);
  },

  setContactProductsControl: function (id, payload) {
    return instance.post(`/contacts/${id}/productscontrol`, payload);
  },

  setContactAsFraudolent: function (id, payload) {
    return instance.post(`/contacts/${id}/setasfraudolent`, payload);
  },

  unmarkContactAsFraudolent: function (id, payload) {
    return instance.post(`/contacts/${id}/unmarkasfraudolent`, payload);
  },

  getContactsToStates: function (orgId, params = null) {
    return instance.get(`/organizations/${orgId}/contacts_plain_new5${params}`);
  },

  getRecordsToStates: function (orgId, params = null) {
    return instance.get(`/organizations/${orgId}/records_plain3${params}`);
  },

  getAllContactsnew: function () {},
  saveContactExtraField: function (id, payload) {
    return instance.post(`/contacts/${id}/extrafield`, payload);
  },

  deleteContactExtraPhone: function (id, payload) {
    return instance.post(`/contacts/${id}/deleteextraphone`, payload);
  },

  updateContactExtraPhone: function (id, payload) {
    return instance.post(`/contacts/${id}/updateextraphonelabel`, payload);
  },

  deleteContactExtraEmail: function (id, payload) {
    return instance.post(`/contacts/${id}/deleteextraemail`, payload);
  },

  updateContactExtraEmail: function (id, payload) {
    return instance.post(`/contacts/${id}/updateextraemaillabel`, payload);
  },

  getContactQuery: function (payload) {
    return instance.get("/contacts/query", payload);
  },

  getContactSourcesReport: function (from, to, payload) {
    return instance.get(`/contacts/sources/report/${from}/${to}`, payload);
  },

  getConsultanPerformanceByMoneyReport: function (orgId, payload) {
    return instance.post(
      `/organizations/${orgId}/consultant_performance_by_money`,
      payload
    );
  },

  updateContactnew: function (id, payload) {
    return instance.put(`/contacts/${id}`, payload);
  },
  financingDone: function (id, payload) {
    return instance.put(`/contacts/${id}/financingdone`, payload);
  },
  deleteContactnew: function (id) {
    return instance.delete(`/contacts/${id}`);
  },
  saveContactSocialPlatform: function (contactId, payload) {
    return instance.post(`/contacts/${contactId}/socialplatforms`, payload);
  },

  updateContactSocialPlatform: function (contactId, id, payload) {
    return instance.put(
      `/contacts/${contactId}/socialplatforms/${id}`,
      payload
    );
  },
  deleteContactSocialPlatform: function (contactId, id) {
    return instance.delete(`/contacts/${contactId}/socialplatforms/${id}`);
  },
  getContactSources: function (orgId) {
    return instance.get("/contacts/sources");
  },

  getContactRefunds: function (id) {
    return instance.get(`/contacts/${id}/refunds`);
  },

  getContactPayments: function (id) {
    return instance.get(`/contacts/${id}/payments`);
  },

  //recordProductsNew

  getDocumentsRP: function (recordId) {
    return instance.get(`/recordproducts/${recordId}/documents`);
  },

  getRecordProductCosts: function (recordId) {
    return instance.get(`/recordproducts/${recordId}/getRecordProductCosts`);
  },

  getRecordProductsStatusHistory: function (recordId) {
    return instance.get(`/recordproducts/${recordId}/statushistory`);
  },

  getAssistantsRP: function (recordId) {
    return instance.get(`/recordproducts/${recordId}/assistants`);
  },

  getAnesthesiologistsRP: function (recordId) {
    return instance.get(`/recordproducts/${recordId}/anesthesiologists`);
  },

  getCirculatorsRP: function (recordId) {
    return instance.get(`/recordproducts/${recordId}/circulators`);
  },

  getRecordProductLogs: function (id) {
    return instance.get(`/recordproducts/${id}/logs`);
  },

  getPortalToken: function (contact_id, language) {
    return instance.get(
      `/records/${contact_id}/portal_token?language=${language}`
    );
  },

  getDocumentsSendRecord: function (recordId) {
    return instance.get(`/record/${recordId}/getDocumentsSent`);
  },

  getRecordProductsNew: function (orgId, params = null) {
    return apiGet(
      `/organizations/${orgId}/recordproducts_new${params}&include=recordfollowup,recordProductsStatusHistory,invoice,responsible,salesman,invoiceItem,record,coordinator`
    );
  },

  getContactRecordProductsByDate: function (id, params = "") {
    return instance.get(`/contacts/${id}/recordproducts-by-date${params}`);
  },

  //Get duplicate Record products
  getDuplicateRecordsProducts: function () {
    return instance.get(`/getrecordsproducts/duplicates`);
  },
  //Get Dashboard VS PaymentReport
  getHistoryVSDetails: function (from, to, org) {
    return instance.get(`/dashboard/vs/paymentreport/${from}/${to}/${org}`);
  },

  //Get Duplicate Contacts Report
  getDuplicateContactsReport: function (org, from, to, building) {
    return instance.get(
      `/getduplicate/contacts/${org}/from/${from}/to/${to}/building/${building}`
    );
  },
  getDuplicateContactsHistoryDetails: function (id) {
    return instance.get(`/getduplicate/contacts/history/${id}`);
  },

  //get records with procedure where procedure in date range
  getRecordWithProducts: function (orgId, params = null) {
    return apiGet(`/organizations/${orgId}/recordproducts_new${params}`);
  },

  saveRecordProductsNew: function (payload) {
    return instance.post("/recordproducts", payload);
  },
  updateRecordProductsNew: function (id, payload) {
    return instance.put(`/recordproducts/${id}`, payload);
  },
  deleteRecordProductsNew: function (id) {
    return instance.delete(`/recordproducts/${id}`);
  },

  //INVOICES New
  getInvoicesnew: function (orgId, params = null) {
    return apiGet(`/organizations/${orgId}/invoices_plain${params}`);
  },

  getALLInvoicesnew: function (orgId, params = null) {
    return apiGet(`/organizations/${orgId}/invoices_plain`);
  },

  saveInvoicenew: function (payload) {
    return instance.post("/invoices", payload);
  },
  updateInvoicenew: function (id, payload) {
    return instance.put(`/invoices/${id}`, payload);
  },
  deleteInvoicenew: function (id) {
    return instance.delete(`/invoices/${id}`);
  },

  getInvoicesSiblings: function (id) {
    return instance.get(`/invoices/${id}/siblings`);
  },

  transferInvoicePayment: function (id, paymentId, payload) {
    return instance.post(
      `/invoices/${id}/payments/${paymentId}/transfer`,
      payload
    );
  },

  transferInvoiceMultiPayment: function (id, payload) {
    return instance.post(`/invoices/payments/${id}/multitransfer`, payload);
  },

  getInvoicePaymentLink: function (id, payload) {
    return instance.get(`/invoices/${id}/getPaymentLink`, payload);
  },

  // Get Invoice Available Devices DropDown
  getInvoiceAvailableDevicesDropDown: function (id) {
    return instance.get(`/merchants/getInvoiceAvailableDevicesDropDown/${id}`);
  },

  //Check and Fix Invoice
  checkAndFixInvoice: function (id) {
    return instance.get(`/fixpayments/invoices/${id}`);
  },

  //FixInvoices with broken payments
  getBrokenInvoicesCount: function () {
    return instance.get(`/getbroken/invoices/count`);
  },
  checkAndFixInvoicePaymentsForAll: function (limit) {
    return instance.get(`/fixpayments/all/${limit}`);
  },

  //FixInvoices with broken refunds
  getBrokenInvoicesRefundsCount: function () {
    return instance.get(`/getbrokenrefunds/invoices/count`);
  },
  checkAndFixInvoiceRefundsForAll: function (limit) {
    return instance.get(`/fixrefunds/all/${limit}`);
  },

  //FixInvoices with broken overPayments
  getBrokenOverPaymentsInvoicesCount: function () {
    return instance.get(`/invoices/overpayments/check`);
  },
  checkAndFixInvoiceOverPaymentsForAll: function (limit) {
    return instance.get(`/invoices/overpayments/checkandfixforall/${limit}`);
  },

  //Rooms
  getRooms: function (orgId, params = null) {
    return apiGet(`/organizations/${orgId}/rooms${params}`);
  },

  getALLRooms: function (orgId, params = null) {
    return apiGet(`/organizations/${orgId}/rooms`);
  },

  saveRooms: function (payload) {
    return instance.post("/rooms", payload);
  },
  updateRooms: function (id, payload) {
    return instance.put(`/rooms/${id}`, payload);
  },
  deleteRooms: function (id) {
    return instance.delete(`/rooms/${id}`);
  },

  //Rooms Services
  getRoomServices: function (orgId, params = null) {
    return apiGet(`/organizations/${orgId}/rooms_services${params}`);
  },

  getALLRoomServices: function (orgId, params = null) {
    return apiGet(`/organizations/${orgId}/rooms_services`);
  },

  saveRoomService: function (payload) {
    return instance.post("/roomsservices", payload);
  },
  updateRoomService: function (id, payload) {
    return instance.put(`/roomsservices/${id}`, payload);
  },
  deleteRoomService: function (id) {
    return instance.delete(`/roomsservices/${id}`);
  },

  //Rooms States
  getRoomStates: function (orgId, params = null) {
    return apiGet(`/organizations/${orgId}/rooms_states${params}`);
  },

  getALLRoomStates: function (orgId, params = null) {
    return apiGet(`/organizations/${orgId}/rooms_states`);
  },

  saveRoomState: function (payload) {
    return instance.post("/roomsstates", payload);
  },
  updateRoomState: function (id, payload) {
    return instance.put(`/roomsstates/${id}`, payload);
  },
  deleteRoomState: function (id) {
    return instance.delete(`/roomsstates/${id}`);
  },

  //Appointments Motives
  getAppointmentsMotives: function (orgId, params = null) {
    return apiGet(`/organizations/${orgId}/appointmentsmotives${params}`);
  },
  getAllAppointmentsMotives: function (orgId, params = null) {
    return instance.get(`/organizations/${orgId}/appointmentsmotives`);
  },
  saveAppointmentsMotive: function (payload) {
    return instance.post("/appointmentsmotives", payload);
  },
  updateAppointmentsMotive: function (id, payload) {
    return instance.put(`/appointmentsmotives/${id}`, payload);
  },
  deleteAppointmentsMotive: function (id) {
    return instance.delete(`/appointmentsmotives/${id}`);
  },

  //contactMetadataCategory

  saveContactMetadataCategory: function (payload) {
    return instance.post("/contactmetadatacategories", payload);
  },
  updateContactMetadataCategory: function (id, payload) {
    return instance.put(`/contactmetadatacategories/${id}`, payload);
  },
  deleteContactMetadataCategory: function (id) {
    return instance.delete(`/contactmetadatacategories/${id}`);
  },

  getContactMetadataCategoryDropdown: function (id) {
    return instance.get(`/organizations/${id}/contactmetadatacategorydropdown`);
  },

  getContactMetadataCategoryDropdownToExport: function (id) {
    return instance.get(
      `/organizations/${id}/contactmetadatacategorydropdowntoexport`
    );
  },

  //CONTACTS
  getContactAppointments: function (id) {
    return instance.get(`/contacts/${id}/appointmentsnew`);
  },

  updateContactDocumment: function (payload, id) {
    return instance.post(`/contacts/updatemedia/${id}`, payload);
  },

  getInvoicesContactsPlain: function (id) {
    return instance.get(`/contacts/${id}/invoicesplain`);
  },

  getInvoicesContactsPlain2: function (id) {
    return instance.get(`/contacts/${id}/invoicesplain2`);
  },

  getInvoicesContactsPlainDeleted: function (id) {
    return instance.get(`/contacts/${id}/invoicesplain2deleted`);
  },

  getInvoicesContacts: function (id) {
    return instance.get(`/contacts/${id}/invoices`);
  },

  getQuotesContacts: function (id) {
    return instance.get(`/contacts/${id}/quotes`);
  },

  createTicket: function (payload) {
    return instance.post(`/tickets`, payload);
  },

  updateTicket: function (id, payload) {
    return instance.post(`/tickets/${id}`, payload);
  },

  getContactTickets: function (id) {
    return instance.get(`/contacts/${id}/tickets`);
  },

  getSearchContacts: function (orgId, params) {
    return instance.get(`/organizations/${orgId}/contacts_search${params}`);
  },
  getSearchContacts2: function (orgId, params) {
    return instance.get(`/organizations/${orgId}/contacts_search2${params}`);
  },
  getSearchContactsMessages: function (orgId, params) {
    return instance.get(
      `/organizations/${orgId}/index_org_contacts_messages${params}`
    );
  },

  getSearchUsersMessages: function (orgId, params) {
    return instance.get(
      `/organizations/${orgId}/index_users_messages${params}`
    );
  },

  getContacts: function (orgId, params) {
    return instance.get(
      `/organizations/${orgId}/contacts_plain?include=organization${params}`
    );
  },
  getContactsSelect: function (orgId) {
    return instance.get(
      `/organizations/${orgId}/contacts_plain?include=organization`
    );
  },
  getContactsByName: function (orgId, name) {
    return instance.get(
      `/organizations/${orgId}/contacts_plain?search=${name}&limit=500`
    );
  },
  getUnassignedContacts: function (orgId) {
    return instance.get(`/organizations/${orgId}/unassigned_contacts`);
  },
  assignContacts: function (payload) {
    return instance.post(`/contacts/assignConsultant`, payload);
  },
  getContact: function (id) {
    return instance.get(`/contacts/${id}`);
  },
  getContactSecureToken: function (id) {
    return instance.post(`/contacts/${id}/getSecureToken`);
  },
  getContactPlain: function (id) {
    return instance.get(`/contactsPlain/${id}?view=1`);
  },
  getContactPlain2: function (id) {
    return instance.get(`/contactsPlain/${id}?view=2`);
  },
  getContactDocuments: function (id) {
    return instance.get(`/contacts/${id}/getAllDocuments`);
  },

  getContactSMSMMSUsers: function (id) {
    return instance.get(`/contacts/${id}/getAllMessageUsers`);
  },
  getContactMmsTemporalUrl: function (id, minutes) {
    return instance.get(`/contact/get/mms/${id}/temporal_url/${minutes}/valid`);
  },
  saveContact: function (payload) {
    return instance.post("/contacts", payload);
  },
  updateContact: function (id, payload) {
    return instance.put(`/contacts/${id}`, payload);
  },
  deleteContact: function (id) {
    return instance.delete(`/contacts/${id}`);
  },
  getContactCustomFields: function (orgId) {
    return instance.get(`/organizations/${orgId}/customfields2/contact`);
  },
  createContactComment: function (id, payload) {
    return instance.post(`/contacts/${id}/comments`, payload);
  },
  getContactComments: function (id) {
    return instance.get(`/contacts/${id}/comments`);
  },

  createContactDocument: function (id, payload) {
    return instance.post(`/contacts/${id}/documents`, payload);
  },

  createContactDocuments: function (id, payload) {
    return instance.post(`/contacts/${id}/documentsall`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  getContactDocument: function (id, docType = null) {
    return instance.get(`/contacts/${id}/getAllDocuments/${docType}`);
  },

  getDocumentsSend: function (id) {
    return instance.get(`/contacts/${id}/getDocummentsSent`);
  },

  saveToContactImage: function (id, media_id, payload) {
    return instance.post(
      `/contacts/${id}/saveToContactImage/${media_id}`,
      payload
    );
  },
  sendContactPaymentLinkBySms: function (id, payload) {
    return instance.post(`/contacts/${id}/send/paymentLinkBySms`, payload);
  },
  sendContactRefundRequestLinkBySms: function (id, payload) {
    return instance.post(`/contacts/${id}/send/refund-request-link`, payload);
  },
  sendContactSmsOrMms: function (id, payload) {
    return instance.post(`/contacts/${id}/send/message`, payload);
  },

  sendWhatsAppMessage: function (payload) {
    return instance.post(`/whatsapps/sendwhatsapp`, payload);
  },

  getContactSMSMMS: function (id, params) {
    return instance.get(`/contacts/${id}/getSMSMMS${params}`);
  },
  getContactSMSMMSTabs: function (id, params) {
    return instance.get(`/contacts/${id}/getSMSMMSTabs${params}`);
  },
  getWhatsAppConfirmed: function (payload) {
    return instance.post(`/whatsapps/getwhatsappconfirmed`, payload);
  },

  getContactSMSMMSByGroups: function (id, group_id, params) {
    return instance.get(
      `/contacts/${id}/${group_id}/getSMSMMSByGroups${params}`
    );
  },

  getContactSMSMMSByGroupsPhone: function (phone, group_id, params) {
    return instance.get(
      `/contacts/${phone}/${group_id}/getSMSMMSByGroupsPhone${params}`
    );
  },

  getContactSMSMMSHistory: function (id, params) {
    return instance.get(`/contacts/${id}/getSMSMMSHistory${params}`);
  },

  getContactSMSMMSHistoryTabs: function (id, params) {
    return instance.get(`/contacts/${id}/getSMSMMSHistoryTabs${params}`);
  },

  getContactSMSMMSHistoryByGroups: function (id, params) {
    return instance.get(`/contacts/${id}/getSMSMMSHistoryByGroups${params}`);
  },

  getContactSMSMMSHistoryUser: function (id, user_id, params) {
    return instance.get(
      `/contacts/${id}/${user_id}/getSMSMMSHistoryUser${params}`
    );
  },

  getContactSMSMMSHistoryUserByGroups: function (id, group_id, params) {
    return instance.get(
      `/contacts/${id}/${group_id}/getSMSMMSHistoryUserByGroups${params}`
    );
  },

  getContactActivity: function (id) {
    return instance.get(`/contacts/${id}/activities`);
  },

  getContactEvaluation: function (id) {
    return instance.get(`/contacts/${id}/getContactEvaluations`);
  },

  getBuildingsWithTypeformDropDown: function () {
    return instance.get(`/buildings/dropdown_by_typeform`);
  },

  getBuildingTypeforms: function () {
    return instance.get(`/buildings/typeforms_dropdown`);
  },

  getRecording: function (domain, call) {
    return instance.get(
      `hooks/download/pbxdomain/${domain}/call/${call}/aksjdhcbaksjhbwakvjhdbkavjhd`
    );
  },

  transfer_call_to: function (callId, destination, leg) {
    return instance.get(
      `hooks/transfer/${callId}/to/${destination}/leg/${leg}`
    );
  },

  cleanTemporalRecordings: function (id) {
    return instance.get(`hooks/clean/temporalfiles/${id}/call`);
  },

  cleanTemporalRecordingsForUsers: function () {
    return instance.get(`hooks/clean/temporalfiles/foruser/calls`);
  },

  addFinalStateToInboundCalls: function (org, domain, from, to, state) {
    return instance.get(
      `hooks/${org}/pbxdomain/${domain}/calling/${from}/to/${to}/final/${state}/kjvlkvjeflwjcbnlkbwmmelkhjotib`
    );
  },

  addFinalStateToOutboundCalls: function (org, domain, from, to, state) {
    return instance.get(
      `hooks/${org}/pbxdomain/${domain}/calling/${from}/to/${to}/final/${state}/outbound/kjvlkvjeflwjcbnlkbwmmelkhjotib`
    );
  },

  addEndDateToCall: function (id) {
    return instance.get(
      `hooks/add/end/date/to/${id}/call/kjvlkvjeflwjcbnlkbwmmelkhjotib`
    );
  },

  originateCallTo: function (domain, sourceExt, originationNum, destination) {
    return instance.get(
      `hooks/user/originate/${domain}/${sourceExt}/${originationNum}/to/${destination}/slkjdvbhacboqcwhflwjhbhl`
    );
  },

  getContactQuotesPlain: function (id) {
    return instance.get(`/contacts/${id}/quotesplain`);
  },
  getContactQuotesPlainDelted: function (id) {
    return instance.get(`/contacts/${id}/quotesplaindeleted`);
  },

  getContactQuotes: function (id) {
    return instance.get(`/contacts/${id}/quotes`);
  },
  getContactInvoices: function (id) {
    return instance.get(`/contacts/${id}/invoices`);
  },
  getContactInvoicesmassages: function (id) {
    return instance.get(`/contacts/${id}/invoicesmassages`);
  },

  getContactInvoiceNoProcedure: function (id) {
    return instance.get(`/contacts/${id}/invoicesnoprocedure`);
  },
  getContactInvoiceNoProcedure2: function (id) {
    return instance.get(`/contacts/${id}/invoicesnoprocedure2`);
  },
  getContactCreditMemos: function (id) {
    return instance.get(`/contacts/${id}/creditmemos`);
  },
  getContactSocialPlatforms: function (id) {
    return instance.get(`/contacts/${id}/socialplatforms`);
  },
  sendFormTemplate: function (id, payload) {
    return instance.post(`/contacts/${id}/sendtemplate`, payload);
  },
  sendMassiveTemplate: function (payload) {
    return instance.post(`/contacts/sendmassivetemplate`, payload);
  },
  attachImageToTemplate: function (id, payload) {
    return instance.post(`/form-templates/attachImage/${id}`, payload);
  },

  checkDuplicate: function (column, value) {
    return instance.get(
      `/contacts/check_duplicate?column=${column}&value=${value}`
    );
  },

  //contacts
  getContactsforBuilding: function (payload, page, limit) {
    return instance.post(
      `/contactsforbuilding?page=${page ? page : 1}&limit=${
        limit ? limit : 50
      }`,
      payload
    );
  },
  getContactRecordProducts: function (id) {
    return instance.get(`/contacts/${id}/recordproducts`);
  },
  makeTransferDateRequest: function (id, payload) {
    return instance.post(`/recordproduct/${id}/requestdatetransfer`, payload);
  },
  //Contacts To Do
  getContactToDos: function (id, params = "") {
    return instance.get(`/contacts/${id}/todo${params}`);
  },
  getContactToDoReport: function (
    done,
    type,
    fromDate,
    consultant,
    responsible,
    buildng
  ) {
    return instance.get(
      `/contacts/todo/report/${done}/${type}/${fromDate}/${consultant}/${responsible}/${buildng}`
    );
  },
  addContactToDo: function (id, payload) {
    return instance.post(`/contacts/${id}/todo`, payload);
  },
  updateContactToDo: function (id, todoid, payload) {
    return instance.post(`/contacts/${id}/todo/${todoid}`, payload);
  },
  updateContactToDoNew: function (id, todoid, payload) {
    return instance.post(`/contacts/${id}/updatetodonew/${todoid}`, payload);
  },
  getToDoActivityLogs: function (id, page, limit) {
    return instance.get(
      `/contacts/todo/${id}/activitiesLogs?page=${page ? page : 0}&limit=${
        limit ? limit : 10
      }`
    );
  },

  // Multi Contacts Operations

  assignTags: function (payload) {
    return instance.post(`/contacts/assignTags`, payload);
  },
  assignBuilding: function (payload) {
    return instance.post(`/contacts/assignBuilding`, payload);
  },
  autoCallsQueue: function (payload) {
    return instance.post(`/contacts/autoCallQueue`, payload);
  },

  // BUILDINGS

  getBuildings: function (orgId) {
    return instance.get(`/organizations/${orgId}/buildings`);
  },
  getAllBuildings: function (orgId) {
    return instance.get(`/organizations/${orgId}/buildings`);
  },
  saveBuilding: function (payload) {
    return instance.post("/buildings", payload);
  },
  updateBuilding: function (id, payload) {
    return instance.put(`/buildings/${id}`, payload);
  },
  deleteBuilding: function (id) {
    return instance.delete(`/buildings/${id}`);
  },
  getBuildingRecordProductsGroupByDate: function (id) {
    return instance.get(`/buildings/${id}/recordproduct_count_by_date`);
  },
  //RECORDFOLLOWUP
  saverecordfollowup: function (payload) {
    return instance.post(`recordfollowup`, payload);
  },

  updaterecordfollowup: function (id, payload) {
    return instance.put(`/recordfollowup/${id}`, payload);
  },

  //RECORDS
  getRecordProductReschedulingRequests: function (id) {
    return instance.get(`/records/recordproduct/${id}/reschedulingrequests`);
  },

  closeRecordProductReschedulingRequest: function (payload, id) {
    return instance.post(`/records/recordproductdatetransfer/${id}`, payload);
  },

  getCommentsRecord: function (record_id) {
    return instance.get(`/records/${record_id}/commentsrecord`);
  },

  sendMassiveTemplateRecords: function (payload) {
    return instance.post(`/records/sendmassivetemplate`, payload);
  },
  getRecordTransferRequests: function (id, page, limit) {
    return instance.get(
      `/records/${id}/record-product-transfer-requests?page=${
        page ? page : 0
      }&limit=${limit ? limit : 10}`
    );
  },
  updateRecordTransferRequests: function (id, payload) {
    return instance.post(`/record-product-transfer-request/${id}`, payload);
  },

  getRemarketingCampaignDetails: function (id, params = null) {
    return instance.get(`/remarketingcampaigns/${id}/details${params}`);
  },

  getRemarketingCampaigns: function (payload = null) {
    return instance.post(`/remarketingcampaigns`, payload);
  },

  getCampaignsStatisticsToDashboard: function (params = null) {
    return instance.get(
      `/remarketingcampaigns/campaignstatisticstodashboard?${params}`
    );
  },

  sendMassiveTemplateRecordsToRemarketing: function (payload) {
    return instance.post(`/remarketingcampaigns/send`, payload);
  },

  getRecordsnew: function (orgId, params = null) {
    return apiGet(`/organizations/${orgId}/records_plain3${params}`);
  },
  getALLRecordsnew: function (orgId) {
    return instance.get(`/organizations/${orgId}/records_plain3`);
  },

  saveRecordnew: function (payload) {
    return instance.post("/records", payload);
  },
  updateRecordnew: function (id, payload) {
    return instance.put(`/records/${id}`, payload);
  },
  deleteRecordnew: function (id) {
    return instance.delete(`/records/${id}`);
  },

  getRecords: function (orgId) {
    return instance.get(`/organizations/${orgId}/records_plain`);
  },

  getRecords2: function (orgId, params) {
    return instance.get(`/organizations/${orgId}/records${params}`);
    //return instance.get(`/accounting/expenseitems?include=accExpenseCategory`);
  },

  getRecordsFilters: function (orgId, params) {
    return instance.get(`/organizations/${orgId}/records${params}`);
  },
  getRecordsFilters2: function (orgId, params) {
    return instance.get(`/organizations/${orgId}/records_plain${params}`);
  },
  getRecord2: function (orgId, params) {
    return instance.get(`/organizations/${orgId}/records_plain2${params}`);
  },
  getRecord3: function (orgId, params) {
    return instance.get(`/organizations/${orgId}/records_plain3${params}`);
  },

  getRecords: function (id) {
    return instance.get(`/recordsplain/${id}?include=creditMemos`);
  },

  getRecord: function (id) {
    return instance.get(`/records/${id}?include=creditMemos`);
  },
  getPortalRecord: function (id) {
    return instance.get(`/portal/records/${id}?include=creditMemos`);
  },

  getNextSugeryRecord: function (id) {
    return instance.get(`/portal/nextsurgery/${id}`);
  },
  getRfsTypesToPortal: function (id) {
    return instance.get(`/portal/rfs_types`);
  },
  getRfsReasonsToPortal: function (id) {
    return instance.get(`/portal/rfs_reasons`);
  },
  submitRefundRequest: function (payload) {
    return instance.post(`/portal/refund-request`, payload);
  },

  isValidTokenToPortal: function (token) {
    return instance.get(`/auth/ptokenvalid/${token}`);
  },

  changePatientPassword: function (id, payload) {
    return instance.post(`/portal/changepassword/${id}`, payload);
  },
  getPortalDocuments: function (id) {
    return instance.get(`/portal/showDocuments/${id}`);
  },
  saveRecord: function (payload) {
    return instance.post("/records", payload);
  },
  updateRecord: function (id, payload) {
    return instance.put(`/records/${id}`, payload);
  },
  deleteRecord: function (id) {
    return instance.delete(`/records/${id}`);
  },
  getRecordCustomFields: function (orgId) {
    return instance.get(`/organizations/${orgId}/customfields/record`);
  },

  getRecordRefundRequests: function (id) {
    return instance.get(`/records/${id}/refundrequests`);
  },

  createRecordComment: function (id, payload) {
    return instance.post(`/records/${id}/comments`, payload);
  },
  createRecordDocument: function (id, payload) {
    return instance.post(`/records/${id}/documents`, payload);
  },
  createPortalRecordDocument: function (id, payload) {
    return instance.post(`/portal/records/${id}/documents`, payload);
  },

  getRecordActivity: function (id) {
    return instance.get(`/records/${id}/activities`);
  },
  getRecordProducts: function (id) {
    return instance.get(`/records/${id}/recordproducts`);
  },
  getRecordProductsPlain: function (id) {
    return instance.get(`/records/${id}/recordproductsplain`);
  },

  getRecordProductsPlainOrigen: function (id) {
    return instance.get(`/records/${id}/recordproductsplainorigen`);
  },

  getRecordProductsImages: function (id) {
    return instance.get(`/records/${id}/recordproductsimagesweb`);
  },
  getRecordProductsImagesOthers: function (id) {
    return instance.get(`/records/${id}/recordproductsimageswebbaothers`);
  },
  getRecordProductsImagesOR: function (id) {
    return instance.get(`/records/${id}/recordproductsimageswebbaor`);
  },

  sendTemplate: function (id, payload) {
    return instance.post(`/records/${id}/sendtemplate`, payload);
  },
  sendContactFormTemplate: function (id, payload) {
    return instance.post(`/contacts/${id}/sendtemplate`, payload);
  },

  getRecordLogs: function (id) {
    return instance.get(`/records/${id}/logs`);
  },

  MarkOrUnamrkContactAsTransfer: function (id, payload) {
    return instance.put(
      `/records/${id}/MarkOrUnamrkContactAsTransfer`,
      payload
    );
  },

  //PRODUCT RECORDS
  updateProductRecord: function (id, payload) {
    return instance.put(`/recordproducts/${id}`, payload);
  },

  deleteProductRecord: function (id) {
    return instance.delete(`/recordproducts/${id}`);
  },
  createProductRecordDocument: function (id, payload) {
    return instance.post(`/recordproducts/${id}/documents`, payload);
  },
  createProductRecordStatusHistory: function (id, payload) {
    return instance.post(`/recordproducts/${id}/statushistory`, payload);
  },
  getProductRecord: function (id) {
    return instance.get(`/recordproducts/${id}`);
  },

  getProductRecordPlain: function (id) {
    return instance.get(`/recordproductsplain/${id}`);
  },
  getProductRecordPlainOrigen: function (id) {
    return instance.get(`/recordproductsplainorigen/${id}`);
  },

  deleteProductRecordAssistants: function (id, assistant_id) {
    return instance.delete(`/recordproducts/${id}/assistants/${assistant_id}`);
  },

  deleteProductRecordAnesthesiologist: function (id, anesthesiologist_id) {
    return instance.delete(
      `/recordproducts/${id}/anesthesiologists/${anesthesiologist_id}`
    );
  },

  deleteProductRecordCirculator: function (id, circulator_id) {
    return instance.delete(
      `/recordproducts/${id}/circulators/${circulator_id}`
    );
  },

  //invitation_link
  createCode: function (contact_id, payload) {
    return instance.post(`/contacts/${contact_id}/getSecureToken`, payload);
  },
  generateUploadInvitation: function (contact_id, payload) {
    return instance.post(
      `/contacts/${contact_id}/generateUploadInvitation`,
      payload
    );
  },

  generateEvaluationInvitation: function (contact_id, payload) {
    return instance.post(
      `/contacts/${contact_id}/generateEvaluationInvitation`,
      payload
    );
  },
  generateTypeformLink: function (contact_id, payload) {
    return instance.post(
      `/contacts/${contact_id}/generateTypeformInvitation`,
      payload
    );
  },

  //Languages
  getLanguages: function (orgId) {
    return instance.get(`/organizations/${orgId}/languages`);
  },

  updateRecordProductsCosts: function (id, payload) {
    return instance.post(`/recordproducts/${id}/recordproductscosts`, payload);
  },

  addRecordProductsCosts: function (id, payload) {
    return instance.post(
      `/recordproducts/${id}/addrecordproductscosts`,
      payload
    );
  },
  updateRecordProductsCosts: function (id, payload) {
    return instance.post(
      `/recordproducts/${id}/updaterecordproductscosts`,
      payload
    );
  },

  deleteRecordProductsCosts: function (id, deleted_id) {
    return instance.delete(
      `/recordproducts/${id}/deleterecordproductscosts/${deleted_id}`
    );
  },

  //QUOTES
  getQuotes: function (orgId) {
    return instance.get(
      `/organizations/${orgId}/quotes?include=contact,user,approvedBy`
    );
  },
  getQuote: function (id) {
    return instance.get(`/quotes/${id}?include=organization`);
  },
  getQuoteDelted: function (id) {
    return instance.get(`/quotes/${id}/deleted?include=organization`);
  },
  getQuoteActivity: function (id) {
    return instance.get(`/quotes/${id}/activities`);
  },
  deleteQuoteItem: function (quoteId, id) {
    return instance.delete(`/quotes/${quoteId}/items/${id}`);
  },
  saveQuote: function (payload) {
    return instance.post("/quotes", payload);
  },
  saveQuotePayment: function (id, payload) {
    return instance.post(`/quotes/${id}/payments`, payload);
  },
  updateQuote: function (id, payload) {
    return instance.put(`/quotes/${id}`, payload);
  },
  deleteQuote: function (id) {
    return instance.delete(`/quotes/${id}`);
  },
  getQuoteCustomFields: function (orgId) {
    return instance.get(`/organizations/${orgId}/customfields/quote`);
  },
  createtQuoteComment: function (id, payload) {
    return instance.post(`/quotes/${id}/comments`, payload);
  },
  createtQuoteDocument: function (id, payload) {
    return instance.post(`/quotes/${id}/documents`, payload);
  },
  syncQuoteResponsibles: function (id, payload) {
    return instance.put(`/quotes/${id}/responsibles`, payload);
  },
  attachQuoteResponsible: function (id, payload) {
    return instance.post(`/quotes/${id}/responsibles`, payload);
  },
  sendContract: function (id) {
    return instance.get(`/quotes/${id}/sendcontract`);
  },
  sendQuote: function (id) {
    return instance.get(`/quotes/${id}/send`);
  },
  quoteApplyItemDiscount: function (id, item_id, payload) {
    return instance.post(`/quotes/${id}/applyitemdiscount/${item_id}`, payload);
  },
  quoteRemoveItemDiscount: function (id, item_id) {
    return instance.delete(`/quotes/${id}/deleteitemdiscount/${item_id}`);
  },

  invoiceApplyItemDiscount: function (id, item_id, payload) {
    return instance.post(
      `/invoices/${id}/applyitemdiscount/${item_id}`,
      payload
    );
  },
  invoiceRemoveItemDiscount: function (id, item_id) {
    return instance.delete(`/invoices/${id}/deleteitemdiscount/${item_id}`);
  },

  approveQuote: function (id, payload) {
    return instance.post(`/quotes/${id}/approve`, payload);
  },
  deleteQuoteResponsible: function (quoteId, id) {
    return instance.delete(`/quotes/${quoteId}/responsibles/${id}`);
  },
  updateQuoteItemDownpayment: function (item_id, payload) {
    return instance.post(`/quotes/update_item_downpayment/${item_id}`, payload);
  },
  getQuoteItemsForQuoteLogs: function (quoteId) {
    return instance.get(`/quotes/${quoteId}/quote_items_for_logs`);
  },
  getQuoteItemActivityLogs: function (id, page, limit) {
    return instance.get(
      `/quote_item/${id}/activitiesLogs?page=${page ? page : 0}&limit=${
        limit ? limit : 10
      }`
    );
  },
  duplicateQuote: function (id) {
    return instance.post(`/quotes/${id}/duplicate-quote`);
  },

  getQuoteContract: function (id, contract_id) {
    return instance.get(`/quotes/${id}/contract/${contract_id}`);
  },

  //INVOICES
  applyCouponsToInvoice: function (id, payload) {
    return instance.post(`/invoices/${id}/apply-coupons`, payload);
  },

  getAvailableCouponsForNewInvoice: function (payload) {
    return instance.post(`/available-coupons`, payload);
  },

  getInvoiceAvailableCoupons: function (id) {
    return instance.get(`/invoices/${id}/available-coupons`);
  },

  updateInvoiceItemDownpayment: function (item_id, payload) {
    return instance.post(
      `/invoices/update_item_downpayment/${item_id}`,
      payload
    );
  },
  applyDiscountToInvoices: function (payload) {
    return instance.post(`/invoices/applydoctordiscount`, payload);
  },

  removeDiscountToInvoices: function (payload) {
    return instance.post(`invoices/removedoctordiscount`, payload);
  },

  deleteInvoiceResponsible: function (invoiceId, id) {
    return instance.delete(`/invoices/${invoiceId}/responsibles/${id}`);
  },
  getInvoices: function (orgId) {
    return instance.get(
      `/organizations/${orgId}/invoices?include=contact,user,items,payments,quote`
    );
  },
  getInvoicesReport: function (orgId, params) {
    return instance.get(
      `/organizations/${orgId}/invoices?include=contact,user,items${params}`
    );
  },
  getInvoicesReport2: function (orgId, params) {
    return instance.get(`/organizations/${orgId}/invoices_plain${params}`);
  },
  getInvoice: function (id) {
    return instance.get(`/invoices/${id}?include=organization`);
  },
  getInvoicePlainNew: function (id) {
    return instance.get(`/invoices/show2/${id}`);
  },
  getInvoiceDeleted: function (id) {
    return instance.get(`/invoices/${id}/itemsdeleted?include=organization`);
  },
  getInvoiceActivity: function (id) {
    return instance.get(`/invoices/${id}/activities`);
  },

  getInvoiceRefundRequests: function (id) {
    return instance.get(`/invoices/${id}/refundrequests`);
  },

  saveInvoice: function (payload) {
    return instance.post("/invoices", payload);
  },
  saveInvoicePayment: function (id, payload) {
    return instance.post(`/invoices/${id}/payments`, payload);
  },

  createMultipayment: function (payload) {
    return instance.post(`/invoices/createmultipayment`, payload);
  },
  updateMultipayment: function (id, payload) {
    return instance.post(`/invoices/updatemultipayment/${id}`, payload);
  },

  deleteMultipayment: function (id) {
    return instance.delete(`invoices/deletemultipayment/${id}`);
  },

  getMultipaymentHistories: function (id) {
    return instance.get(`/invoices/paymentheader/${id}/histories`);
  },

  getPaymentHistoryHeader: function (id) {
    return instance.get(`/invoices/payment_history/${id}`);
  },

  createMultirefund: function (payload) {
    return instance.post("/invoices/createmultirefund", payload);
  },
  updateMultirefund: function (payload, id) {
    return instance.post(`/invoices/updatemultirefund/${id}`, payload);
  },

  changeMultipaymentStatus: function (payload, id) {
    return instance.post(`/invoices/changemultipaymentstatus/${id}`, payload);
  },

  saveInvoiceRefundNew: function (id, payload) {
    return instance.post(`/invoices/${id}/refundsnew`, payload);
  },

  markRefundAsWonLost: function (id, refund_id, payload) {
    return instance.post(
      `/invoices/${id}/markrefundwonlost/${refund_id}`,
      payload
    );
  },

  editInvoicePayment: function (id, payment_id, payload) {
    return instance.post(`/invoices/${id}/payments/${payment_id}`, payload);
  },

  editInvoiceRefund: function (id, payment_id, payload) {
    return instance.post(`/invoices/${id}/refunds/${payment_id}`, payload);
  },

  forfeitureBackPayment: function (id, payment_id) {
    return instance.post(
      `/invoices/${id}/payments/${payment_id}/forfeitureBackPayment`
    );
  },

  deleteInvoicePayment: function (invoiceId, id) {
    return instance.delete(`/invoices/${invoiceId}/payments/${id}`);
  },

  markAsPossibleRefund: function (id, payment_id, payload) {
    return instance.post(
      `/invoices/${id}/markPossibleRefund/${payment_id}`,
      payload
    );
  },

  getDataToApplyCredit: function (id) {
    return instance.get(`/invoices/${id}/dataToApplyCredit`);
  },

  getAllPaymentsWItems: function (id) {
    return instance.get(`/invoices/${id}/allpaymentsitems`);
  },

  forfeitureApplyCredit: function (id, payload) {
    return instance.post(`/invoices/${id}/forfeitureApplyCredit`, payload);
  },

  getInvoiceRefunds: function (id) {
    return instance.get(`/invoices/${id}/refunds?include=refundInvoiceItems`);
  },
  saveInvoiceRefund: function (id, payload) {
    return instance.post(`/invoices/${id}/refunds`, payload);
  },

  deleteInvoiceRefund: function (id, invoice_id) {
    return instance.delete(`/invoices/${invoice_id}/refunds/${id}`);
  },
  updateInvoice: function (id, payload) {
    return instance.put(`/invoices/${id}`, payload);
  },
  deleteInvoice: function (id) {
    return instance.delete(`/invoices/${id}`);
  },
  getInvoiceCustomFields: function (orgId) {
    return instance.get(`/organizations/${orgId}/customfields/invoices`);
  },

  createtInvoiceComment: function (id, payload) {
    return instance.post(`/invoices/${id}/comments`, payload);
  },
  createtInvoiceDocument: function (id, payload) {
    return instance.post(`/invoices/${id}/documents`, payload);
  },
  addInvResponsibles: function (id, payload) {
    return instance.post(`/invoices/${id}/responsibles`, payload);
  },
  deleteInvoiceItem: function (invoiceId, id) {
    return instance.delete(`/invoices/${invoiceId}/items/${id}`);
  },

  voidInvoiceItem: function (invoiceId, id) {
    return instance.put(`/invoices/${invoiceId}/items/${id}`);
  },

  unvoidInvoiceItem: function (invoiceId, id) {
    return instance.put(`/invoices/${invoiceId}/unvoiditems/${id}`);
  },

  saveInvoiceExtraPayment: function (payload) {
    return instance.post(`/invoices/extrapayments`, payload);
  },

  applyInvoiceCreditMemo: function (id, payload) {
    return instance.post(`/invoices/${id}/applyinvoicecreditmemo`, payload);
  },
  updateCreditMemosInvoice: function (id, payload) {
    return instance.put(`/creditmemosinvoice/${id}`, payload);
  },

  createInvoiceItemControlHistory: function (id, itemid, payload) {
    return instance.post(
      `/invoices/${id}/item/${itemid}/itemcontrolhistory`,
      payload
    );
  },

  sendInvoice: function (id) {
    return instance.get(`/invoices/${id}/send`);
  },

  onSendWPayment: function (id) {
    return instance.get(`/invoices/${id}/sendPayment`);
  },

  deleteInvoiceCredit: function (invoiceId, id) {
    return instance.delete(`/invoices/${invoiceId}/creditmemos/${id}`);
  },

  generateInvoiceQuote: function (id) {
    return instance.post(`/invoices/${id}/generate_quote`);
  },

  voidInvoiceItemQuantity: function (id, payload) {
    return instance.post(`/invoices/items/${id}/void_quantity`, payload);
  },

  bindPaymentWithMerchantTransaction: function (id, payload) {
    return instance.put(
      `/invoice-payment-history-header/${id}/bind-with-merchant-transaction`,
      payload
    );
  },
  reconcilePaymentManually: function (id, payload) {
    return instance.put(
      `/invoice-payment-history-header/${id}/manual-reconciliation`,
      payload
    );
  },
  unreconcilePayment: function (id, payload) {
    return instance.put(
      `/invoice-payment-history-header/${id}/unreconciliation`,
      payload
    );
  },
  getPaymentReconciliationHistory: function (id, payload) {
    return instance.get(
      `/invoice-payment-history-header/${id}/reconciliation_history`,
      payload
    );
  },
  getReconciliationHistory: function (params = "") {
    return instance.get(`/payment-header-reconciliation-history${params}`);
  },

  getPaymentHeaderActivitiesLogs: function (id, page, limit) {
    return instance.get(`/invoices/paymentheader/${id}/activitiesLogs?page=${page ? page : 0}&limit=${
        limit ? limit : 10
      }`);
  },


  //DOCUMENT TYPES
  getDocumentTypes: function (orgId) {
    return instance.get(`/organizations/${orgId}/doctypes`);
  },
  getPortalDocumentTypes: function (orgId) {
    return instance.get(`/portal/${orgId}/doctypes`);
  },
  saveDocumentType: function (payload) {
    return instance.post(`/doctypes`, payload);
  },
  updateDocumentType: function (id, payload) {
    return instance.put(`/doctypes/${id}`, payload);
  },
  deleteDocumentType: function (id) {
    return instance.delete(`/doctypes/${id}`);
  },

  //REPORTS
  getPayrollReport: function (orgId) {
    return instance.get(
      `/organizations/${orgId}/recordproducts?include=recordfollowup,recordProductsStatusHistory,invoice,responsible,salesman,invoiceItem,record&filter[finalized]=1`
    );
  },

  getPayrollReportFiltered: function (orgId, params) {
    return instance.get(
      `/organizations/${orgId}/recordproducts${params}&include=recordfollowup,recordProductsStatusHistory,invoice,responsible,salesman,invoiceItem,record`
    );
  },
  getQuotesReport2: function (orgId, params) {
    return instance.get(`/organizations/${orgId}/quotes_plain${params}`);
  },
  getQuotesReport3: function (orgId, params) {
    return instance.get(`/organizations/${orgId}/quotes_plain3${params}`);
  },
  getQuotesReport: function (orgId, params) {
    return instance.get(
      `/organizations/${orgId}/quotes?include=contact,user,approvedBy,items${params}`
    );
  },
  getContractsReport: function (orgId) {
    return instance.get(
      `/organizations/${orgId}/quotes?include=contact,user,approvedBy,items&filter[status]=approved`
    );
  },

  getTotalApprovedContractsByDateReport: function (orgId, start, end) {
    return instance.get(
      `/organizations/${orgId}/count_approved_contracts?filter[approved_at]>${start}&filter[approved_at]<${end}`
    );
  },
  getTotalContractsByDateReport: function (orgId, start, end) {
    return instance.get(
      `/organizations/${orgId}/count_contracts?filter[created_at]>${start}&filter[created_at]<${end}`
    );
  },
  getTotalInvoicesByDateReport: function (orgId, start, end) {
    return instance.get(
      `/organizations/${orgId}/count_invoices?filter[created_at]>${start}&filter[created_at]<${end}`
    );
  },
  getSumPaymentsByDateReport: function (orgId, start, end) {
    return instance.get(
      `/organizations/${orgId}/sum_payments_date?filter[date_between]=${start},${end}`
    );
  },
  getSumPaymentsByTypeReport: function (orgId, start, end) {
    return instance.get(
      `/organizations/${orgId}/sum_payments_type?filter[date_between]=${start},${end}`
    );
  },
  getSumRefundsReport: function (orgId, start, end) {
    return instance.get(
      `/organizations/${orgId}/sum_refunds?filter[date_between]=${start},${end}`
    );
  },
  getSumRefundsByDateReport: function (orgId, start, end) {
    return instance.get(
      `/organizations/${orgId}/sum_refunds_date?filter[date_between]=${start},${end}`
    );
  },

  getPaymentReport: function (orgId, args) {
    return instance.get(`/organizations/${orgId}/payments${args}`);
  },
  getPaymentReportAllInfoList: function (orgId, args, payload) {
    return instance.post(
      `/organizations/${orgId}/payments_all_info_list${args}`,
      payload
    );
  },
  getPaymentReportAllInfoListForDashboard2: function (orgId, args, payload) {
    return instance.post(
      `/organizations/${orgId}/payments_all_info_list_dashboard2${args}`,
      payload
    );
  },
  getPaymentReportAllInfo: function (orgId, args, payload) {
    return instance.post(
      `/organizations/${orgId}/payments_all_info${args}`,
      payload
    );
  },
  getPaymentReportAllInfov2: function (orgId, args, payload) {
    return instance.post(
      `/organizations/${orgId}/payments_all_infov2${args}`,
      payload
    );
  },
  getPaymentReportAllInfoHistory: function (orgId, args, payload) {
    return instance.post(
      `/organizations/${orgId}/payments_all_info_history${args}`,
      payload
    );
  },

  getPaymentReportNewHistoryByInvoices: function (orgId, args, payload) {
    return instance.post(
      `/organizations/${orgId}/payments_summary_by_invoices${args}`,
      payload
    );
  },

  getRecordProductsReport: function (orgId, args) {
    return instance.get(
      `/organizations/${orgId}/recordproducts${args}&include=recordfollowup,recordProductsStatusHistory,invoice,responsible,salesman,invoiceItem,record,coordinator`
    );
  },
  getRecordProductsReport2: function (orgId, args) {
    return instance.get(
      `/organizations/${orgId}/recordproducts_plain${args}&include=recordfollowup,recordProductsStatusHistory,invoice,responsible,salesman,invoiceItem,record,coordinator`
    );
  },
  getRecordProductsReport3: function (orgId, args) {
    return instance.get(
      `/organizations/${orgId}/recordproducts_payroll2${args}`
    );
  },

  getRecordproductsPayrollGroupedPrint: function (orgId, args) {
    return instance.get(
      `/organizations/${orgId}/recordproducts_payrollGroupedPrint${args}`
    );
  },

  getTransferPatientsDoctorReportPrint: function (orgId, args) {
    return instance.get(
      `/organizations/${orgId}/transferpatientsdoctorreportprint${args}`
    );
  },

  getRecordProductsReportForOthers: function (orgId, args) {
    return instance.get(
      `/organizations/${orgId}/recordproducts_payroll_dashboard2${args}`
    );
  },
  getTimelineNewReport: function (orgId, args) {
    return instance.get(`/organizations/${orgId}/timeline_new${args}`);
  },
  getTimelineNewReport2: function (orgId, args) {
    return instance.get(`/organizations/${orgId}/timeline_new2${args}`);
  },
  getTimelineNewReport3: function (orgId, args) {
    return instance.get(`/organizations/${orgId}/timeline_new3${args}`);
  },
  getRecordProcedures: function (orgId, args) {
    return instance.get(`/organizations/${orgId}/getrecordprocedures${args}`);
  },

  getRefunds: function (orgId, args) {
    return instance.get(
      `/organizations/${orgId}/refunds?include=refundInvoiceItems${args}`
    );
  },
  getDashboardAnalytics: function (orgId, start, end) {
    return instance.get(
      `/organizations/${orgId}/all_analytics?filter[date_between]=${start},${end}`
    );
  },

  // My

  getSalesMyContracts: function (orgId, user_id, start, end) {
    return instance.get(
      `/organizations/${orgId}/getsalesmycontracts/${user_id}?filter[date_between_from_to]=${start},${end}`
    );
  },
  getSalesMyContacts: function (orgId, user_id, start, end) {
    return instance.get(
      `/organizations/${orgId}/getsalesmycontact/${user_id}?filter[date_between_from_to]=${start},${end}`
    );
  },
  getSalesMyTickets: function (orgId, user_id, start, end) {
    return instance.get(
      `/organizations/${orgId}/getsalesmytickets/${user_id}?filter[date_between_from_to]=${start},${end}`
    );
  },
  getSalesMyTicketsUser: function (orgId, user_id, start, end) {
    return instance.get(
      `/organizations/${orgId}/getsalesmyticketsuser/${user_id}?filter[date_between_from_to]=${start},${end}`
    );
  },

  getSalesMySMSs: function (orgId, user_id, start, end) {
    return instance.get(
      `/organizations/${orgId}/getsalesmysms/${user_id}?filter[date_between_from_to]=${start},${end}`
    );
  },
  getSalesMyCalls: function (orgId, user_id, start, end) {
    return instance.get(
      `/organizations/${orgId}/getsalesmycalls/${user_id}?filter[date_between_from_to]=${start},${end}`
    );
  },
  getContractsByDoctors: function (orgId, start, end) {
    return instance.get(
      `/organizations/${orgId}/contractsbydoctors?filter[date_between]=${start},${end}`
    );
  },
  getContractsByTeam: function (orgId, start, end) {
    return instance.get(
      `/organizations/${orgId}/contractsbysalesteam?filter[date_between]=${start},${end}`
    );
  },
  getLeads: function (orgId, start, end) {
    return instance.get(
      `/organizations/${orgId}/getleads?filter[date_between]=${start},${end}`
    );
  },
  getPaymentsRefund: function (orgId, start, end, isRefund) {
    return instance.get(
      `/organizations/${orgId}/getpaymentsrefund/${isRefund}?filter[date_between]=${start},${end}`
    );
  },
  getDashboardInvoices: function (orgId, start, end, isRefund) {
    return instance.get(
      `/organizations/${orgId}/getdashboardinvoices?filter[date_between]=${start},${end}`
    );
  },

  getTodayPreOps: function (orgId) {
    return instance.get(`/organizations/${orgId}/gettodaypreops`);
  },
  getTomorrowSurgerys: function (orgId) {
    return instance.get(`/organizations/${orgId}/gettomorrowsurgerys`);
  },

  getDoctorContracts: function (orgId) {
    return instance.get(`/organizations/${orgId}/getdoctorcontracts`);
  },

  // Team

  getSalesTeamContracts: function (orgId, user_id, start, end) {
    return instance.get(
      `/organizations/${orgId}/getsalesteamcontracts/${user_id}?filter[date_between_from_to]=${start},${end}`
    );
  },
  getSalesTeamTickets: function (orgId, user_id, start, end) {
    return instance.get(
      `/organizations/${orgId}/getsalesteamtickets/${user_id}?filter[date_between_from_to]=${start},${end}`
    );
  },
  getSalesTeamTicketsUser: function (orgId, user_id, start, end) {
    return instance.get(
      `/organizations/${orgId}/getsalesteamticketsuser/${user_id}?filter[date_between_from_to]=${start},${end}`
    );
  },
  getSalesTeamTicketsAgent: function (orgId, user_id, start, end) {
    return instance.get(
      `/organizations/${orgId}/getsalesteamticketsagent/${user_id}?filter[date_between_from_to]=${start},${end}`
    );
  },

  getSalesTeamContacts: function (orgId, user_id, start, end) {
    return instance.get(
      `/organizations/${orgId}/getsalesteamcontact/${user_id}?filter[date_between_from_to]=${start},${end}`
    );
  },
  getSalesTeamSMSs: function (orgId, user_id, start, end) {
    return instance.get(
      `/organizations/${orgId}/getsalesteamsms/${user_id}?filter[date_between_from_to]=${start},${end}`
    );
  },
  getSalesTeamCalls: function (orgId, user_id, start, end) {
    return instance.get(
      `/organizations/${orgId}/getsalesteamcalls/${user_id}?filter[date_between_from_to]=${start},${end}`
    );
  },

  getDashboarBuildingsdAnalytics: function (orgId, start, end) {
    return instance.get(
      `/organizations/${orgId}/all_buildings_analytics?filter[date_between]=${start},${end}`
    );
  },
  getDashboarBuildingsdAnalyticsNew: function (orgId, start, end) {
    return instance.get(
      `/organizations/${orgId}/all_buildings_analyticsnew?filter[date_between]=${start},${end}`
    );
  },
  getDashboarBuildingsdAnalyticsNew2: function (orgId, start, end) {
    return instance.get(
      `/organizations/${orgId}/all_buildings_analyticsnew2?filter[date_between]=${start},${end}`
    );
  },
  getDashboarSalesReportDashboardNew: function (orgId, start, end) {
    return instance.get(
      `/organizations/${orgId}/salesReportDashboardNewOK?filter[date_between]=${start},${end}`
    );
  },
  getTopSellerDataAnalyticsNew: function (orgId, payload) {
    return instance.post(`/organizations/${orgId}/topsellernew`, payload);
  },

  getTopDashboardPaymentsRefund: function (orgId, start, end, is_refund) {
    return instance.get(
      `/top-dashboard/organizations/${orgId}/paymentsrefund/${is_refund}?filter[date_between]=${start},${end}`
    );
  },

  getTopDashboardLeads: function (orgId, start, end) {
    return instance.get(
      `/top-dashboard/organizations/${orgId}/leads?filter[date_between]=${start},${end}`
    );
  },

  getTopDashboardSalesReportByUser: function (orgId, start, end) {
    return instance.get(
      `/top-dashboard/organizations/${orgId}/salesreport?filter[date_between_from_to]=${start},${end}`
    );
  },

  getBottomSellerDataAnalyticsNew: function (orgId, payload) {
    return instance.post(`/organizations/${orgId}/bottomsellernew`, payload);
  },

  getTopSellerReport: function (orgId, payload) {
    return instance.post(`/organizations/${orgId}/topsellernewreport`, payload);
  },
  getBottomSellerReport: function (orgId, payload) {
    return instance.post(
      `/organizations/${orgId}/bottomsellernewreport`,
      payload
    );
  },
  getCommissionReport: function (orgId, args) {
    return instance.get(
      `/organizations/${orgId}/count_user_contracts?include=user${args}`
    );
  },
  getContractsByUser: function (orgId, args) {
    return instance.get(
      `/organizations/${orgId}/count_contracts_by_user${args}`
    );
  },

  getRoundRobinByUser: function (orgId, args) {
    return instance.get(
      `/organizations/${orgId}/count_round_robin_by_user${args}`
    );
  },

  getContactPerformanceByUser: function (orgId, args) {
    return instance.get(
      `/organizations/${orgId}/consultant_performance${args}`
    );
  },
  getContactPerformanceByUserAlternative: function (orgId, args) {
    return instance.get(
      `/organizations/${orgId}/consultant_performance_alternative${args}`
    );
  },
  getContactPerformanceBySource: function (orgId, args) {
    return instance.get(`/organizations/${orgId}/source_performance${args}`);
  },
  getContactPerformanceByUserAllOrganization: function (orgId, args) {
    return instance.get(
      `/organizations/${orgId}/consultant_performance_organization${args}`
    );
  },
  getBuildingsPbxDomains: function () {
    return instance.get(`/buildings/pbx_domains`);
  },
  getExtensionSummaryForAllOrganization: function (orgId, args) {
    return instance.get(
      `/organizations/${orgId}/all_extensions_summary${args}`
    );
  },
  getContactClosingSpeedByUserAllOrganization: function (orgId, args) {
    return instance.get(
      `/organizations/${orgId}/consultant_closing_speed${args}`
    );
  },

  getContactDistributionByBuilding: function (orgId, args) {
    return instance.get(
      `/organizations/${orgId}/count_round_robin_by_building${args}`
    );
  },

  getAssistantReportInfo: function (org, from, to, building, args) {
    return instance.get(`/organizations/${org}/assistantreport`);
  },

  //DOCUMENTS

  deleteDocument: function (orgId, id) {
    return instance.delete(`/organizations/${orgId}/documents/${id}`);
  },

  //TAGS
  getTags: function (orgId, params = "") {
    return instance.get(`/organizations/${orgId}/tags${params}`);
  },
  createTag: function (orgId) {
    return instance.post(`/organizations/${orgId}/tags`);
  },
  updateTag: function (orgId, Id) {
    return instance.put(`/organizations/${orgId}/tags/${Id}`);
  },
  deleteTag: function (orgId, Id) {
    return instance.delete(`/organizations/${orgId}/tags/${Id}`);
  },

  //Cancellation Motives
  getCancellationMotives: function (orgId) {
    return instance.get(`/organizations/${orgId}/cancellationmotives`);
  },
  saveCancellationMotive: function (payload) {
    return instance.post(`/cancellationmotives`, payload);
  },
  updateCancellationMotive: function (id, payload) {
    return instance.put(`/cancellationmotives/${id}`, payload);
  },
  deleteCancellationMotive: function (id) {
    return instance.delete(`/cancellationmotives/${id}`);
  },

  //Refund Reasons
  getRefundReasons: function (orgId) {
    return instance.get(`/organizations/${orgId}/refundreasonsplain`);
  },
  saveRefundReason: function (payload) {
    return instance.post(`/refundreasons`, payload);
  },
  updateRefundReason: function (id, payload) {
    return instance.put(`/refundreasons/${id}`, payload);
  },
  deleteRefundReason: function (id) {
    return instance.delete(`/refundreasons/${id}`);
  },

  //templatescategories
  getTemplatesCategories: function (orgId) {
    return instance.get(`/organizations/${orgId}/templatescategories`);
  },
  getTemplatesCategory: function (id) {
    return instance.get(`/templatescategories/${id}`);
  },
  saveTemplatesCategory: function (payload) {
    return instance.post(`/templatescategories`, payload);
  },
  updateTemplatesCategory: function (id, payload) {
    return instance.put(`/templatescategories/${id}`, payload);
  },
  deleteTemplatesCategory: function (id) {
    return instance.delete(`/templatescategories/${id}`);
  },

  getContactTemplateFormsCategories: function () {
    return instance.get(`/form-templates/categories`);
  },

  //Refund Requests
  getRefundRequests: function (orgId) {
    return instance.get(`/organizations/${orgId}/refundrequests`);
  },
  getRefundRequest: function (id) {
    return instance.get(`/refundrequests/${id}`);
  },

  saveRefundRequest: function (payload) {
    return instance.post(`/refundrequests`, payload);
  },
  updateRefundRequest: function (id, payload) {
    return instance.put(`/refundrequests/${id}`, payload);
  },
  deleteRefundRequest: function (id) {
    return instance.delete(`/refundrequests/${id}`);
  },

  //Credit Memos
  getCreditMemos: function (orgId) {
    return instance.get(`/organizations/${orgId}/creditmemos`);
  },
  saveCreditMemo: function (payload) {
    return instance.post(`/creditmemos`, payload);
  },
  updateCreditMemo: function (id, payload) {
    return instance.put(`/creditmemos/${id}`, payload);
  },
  deleteCreditMemo: function (id) {
    return instance.delete(`/creditmemos/${id}`);
  },

  //Social Platforms
  getSocialPlatforms: function (orgId) {
    return instance.get(`/organizations/${orgId}/socialplatforms`);
  },
  saveSocialPlatform: function (payload) {
    return instance.post(`/socialplatforms`, payload);
  },
  updateSocialPlatform: function (id, payload) {
    return instance.put(`/socialplatforms/${id}`, payload);
  },
  deleteSocialPlatform: function (id) {
    return instance.delete(`/socialplatforms/${id}`);
  },

  // TASKS
  getTasks: function (orgId) {
    return instance.get(`/organizations/${orgId}/tasks?include=participants`);
  },
  createTask: function (orgId) {
    return instance.post(`/tasks`);
  },
  updateTask: function (orgId, id) {
    return instance.put(`/tasks/${id}`);
  },
  deleteTask: function (orgId, id) {
    return instance.delete(`/tasks/${id}`);
  },

  //TEMPLATES
  getTemplates: function (orgId) {
    return instance.get(`/organizations/${orgId}/templates`);
  },
  getTemplatesBuilding: function (orgId, building_id, billing) {
    return instance.get(
      `/organizations/${orgId}/templates/${building_id}/${billing}`
    );
  },
  getTemplate: function (id) {
    return instance.get(`/templates/${id}`);
  },
  getDoctorRemarksTemplates: function (id) {
    return instance.get(`/doctorremarkstemplate/${id}`);
  },
  getDoctorRemarksTemplatesAll: function (orgId) {
    return instance.get(`/organizations/${orgId}/doctorremarkstemplateAll`);
  },
  saveTemplate: function (payload) {
    return instance.post(`/templates`, payload);
  },
  updateTemplate: function (id, payload) {
    return instance.put(`/templates/${id}`, payload);
  },
  deleteTemplate: function (id) {
    return instance.delete(`/templates/${id}`);
  },

  //CONTACT FORM TEMPLATES
  getContactFormTemplates: function (orgId, payload) {
    return instance.post(`/organizations/${orgId}/form-templates`, payload);
  },
  saveContactFormTemplate: function (payload) {
    return instance.post(`/form-templates`, payload);
  },
  updateContactFormTemplate: function (id, payload) {
    return instance.put(`/form-templates/${id}`, payload);
  },
  deleteContactFormTemplate: function (id) {
    return instance.delete(`/form-templates/${id}`);
  },

  // CONTRACTS
  getContracts: function (orgId) {
    return instance.get(`/organizations/${orgId}/contracts`);
  },
  getContractDocumentTypesDropdown: function (orgId) {
    return instance.get(`/organizations/${orgId}/contracts/doctypes-dropdown`);
  },
  getContractBuildingsDropdown: function (orgId) {
    return instance.get(`/organizations/${orgId}/contracts/buildings-dropdown`);
  },
  getContractByQuote: function (quoteId) {
    return instance.get(`/contracts/byquote/${quoteId}`);
  },
  saveContract: function (siteId, payload) {
    return instance.post(`/sites/${siteId}/contracts`, payload);
  },
  updateContract: function (id, payload) {
    return instance.put(`/contracts/${id}`, payload);
  },
  deleteContract: function (id) {
    return instance.delete(`/contracts/${id}`);
  },
  createContractRequest: function (orgId, $payload) {
    return instance.post(`/organizations/${orgId}/request_contract`, $payload);
  },
  sendContractRequest: function (orgId, id, $payload) {
    return instance.post(
      `/organizations/${orgId}/request_contract/${id}/send`,
      $payload
    );
  },
  getContractRequestActivity: function (orgId, id) {
    return instance.get(
      `/organizations/${orgId}/request_contract_activity/${id}/activity`
    );
  },

  //appointmentsnew
  getAppointmentsNews: function (args) {
    return instance.get(`/appointmentsnew${args}`);
  },

  attachToAppointment: function (id, payload) {
    return instance.post(`/appointmentsnew/attachElement/${id}`, payload);
  },

  getMinMaxAppointmentsbyBuilding: function (payload) {
    return instance.post(`/appointmentstypesminmaxbybuilding`, payload);
  },

  getBuildingMinMaxDataByType: function (payload) {
    return instance.post(`/appointmentstypesbuildingminmax`, payload);
  },

  saveMinMaxItems: function (payload) {
    return instance.post(`/appointmentstypesminmaxsave`, payload);
  },

  getAvailableTypes: function (payload) {
    return instance.post(`/appointmentstypesavailables`, payload);
  },
  getAppointmentsByIds: function (payload) {
    return instance.post(`/appointmentsnew/byids`, payload);
  },

  getAppointmentsActivityLogs: function (id, page, limit) {
    return instance.get(
      `/appointmentsnew/${id}/activitiesLogs?page=${page ? page : 0}&limit=${
        limit ? limit : 10
      }`
    );
  },

  sendAppointmentCode: function (payload) {
    return instance.post(`/sendAppointmentCode`, payload);
  },

  getReportData: function (args) {
    return instance.get(`/appointmentsreport${args}`);
  },

  getDailyAppointmentsReportData: function (args) {
    return instance.get(`/appointmentsnew/dailymassagerecord${args}`);
  },

  getPendingAppointments: function (args) {
    return instance.get(`/pendingappointmentsreport${args}`);
  },

  getAppointments: function (args) {
    return instance.get(`/appointmentsnew${args}`);
  },

  getAppointmentsOptions: function (payload) {
    return instance.post("/appointmentsoption", payload);
  },

  getAppointmentsToCalendar: function (payload) {
    return instance.post("/appointmentstocalendar", payload);
  },

  getAppointmentNew: function (id) {
    return instance.get(`/appointmentsnew/${id}`);
  },

  getAppointmentsReportData: function (payload) {
    return instance.post("/appointmentsreport", payload);
  },

  updateNewAppointment: function (id, payload) {
    return instance.put(`/appointmentsnew/${id}`, payload);
  },

  createNewAppointment: function (payload) {
    return instance.post(`/appointmentsnew`, payload);
  },
  deleteNewAppointment: function (id) {
    return instance.delete(`/appointmentsnew/${id}`);
  },

  appointmentsDashboardData: function (payload) {
    return instance.post(`/appointmentsnewdashboard`, payload);
  },

  usersAvailablesDropDown: function (payload) {
    return instance.post(`/usersavailablesdropdown`, payload);
  },

  masseursDropdown: function (id) {
    return instance.get(`/organizations/${id}/masseurs_dropdown`);
  },

  //appointmentBlockDate
  getBlockDates: function (user_id) {
    return instance.get(`/appointmentsblockdate/${user_id}`);
  },

  getBlockDate: function (id) {
    return instance.get(`/appointmentsblockdate/${id}`);
  },

  updateBlockDate: function (id, payload) {
    return instance.put(`/appointmentsblockdate/${id}`, payload);
  },

  createBlockDate: function (payload) {
    return instance.post(`/appointmentsblockdate`, payload);
  },
  deleteBlockDate: function (id) {
    return instance.delete(`/appointmentsblockdate/${id}`);
  },

  //APPOINTMENTS
  getAppointments: function (orgId) {
    return instance.get(`/appointments`);
  },
  getAppointmentsNODate: function (orgId) {
    return instance.get(`/organizations/${orgId}/appointmentsnotdate`);
  },
  getAppointment: function (id) {
    return instance.get(`/appointments/${id}`);
  },
  saveAppointment: function (payload) {
    return instance.post("/appointments", payload);
  },
  updateAppointment: function (id, payload) {
    return instance.put(`/appointments/${id}`, payload);
  },
  deleteAppointment: function (id) {
    return instance.delete(`/appointments/${id}`);
  },

  //COMMISION RULES
  getCommissionRules: function (orgId) {
    return instance.get(`/organizations/${orgId}/commissionRules`);
  },
  getCommissionRule: function (id) {
    return instance.get(`/commissionRules/${id}`);
  },
  saveCommissionRule: function (payload) {
    return instance.post("/commissionRules", payload);
  },
  updateCommissionRule: function (id, payload) {
    return instance.put(`/commissionRules/${id}`, payload);
  },
  deleteCommissionRule: function (id) {
    return instance.delete(`/commissionRules/${id}`);
  },

  //AccountingModule
  //Expensecategory
  getExpenseCategories: function (filters = null) {
    return apiGet("/accounting/expensecategories", filters);
  },
  getAllExpenseCategories: function () {
    return instance.get(`/accounting/expensecategories/all`);
  },
  saveExpenseCategory: function (payload) {
    return instance.post("/accounting/expensecategory", payload);
  },
  updateExpenseCategory: function (id, payload) {
    return instance.put(`/accounting/expensecategory/${id}`, payload);
  },
  deleteExpenseCategory: function (id) {
    return instance.delete(`/accounting/expensecategory/${id}`);
  },

  //ExpenseItem
  getExpenseItems: function (filters = null) {
    return apiGet("/accounting/expenseitems", filters);
    //return instance.get(`/accounting/expenseitems?include=accExpenseCategory`);
  },
  saveExpenseItem: function (payload) {
    return instance.post("/accounting/expenseitem", payload);
  },
  updateExpenseItem: function (id, payload) {
    return instance.put(`/accounting/expenseitem/${id}`, payload);
  },
  deleteExpenseItem: function (id) {
    return instance.delete(`/accounting/expenseitem/${id}`);
  },

  //commentCategories

  saveCommentCategory: function (payload) {
    return instance.post("/commentcategories", payload);
  },
  updateCommentCategory: function (id, payload) {
    return instance.put(`/commentcategories/${id}`, payload);
  },
  deleteCommentCategory: function (id) {
    return instance.delete(`/commentcategories/${id}`);
  },

  //Tickets

  getTicket: function (id) {
    return instance.get(`/tickets/${id}`);
  },

  createtTicketComment: function (id, payload) {
    return instance.post(`/tickets/${id}/comments`, payload);
  },

  reAssignTickets: function (payload) {
    return instance.post(`/tickets/reassigntickets`, payload);
  },

  saveTicketCategory: function (payload) {
    return instance.post("/ticketcategories", payload);
  },
  updateTicketCategory: function (id, payload) {
    return instance.put(`/ticketcategories/${id}`, payload);
  },
  deleteTicketCategory: function (id) {
    return instance.delete(`/ticketcategories/${id}`);
  },

  // TEST
  getRemoveContacts: function (orgId, limit, page) {
    return instance.get(
      `organizations/${orgId}/contacts?include=organization&page=${page}&limit=${limit}`
    );
  },

  getTicketResponsibleReports: function (orgId, args) {
    return instance.get(
      `/organizations/${orgId}/ticketResponsibleReports${args}`
    );
  },

  getTicketUsersReports: function (orgId, args) {
    return instance.get(`/organizations/${orgId}/ticketUsersReports${args}`);
  },

  getDashboardInventoryAnalytics: function (start, end) {
    return instance.get(
      `/inventory/all_analytics?filter[date_between]=${start},${end}`
    );
  },
  getDepositTotal: function (orgId) {
    return instance.get(`/organizations/${orgId}/depositTotal`);
  },
  getDepositSalesTotal: function (orgId, params) {
    return instance.get(`/organizations/${orgId}/depositSalesTotal?${params}`);
  },
  getDepositTotalALL: function (orgId) {
    return instance.get(`/organizations/${orgId}/depositTotalALL`);
  },

  getAccountingReportNewProcedures: function (id, type, params) {
    return instance.get(
      `/organizations/${id}/accountingreportproceduresdetails/${type}${params}`
    );
  },

  getAccountingReportNewServices: function (id, type, params) {
    return instance.get(
      `/organizations/${id}/accountingreportservicesdetails/${type}${params}`
    );
  },

  getAccountingReportNewProducts: function (id, type, params) {
    return instance.get(
      `/organizations/${id}/accountingreportproductsdetails/${type}${params}`
    );
  },

  getDepositTotalTwoYears: function (orgId) {
    return instance.get(`/organizations/${orgId}/depositTotal3`);
  },

  getReportPatient: function () {
    return instance.get(`/portal/dashboard`);
  },

  getReportPatient2: function () {
    return instance.get(`/portal/dashboard2`);
  },
  getPortalInvoices: function (id) {
    return instance.get(`/portal/invoices/${id}`);
  },
  getPortalProcedures: function (record_id) {
    return instance.get(`/portal/procedures/${record_id}`);
  },
  getPortalInvoice: function (invoiceId) {
    return instance.get(`/portal/invoice/${invoiceId}`);
  },
  getRefundsReport: function (orgId, args) {
    return instance.get(`/organizations/${orgId}/all_refund_analytics${args}`);
  },
  getRefundByOption: function (orgId, payload) {
    return instance.post(`/organizations/${orgId}/refundbyoption`, payload);
  },

  getMessage: function (id) {
    return instance.get(`/messages/${id}`);
  },
  getPortalMessages: function (messageType, page) {
    return instance.get(
      `/portal/messages?limit=10&messageType=${messageType}&page=${page}`
    );
  },
  postPortalMessages: function (messageType, payload) {
    return instance.post(
      `/portal/messages?messageType=${messageType}`,
      payload
    );
  },
  getPortalMessage: function (id) {
    return instance.get(`/portal/messages/${id}`);
  },
  getMessagesUnRead: function () {
    return instance.get(`/messages/notifications/unread`);
  },
  getPortalMessagesUnRead: function () {
    return instance.get(`portal/messages/notifications/unread`);
  },

  getPuntuationReport: function (dateFrom, dateTo) {
    return instance.get(
      `polls/getPuntuationReport?dateStart=${dateFrom}&dateTo=${dateTo}`
    );
  },
  getState: function (objectid, workflow, include) {
    return apiGet("SHOW_STATES", {
      include: include,
      params: {
        id: objectid,
        workflow: workflow,
      },
    });
  },

  setState: function (state_id, object_id, force = false) {
    return apiPost(
      "POST_STATES_MOVE",
      {
        stateID: state_id,
        objectID: object_id,
        force,
      },
      null,
      "post"
    );
  },

  getStateHistory: function (state_id, model_id, include) {
    return apiGet("SHOW_STATES_HISTORY", {
      include: include,
      params: {
        model_id: model_id,
        state_id: state_id,
      },
    });
  },
  getStatesByWorkflow: function (wfName) {
    return apiGet("GET_STATESBYWORKFLOW", {
      params: {
        id: wfName,
      },
    });
  },
  uploadImage: function (id, payload) {
    return instance.post(`/organizations/${id}/uploadimage`, payload);
  },

  getConsultantSalesWithtrashInGroup: function (id) {
    return instance.get(
      `/organizations/${id}/consultantsaleswithingrouptrashdropdown`
    );
  },

  getFinancedPatientsPrint: function (id, params) {
    return instance.get(
      `/organizations/${id}/financedpatientsreportprint${params}`
    );
  },

  getContactsRecordsByZipDetails: function (id, params) {
    return instance.get(
      `/organizations/${id}/contactsrecordsbyzipdetails${params}`
    );
  },

  saveCreditMemoReason: function (payload) {
    return instance.post("/creditmemoreasons", payload);
  },
  updateCreditMemoReason: function (id, payload) {
    return instance.post(`/creditmemoreasons/${id}`, payload);
  },

  deleteCreditMemoReason: function (id) {
    return instance.delete(`/creditmemoreasons/${id}`);
  },

  getAllCreditMemoReason: function (id) {
    return instance.get(`organizations/${id}/creditmemoreasons`);
  },

  getAllCreditMemoReasonPlain: function (id) {
    return instance.get(`organizations/${id}/creditmemoreasonsplain`);
  },

  getAccountingReportProcedures: function (id, type, params) {
    return instance.get(
      `/organizations/${id}/accountingreportnew1cc/${type}${params}`
    );
  },

  getAccountingReportServices: function (id, type, params) {
    return instance.get(
      `/organizations/${id}/accountingreportnew2cc/${type}${params}`
    );
  },

  getAccountingReportProducts: function (id, type, params) {
    return instance.get(
      `/organizations/${id}/accountingreportnew3cc/${type}${params}`
    );
  },

  // -----------------------------------------------------

  getProceduresAccountingReportProcedures: function (id, type, params) {
    return instance.get(
      `/organizations/${id}/proceduresaccountingreportprocedures/${type}${params}`
    );
  },

  getProceduresAccountingReportServices: function (id, type, params) {
    return instance.get(
      `/organizations/${id}/proceduresaccountingreportservices/${type}${params}`
    );
  },

  getProceduresAccountingReportProducts: function (id, type, params) {
    return instance.get(
      `/organizations/${id}/proceduresaccountingreportproducts/${type}${params}`
    );
  },

  // -----------------------------------------------------

  getDepositContactsTotal: function (id, params) {
    return instance.get(
      `/organizations/${id}/getdepositcontactstotal${params}`
    );
  },

  saveWhiteBlackRules: function (payload) {
    return instance.post(`/whiteblackrules`, payload);
  },

  updateWhiteBlackRules: function (id, payload) {
    return instance.post(`/whiteblackrules/${id}`, payload);
  },

  duplicateWhiteBlackRules: function (id, payload) {
    return instance.post(`/whiteblackrules/${id}/duplicate`, payload);
  },

  getRolesChangeReasons: function () {
    return instance.get(`/userchangerolesreasons`);
  },

  saveUserRolesChangeReason: function (payload) {
    return instance.post(`/userchangeroleshistories`, payload);
  },

  saveUserChangeRolesReason: function (payload) {
    return instance.post("/userchangerolesreasons", payload);
  },
  updateUserChangeRolesReason: function (id, payload) {
    return instance.put(`/userchangerolesreasons/${id}`, payload);
  },

  getEmailIsBanned: function (id) {
    return instance.get(`users/${id}/checkuseremailsuppression`);
  },

  unlockEmailIsBanned: function (id) {
    return instance.get(`users/${id}/clearuseremailsuppression`);
  },

  getProductsServices: function (id) {
    return instance.get(`organizations/${id}/productsServices`);
  },

  getProductOffers: function (id) {
    return instance.get(`products/${id}/offers`);
  },
  createProductOffer: function (id, payload) {
    return instance.post(`products/${id}/offers`, payload);
  },

  updateProductOffer: function (id, user_product_price_id, payload) {
    return instance.post(
      `products/${id}/offers/${user_product_price_id}`,
      payload
    );
  },

  deleteProductOffer: function (id, user_product_price_id) {
    return instance.delete(`products/${id}/offers/${user_product_price_id}`);
  },
  addProductOfferDoctor: function (id, user_product_price_id, user_id) {
    return instance.put(
      `products/${id}/offers/${user_product_price_id}/adddoctor/${user_id}`
    );
  },
  deleteProductOfferDoctor: function (id, user_product_price_id, user_id) {
    return instance.delete(
      `products/${id}/offers/${user_product_price_id}/deletedoctor/${user_id}`
    );
  },

  //Generic Calls
  getEntities: function (apiParams) {
    return apiGet(
      apiParams.dataUrl.code,
      apiParams.filters,
      apiParams.dataUrl.oid ? apiParams.dataUrl.oid : null
    );
  },
  getContactEntities: function (apiParams) {
    return apiGet(
      apiParams.dataUrl.code,
      apiParams.filters,
      apiParams.dataUrl.oid ? apiParams.dataUrl.oid : null,
      apiParams.dataUrl.contact_id ? apiParams.dataUrl.contact_id : null
    );
  },
  saveEntity: function (apiParams) {
    return apiPost(
      apiParams.dataUrl.code,
      apiParams.payload,
      apiParams.filters,
      "post"
    );
    //return instance.post(dataUrl.code, payload);
  },
  updateEntity: function (apiParams) {
    return apiPost(
      apiParams.dataUrl.code,
      apiParams.payload,
      apiParams.filters,
      "put"
    );
    //return instance.put(dataUrl.code, payload);
  },
  deleteEntity: function (apiParams) {
    return apiDelete(apiParams.dataUrl.code, apiParams.filters);
    return instance.delete(apiParams.dataUrl.code);
  },
  updateEntityPost: function (apiParams) {
    return apiPost(
      apiParams.dataUrl.code,
      apiParams.payload,
      apiParams.filters,
      "post"
    );
    //return instance.put(dataUrl.code, payload);
  },

  //poll response
  setPollQuestionResponse: function (
    contact_id,
    question_id,
    option_id,
    response
  ) {
    return instance.post("/polls/responses/" + question_id, {
      contact_id: contact_id,
      response: response,
      option_id: option_id,
    });
  },

  //poll reports
  getPollReports: function (id) {
    return instance.get("polls/reports/" + id);
  },
  getPollPuntuation: function () {
    return instance.get(`/portal/showPollPuntuation`);
  },
  getContactPuntuations: function () {
    return instance.get(`/portal/contactPuntuations`);
  },
  createPollPuntuation: function (payload) {
    return instance.post(`/portal/pollPuntuation`, payload);
  },

  registerToken: function (id, payload) {
    return instance.post(`/users/${id}/tokens`, payload);
  },

  //financing control
  storeFinancing: function (id, payload) {
    return instance.post(`/organizations/${id}/financing`, payload);
  },
  updateFinancing: function (id, payload) {
    return instance.put(`/organizations/${id}/financing/${fid}`, payload);
  },
  removeFinancing: function (id, payload) {
    return instance.delete(`/organizations/${id}/financing/${fid}`, payload);
  },

  //contact images before after
  getImagesGroup: function (id) {
    return instance.get(`/contacts/${id}/listgroups`);
  },
  storeImagesGroup: function (id, payload) {
    return instance.post(`/contacts/${id}/imagesgroup`, payload);
  },
  getContactImages: function (id, groupId) {
    return instance.get(`/contacts/${id}/listgroups/${groupId}`);
  },
  storeContactImages: function (id, payload) {
    return instance.post(`/contacts/${id}/images`, payload);
  },

  storeImageWeb: function (id, payload) {
    return instance.post(`/contacts/${id}/storeimagecontactsimage`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  storeImageORWeb: function (id, payload) {
    return instance.post(`/contacts/${id}/proceduresbaorimagesall`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  storeImageOtherWeb: function (id, payload) {
    return instance.post(`/contacts/${id}/baotherimages`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  updateContactImagesOR: function (id, payload) {
    return instance.post(`/baorimages/${id}`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  updateContactImagesOther: function (id, payload) {
    return instance.post(`/baotherimages/${id}`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  updateContactImages: function (id, payload) {
    return instance.post(`/contactsimages/${id}`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  deleteImageWeb: function (id) {
    return instance.delete(`/contactsimages/${id}`);
  },

  deleteImageWebOR: function (id) {
    return instance.delete(`/baorimages/${id}`);
  },

  deleteImageWebOTher: function (id) {
    return instance.delete(`/baotherimages/${id}`);
  },

  claimCredit: function (id) {
    return instance.get(`/contacts/${id}/claimcredit`);
  },
  claimSuperSale: function (id) {
    return instance.get(`/contacts/${id}/claimcsupersale`);
  },
  getContactName: function (id) {
    return instance.get(`/invitation/${id}/name`);
  },
  extractVideos: function (payload) {
    return instance.post(`/extract-videos`, payload);
  },
  getRecordVideos: function (id, record_products_id) {
    return instance.get(`/contacts/${id}/videos/${record_products_id}`);
  },
  postRecordVideo: function (id, record_products_id, payload) {
    return instance.post(
      `/contacts/${id}/${record_products_id}/videos`,
      payload
    );
  }, //User Communications Groups
  getCommunicationGroups: function (org) {
    return instance.get(`/users/fororg/${org}/show/comunication/group`);
  },
  addGroupToUser: function (idUser, idGroup) {
    return instance.get(
      `/users/${idUser}/add/to/comunication/group/${idGroup}`
    );
  },
  deleteGroupFromUser: function (idUser, idGroup) {
    return instance.get(
      `/users/${idUser}/del/from/comunication/group/${idGroup}`
    );
  },

  checkNumberToSendMessageWithBuildingHideOldPTCSMS: function (num) {
    return instance.get(
      `/users/checkNumberToSendMessageWithBuildingHideOldPTCSMS/${num}`
    );
  },

  // merchants
  createMerchant: function (payload) {
    return instance.post(`merchants`, payload);
  },
  updateMerchant: function (id, payload) {
    return instance.put(`merchants/${id}`, payload);
  },
  deleteMerchant: function (id) {
    return instance.delete(`/merchants/${id}`);
  },
  getConsolidationGroupsDropdown: function () {
    return instance.get(`consolidation-report-groups-dropdown`);
  },
  getInvoiceData: function (id) {
    return instance.get(`merchant/invoices/${id}`);
  },
  requestDevicePaymentCancellation: function (id, payload) {
    return instance.post(
      `/merchants/requestDevicePaymentCancellation/${id}`,
      payload
    );
  },
  verifyOnlineTransactionById: function (payload) {
    return instance.post(`/merchants/verify-transaction`, payload);
  },
  getBuildingMerchantConfiguration: function (id) {
    return instance.get(`hooks/merchants/getBuildingConfiguration/${id}`);
  },
  sendPaymentRequest: function (payload) {
    return instance.post(`hooks/merchants/sendPaymentRequest`, payload);
  },
  checkContactOnlinePaymentsRefundability: function (
    contactId,
    invoiceId = null
  ) {
    return instance.get(
      `contacts/${contactId}/checkContactOnlinePaymentsRefundability` +
        (invoiceId ? `?iid=${invoiceId}` : "")
    );
  },
  getPendingRefunds: function (id) {
    return instance.get(`invoices/${id}/getPendingRefunds`);
  },
  getDeclinedRefunds: function (id) {
    return instance.get(`invoices/${id}/getDeclinedRefunds`);
  },
  verifyGatewayName: function (payload) {
    return instance.post(`merchants/verify-gateway-name`, payload);
  },
  createGatewayPartner: function (payload) {
    return instance.post(`merchants/gateyay-partner`, payload);
  },
  updateGatewayPartner: function (id, payload) {
    return instance.put(`merchants/gateyay-partner/${id}`, payload);
  },
  getMerchantsDropdown: function () {
    return instance.get(`/merchants/dropdown`);
  },

  getSimilarTransactionsInCRMByMerchantTransaction: function (
    payload,
    params = ""
  ) {
    return instance.post(
      `/merchant-find-similar-transactions${params}`,
      payload
    );
  },

  // merchant configuration
  createMerchantConfiguration: function (payload) {
    return instance.post(`merchant-configurations`, payload);
  },
  updateMerchantConfiguration: function (id, payload) {
    return instance.put(`merchant-configurations/${id}`, payload);
  },
  deleteMerchantConfiguration: function (id) {
    return instance.delete(`merchant-configurations/${id}`);
  },
  // merchant consolidation report group
  createMerchantConsolidationReportGroup: function (payload) {
    return instance.post(`/consolidation-report-groups`, payload);
  },
  updateMerchantConsolidationReportGroup: function (id, payload) {
    return instance.put(`/consolidation-report-groups/${id}`, payload);
  },
  deleteMerchantConsolidationReportGroup: function (id) {
    return instance.delete(`/consolidation-report-groups/${id}`);
  },
  getConsolidationReportGroupsDropdown: function () {
    return instance.get(`/consolidation-report-groups-dropdown`);
  },

  getExtensionsInformation: function (domain) {
    return instance.get(
      `hooks/get/extensions/information/${domain}/aksjdhcbaksjhbwakvjhdbkavjhd`
    );
  },
  genericQueryModel: function (model, opts = {}) {
    const default_opts = merge(
      {
        per_page: 5,
        page: 1,
        order: "asc",
        orderBy: null,
      },
      opts
    );

    let data = {
      model: model,
      per_page: default_opts.per_page,
      page: default_opts.page,
      with: default_opts.with,
      filters: default_opts.filters,
    };

    if (default_opts.orderBy) {
      data.sort_by = `${default_opts.orderBy} ${default_opts.order}`;
    }
    //this line below if to filter keys wich values is null
    data = Object.keys(data)
      .filter((key) => data[key] != null)
      .reduce((p, c) => ({ ...p, [c]: data[c] }), {});

    return instance.post("/generic/getAll", data);
  },
  genericQueryByIdModel: function (model, id, opts = {}) {
    let data = {
      model: model,
      with: opts.with,
      id,
    };

    return instance.post("/generic/getById", data);
  },

  genericUpdateModel: function (model, id, data = {}, multipart = false) {
    if (id === null || id === undefined) {
      throw Exception("Missing id on model to update");
    }
    const updateData = {
      model: model,
      id,
      data,
    };
    return instance.post("/generic/update", updateData);
  },
  genericInsertModel: function (model, data = {}) {
    const insertData = {
      model: model,
      data,
    };

    return instance.post("/generic/insert", insertData);
  },
  generiReport: function (report, opts = {}) {
    return instance.post(`/reports/${report}`, opts);
  },
  genericQueryCountModel: function (model, opts = {}) {
    let data = {
      model: model,
      ...opts,
    };
    data = Object.keys(data)
      .filter((key) => data[key] != null)
      .reduce((p, c) => ({ ...p, [c]: data[c] }), {});

    return instance.post("/generic/getCount", data);
  },
  fileIndex: function (model, id) {
    let data = {
      model,
      id,
    };
    return instance.post("/generic/file", data);
  },

  fileUpload: function (file, props = {}) {
    let fd = new FormData();
    fd.append("file", file);
    Object.keys(props).forEach((p) => fd.append(p, props[p])); //add all others props needed
    return instance.post("/generic/fileUpload", fd, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  fileDelete: function (fileId) {
    return instance.delete(`/generic/file/${fileId}`);
  },

  importContacts: function (payload) {
    return instance.post(`/contacts/Importcsv`, payload);
  },

  // whatsAppConfigurations
  saveWhatsAppConfiguration: function (payload) {
    return instance.post("/whatsAppConfiguration", payload);
  },
  updateWhatsAppConfiguration: function (id, payload) {
    return instance.put(`/whatsAppConfiguration/${id}`, payload);
  },
  deleteWhatsAppConfiguration: function (id) {
    return instance.delete(`/whatsAppConfiguration/${id}`);
  },

  // WhatsAppApplication
  saveWhatsAppApplication: function (payload) {
    return instance.post("/whatsappapplication", payload);
  },
  updateWhatsAppApplication: function (id, payload) {
    return instance.put(`/whatsappapplication/${id}`, payload);
  },
  deleteWhatsAppApplication: function (id) {
    return instance.delete(`/whatsappapplication/${id}`);
  },

  // WhatsAppGroups
  saveWhatsAppGroups: function (payload) {
    return instance.post("/whatsappgroups", payload);
  },
  updateWhatsAppGroups: function (id, payload) {
    return instance.put(`/whatsappgroups/${id}`, payload);
  },
  deleteWhatsAppGroups: function (id) {
    return instance.delete(`/whatsappgroups/${id}`);
  },

  getWhatsAppConfigurationDropdown: function () {
    return instance.get("/whatsAppConfigurationDropdown");
  },

  getWhatsAppApplicationDropdown: function () {
    return instance.get("/whatsappapplicationDropdown");
  },

  getWhatsAppGroupsDropdown: function () {
    return instance.get("/whatsappgroupsDropdown");
  },

  getRolesWPermissions: function (id) {
    return instance.get(`/organizations/${id}/roleswpermissions`);
  },

  reIndexContacts: function (payload) {
    return instance.post("/contacts/reIndex/request", payload);
  },
  //campaign_message_types
  getCampaignMessageTypes: function () {
    return instance.get("/campaign_message_types");
  },

  //campaign_templates
  getCampaignTemplates: function (params = "") {
    return instance.get(`/campaign_templates${params}`);
  },
  createCampaignTemplate: function (payload) {
    return instance.post("/campaign_templates", payload);
  },
  updateCampaignTemplate: function (id, payload) {
    return instance.post(`/campaign_templates/${id}`, payload);
  },
  deleteCampaignTemplate: function (id) {
    return instance.delete(`/campaign_templates/${id}`);
  },

  //assigned_campaign_priorities
  getAssignedCampaignPriorities: function (params = "") {
    return instance.get(`/assigned_campaign_priorities${params}`);
  },
  createAssignedCampaignPriority: function (payload) {
    return instance.post("/assigned_campaign_priorities", payload);
  },
  updateAssignedCampaignPriority: function (id, payload) {
    return instance.post(`/assigned_campaign_priorities/${id}`, payload);
  },
  deleteAssignedCampaignPriority: function (id) {
    return instance.delete(`/assigned_campaign_priorities/${id}`);
  },

  //campaign_types
  getCampaignTypes: function () {
    return instance.get("/campaign_types");
  },

  //campaigns
  getApproximateCampaignCost: function (payload) {
    return instance.post(`/campaigns_estimated_cost`, payload);
  },
  getCampaignsAlerts: function (id) {
    return instance.get(`/organizations/${id}/campaigns_alerts`);
  },
  getCampaigns: function (params = "") {
    return instance.get(`/campaigns${params}`);
  },
  createCampaign: function (payload) {
    return instance.post("/campaigns", payload);
  },
  updateCampaign: function (id, payload) {
    return instance.post(`/campaigns/${id}`, payload);
  },
  deleteCampaign: function (id) {
    return instance.delete(`/campaigns/${id}`);
  },
  scheduleCampaign: function (payload) {
    return instance.post(`/schedulecampaign`, payload);
  },
  getAssignedCampaignpendingDetails: function (id, params = "") {
    return instance.get(`/assignedcampaigns/${id}/getdetails${params}`);
  },
  getAssignedCampaignSentDetails: function (id, params = "") {
    return instance.get(`/assignedcampaigns/${id}/getsentdetails${params}`);
  },
  getPendingCampaignDetailsAmount: function () {
    return instance.get(`/pendingcampaigndetailsamount`);
  },
  cancelAssignedCampaign: function (id) {
    return instance.post(`/assignedcampaigns/${id}/cancel_campaign`);
  },
  updateCampaignPriority: function (id, payload) {
    return instance.post(`/assignedcampaigns/${id}/updatepriority`, payload);
  },
  updateAssignedCampaign: function (id, payload) {
    return instance.post(
      `/assignedcampaigns/${id}/update_assigned_campaign`,
      payload
    );
  },

  getInProgressAndPendingCampaignsTotals: function (payload) {
    return instance.post(
      `/assignedcampaignstodashboard/inprogressandpending`,
      payload
    );
  },
  getAssignedCampaignsGroupedByStatus: function (payload) {
    return instance.post(`/assignedcampaignstodashboard/bystatus`, payload);
  },
  getAssignedCampaignStatistics: function (payload) {
    return instance.post(
      `/assignedcampaignstodashboard/assignedcampaignsstatistics`,
      payload
    );
  },
  pauseAssignedCampaign: function (id, payload) {
    return instance.post(`/assignedcampaigns/${id}/pause`, payload);
  },
  messageCampaignStatistics: function (params = "") {
    return instance.post(`/assignedcampaigns/statisticsbytype${params}`);
  },
  getMessageStatuses: function (payload) {
    return instance.post(`/campaignmessagestatuses`, payload);
  },
  getCampaignEstimatedDateOfCompletion: function (payload) {
    return instance.post(`/campaigns_estimated_date_of_completion`, payload);
  },
  getNumbersForCampaigns: function (id, params = "") {
    return instance.get(
      `/organizations/${id}/phone_numbers_for_campaigns${params}`
    );
  },
  checkPhoneNumbersForCampaigns: function (id) {
    return instance.get(
      `/organizations/${id}/check_phone_numbers_for_campaigns`
    );
  },
  getCampaignPendingMessageBody: function (id) {
    return instance.get(`/campaigns/pendingdetail/${id}/getbody`);
  },
  getCampaignSentMessageBody: function (id) {
    return instance.get(`/campaigns/sentdetail/${id}/getbody`);
  },
  getAssignedCampaignBody: function (id) {
    return instance.get(`/assignedcampaignbody/${id}/getbody`);
  },

  // payroll

  createPayrollConfiguration: function (payload) {
    return instance.post(`/payrollconfigurations`, payload);
  },

  updatePayrollConfiguration: function (id, payload) {
    return instance.put(`/payrollconfigurations/${id}`, payload);
  },

  getPayrollConfigurationsDropdown: function (id) {
    return instance.get(`/organizations/${id}/payrollconfigurationsdropDown`);
  },

  createPayrollConfigurationRule: function (payload) {
    return instance.post(`/payrollconfigurationrules`, payload);
  },

  updatePayrollConfigurationRule: function (id, payload) {
    return instance.put(`/payrollconfigurationrules/${id}`, payload);
  },

  getPayrollConfigurationGroups: function (id) {
    return instance.get(`/payrollconfigurations/${id}/groups`);
  },

  deletePayrollConfigurationGroups: function (id, group_id) {
    return instance.delete(`/payrollconfigurations/${id}/groups/${group_id}`);
  },

  addPayrollConfigurationGroups: function (id, group_id) {
    return instance.post(`/payrollconfigurations/${id}/groups/${group_id}`);
  },

  //internalChat
  getChatHeaders: function (user_id, payload) {
    return instance.post(`/chat/get_headers/${user_id}`, payload);
  },
  getChatConversationMessages: function (
    conversation_id,
    header_id,
    selectedMessageID,
    page,
    limit
  ) {
    return instance.get(
      `/chat/conversation/${conversation_id}/get_messages?page=${
        page ? page : 1
      }&limit=${
        limit ? limit : 50
      }&header_id=${header_id}&message_id=${selectedMessageID}`
    );
  },
  sendChatMessage: function (payload) {
    return instance.post(`chat/send_message`, payload);
  },
  updateChatHeader: function (id, payload) {
    return instance.post(`chat/header/${id}/update`, payload);
  },
  blockUserInChat: function (payload) {
    return instance.post(`chat/block_user`, payload);
  },
  getConversationBetweenTwoUsers: function (payload, page, limit) {
    return instance.post(
      `chat/get_conversation_between_two_users?page=${page ? page : 1}&limit=${
        limit ? limit : 50
      }`,
      payload
    );
  },
  getUserOptionsToInternalChatReport: function () {
    return instance.get(`chat/get_users_to_report`);
  },
  getBlockedUsers: function (params = "") {
    return instance.get(`chat/get_blocked_users_report${params}`);
  },
  deleteBlockedUserChat: function (id) {
    return instance.delete(`chat/delete_blocked_user_chat/${id}`);
  },
  createChatGroup: function (payload) {
    return instance.post(`chat_groups`, payload);
  },
  updateChatGroup: function (payload, id) {
    return instance.post(`/chat_groups/${id}`, payload);
  },
  setChatGroupAdmin: function (payload, id) {
    return instance.post(`/chat_groups/${id}/set_group_admin`, payload);
  },
  exitChatGroup: function (payload, id) {
    return instance.post(`/chat_groups/${id}/exit`, payload);
  },
  deleteChatGroup: function (id) {
    return instance.delete(`/chat_groups/${id}`);
  },

  getUserToUserChatHeaders: function (user_id) {
    return instance.get(`/chat/user_to_user_headers/${user_id}`);
  },
  getUserToUserHeaderMessages: function (header_id, page, limit) {
    return instance.get(
      `chat/user_chat_header/${header_id}/get_messages?page=${
        page ? page : 1
      }&limit=${limit ? limit : 50}`
    );
  },
  getUserToGroupChatHeaders: function (user_id) {
    return instance.get(`/chat/user_to_group_headers/${user_id}`);
  },
  getUserToGroupChatHeaderMessages: function (header_id, page, limit) {
    return instance.get(
      `chat/group_chat_header/${header_id}/get_messages?page=${
        page ? page : 1
      }&limit=${limit ? limit : 50}`
    );
  },
  sendChatMessageToUser: function (payload) {
    return instance.post(`chat/send_message_to_user`, payload);
  },

  sendChatMessageToGroup: function (payload) {
    return instance.post(`/chat/send_message_to_group`, payload);
  },
  updateUserChatHeader: function (id, payload) {
    return instance.post(`chat/user_chat_header/${id}/update`, payload);
  },
  getAvailableUsersToChat: function (payload) {
    return instance.post(`chat/get_available_users_to_chat`, payload);
  },
  updateGroupChatHeader: function (id, payload) {
    return instance.post(`chat/group_chat_header/${id}/update`, payload);
  },
  chatUserTyping: function (payload) {
    return instance.post(`chat/conversation/user_typing`, payload);
  },
  chatCompanyGroupUserTyping: function (payload) {
    return instance.post(
      `chat/conversation/user_typing_in_company_group`,
      payload
    );
  },
  chatGroupUserTyping: function (payload) {
    return instance.post(
      `chat/conversation/user_typing_in_chat_group`,
      payload
    );
  },
  searchInHeaders: function (payload) {
    return instance.post(`chat/search_in_headers`, payload);
  },
  searchInMessages: function (payload, page, limit) {
    return instance.post(
      `chat/search_in_messages?page=${page ? page : 1}&limit=${
        limit ? limit : 50
      }`,
      payload
    );
  },
  getGroupsAvailableToJoin: function () {
    return instance.get(`/chat/get_groups_available_to_join`);
  },
  joinCompanyGroupChat: function (group_id) {
    return instance.post(`/chat/join_company_group_chat/${group_id}`);
  },
  viewChatMessage: function (message_id) {
    return instance.post(`/chat/conversation/view_message/${message_id}`);
  },
  getTotalUnreadChatMessages: function () {
    return instance.get(`/chat/get_total_unread_chat_messages`);
  },
  getUserProductPrice: function (id, user_product_price_id) {
    return instance.get(
      `organizations/${id}/userproductprice/${user_product_price_id}`
    );
  },

  sendSystemDeploymentNotification: function () {
    return instance.post(`/send_system_deployment_notification`);
  },

  sendSystemDeploymentNotificationToAll: function (payload) {
    return instance.post(`/send_system_custom_notification_to_all`, payload);
  },

  //building downpayment
  getDownpaymentsPerProcedure: function (id, params = "") {
    return instance.get(`/buildings/${id}/downpayment_per_procedure${params}`);
  },
  addDownpaymentPerProcedure: function (payload) {
    return instance.post(`/downpayment_per_procedure`, payload);
  },
  updateDownpaymentPerProcedure: function (id, payload) {
    return instance.put(`/downpayment_per_procedure/${id}`, payload);
  },
  deleteDownpaymentPerProcedure: function (id) {
    return instance.delete(`/downpayment_per_procedure/${id}`);
  },

  getSurgicalAssistant: function (id) {
    return instance.get(`organizations/${id}/surgicalAssistantDropdown`);
  },

  getAnesthesiologists: function (id) {
    return instance.get(`organizations/${id}/anesthesiologistsDropdown`);
  },

  //appointmentsnew_instructions
  getAppointmentInstructions: function () {
    return instance.get(`/appointmentsnew_instructions`);
  },
  storeAppointmentInstruction: function (payload) {
    return instance.post(`/appointmentsnew_instructions`, payload);
  },
  updateAppointmentInstruction: function (id, payload) {
    return instance.put(`/appointmentsnew_instructions/${id}`, payload);
  },
  deleteAppointmentInstruction: function (id) {
    return instance.delete(`/appointmentsnew_instructions/${id}`);
  },

  getUsersGeneralDropdownAll: function (id) {
    return instance.get(`/organizations/${id}/usersgeneraldropdownall`);
  },
  //user_activities
  getModelsWithConfiguredLogs: function () {
    return instance.get(`/activity_logs/models`);
  },

  getUserActivityDetails: function (id, params = "") {
    return instance.get(`/users/${id}/activities${params}`);
  },

  //Get call center activity history
  getCallCenterActivityDetails: function (from, to, domain) {
    return instance.get(
      `/users/getcallcenter/information?from=${from}&to=${to}&domain=${domain}`
    );
  },

  //phone_numbers
  unmarkPhoneNumberAsInvalidForMessaging: function (phone) {
    return instance.delete(`/invalid-phone-number-for-messaging/${phone}`);
  },
  getInvalidPhoneNumberForMessaging: function (phone) {
    return instance.get(`/invalid-phone-number-for-messaging/${phone}`);
  },

  markPhoneNumberAsInvalidForVoice: function (phone) {
    return instance.post(`/invalid-phone-number-for-voice/${phone}`);
  },

  unmarkPhoneNumberAsInvalidForVoice: function (phone) {
    return instance.delete(`/invalid-phone-number-for-voice/${phone}`);
  },
  getInvalidPhoneNumberForVoice: function (phone) {
    return instance.get(`/invalid-phone-number-for-voice/${phone}`);
  },

  getSMSMMS: function (params = "") {
    return instance.get(`/smsmms${params}`);
  },

  //influencers
  getInfluencers: function (params = "") {
    return instance.get(`/influencers${params}`);
  },
  createInfluencer: function (payload) {
    return instance.post("/influencers", payload);
  },
  updateInfluencer: function (id, payload) {
    return instance.put(`/influencers/${id}`, payload);
  },
  deleteInfluencer: function (id) {
    return instance.delete(`/influencers/${id}`);
  },
  getInfluencerComments: function (id) {
    return instance.get(`/influencers/${id}/comments`);
  },
  createInfluencerComment: function (id, payload) {
    return instance.post(`/influencers/${id}/comments`, payload);
  },

  getInfluencerSocialPlatforms: function (id) {
    return instance.get(`/influencers/${id}/social-platforms`);
  },
  createInfluencerSocialPlatforms: function (id, payload) {
    return instance.post(`/influencers/${id}/social-platforms`, payload);
  },
  updateInfluencerSocialPlatforms: function (platform_id, payload) {
    return instance.put(
      `/influencers/social-platforms/${platform_id}`,
      payload
    );
  },
  deleteInfluencerSocialPlatforms: function (platform_id) {
    return instance.delete(`/influencers/social-platforms/${platform_id}`);
  },

  getInfluencerDocuments: function (id) {
    return instance.get(`/influencers/${id}/documents`);
  },
  createInfluencerDocuments: function (id, payload) {
    return instance.post(`/influencers/${id}/documents`, payload);
  },
  deleteInfluencerDocuments: function (id) {
    return instance.delete(`/influencers/documents/${id}`);
  },

  getInfluencerContractRequests: function (id) {
    return instance.get(`/influencers/${id}/contract-requests`);
  },
  getInfluencerActivities: function (id) {
    return instance.get(`/influencers/${id}/activities`);
  },

  getInfluencerActivityLogs: function (id, page, limit) {
    return instance.get(
      `/influencers/${id}/activitiesLogs?page=${page ? page : 0}&limit=${
        limit ? limit : 10
      }`
    );
  },
  getContractsActivityLogs: function (id, page, limit) {
    return instance.get(
      `/contracts/${id}/activitiesLogs?page=${page ? page : 0}&limit=${
        limit ? limit : 10
      }`
    );
  },
  getInfluencerContracts: function (oid) {
    return instance.get(`/organizations/${oid}/influencer-contracts`);
  },

  createInfluencerContractRequest: function (id, payload) {
    return instance.post(`/influencers/${id}/contract-request`, payload);
  },

  createCampaignCredit: function (payload, oid) {
    return instance.post(`/organizations/${oid}/campaign-credits`, payload);
  },
  updateCampaignCredit: function (id, payload) {
    return instance.put(`/campaign-credits/${id}`, payload);
  },
  deleteCampaignCredit: function (id) {
    return instance.delete(`/campaign-credits/${id}`);
  },
  getCampaignCreditActivityLogs: function (id, page, limit) {
    return instance.get(
      `/campaign-credits/${id}/activitiesLogs?page=${page ? page : 0}&limit=${
        limit ? limit : 10
      }`
    );
  },

  //baotherimages
  moveOrCopyBAOtherImagesToORsImages: function (payload) {
    return instance.post(`/baotherimages/to-baorimages`, payload);
  },

  //baorimages
  moveOrCopyBAORsImagesToOthersImages: function (payload) {
    return instance.post(`/baorimages/to-baotherimages`, payload);
  },

  //patient welcome templates
  getPatientWelcomeTemplates: function () {
    return instance.get(`/patient-welcome-templates`);
  },
  createPatientWelcomeTemplate: function (payload) {
    return instance.post(`/patient-welcome-templates`, payload);
  },
  updatePatientWelcomeTemplate: function (id, payload) {
    return instance.put(`/patient-welcome-templates/${id}`, payload);
  },
  deletePatientWelcomeTemplate: function (id) {
    return instance.delete(`/patient-welcome-templates/${id}`);
  },

  checkPatientWelcomeTemplateConfiguration: function (payload) {
    return instance.post(
      `/patient-welcome-templates-check-configuration`,
      payload
    );
  },

  //phone validation
  validatePhoneNumberForVoice: function (phone) {
    return instance.get(`/validate-phone-number-for-voice/${phone}`);
  },

  //white-list-numbers-for-campaigns
  getWhiteListNumbersForCampaignsTemplates: function () {
    return instance.get(`/white-list-numbers-for-campaigns`);
  },
  createWhiteListNumbersForCampaigns: function (payload) {
    return instance.post(`/white-list-numbers-for-campaigns`, payload);
  },
  updateWhiteListNumbersForCampaigns: function (id, payload) {
    return instance.put(`/white-list-numbers-for-campaigns/${id}`, payload);
  },
  deleteWhiteListNumbersForCampaigns: function (id) {
    return instance.delete(`/white-list-numbers-for-campaigns/${id}`);
  },
  getWhiteListPhoneNumberActivityLogs: function (id, page, limit) {
    return instance.get(
      `/white-list-numbers-for-campaigns/${id}/activitiesLogs?page=${
        page ? page : 0
      }&limit=${limit ? limit : 10}`
    );
  },

  //procedure-analytics-by-building-details
  getBuildingProceduresAnalyticsDetails: function (
    id,
    building_id,
    params = ""
  ) {
    return instance.get(
      `/organizations/${id}/procedureanalyticsreportdetailsnew/${building_id}${params}`
    );
  },

  getBuildingServicesAnalyticsDetails: function (id, building_id, params = "") {
    return instance.get(
      `/organizations/${id}/servicesanalyticsreportdetails/${building_id}${params}`
    );
  },

  getBuildingProductsAnalyticsDetails: function (id, building_id, params = "") {
    return instance.get(
      `/organizations/${id}/productsanalyticsreportdetails/${building_id}${params}`
    );
  },

  //sms-mms
  getUnansweredSMSMMS: function (params = "") {
    return instance.get(`/unanswered-sms-mms-by-user${params}`);
  },
  getUserUnansweredMessages: function (id, params = "") {
    return instance.get(`/unanswered-sms-mms-by-user/${id}${params}`);
  },
  getUserUnansweredMessagesByContact: function (
    id,
    contact_id,
    phone,
    params = ""
  ) {
    return instance.get(
      `/unanswered-sms-mms-by-user/${id}/contacts/${contact_id}/${phone}${params}`
    );
  },

  //payment-link-requests
  getPaymentLinkRequests: function (params = "") {
    return instance.get(`/payment-link-requests${params}`);
  },

  createPaymentLinkRequest: function (payload) {
    return instance.post(`/payment-link-requests`, payload);
  },

  closePaymentLinkRequest: function (payload, id) {
    return instance.put(`/payment-link-requests/${id}`, payload);
  },

  getPaymentLinkRequest: function (id) {
    return instance.get(`/payment-link-requests/${id}`);
  },

  createSpecialPaymentLink: function (payload) {
    return instance.post(`/special-payment-link`, payload);
  },
  getInvoicePaymentLinkRequests: function (invoice_id) {
    return instance.get(`/invoices/${invoice_id}/payment-link-requests`);
  },

  resendPaymentLinkRequestLink: function (id) {
    return instance.post(`/payment-link-requests/${id}/resend-link`);
  },

  //upload-invitations
  getUploadInvitationByToken: function (token) {
    return instance.get(`/upload_invitations/${token}`);
  },

  //coupons
  getCoupons: function (oid) {
    return instance.get(`/organizations/${oid}/coupons`);
  },
  createCoupon: function (payload) {
    return instance.post(`/coupons`, payload);
  },
  updateCoupon: function (id, payload) {
    return instance.put(`/coupons/${id}`, payload);
  },
  deleteCoupon: function (id) {
    return instance.delete(`/coupons/${id}`);
  },
  getCouponActivityLogs: function (id, page, limit) {
    return instance.get(
      `/coupons/${id}/activities-logs?page=${page ? page : 0}&limit=${
        limit ? limit : 10
      }`
    );
  },

  //Auto Call Queue
  getAutoCalls: function () {
    return instance.get(`/hooks/get/auto/call/queue/information/aksjdhcbaksjhbwakvjhdbkavjhd`);
  },

  getConversationsUsersOfContact: function (id) {
    return instance.get(`/contacts/${id}/conversations/with/users`);
  },

};

export default Api;
