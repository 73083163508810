import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import logo from "../demologo.png";
import { CssBaseline } from "@material-ui/core";

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
    background: url(/demologobackground.jpg);
    background-repeat: no-repeat;
    background-size: cover;
  }
`;

const Root = styled.div`
  max-width: 80%;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
`;
const Logo = styled.img`
  position: absolute;
  top: 25px;
  left: 15px;
`;
function GenericLayout({ children }) {
  return (
    <Root>
      <Logo src={logo} alt="305" />
      <CssBaseline />
      <GlobalStyle />
      {children}
    </Root>
  );
}

export default GenericLayout;
