import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Checkbox,
  CircularProgress,
  Divider,
  Drawer,
  List,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { fetchEntitiesService as fetchProceduresService } from "../../redux/slices/procedureDropdownSlice.js";
import { blue } from "@material-ui/core/colors";
import Controls from "../../components/controls";
import { debounce, isEmpty } from "lodash";
import Popup from "../Popup";
import IconButton from "../controls/IconButton";
import ScrollBar from "react-perfect-scrollbar";
import SearchIcon from "@material-ui/icons/Search";
import Iconify from "../Iconify";
import Api from "../../lib/api";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import reactStringReplace from "react-string-replace";
import AddIcon from "@material-ui/icons/Add";
import MyTemplatesForm from "./MyTemplatesForm.js";
import EditIcon from "@material-ui/icons/Edit";
import ConfirmDialog from "../ConfirmDialog.js";
import CRMUtils from "../../utils/index.js";

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  drawer: {
    maxHeight: 600,
    "& .MuiDrawer-paper": {
      position: "initial",
    },
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    border: "1px solid #eeeeee",
    borderRadius: "10px",
    padding: 10,
    "&.MuiDrawer-paperAnchorDockedLeft": {
      borderRight: "1px solid rgba(0, 0, 0, 0.04)",
    },
    width: drawerWidth,
  },
  input: {
    color: "#EEEFF1",
    "&.Mui-focused": {
      color: "#EEEFF1",
    },
  },
  select: {
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  root: {
    "& .MuiFormControl-root": {
      width: "80%",
      margin: theme.spacing(1),
    },
  },
  detailContainer: {
    margin: "1px 5px",
  },
  detailTitle: {
    color: "#364d93",
    fontWeight: 500,
    fontSize: 16,
  },
  detailText: {
    fontSize: 16,
  },
}));

var dataSliceProceduresDropdown = {
  stateListName: "proceduresDropdown",
  dataUrl: {},
  orgId: 1,

  getFilters: {
    include: [],
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },
  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },
  errorHandle: null,
};

export default function TemplatesSMSMMS(props) {
  const dispatch = useDispatch();
  const {
    authUser,
    send_unsuscribe_link,
    contactForEdit,
    setIsOpenDialogTemplates,
    templates,
    sendSmsMms,
    setTemplates,
    category,
  } = props;
  const [isSharing, setIsSharing] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [popupMyTemplatesForm, setPopupMyTemplatesForm] = useState(false);
  const [templateSelected, setTemplateSelected] = useState(null);
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const classes = useStyles();
  const [templateForEdit, setTemplateForEdit] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [buildings, setBuildings] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const [contact, setContact] = useState(null);
  const { proceduresDropdown } = useSelector(
    (state) => state.proceduresDropdown
  );
  const [expandedControl, setExpandedControl] = useState({
    building: null,
    dr: null,
  });
  const [users, setUsers] = useState([]);
  const [isOpenSharePopup, setIsOpenSharePopup] = useState(false);
  const filtersApplied = useRef({ filters: {} });
  const [filters, setFilters] = useState({ filters: {} });
  var confirmDialogLoading = false;
  const [doctors, setDoctors] = useState([]);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [shareTo, setShareTo] = useState(null);
  const userCanViewAll = CRMUtils.userHasPermission(authUser, [
    "user_viewallsmsmmstemplates",
  ]);

  const getBuildings = async () => {
    const { data } = await Api.getBuildingsWithDoctors();

    if (userCanViewAll) {
      setBuildings([...data.data]);
    } else {
      var buildings = [...data.data].filter(
        (a) => a.id == authUser.building_id
      );
      setBuildings([...buildings]);
    }
  };

  const getContact = async () => {
    const { data } = await Api.getContact(contactForEdit.id);

    setContact({ ...data.data });
  };

  const getDoctors = async () => {
    const { data } = await Api.getDoctors(1);
    setDoctors([...data.data]);
  };

  const procedureOptions = () => {
    let procedure = [];

    procedure = proceduresDropdown.map((product) => {
      return {
        id: product.id,
        title: product.name,
      };
    });

    return procedure;
  };

  const getMyUsers = async () => {
    const { data } = await Api.getMyGroupsUsers(authUser.id);

    setUsers([...data.data]);
  };

  const openMyTemplatesForm = async (item = null) => {
    if (item && item != null) {
      const { data } = await Api.getSMSMMSTemplate(item.id);
      setTemplateForEdit(data.data);
    }

    setPopupMyTemplatesForm(true);
  };

  const closeMyTemplatesForm = () => {
    setTemplateForEdit(null);
    setPopupMyTemplatesForm(false);
  };

  useEffect(() => {
    getSMSMMSTemplatesByCategory(category.id);
    getBuildings();
    getDoctors();
    if (isEmpty(proceduresDropdown)) {
      dispatch(fetchProceduresService(dataSliceProceduresDropdown));
    }
    getMyUsers();
  }, []);

  useEffect(() => {
    if (contactForEdit) {
      getContact();
    }
  }, [contactForEdit]);

  const closeTemplate = () => {
    setTemplateSelected(null);
  };

  const onChangeCheckBox = (item) => {
    var index = selectedTemplates.findIndex((a) => a.id == item.id);
    var templatesSelected = [];
    if (index == -1) {
      templatesSelected = [...selectedTemplates, item];
      setSelectedTemplates([...selectedTemplates, item]);
    } else {
      templatesSelected = selectedTemplates.filter((a) => a.id != item.id);
      setSelectedTemplates(selectedTemplates.filter((a) => a.id != item.id));
    }

    if (
      templates &&
      templatesSelected &&
      templates.length == templatesSelected.length
    ) {
      setIsSelectedAll(true);
    } else {
      setIsSelectedAll(false);
    }
  };

  const sendTemplates = async () => {
    var payloads = [];
    selectedTemplates.map(async (item) => {
      var file = null;
      var bodySend = contact
        ? htmlReplaceTokens(item.body ? item.body : "").join(" ")
        : item.body
        ? item.body
        : "";

      var payload = {
        group: 0,
        attchURL: item.tempUrl ? item.tempUrl : null,
        body: bodySend,
        mediaType: item.media_type,
        category_id: 4,
      };

      payloads.push(payload);
    });
    await sendSmsMms(payloads);
  };

  const htmlReplaceTokens = (body) => {
    if (contact) {
      body = reactStringReplace(body, "{{CUSTOMER_ID}}", (match, i) => {
        return contact.id;
      });
      body = reactStringReplace(body, "{{CUSTOMER_FNAME}}", (match, i) => {
        return contact.fname;
      });
      body = reactStringReplace(body, "{{CUSTOMER_LNAME}}", (match, i) => {
        return contact.lname;
      });
      body = reactStringReplace(body, "{{CUSTOMER_EMAIL}}", (match, i) => {
        return contact.email ? contact.email : "";
      });
      body = reactStringReplace(body, "{{CUSTOMER_PHONE}}", (match, i) => {
        return contact.phone ? contact.phone : "";
      });
      body = reactStringReplace(
        body,
        "{{CUSTOMER_UNSUBSCRIBE_LINK}}",
        (match, i) => {
          return send_unsuscribe_link && contact.subscription_token
            ? process.env.REACT_APP_FRONTEND_ENDPOINT
              ? process.env.REACT_APP_FRONTEND_ENDPOINT +
                "/unsubscribe/" +
                contact.subscription_token
              : "loccalhost"
            : "";
        }
      );

      body = reactStringReplace(body, "{{CUSTOMER_DOB}}", (match, i) => {
        return contact.dob ? contact.dob : "";
      });
      body = reactStringReplace(body, "{{CUSTOMER_SEX}}", (match, i) => {
        return contact.sex ? (contact.sex == "M" ? "Male" : "Female") : "";
      });
      body = reactStringReplace(body, "{{CUSTOMER_WEIGHT}}", (match, i) => {
        return contact.weight;
      });

      body = reactStringReplace(body, "{{CUSTOMER_HEIGHT}}", (match, i) => {
        return contact.height;
      });

      body = reactStringReplace(body, "{{CUSTOMER_BMI}}", (match, i) => {
        return contact.bmi;
      });
      body = reactStringReplace(body, "{{ORGANIZATION_NAME}}", (match, i) => {
        return contact.building_id &&
          contact.building &&
          !isEmpty(contact.building.data)
          ? contact.building.data.name
          : contact.organization && !isEmpty(contact.organization.data)
          ? contact.organization.data.name
          : "";
      });
      body = reactStringReplace(
        body,
        "{{ORGANIZATION_ADDRESS}}",
        (match, i) => {
          return contact.building_id &&
            contact.building &&
            !isEmpty(contact.building.data)
            ? contact.building.data.address
            : contact.organization && !isEmpty(contact.organization.data)
            ? contact.organization.data.address
            : "";
        }
      );
      body = reactStringReplace(body, "{{ORGANIZATION_PHONE}}", (match, i) => {
        return contact.building_id &&
          contact.building &&
          !isEmpty(contact.building.data)
          ? contact.building.data.phone
          : contact.organization && !isEmpty(contact.organization.data)
          ? contact.organization.data.phone
          : "";
      });
      body = reactStringReplace(body, "{{ORGANIZATION_FAX}}", (match, i) => {
        return contact.building_id &&
          contact.building &&
          !isEmpty(contact.building.data)
          ? contact.building.data.fax
          : contact.organization && !isEmpty(contact.organization.data)
          ? contact.organization.data.fax
          : "";
      });
      body = reactStringReplace(
        body,
        "{{CONSULTANT_FULL_NAME}}",
        (match, i) => {
          return contact.owner ? contact.owner.data.title : "";
        }
      );
      body = reactStringReplace(body, "{{CLAIM_CREDIT}}", (match, i) => {
        return process.env.REACT_APP_FRONTEND_ENDPOINT
          ? process.env.REACT_APP_FRONTEND_ENDPOINT +
              "/claim-credit?invitation=" +
              contact.id
          : "";
      });
    }
    return body;
  };

  const deleteTemplate = async (id) => {
    const { data } = await Api.deleteSMSMMSTemplate(id);
    setTemplates(templates.filter((a) => a.id != id));
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
  };

  const getSMSMMSTemplatesByCategory = async (id, payload = null) => {
    setIsLoading(true);
    const { data } = await Api.getSMSMMSTemplatesByCategory(id, payload);
    setIsLoading(false);
    setTemplates([...data.data]);
  };

  const onSearchChange = (value) => {
    setSearchValue(value);

    getSMSMMSTemplatesByCategory(category.id, {
      ...filtersApplied.current,
      ...{ name: value },
    });
  };

  const onClickDelete = (id) => {
    setConfirmDialog({
      isOpen: true,
      title: "Are you sure to delete this Template?",
      subTitle: "You can't undo this operation",
      onConfirm: () => deleteTemplate(id),
    });
  };

  const debouncedSearch = useCallback(
    debounce((newValue) => onSearchChange(newValue), 800),
    []
  );

  const shareTemplates = async () => {
    var ids = selectedTemplates.map((template) => template.id);
    var payload = {
      ids: ids,
      share_to: shareTo,
    };

    setIsSharing(true);
    await Api.shareSMSMMSTemplates(payload);
    setIsSharing(false);
    setIsOpenSharePopup(false);
    setSelectedTemplates([]);
  };

  const shareToOptions = () => {
    return users.map((user) => {
      return { id: user.id, title: user.users_fname };
    });
  };

  return (
    <div style={{ height: 700 }}>
      <div
        style={{
          padding: 5,
          borderBottom: "1px solid #e4e0e0",
          margin: 10,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Iconify
          icon={"bi:file-earmark-image"}
          style={{
            margin: 5,
            width: 44,
            height: 44,
            color: "rgb(68 117 255)",
          }}
        />
        <Typography style={{ fontWeight: 100, fontSize: 16.3, width: "100%" }}>
          {" "}
          {category.title.toUpperCase()}
        </Typography>

        <div style={{ width: "100%", textAlign: "right" }}>
          <TextField
            style={{ maxWidth: "45%" }}
            onChange={(value) => {
              console.log("FILTERS ANTES: ", filters);
              !isLoading
                ? debouncedSearch(value.target.value)
                : onSearchChange(value.target.value);
            }}
            InputProps={{
              endAdornment: <SearchIcon style={{ color: "rgb(68 117 255)" }} />,
            }}
            label=" Search..."
          />
        </div>
        {category.id == 5 && (
          <IconButton
            style={{
              maxHeight: 45,
              maxWidth: 45,
              marginLeft: 13,
              backgroundColor: "rgb(68 117 255)",
            }}
            aria-label="Add Template"
            onClick={() => {
              openMyTemplatesForm(null);
            }}
          >
            <AddIcon style={{ color: "white" }} />
          </IconButton>
        )}
      </div>

      <>
        <div style={{ display: "flex", height: 540 }}>
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor="left"
          >
            <ScrollBar>
              <Typography
                style={{ color: "gray", fontWeight: 100, fontSize: 15 }}
              >
                Folders
              </Typography>
              <List>
                <Button
                  onClick={() => {
                    setFilters({ filters: {} });
                    filtersApplied.current = { filters: {} };
                    getSMSMMSTemplatesByCategory(category.id, {
                      filters: {},
                      name: searchValue,
                    });
                  }}
                  style={{
                    width: "100%",
                    justifyContent: "left",
                    backgroundColor:
                      JSON.stringify(filters.filters) == JSON.stringify({})
                        ? "rgb(236 240 243)"
                        : "",
                  }}
                >
                  <Iconify
                    style={{
                      width: 23,
                      height: 23,
                      marginLeft: 4,
                      color: "#465971",
                    }}
                    icon={"codicon:list-flat"}
                  />
                  <p style={{ fontSize: 16, fontWeight: 100, margin: 3 }}>
                    All Templates
                  </p>
                </Button>

                <Accordion expanded={expandedControl.building == -1} square>
                  <AccordionSummary
                    onClick={() => {
                      setExpandedControl({
                        ...expandedControl,
                        ...{
                          building: expandedControl.building == -1 ? null : -1,
                        },
                      });
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Iconify
                        style={{
                          height: 17,
                          width: 17,
                          margin: 6,
                          color: "#b4c3d4",
                        }}
                        icon={"entypo:folder"}
                      />
                      <Typography
                        style={{
                          fontWeight: 100,
                          fontSize: 14.5,
                          color: "#2f2f2f",
                        }}
                      >
                        No Building
                      </Typography>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    {expandedControl.building == -1 ? (
                      <List>
                        <Accordion
                          square
                          expanded={
                            expandedControl.dr == -1 &&
                            expandedControl.building == -1
                          }
                        >
                          <AccordionSummary
                            onClick={() => {
                              setExpandedControl({
                                ...expandedControl,
                                ...{ dr: expandedControl.dr == -1 ? null : -1 },
                              });
                            }}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Iconify
                                style={{
                                  height: 17,
                                  width: 17,
                                  margin: 6,
                                  color: "#b4c3d4",
                                }}
                                icon={"entypo:folder"}
                              />
                              <Typography
                                style={{
                                  fontWeight: 100,
                                  fontSize: 14.5,
                                  color: "#2f2f2f",
                                }}
                              >
                                No Doctor
                              </Typography>
                            </div>
                          </AccordionSummary>
                          <AccordionDetails>
                            {expandedControl.dr == -1 &&
                            expandedControl.building == -1 ? (
                              <List>
                                <Button
                                  onClick={() => {
                                    var filter = {
                                      ...filters?.filters,
                                      ...{
                                        building_id: null,
                                        doctor_id: null,
                                        procedure_id: null,
                                      },
                                    };

                                    setFilters({
                                      ...filters,
                                      ...{ filters: filter },
                                    });
                                    filtersApplied.current = {
                                      ...filters,
                                      ...{ filters: filter },
                                    };
                                    getSMSMMSTemplatesByCategory(category.id, {
                                      ...filters,
                                      ...{ filters: filter },
                                    });
                                  }}
                                  style={{
                                    justifyContent: "left",
                                    width: "100%",
                                    textAlign: "left",
                                    backgroundColor:
                                      JSON.stringify(filters.filters) !=
                                        JSON.stringify({}) &&
                                      filters.filters.building_id == null &&
                                      filters.filters.doctor_id == null &&
                                      filters.filters.procedure_id == null
                                        ? "rgb(236 240 243)"
                                        : "",
                                  }}
                                >
                                  <Typography
                                    style={{
                                      fontWeight: 100,
                                      fontSize: 14.5,
                                      color: "#2f2f2f",
                                    }}
                                  >
                                    No Procedure
                                  </Typography>
                                </Button>

                                {procedureOptions().map((procedure) => {
                                  return (
                                    <Button
                                      onClick={() => {
                                        var filter = {
                                          ...filters?.filters,
                                          ...{
                                            building_id: null,
                                            doctor_id: null,
                                            procedure_id: procedure.id,
                                          },
                                        };

                                        setFilters({
                                          ...filters,
                                          ...{ filters: filter },
                                        });
                                        filtersApplied.current = {
                                          ...filters,
                                          ...{ filters: filter },
                                        };
                                        getSMSMMSTemplatesByCategory(
                                          category.id,
                                          {
                                            ...filters,
                                            ...{ filters: filter },
                                          }
                                        );
                                      }}
                                      style={{
                                        justifyContent: "left",
                                        width: "100%",
                                        textAlign: "left",
                                        backgroundColor:
                                          filters.filters.building_id == null &&
                                          filters.filters.doctor_id == null &&
                                          filters.filters.procedure_id ==
                                            procedure.id
                                            ? "rgb(236 240 243)"
                                            : "",
                                      }}
                                    >
                                      <Typography
                                        style={{
                                          fontWeight: 100,
                                          fontSize: 14.5,
                                          color: "#2f2f2f",
                                        }}
                                      >
                                        {procedure.title}
                                      </Typography>
                                    </Button>
                                  );
                                })}
                              </List>
                            ) : (
                              ""
                            )}
                          </AccordionDetails>
                        </Accordion>
                        {doctors?.map((doctor) => {
                          return (
                            <Accordion
                              square
                              expanded={
                                expandedControl.dr == doctor.id &&
                                expandedControl.building == -1
                              }
                            >
                              <AccordionSummary>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Iconify
                                    style={{
                                      height: 17,
                                      width: 17,
                                      margin: 6,
                                      color: "#b4c3d4",
                                    }}
                                    icon={"entypo:folder"}
                                  />
                                  <Typography
                                    style={{
                                      fontWeight: 100,
                                      fontSize: 14.5,
                                      color: "#2f2f2f",
                                    }}
                                  >
                                    {doctor.fullname}
                                  </Typography>
                                </div>
                              </AccordionSummary>
                              <AccordionDetails>
                                {expandedControl.dr == doctor.id &&
                                expandedControl.building == -1 ? (
                                  <List>
                                    <Button
                                      style={{
                                        justifyContent: "left",
                                        width: "100%",
                                        textAlign: "left",
                                        backgroundColor:
                                          JSON.stringify(filters.filters) !=
                                            JSON.stringify({}) &&
                                          filters.filters.building_id == null &&
                                          filters.filters.doctor_id ==
                                            doctor.id &&
                                          filters.filters.procedure_id == null
                                            ? "rgb(236 240 243)"
                                            : "",
                                      }}
                                      onClick={() => {
                                        var filter = {
                                          ...filters?.filters,
                                          ...{
                                            building_id: null,
                                            doctor_id: doctor.id,
                                            procedure_id: null,
                                          },
                                        };

                                        setFilters({
                                          ...filters,
                                          ...{ filters: filter },
                                        });
                                        filtersApplied.current = {
                                          ...filters,
                                          ...{ filters: filter },
                                        };
                                        getSMSMMSTemplatesByCategory(
                                          category.id,
                                          {
                                            ...filters,
                                            ...{ filters: filter },
                                          }
                                        );
                                      }}
                                    >
                                      <Typography
                                        style={{
                                          fontWeight: 100,
                                          fontSize: 14.5,
                                          color: "#2f2f2f",
                                        }}
                                      >
                                        No Procedure
                                      </Typography>
                                    </Button>
                                    {procedureOptions().map((procedure) => {
                                      return (
                                        <Button
                                          onClick={() => {
                                            var filter = {
                                              ...filters?.filters,
                                              ...{
                                                building_id: null,
                                                doctor_id: doctor.id,
                                                procedure_id: procedure.id,
                                              },
                                            };

                                            setFilters({
                                              ...filters,
                                              ...{ filters: filter },
                                            });
                                            filtersApplied.current = {
                                              ...filters,
                                              ...{ filters: filter },
                                            };
                                            getSMSMMSTemplatesByCategory(
                                              category.id,
                                              {
                                                ...filters,
                                                ...{ filters: filter },
                                              }
                                            );
                                          }}
                                          style={{
                                            justifyContent: "left",
                                            width: "100%",
                                            textAlign: "left",
                                            backgroundColor:
                                              JSON.stringify(filters.filters) !=
                                                JSON.stringify({}) &&
                                              filters.filters.building_id ==
                                                null &&
                                              filters.filters.doctor_id ==
                                                doctor.id &&
                                              filters.filters.procedure_id ==
                                                procedure.id
                                                ? "rgb(236 240 243)"
                                                : "",
                                          }}
                                        >
                                          <Typography
                                            style={{
                                              fontWeight: 100,
                                              fontSize: 14.5,
                                              color: "#2f2f2f",
                                            }}
                                          >
                                            {procedure.title}
                                          </Typography>
                                        </Button>
                                      );
                                    })}
                                  </List>
                                ) : (
                                  ""
                                )}
                              </AccordionDetails>
                            </Accordion>
                          );
                        })}
                      </List>
                    ) : (
                      ""
                    )}
                  </AccordionDetails>
                </Accordion>

                {buildings?.map((building, index) => (
                  <Accordion
                    square
                    expanded={expandedControl.building == building.id}
                  >
                    <AccordionSummary
                      onClick={() => {
                        setExpandedControl({
                          ...expandedControl,
                          ...{
                            building:
                              expandedControl.building == building.id
                                ? null
                                : building.id,
                          },
                        });
                      }}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Iconify
                          style={{
                            height: 17,
                            width: 17,
                            margin: 6,
                            color: "#b4c3d4",
                          }}
                          icon={"entypo:folder"}
                        />
                        <Typography
                          style={{
                            fontWeight: 100,
                            fontSize: 14.5,
                            color: "#2f2f2f",
                          }}
                        >
                          {building.name}
                        </Typography>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      {expandedControl.building == building.id ? (
                        <List>
                          <Accordion
                            square
                            expanded={
                              expandedControl.building == building.id &&
                              expandedControl.dr == -1
                            }
                          >
                            <AccordionSummary
                              onClick={() => {
                                setExpandedControl({
                                  ...expandedControl,
                                  ...{
                                    dr: expandedControl.dr == -1 ? null : -1,
                                  },
                                });
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Iconify
                                  style={{
                                    height: 17,
                                    width: 17,
                                    margin: 6,
                                    color: "#b4c3d4",
                                  }}
                                  icon={"entypo:folder"}
                                />
                                <Typography
                                  style={{
                                    fontWeight: 100,
                                    fontSize: 14.5,
                                    color: "#2f2f2f",
                                  }}
                                >
                                  No Doctor
                                </Typography>
                              </div>
                            </AccordionSummary>
                            <AccordionDetails>
                              {expandedControl.building == building.id &&
                              expandedControl.dr == -1 ? (
                                <List>
                                  <Button
                                    onClick={() => {
                                      var filter = {
                                        ...filters?.filters,
                                        ...{
                                          building_id: building.id,
                                          doctor_id: null,
                                          procedure_id: null,
                                        },
                                      };

                                      setFilters({
                                        ...filters,
                                        ...{ filters: filter },
                                      });
                                      filtersApplied.current = {
                                        ...filters,
                                        ...{ filters: filter },
                                      };
                                      getSMSMMSTemplatesByCategory(
                                        category.id,
                                        {
                                          ...filters,
                                          ...{ filters: filter },
                                        }
                                      );
                                    }}
                                    style={{
                                      justifyContent: "left",
                                      width: "100%",
                                      textAlign: "left",
                                      backgroundColor:
                                        JSON.stringify(filters.filters) !=
                                          JSON.stringify({}) &&
                                        filters.filters.building_id ==
                                          building.id &&
                                        filters.filters.doctor_id == null &&
                                        filters.filters.procedure_id == null
                                          ? "rgb(236 240 243)"
                                          : "",
                                    }}
                                  >
                                    <Typography
                                      style={{
                                        fontWeight: 100,
                                        fontSize: 14.5,
                                        color: "#2f2f2f",
                                      }}
                                    >
                                      No Procedure
                                    </Typography>
                                  </Button>

                                  {procedureOptions().map((procedure) => {
                                    return (
                                      <Button
                                        onClick={() => {
                                          var filter = {
                                            ...filters?.filters,
                                            ...{
                                              building_id: building.id,
                                              doctor_id: null,
                                              procedure_id: procedure.id,
                                            },
                                          };

                                          setFilters({
                                            ...filters,
                                            ...{ filters: filter },
                                          });
                                          filtersApplied.current = {
                                            ...filters,
                                            ...{ filters: filter },
                                          };
                                          getSMSMMSTemplatesByCategory(
                                            category.id,
                                            {
                                              ...filters,
                                              ...{ filters: filter },
                                            }
                                          );
                                        }}
                                        style={{
                                          justifyContent: "left",
                                          width: "100%",
                                          textAlign: "left",
                                          backgroundColor:
                                            JSON.stringify(filters.filters) !=
                                              JSON.stringify({}) &&
                                            filters.filters.building_id ==
                                              building.id &&
                                            filters.filters.doctor_id == null &&
                                            filters.filters.procedure_id ==
                                              procedure.id
                                              ? "rgb(236 240 243)"
                                              : "",
                                        }}
                                      >
                                        <Typography
                                          style={{
                                            fontWeight: 100,
                                            fontSize: 14.5,
                                            color: "#2f2f2f",
                                          }}
                                        >
                                          {procedure.title}
                                        </Typography>
                                      </Button>
                                    );
                                  })}
                                </List>
                              ) : (
                                ""
                              )}
                            </AccordionDetails>
                          </Accordion>
                          {building?.doctors?.data?.map((doctor) => {
                            return (
                              <Accordion
                                square
                                expanded={
                                  expandedControl.building == building.id &&
                                  expandedControl.dr == doctor.id
                                }
                              >
                                <AccordionSummary
                                  onClick={() => {
                                    setExpandedControl({
                                      ...expandedControl,
                                      ...{
                                        dr:
                                          expandedControl.dr == doctor.id
                                            ? null
                                            : doctor.id,
                                      },
                                    });
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Iconify
                                      style={{
                                        height: 17,
                                        width: 17,
                                        margin: 6,
                                        color: "#b4c3d4",
                                      }}
                                      icon={"entypo:folder"}
                                    />
                                    <Typography
                                      style={{
                                        fontWeight: 100,
                                        fontSize: 14.5,
                                        color: "#2f2f2f",
                                      }}
                                    >
                                      {doctor.users_fname}
                                    </Typography>
                                  </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                  {expandedControl.building == building.id &&
                                  expandedControl.dr == doctor.id ? (
                                    <List>
                                      <Button
                                        onClick={() => {
                                          var filter = {
                                            ...filters?.filters,
                                            ...{
                                              building_id: building.id,
                                              doctor_id: doctor.id,
                                              procedure_id: null,
                                            },
                                          };

                                          setFilters({
                                            ...filters,
                                            ...{ filters: filter },
                                          });
                                          filtersApplied.current = {
                                            ...filters,
                                            ...{ filters: filter },
                                          };
                                          getSMSMMSTemplatesByCategory(
                                            category.id,
                                            {
                                              ...filters,
                                              ...{ filters: filter },
                                            }
                                          );
                                        }}
                                        style={{
                                          justifyContent: "left",
                                          width: "100%",
                                          textAlign: "left",
                                          backgroundColor:
                                            JSON.stringify(filters.filters) !=
                                              JSON.stringify({}) &&
                                            filters.filters.building_id ==
                                              building.id &&
                                            filters.filters.doctor_id ==
                                              doctor.id &&
                                            filters.filters.procedure_id == null
                                              ? "rgb(236 240 243)"
                                              : "",
                                        }}
                                      >
                                        <Typography
                                          style={{
                                            fontWeight: 100,
                                            fontSize: 14.5,
                                            color: "#2f2f2f",
                                          }}
                                        >
                                          No Procedure
                                        </Typography>
                                      </Button>

                                      {procedureOptions().map((procedure) => {
                                        return (
                                          <Button
                                            onClick={() => {
                                              var filter = {
                                                ...filters?.filters,
                                                ...{
                                                  building_id: building.id,
                                                  doctor_id: doctor.id,
                                                  procedure_id: procedure.id,
                                                },
                                              };

                                              setFilters({
                                                ...filters,
                                                ...{ filters: filter },
                                              });
                                              filtersApplied.current = {
                                                ...filters,
                                                ...{ filters: filter },
                                              };
                                              getSMSMMSTemplatesByCategory(
                                                category.id,
                                                {
                                                  ...filters,
                                                  ...{ filters: filter },
                                                }
                                              );
                                            }}
                                            style={{
                                              justifyContent: "left",
                                              width: "100%",
                                              textAlign: "left",
                                              backgroundColor:
                                                JSON.stringify(
                                                  filters.filters
                                                ) != JSON.stringify({}) &&
                                                filters.filters.building_id ==
                                                  building.id &&
                                                filters.filters.doctor_id ==
                                                  doctor.id &&
                                                filters.filters.procedure_id ==
                                                  procedure.id
                                                  ? "rgb(236 240 243)"
                                                  : "",
                                            }}
                                          >
                                            <Typography
                                              style={{
                                                fontWeight: 100,
                                                fontSize: 14.5,
                                                color: "#2f2f2f",
                                              }}
                                            >
                                              {procedure.title}
                                            </Typography>
                                          </Button>
                                        );
                                      })}
                                    </List>
                                  ) : (
                                    ""
                                  )}
                                </AccordionDetails>
                              </Accordion>
                            );
                          })}
                        </List>
                      ) : (
                        ""
                      )}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </List>
            </ScrollBar>
          </Drawer>

          <div style={{ width: "100%" }}>
            <div
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <Button
                style={{ width: "35%", justifyContent: "left" }}
                onClick={() => {
                  setIsSelectedAll(!isSelectedAll);
                  if (!isSelectedAll == true) {
                    setSelectedTemplates(templates);
                  } else {
                    setSelectedTemplates([]);
                  }
                }}
              >
                {templates &&
                selectedTemplates &&
                templates.length > 0 &&
                templates.length == selectedTemplates.length
                  ? "Unselect All"
                  : "Select All"}
              </Button>

              {selectedTemplates.length > 0 && (
                <Typography
                  style={{ width: "100%" }}
                >{`(${selectedTemplates.length} Selected)`}</Typography>
              )}

              {category.id == 5 && (
                <div style={{ width: "100%", textAlign: "right" }}>
                  <Button
                    style={{ color: "#646464", whiteSpace: "pre" }}
                    onClick={() => {
                      setEditMode(!editMode);
                      setSelectedTemplates([]);
                    }}
                  >
                    {editMode ? (
                      <CloseIcon
                        style={{ height: 17, margin: 3, color: "#d35c3f" }}
                      />
                    ) : (
                      <EditIcon
                        style={{ height: 17, margin: 3, color: "#9ba3e4" }}
                      />
                    )}{" "}
                    {editMode ? "Exit Edit Mode" : "Edit Mode"}
                  </Button>
                </div>
              )}
            </div>

            {templates && templates.length > 0 && !isLoading ? (
              <>
                <ScrollBar style={{ maxHeight: 640 }}>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "left",
                    }}
                  >
                    {templates.map((a) => {
                      return (
                        <Card
                          style={{
                            textAlign: "center",
                            margin: 5,
                            backgroundColor: "#fdfeff",
                            borderStyle: "solid",
                            borderWidth: "1px",
                            borderColor: "rgb(238 238 238)",
                            borderRadius: 4,
                            maxWidth: 136,
                            alignSelf: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              backgroundColor: editMode ? "#f2f6f9" : "#f6f9fc",
                              justifyContent: editMode ? "right" : "right",
                            }}
                          >
                            {category.id == 5 && editMode && (
                              <>
                                <IconButton
                                  style={{ maxHeight: 10, maxWidth: 10 }}
                                >
                                  <EditIcon
                                    onClick={() => {
                                      openMyTemplatesForm(a);
                                    }}
                                    style={{ maxHeight: 18, color: "#2852b6" }}
                                  />
                                </IconButton>
                                <IconButton
                                  style={{ maxHeight: 10, maxWidth: 10 }}
                                  onClick={() => {
                                    onClickDelete(a.id);
                                  }}
                                >
                                  <CloseIcon
                                    style={{ maxHeight: 18, color: "#d35c3f" }}
                                  />
                                </IconButton>
                              </>
                            )}

                            {!editMode && (
                              <div style={{ display: "flex" }}>
                                {a.shared_by_user ? (
                                  <Typography
                                    style={{ fontSize: 11, color: "#4a5c7d" }}
                                  >
                                    {`Shared by ${a.shared_by_user}`}
                                  </Typography>
                                ) : (
                                  ""
                                )}
                                <div>
                                  <Checkbox
                                    size="small"
                                    checked={
                                      selectedTemplates.findIndex(
                                        (f) => f.id == a.id
                                      ) != -1
                                    }
                                    style={{ margin: -9 }}
                                    onChange={() => {
                                      onChangeCheckBox(a);
                                    }}
                                    color="primary"
                                  />
                                </div>
                              </div>
                            )}
                          </div>

                          <CardActionArea
                            style={{ width: 135, height: 128 }}
                            onClick={() => {
                              setTemplateSelected(a);
                            }}
                          >
                            {a.type_of_message == "mms" &&
                            a.media_type != "video/mp4" ? (
                              <img
                                style={{ maxWidth: "100%", maxHeight: "100%" }}
                                src={a.tempUrl}
                              />
                            ) : a.type_of_message == "mms" &&
                              a.media_type == "video/mp4" ? (
                              <video
                                style={{ maxHeight: "100%", maxWidth: "100%" }}
                                controls
                              >
                                <source
                                  src={a.tempUrl}
                                  type="video/mp4"
                                ></source>
                              </video>
                            ) : (
                              <ScrollBar style={{ maxHeight: "100%" }}>
                                <Typography
                                  style={{
                                    maxHeight: "100%",
                                    fontSize: 10,
                                    textAlign: "justify",
                                  }}
                                >
                                  {a.body}
                                </Typography>
                              </ScrollBar>
                            )}
                          </CardActionArea>

                          <div
                            style={{
                              backgroundColor: "rgb(238 238 238)",
                              color: "#6a6a6a",
                            }}
                          >
                            <Typography>{a.name}</Typography>
                          </div>
                        </Card>
                      );
                    })}
                  </div>
                </ScrollBar>

                {selectedTemplates.length > 0 && (
                  <div style={{ display: "flex", justifyContent: "right" }}>
                    {category.id == 5 ? (
                      <Button
                        style={{ float: "right", fontSize: 17 }}
                        onClick={() => {
                          setIsOpenSharePopup(true);
                        }}
                        startIcon={
                          <Iconify
                            icon="solar:share-bold"
                            style={{ color: blue[700], width: 23, height: 23 }}
                          />
                        }
                      >
                        Share
                      </Button>
                    ) : (
                      ""
                    )}

                    <Button
                      style={{ float: "right", fontSize: 17 }}
                      onClick={() => {
                        sendTemplates().then(() => {
                          setIsOpenDialogTemplates(false);
                          setTemplateSelected(null);
                        });
                      }}
                      startIcon={
                        <Iconify
                          icon="fluent:send-20-filled"
                          style={{ color: blue[700], width: 23, height: 23 }}
                        />
                      }
                    >
                      Send
                    </Button>
                  </div>
                )}
              </>
            ) : isLoading ? (
              <div style={{ margin: 20, width: "94%", textAlign: "center" }}>
                <CircularProgress disableShrink />
              </div>
            ) : (
              <Typography
                style={{
                  padding: 16,
                  fontSize: 16,
                  textAlign: "center",
                  color: "#3f3f3f",
                }}
              >
                No templates to display
              </Typography>
            )}
          </div>
        </div>

        <Popup
          className={true}
          title={templateSelected?.name}
          openPopup={templateSelected}
          onClose={closeTemplate}
        >
          <div>
            {" "}
            <br />
            <Card
              style={{
                textAlign: "center",
                textAlign: "center",
                maxWidth: "fit-content",
                minWidth: "60%",
                margin: "0 auto",
              }}
            >
              <CardContent>
                <ScrollBar style={{ maxHeight: "580px" }}>
                  {templateSelected &&
                  templateSelected.tempUrl &&
                  templateSelected.type_of_message == "mms" ? (
                    templateSelected.media_type != "video/mp4" ? (
                      <>
                        <img
                          style={{ maxHeight: "340px" }}
                          src={templateSelected?.tempUrl}
                        />
                        {templateSelected?.body && (
                          <div
                            style={{
                              padding: 9,
                              margin: 18,
                            }}
                          >
                            <Typography
                              style={{ textAlign: "justify", fontSize: 15 }}
                            >
                              {contact
                                ? htmlReplaceTokens(
                                    templateSelected?.body
                                      ? templateSelected.body
                                      : ""
                                  ).join(" ")
                                : templateSelected?.body}
                            </Typography>
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        <video
                          style={{ maxHeight: "100%", maxWidth: "100%" }}
                          controls
                        >
                          <source
                            src={templateSelected?.tempUrl}
                            type="video/mp4"
                          ></source>
                        </video>
                        {templateSelected?.body && (
                          <div
                            style={{
                              padding: 9,
                              margin: 18,
                            }}
                          >
                            <Typography
                              style={{ textAlign: "justify", fontSize: 15 }}
                            >
                              {contact
                                ? htmlReplaceTokens(
                                    templateSelected?.body
                                      ? templateSelected?.body
                                      : ""
                                  ).join(" ")
                                : templateSelected?.body}
                            </Typography>
                          </div>
                        )}
                      </>
                    )
                  ) : (
                    <div
                      style={{
                        padding: 9,
                        margin: 18,
                      }}
                    >
                      <Typography
                        style={{ textAlign: "justify", fontSize: 15 }}
                      >
                        {contact
                          ? htmlReplaceTokens(
                              templateSelected?.body
                                ? templateSelected.body
                                : ""
                            ).join(" ")
                          : templateSelected?.body}
                      </Typography>
                    </div>
                  )}
                </ScrollBar>
              </CardContent>
            </Card>
            <div
              style={{
                marginTop: 18,
                borderRadius: 14,
                background: "#e2e7eb57",
                padding: 13,
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Iconify
                  style={{
                    color: "#516da3",
                    width: 19,
                    height: 19,
                    marginRight: 3,
                  }}
                  icon="tabler:template"
                />
                <Typography style={{ fontWeight: 600, fontSize: 17 }}>
                  Template Details
                </Typography>
              </div>
              <Divider style={{ marginBottom: 8, marginTop: 5 }} />
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className={classes.detailContainer}>
                  <Typography className={classes.detailTitle}>
                    {"Building: "}
                  </Typography>
                  <Typography className={classes.detailText}>
                    {templateSelected?.building?.data?.name}
                  </Typography>
                </div>
                <div className={classes.detailContainer}>
                  <Typography className={classes.detailTitle}>
                    {"Dr: "}
                  </Typography>
                  <Typography className={classes.detailText}>
                    {templateSelected?.doctor?.data?.users_fname}
                  </Typography>
                </div>
                <div className={classes.detailContainer}>
                  <Typography className={classes.detailTitle}>
                    {"Procedure: "}
                  </Typography>
                  <Typography className={classes.detailText}>
                    {templateSelected?.procedure?.data?.name}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </Popup>
      </>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        confirmLoading={confirmDialogLoading}
      />
      <Popup
        fullWidth={true}
        title={"Template"}
        openPopup={popupMyTemplatesForm}
        onClose={closeMyTemplatesForm}
      >
        <MyTemplatesForm
          closeMyTemplatesForm={closeMyTemplatesForm}
          authUser={authUser}
          templateForEdit={templateForEdit}
          setTemplateForEdit={setTemplateForEdit}
          templates={templates}
          setTemplates={setTemplates}
        />
      </Popup>

      <Popup
        fullWidth={true}
        title={"Share selected templates"}
        openPopup={isOpenSharePopup}
        onClose={() => {
          setIsOpenSharePopup(false);
        }}
        maxWidth="sm"
      >
        <div style={{ display: "flex" }}>
          <Controls.Select
            label={"Share to"}
            allowEmpty={true}
            options={shareToOptions()}
            onChange={(el) => setShareTo(el.target.value)}
            value={shareTo}
          />

          <Button
            disabled={isSharing}
            style={{ marginLeft: 4 }}
            startIcon={<Iconify icon="ic:sharp-share" />}
            color="primary"
            onClick={shareTemplates}
            variant="contained"
          >
            {isSharing ? "Sharing..." : "Share"}
          </Button>
        </div>
      </Popup>
    </div>
  );
}
