import {
  Avatar,
  Badge,
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Fade,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  PaperProps,
  TextField,
  Typography,
  Zoom,
} from "@material-ui/core";
import { green, grey } from "@material-ui/core/colors";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import CallIcon from "@material-ui/icons/Call";
import CloseIcon from "@material-ui/icons/Close";
import { default as ExpandMore, default as ExpandMoreIcon } from "@material-ui/icons/ExpandMore";
import FaceIcon from "@material-ui/icons/Face";
import SmsIcon from "@material-ui/icons/Sms";
import { debounce, filter, find, map, orderBy } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { CirclePicker } from "react-color";
import Draggable from "react-draggable";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Api from "../lib/api";
import ContactDetails from "../pages/contacts/details2";
import RecordDetails from "../pages/records/details";
import { updatePbxInfo } from "../redux/slices/auth";
import {
  changeColorService as changeColorServiceByGroups,
  fetchEntitiesService as fetchUserContactsSMSMMSByGroupsService,
  unreadMessageCountService as unreadMessageCountServiceByGroups,
  updateOnlyMessageCountService as updateOnlyMessageCountServiceByGroups,
} from "../redux/slices/userSMSMMSByGroupsSlice.js";
import {
  changeColorService,
  fetchEntitiesService as fetchUserContactsSMSMMSService,
  unreadMessageCountService,
  updateOnlyMessageCountService,
} from "../redux/slices/userSMSMMSSlice.js";
import IsImportant from "./IsImportant";
import Popup from "./Popup";
import Controls from "./controls";
import NewSmsMmsForm from "./forms/NewSmsMmsForm";

const Wrapper = styled.div`
  position: relative;
 
  display: "block";
  visibilityIcon
  width: 100%;
  margin: "18px",
  padding: "5px",
`;

import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { useTheme } from "@material-ui/core/styles";
import GroupIcon from "@material-ui/icons/Group";
import PropTypes from "prop-types";

import InfiniteScroll from "react-infinite-scroll-component";
import { v4 as uuidv4 } from "uuid";

//import { getMessaging, onMessage } from "firebase/messaging";

function trimDownToWord(string, maxLength) {
  if (string <= maxLength) {
    return string;
  }

  let first = string.substr(0, maxLength);
  const second = string.substr(maxLength);

  if (/\w$/.test(first) && /^\w/.test(second)) {
    first = first.replace(/\b[^\w]*\w+$/, "");
  }
  if (string.length > maxLength) {
    return first.trim() + " ...";
  } else return first.trim();
}

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  /* search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: lighten("0.2", "rgba(137,132,128,0.2)"),
    alignSelf: "center",
    marginLeft: 0,
    width: "80%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },*/
  searchInput: {
    minWidth: "95%",
  },
  wrapText: {
    whiteSpace: "normal",
    wordWrap: "break-word",
    maxWidth: "250px",
  },
  wrapText2: {
    whiteSpace: "normal",
    wordWrap: "break-word",
    maxWidth: "250px",
  },
  formControlLabel: {
    fontWeight: 700,
  },

  
  popup: {
    minWidth: "300px",
  },
  dialogWrapper: {
    position: "absolute",

    height: "35%",
    boxShadow:
      "rgba(0, 0, 0, 0.2) 0px 8px 10px -5px, rgba(0, 0, 0, 0.14) 0px 16px 24px 2px, rgba(0, 0, 0, 0.12) 0px 6px 30px 5px",
    top: "15%",
    borderRadius: "15px",
    zIndex: theme.zIndex.tooltip + 1000,
  },
  listUsersWrapper: {
    width: "380px",
    padding: "5px",
    borderRight: "1px solid #CCC",
    overflow: "hidden",
  },
  selectedUser: {
    background: "#6266ce",
    padding: "2px",
    margin: "2px",
  },

  userItem: {
    padding: "2px",
    margin: "2px",
  },
  dialogTitle2: {
    color: "white",
    backgroundColor: "#066fac",
  },

  secundaryText: {
    color: "#80cbc4",
    fontSize: "12px",
    whiteSpace: "normal",
    wordWrap: "break-word",
    maxWidth: "150px",
  },
  ListItemTextRoot: {
    padding: "4px",
    margin: "4px",
    height: "90px",
    "&:hover": {
      height: "90px",
    },
  },
  ListItemTextText: {
    padding: "2px",
    margin: "5px",
    whiteSpace: "normal",
    wordWrap: "break-word",
    maxWidth: "150px",
  },

  ListItemTextSelectedRoot: {
    background: grey[100],
    borderRadius: "5px",
    cursor: "pointer",
    padding: "4px",
    margin: "4px",
    height: "90px",
  },
  ListItemTextSelectedText: {
    fontWeight: 700,
    padding: "2px",
    cursor: "pointer",
    margin: "5px",
    whiteSpace: "normal",
    wordWrap: "break-word",
    maxWidth: "150px",
  },
}));

var dataSliceUserContactsSMSMMS = {
  stateListName: "userContactsSMSMMS",
  dataUrl: {},
  orgId: 1,
  userId: 0,

  getFilters: {
    include: [],

    page: 1,
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },

  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },
  errorHandle: null,
};

var dataSliceUserContactsSMSMMSByGroups = {
  stateListName: "userContactsSMSMMSByGroups",
  dataUrl: {},
  orgId: 1,
  userId: 0,

  getFilters: {
    include: [],

    page: 1,
    params: [],
    filters: [],
  },
  dataList: {
    showPageLoading: false,
    successHandle: null,
  },

  dataUpdate: {
    id: null,
    data: null,
    successHandle: null,
  },
  dataCreate: {
    data: null,
    successHandle: null,
  },
  dataDelete: {
    id: null,
    successHandle: null,
  },
  errorHandle: null,
};

function PaperComponent(props: PaperProps) {
  return <Paper {...props} style={{ margin: 0, maxHeight: "100%" }} />;
}

const SmsMmsContactList = (props) => {
  const classes = useStyles();
  const {
    saveTo2 = null,
    mergeData,
    setMergeData,
    mergeDataByGroups,
    setContactPhoneSearchText,
    changePhone,
    changeContact,
    phone,
    contact,
    setContact,
    setNewContact,
    contactColors,
    setSelectedGroup,
    setSelectedGroupContact = null,
    selectedGroupContact = null,
    selectedGroup = null,
    groups = null,
    tabValueExternal = null,
    setTabValueExternal = null,
    setSelectedGroupPhone = null,
    selectedGroupPhone = null,
    newSMS = false,
    setNewSMS = null,
  } = props;
  const [openContactPopup, setOpenContactPopup] = useState(false);
  const [contactDetail, setContactDetail] = useState(null);
  const [recordDetail, setRecordDetail] = useState(null);
  const [openRecordPopup, setOpenRecordPopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [mergeDataLocal, setMergeDataLocal] = useState([]);
  const [mergeDataLocalByGroups, setMergeDataLocalByGroups] = useState([]);
  const [onlyUnread, setOnlyUnread] = useState(0);
  const [onlyImportant, setOnlyImportant] = useState(0);

  const [listScroll, setListScroll] = useState({ initial: 0, amount: 14 });
  const [listScrollGroups, setListScrollGroups] = useState({
    initial: 0,
    amount: 14,
  });

  const { authUser, pbxConfig } = useSelector((state) => state.auth);

  const uuid = uuidv4();

  dataSliceUserContactsSMSMMS.orgId = authUser.id;
  dataSliceUserContactsSMSMMSByGroups.orgId = authUser.id;

  const [tabValue, setTabValue] = useState(0);
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
    setTabValueExternal(newValue);
  };
  function a11yProps(index) {
    return {
      id: `scrollable-force-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
    /*return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };*/
  }
  const handleChangeIndex = (index) => {
    setTabValue(index);
  };
  const theme = useTheme();
  function TabPanel(props) {
    const classes = useStyles();
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-force-tabpanel-${index}`}
        aria-labelledby={`nav-tab-${index}`}
        key={`scrollable-force-tabpanel-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    );
    /*const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );*/
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const setSearch = (search) => {
    setContactPhoneSearchText(search);
  };

  const debouncedSearch = useCallback(
    debounce((newValue) => setSearch(newValue), 500),
    []
  );
  const openMessagePopup = (item) => {
    setOpenPopup(true);
    setTabValue(0);
    setTabValueExternal(0);
  };

  const saveTo = (data) => {
    //console.log("saveTo at SmsMmsContactList data Ronald log:",data,saveTo2);
    if (saveTo2) {
      saveTo2(data);
    } else {
      setNewContact(data);
      setSelectedGroup(null);
    }
  };

  const onCloseMessagePopup = () => {
    setOpenPopup(false);
  };

  const openContact = async () => {
    if (authUser.is_coordinator) {
      const contact = mergeDataLocal.find((item) => {
        return item.id == selectedItem;
      });
      if (contact) {
        const { data } = await Api.getRecord(contact.record_id);
        setRecordDetail(data.data);
        setOpenRecordPopup(true);
      } else {
        const contactA = mergeDataLocalByGroups.find((item) => {
          return item.id == selectedItem;
        });

        if (contactA) {
  
          const { data } = await Api.getRecord(contactA.record_id);
          setRecordDetail(data.data);
          setOpenRecordPopup(true);
        }
      }

      
    } else {
      const { data } = await Api.getContactPlain(selectedItem);
      setContactDetail(data.data);
      setOpenContactPopup(true);
    }
  };

  const oncloseContact = () => {
    setOpenContactPopup(false);
  };

  const oncloseRecord = () => {
    setOpenRecordPopup(false);
    setRecordDetail(false);
  };

  useEffect(() => {
    if (onlyImportant) {
      setMergeDataLocal(
        orderBy(mergeData, ["lastMessageDate"], ["desc"]).filter((item) => {
          return item.smsMMSColorIsImportant > 0;
        })
      );
    } else {
      if (!onlyUnread && mergeData) {
        setMergeDataLocal(orderBy(mergeData, ["lastMessageDate"], ["desc"]));
      }
    }
  }, [mergeData, onlyImportant]);

  useEffect(() => {
    if (onlyUnread) {
      setMergeDataLocal(
        orderBy(mergeData, ["lastMessageDate"], ["desc"]).filter((item) => {
          return (
            item.unread > 0 ||
            (phone && phone.phone == item.phone) ||
            (contact && item.id && contact.id == item.id)
          );
        })
      );
    } else {
      if (!onlyImportant && mergeData) {
        setMergeDataLocal(orderBy(mergeData, ["lastMessageDate"], ["desc"]));
      }
    }
   
  }, [mergeData, onlyUnread]);

  useEffect(() => {
    if (onlyUnread) {
      setMergeDataLocalByGroups(
        orderBy(mergeDataByGroups, ["lastMessageDate"], ["desc"]).filter(
          (item) => {
            return item.unread > 0;
          }
        )
      );
    } else {
      if (mergeDataByGroups) {
        setMergeDataLocalByGroups(
          orderBy(mergeDataByGroups, ["lastMessageDate"], ["desc"])
        );
      }
    }
  }, [mergeDataByGroups, onlyUnread]);

  useEffect(() => {
    setTabValue(tabValueExternal);
  }, [tabValueExternal]);

  useEffect(() => {
    if (newSMS) {
      openMessagePopup();
      if (setNewSMS) {
        setNewSMS(false);
      }
    }
  }, [newSMS]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const makeACall = () => {
   
    let tmp = {};

    tmp = {
      ...pbxConfig,
      makeACall: true,
    };

    if (pbxConfig.callToPhone || pbxConfig.callToName) {
      tmp.callToPhone = null;
      tmp.callToName = null;
    }

    dispatch(updatePbxInfo(tmp));
  };

  const [phoneLocal, setPhoneLocal] = useState([]);
  const [anchorElMoreMenu, setAnchorElMoreMenu] = React.useState(null);
  const [outboundMessagesCount, setOutboundMessagesCount] =
    React.useState(null);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [userId, setUserId] = React.useState(null);
  const handleCloseMoreSetting = () => {
    setAnchorElMoreMenu(null);
    setSelectedItem(null);
    setOutboundMessagesCount(null);
  };
  const openMoreMenu = (currentTarget) => {
    const name = currentTarget.name.split("-")[0];
    let user_id;
    let group_id;
    if (!selectedGroup) user_id = currentTarget.name.split("-")[1];
    else group_id = currentTarget.name.split("-")[1];
    const outbound_Messages_Count = currentTarget.name.split("-")[2];
    setSelectedItem(currentTarget.id);

    setPhoneLocal(name);
    setOutboundMessagesCount(outbound_Messages_Count);
    if (!selectedGroup) searchMergeable(name);
    else searchMergeableByGroups(name);
    if (!selectedGroup) setUserId(user_id);
    else setUserId(group_id);

    setAnchorElMoreMenu(currentTarget);
  };

  const [searchMergeablePhone, setSearchMergeablePhone] = useState([]);

  const searchMergeable = (phone) => {
    let filtered = filter(mergeData, { phone: phone });

    setSearchMergeablePhone(filtered);
  };

  const searchMergeableByGroups = (phone) => {
    let filtered = filter(mergeDataByGroups, { phone: phone });
    setSearchMergeablePhone(filtered);
  };

  const mergeContact = async () => {
    if (searchMergeablePhone.length >= 2) {
      const contacta = find(searchMergeablePhone, function (contact) {
        return contact.id > 0;
      });

      if (contacta) {
        const payload = {
          phone: contacta.phone,
          contact_id: contacta.id,
        };

        if (!selectedGroup)
          Api.mergeContacts(authUser.id, payload).then(() => {
            dispatch(
              fetchUserContactsSMSMMSService(dataSliceUserContactsSMSMMS)
            ).then(() => {
              changeContact(
                find(mergeData, function (data) {
                  return data.id == contacta.id;
                })
              );
            });
          });
        if (selectedGroup)
          Api.mergeContactsByGroups(selectedGroup, payload).then(() => {
            dispatch(
              fetchUserContactsSMSMMSByGroupsService(
                dataSliceUserContactsSMSMMSByGroups
              )
            ).then(() => {
              changeContact(
                find(mergeDataByGroups, function (data) {
                  return data.id == contacta.id;
                })
              );
            });
          });
      } else if (phone) {
        {
          const payload = {
            phone: phone.phone,
            contact_id: null,
          };

          if (!selectedGroup)
            Api.mergeContacts(authUser.id, payload).then(() => {
              dispatch(
                fetchUserContactsSMSMMSService(dataSliceUserContactsSMSMMS)
              ).then(() => {
                changePhone(
                  find(mergeData, function (data) {
                    return data.phone == phone.phone;
                  })
                );
              });
            });
          if (selectedGroup)
            Api.mergeContactsByGroups(selectedGroup, payload).then(() => {
              dispatch(
                fetchUserContactsSMSMMSByGroupsService(
                  dataSliceUserContactsSMSMMSByGroups
                )
              ).then(() => {
                changeContact(
                  find(mergeDataByGroups, function (data) {
                    return data.phone == phone.phone;
                  })
                );
              });
            });
        }
      }
    }
  };

  const setContactPhone = (item) => {
  
    let localpayload = {
      data: { contact_id: null, phone: null },
      addsubtract: 0,
    };

    if (item.id) {
      if (item.id > 0) {
        localpayload.data.contact_id = item.id;
        changeContact(item);
      } else {
        changePhone(item);
        localpayload.data.phone = item.phone;
      }
    } else {
      changePhone(item);
      localpayload.data.phone = item.phone;
    }
    dispatch(updateOnlyMessageCountService(localpayload));
  };

  const updateUnreadCountByGroups = (item) => {
    let localpayload = {
      data: { contact_id: null, phone: null },
      addsubtract: 0,
    };

    if (item.id) {
      if (item.id > 0) {
        localpayload.data.contact_id = item.id;
        //changeContact(item);
      } else {
        //changePhone(item);
        localpayload.data.phone = item.phone;
      }
    } else {
      //changePhone(item);
      localpayload.data.phone = item.phone;
    }
    dispatch(updateOnlyMessageCountServiceByGroups(localpayload));
  };

  const markasUnread = (item) => {
    let localpayload = {
      data: { contact_id: selectedItem, phone: phoneLocal },
      unread: item,
      user_id: authUser.id,
    };

    if (tabValue === 0) dispatch(unreadMessageCountService(localpayload));
    if (selectedGroup && tabValue === 1) {
      localpayload.group = selectedGroup;
      //Api.setSmsMmsContactColorByGroups(selectedGroup, payload);
      dispatch(unreadMessageCountServiceByGroups(localpayload));
    }
  };

  const setColor = (color) => {
    const payload = {
      data: { contact_id: selectedItem, phone: phoneLocal },
      color: color.hex,
      user_id: authUser.id,
    };
    if (tabValue === 0) dispatch(changeColorService(payload));
    if (selectedGroup && tabValue === 1) {
      payload.group = selectedGroup;
      //Api.setSmsMmsContactColorByGroups(selectedGroup, payload);
      dispatch(changeColorServiceByGroups(payload));
    }
  };

  const getGroupName = (item) => {
    let result = {};
    if (groups && groups.length > 0)
      result = groups.filter((group) => {
        return (group.group_id = item.group_id);
      });
    if (result && result.name) return result.name;
    else return "";
  };

  const getTotalUnread = (arr) => {
    let total = 0;
    if (arr.length > 0) {
      total = arr.reduce((total, item) => total + item.unread, 0);
    }
    return total;
  };

  return (
    <>
      <div className={classes.listUsersWrapper}>
        <Grid container>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            item
            xs={10}
          >
            <TextField
              placeholder="Search…"
              onChange={(event) => debouncedSearch(event.target.value)}
              className={classes.searchInput}
              InputProps={{
                inputProps: { style: { borderRadius: 35 } },
                style: { borderRadius: 35 },
              }}
              variant="outlined"
            ></TextField>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            item
            xs={2}
          >
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              TransitionComponent={Fade}
              onClose={handleClose}
              elevation={0}
              getContentAnchorEl={null}
              PaperProps={{
                style: {
                  borderRadius: 10,
                  borderColor: "rgba(180, 180, 180, 0.87)",
                  borderWidth: "1px",
                  borderStyle: "solid",
                  marginTop: 15,
                },
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <MenuItem
                onClick={(event) => {
                  openMessagePopup();
                  handleClose();
                }}
                key={"NewMessage"}
              >
                <ListItemIcon>
                  <SmsIcon
                    fontSize="small"
                    style={{
                      color: "#2196f3",
                    }}
                  />
                </ListItemIcon>
                <Typography variant="inherit">New Message</Typography>
              </MenuItem>
              <MenuItem
                onClick={(event) => {
                  makeACall();
                  handleClose();
                }}
                key={"NewCall"}
                disabled={
                  !pbxConfig ||
                  (pbxConfig &&
                    (!pbxConfig.aor ||
                      !pbxConfig.receive_active ||
                      pbxConfig.registrationError ||
                      !pbxConfig.callCheckMedia ||
                      !pbxConfig.callCheckInput ||
                      !pbxConfig.callCheckOutput ||
                      !pbxConfig.callCheckNotification))
                }
              >
                <ListItemIcon>
                  <CallIcon
                    fontSize="small"
                    style={{
                      color:
                        !pbxConfig ||
                        (pbxConfig &&
                          (!pbxConfig.aor ||
                            !pbxConfig.receive_active ||
                            pbxConfig.registrationError ||
                            !pbxConfig.callCheckMedia ||
                            !pbxConfig.callCheckInput ||
                            !pbxConfig.callCheckOutput ||
                            !pbxConfig.callCheckNotification))
                          ? "#f44336"
                          : "#2196f3",
                    }}
                  />
                </ListItemIcon>
                <Typography variant="inherit">New Call</Typography>
              </MenuItem>
            </Menu>
            {
              <Controls.IconButton
                aria-label="Expand"
                onClick={(event) => {
                  setAnchorEl(event.currentTarget);
                }}
                className={classes.popover}
                //boxShadow={3}
              >
                <ExpandMoreIcon />
              </Controls.IconButton>
            }
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            item
            xs={12}
          >
            <FormControlLabel
              control={
                <GreenCheckbox
                  checked={onlyUnread == 1 ? true : false}
                  onChange={(v) => setOnlyUnread(!onlyUnread)}
                />
              }
              label={
                <Typography className={classes.formControlLabel}>
                  Unread Only
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <GreenCheckbox
                  checked={onlyImportant == 1 ? true : false}
                  onChange={(v) => setOnlyImportant(!onlyImportant)}
                />
              }
              label={
                <Typography className={classes.formControlLabel}>
                  Importans Only
                </Typography>
              }
            />
          </Grid>
        </Grid>

        {
          <AppBar position="static" color="default">
            <Tabs
              value={tabValue}
              onChange={handleChangeTab}
              indicatorColor="primary"
              textColor="primary"
              //variant="fullWidth"
              //aria-label="full width tabs example"
              scrollButtons="auto"
            >
              <Tab
                label={"By Users (" + getTotalUnread(mergeDataLocal) + ")"}
              />

              <Tab
                label={
                  "By Groups (" + getTotalUnread(mergeDataLocalByGroups) + ")"
                }
              />
            </Tabs>
          </AppBar>
        }

        <List
          component="nav"
          id={"scrollableDiv" + uuid}
          style={{
            height: "80%",
            width: "98%",
            overflowY: "auto",
            overflowX: "hidden",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            role="tabpanel"
            hidden={tabValue !== 0}
            id={`scrollable-force-tabpanel-${0}`}
            aria-labelledby={`nav-tab-${0}`}
            key={`scrollable-force-tabpanel-${0}`}
          >
            {/* <TabPanel value={tabValue} index={0} {...a11yProps(0)}> */}
            {tabValue === 0 && (
              <Box p={3}>
                {" "}
                <InfiniteScroll
                  dataLength={
                    mergeDataLocal.slice(listScroll.initial, listScroll.amount)
                      .length
                  }
                  next={() => {
                  
                    setListScroll({
                      initial: listScroll.initial,
                      amount: listScroll.amount + 14,
                    });
                  }}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    overflowY: "auto",
                    overflowX: "hidden",
                  }}
                  
                  hasMore={true}
                 
          
                  scrollableTarget={"scrollableDiv" + uuid}
              
                >
                  {mergeDataLocal && mergeDataLocal.length > 15
                    ? map(
                        mergeDataLocal.slice(
                          listScroll.initial,
                          listScroll.amount
                        ),
                        (item, index) => {
                          return (
                            <>
                              <ListItem
                                button
                                key={item.id}
                                classes={{
                                  root: contact
                                    ? contact.id == item.id
                                      ? classes.ListItemTextSelectedRoot
                                      : classes.ListItemTextRoot
                                    : phone && (!item.id || item?.id <= 0)
                                    ? phone.phone == item.phone
                                      ? classes.ListItemTextSelectedRoot
                                      : classes.ListItemTextRoot
                                    : classes.ListItemTextRoot,
                                }}
                                style={{ marginBottom: "20px" }}
                       
                                onClick={() => {
                                  setContactPhone(item);
                                  setSelectedGroup(null);
                                }}
                              >
                                <ListItemAvatar>
                                  <Badge
                                    badgeContent={item.unread ? item.unread : 0}
                                    color="primary"
                                  >
                                    <Avatar
                                      style={{
                                        backgroundColor:
                                          item.smsMMSColor &&
                                          item.smsMMSColor != ""
                                            ? item.smsMMSColor
                                            : "#BDBDBD",
                                      }}
                                    >
                                      <FaceIcon />
                                    </Avatar>
                                  </Badge>
                                </ListItemAvatar>

                                <ListItemText
                                  classes={{
                                    primary: contact
                                      ? contact.id == item.id
                                        ? classes.ListItemTextSelectedText
                                        : classes.ListItemTextText
                                      : phone && (!item.id || item?.id <= 0)
                                      ? phone.phone == item.phone
                                        ? classes.ListItemTextSelectedText
                                        : classes.ListItemTextText
                                      : classes.ListItemTextText,
                                    secondary: classes.secundaryText,
                                  }}
                                  primary={
                                    item.fname && item.lname
                                      ? `${item.fname} ${item.lname}`
                                      : item.phone
                                  }
                                  secondary={
                                    <>
                                      {
                                        <div
                                          style={{
                                            color: "black",
                                            fontStyle: "italic",
                                            textAlign: "justify",
                                          }}
                                        >
                                          {(item.lastMessageUser == 1
                                            ? "You: "
                                            : "") +
                                            (item.lastMessageBody
                                              ? trimDownToWord(
                                                  item.lastMessageBody,
                                                  50
                                                )
                                              : "")}
                                        </div>
                                      }
                                      <div>
                                        {item.user_id
                                          ? authUser.id != item.user_id
                                            ? "PTC: " + item.owner_name
                                            : ""
                                          : ""}{" "}
                                      </div>
                                    </>
                                  }
                                />

                                <ListItemSecondaryAction
                                  style={{
                                    fontSize: "12px",
                                    textAlign: "center",
                                  }}
                                >
                                  {item.lastMessageDate ? (
                                    moment(item.lastMessageDate).format(
                                      "MM-DD-YY"
                                    ) == moment().format("MM-DD-YY") ? (
                                      <strong>Today</strong>
                                    ) : moment(item.lastMessageDate).format(
                                        "MM-DD-YY"
                                      ) ==
                                      moment()
                                        .subtract(1, "days")
                                        .format("MM-DD-YY") ? (
                                      <strong>Yesterday</strong>
                                    ) : (
                                      moment(item.lastMessageDate).format(
                                        "MM-DD-YY"
                                      )
                                    )
                                  ) : (
                                    ""
                                  )}
                            
                                  <IsImportant
                                    item={item}
                                    setMergeData={setMergeData}
                                  />
                                  <br />

                                  <IconButton
                                    name={
                                      item.phone +
                                      "-" +
                                      (item.owner_id
                                        ? item.owner_id
                                        : item.user_id) +
                                      "-" +
                                      item.outboundMessagesCount
                                    }
                                    id={item.id}
                                    edge="end"
                                    aria-label="more"
                                    onClick={(event) =>
                                      openMoreMenu(event.currentTarget)
                                    }
                                    size={"small"}
                                  >
                                    <ExpandMore size="small" />
                                  </IconButton>
                                </ListItemSecondaryAction>
                              </ListItem>
                            </>
                          );
                        }
                      )
                    : map(mergeDataLocal, (item, index) => {
                        return (
                          <>
                            <ListItem
                              button
                              key={item.id}
                              classes={{
                                root: contact
                                  ? contact.id == item.id
                                    ? classes.ListItemTextSelectedRoot
                                    : classes.ListItemTextRoot
                                  : phone && (!item.id || item?.id <= 0)
                                  ? phone.phone == item.phone
                                    ? classes.ListItemTextSelectedRoot
                                    : classes.ListItemTextRoot
                                  : classes.ListItemTextRoot,
                              }}
                              style={{ marginBottom: "20px", height: "100px" }}
                            
                              onClick={() => {
                                setContactPhone(item);
                                setSelectedGroup(null);
                              }}
                            >
                              <ListItemAvatar>
                                <Badge
                                  badgeContent={item.unread ? item.unread : 0}
                                  color="primary"
                                >
                                  <Avatar
                                    style={{
                                      backgroundColor:
                                        item.smsMMSColor &&
                                        item.smsMMSColor != ""
                                          ? item.smsMMSColor
                                          : "#BDBDBD",
                                    }}
                                  >
                                    <FaceIcon />
                                  </Avatar>
                                </Badge>
                              </ListItemAvatar>

                              <ListItemText
                                classes={{
                                  primary: contact
                                    ? contact.id == item.id
                                      ? classes.ListItemTextSelectedText
                                      : classes.ListItemTextText
                                    : phone && (!item.id || item?.id <= 0)
                                    ? phone.phone == item.phone
                                      ? classes.ListItemTextSelectedText
                                      : classes.ListItemTextText
                                    : classes.ListItemTextText,
                                  secondary: classes.secundaryText,
                                }}
                                primary={
                                  item.fname && item.lname
                                    ? `${item.fname} ${item.lname}`
                                    : item.phone
                                }
                                secondary={
                                  <>
                                    {
                                      <div
                                        style={{
                                          color: "black",
                                          fontStyle: "italic",
                                          textAlign: "justify",
                                        }}
                                      >
                                        {(item.lastMessageUser == 1
                                          ? "You: "
                                          : "") +
                                          (item.lastMessageBody
                                            ? trimDownToWord(
                                                item.lastMessageBody,
                                                50
                                              )
                                            : "")}
                                      </div>
                                    }
                                    <div>
                                      {item.user_id
                                        ? authUser.id != item.user_id
                                          ? "PTC: " + item.owner_name
                                          : ""
                                        : ""}{" "}
                                    </div>
                                  </>
                                }
                              />

                              <ListItemSecondaryAction
                                style={{
                                  fontSize: "12px",
                                  textAlign: "center",
                                }}
                              >
                                {item.lastMessageDate ? (
                                  moment(item.lastMessageDate).format(
                                    "MM-DD-YY"
                                  ) == moment().format("MM-DD-YY") ? (
                                    <strong>Today</strong>
                                  ) : moment(item.lastMessageDate).format(
                                      "MM-DD-YY"
                                    ) ==
                                    moment()
                                      .subtract(1, "days")
                                      .format("MM-DD-YY") ? (
                                    <strong>Yesterday</strong>
                                  ) : (
                                    moment(item.lastMessageDate).format(
                                      "MM-DD-YY"
                                    )
                                  )
                                ) : (
                                  ""
                                )}
                                <IsImportant
                                  item={item}
                                  setMergeData={setMergeData}
                                />
                                <br />
                                <IconButton
                                  name={
                                    item.phone +
                                    "-" +
                                    (item.owner_id
                                      ? item.owner_id
                                      : item.user_id) +
                                    "-" +
                                    item.outboundMessagesCount
                                  }
                                  id={item.id}
                                  edge="end"
                                  aria-label="more"
                                  onClick={(event) =>
                                    openMoreMenu(event.currentTarget)
                                  }
                                  size={"small"}
                                >
                                  <ExpandMore size="small" />
                                </IconButton>
                              </ListItemSecondaryAction>
                            </ListItem>
                          </>
                        );
                      })}
                </InfiniteScroll>{" "}
              </Box>
            )}
            {/* </TabPanel> */}
          </div>
          <div
            role="tabpanel"
            hidden={tabValue !== 1}
            id={`scrollable-force-tabpanel-${1}`}
            aria-labelledby={`nav-tab-${1}`}
            key={`scrollable-force-tabpanel-${1}`}
          >
            {/* <TabPanel value={tabValue} index={1} {...a11yProps(1)}> */}
            {tabValue === 1 && (
              <Box p={3}>
                <InfiniteScroll
                  dataLength={
                    mergeDataLocalByGroups.slice(
                      listScrollGroups.initial,
                      listScrollGroups.amount
                    ).length
                  }
                  next={() => {
                    //getNewSMSMMS();
                    setListScrollGroups({
                      initial: listScrollGroups.initial,
                      amount: listScrollGroups.amount + 14,
                    });
                  }}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    overflowY: "auto",
                    overflowX: "hidden",
                  }}
                
                  hasMore={true}
               
              
                  scrollableTarget={"scrollableDiv" + uuid}
               
                >
                  {groups
                    ? map(groups, (item, index) => {
                        let tmp = mergeDataLocalByGroups.filter((row) => {
                          return row.group_id === item.group_id;
                        });
                        if (!tmp)
                          return (
                            <>
                              <ListItem
                                button
                                key={item.id}
                                classes={{
                                  root: selectedGroupContact
                                    ? selectedGroupContact == item.id
                                      ? classes.ListItemTextSelectedRoot
                                      : classes.ListItemTextRoot
                                    : selectedGroupPhone &&
                                      (!item.id || item?.id <= 0)
                                    ? selectedGroupPhone == item.fname
                                      ? classes.ListItemTextSelectedRoot
                                      : classes.ListItemTextRoot
                                    : classes.ListItemTextRoot,
                                }}
                                style={{ marginBottom: "25px" }}
                               
                                onClick={() => {
                                  
                                  setSelectedGroup(item.group_id);
                                  setSelectedGroupContact(item.id);
                                  setSelectedGroupPhone(item.fname);
                                  setContact(null);
                                }}
                              >
                                <ListItemAvatar>
                                  <Badge
                                    badgeContent={item.unread ? item.unread : 0}
                                    color="primary"
                                  >
                                    <Avatar
                                      style={{
                                        backgroundColor:
                                          item.smsMMSColor &&
                                          item.smsMMSColor != ""
                                            ? item.smsMMSColor
                                            : "#D7FDFF",
                                      }}
                                    >
                                      <GroupIcon />
                                    </Avatar>
                                  </Badge>
                                </ListItemAvatar>

                                <ListItemText
                                  classes={{
                                    primary: selectedGroupContact
                                      ? selectedGroupContact == item.id
                                        ? classes.ListItemTextSelectedText
                                        : classes.ListItemTextText
                                      : selectedGroupPhone &&
                                        (!item.id || item?.id <= 0)
                                      ? selectedGroupPhone == item.fname
                                        ? classes.ListItemTextSelectedText
                                        : classes.ListItemTextText
                                      : classes.ListItemTextText,
                                    secondary: classes.secundaryText,
                                  }}
                                  primary={
                                    item.fname && item.lname
                                      ? item.fname +
                                        " " +
                                        item.lname +
                                        " from " +
                                        (item.building
                                          ? item.building.substring(0, 3)
                                          : "none") +
                                        "." +
                                        " in " +
                                        item.group_name
                                      : item.phone + " in " + item.group_name
                                  }
                                  secondary={
                                    <>
                                      {
                                        <div
                                          style={{
                                            color: "black",
                                            fontStyle: "italic",
                                            textAlign: "justify",
                                          }}
                                        >
                                          {(item.lastMessageUser == 1
                                            ? "You: "
                                            : "") +
                                            (item.lastMessageBody
                                              ? trimDownToWord(
                                                  item.lastMessageBody,
                                                  50
                                                )
                                              : "")}
                                        </div>
                                      }
                                      <div>
                                        {item.user_id
                                          ? authUser.id != item.user_id
                                            ? "PTC: " + item.owner_name
                                            : ""
                                          : ""}{" "}
                                      </div>
                                    </>
                                  }
                                />

                                <ListItemSecondaryAction
                                  style={{
                                    fontSize: "12px",
                                    textAlign: "center",
                                  }}
                                >
                                  {item.lastMessageDate ? (
                                    moment(item.lastMessageDate).format(
                                      "MM-DD-YY"
                                    ) == moment().format("MM-DD-YY") ? (
                                      <strong>Today</strong>
                                    ) : moment(item.lastMessageDate).format(
                                        "MM-DD-YY"
                                      ) ==
                                      moment()
                                        .subtract(1, "days")
                                        .format("MM-DD-YY") ? (
                                      <strong>Yesterday</strong>
                                    ) : (
                                      moment(item.lastMessageDate).format(
                                        "MM-DD-YY"
                                      )
                                    )
                                  ) : (
                                    ""
                                  )}
                                  <br />

                                  <IconButton
                                    name={
                                      item.phone +
                                      "-" +
                                      (item.owner_id
                                        ? item.owner_id
                                        : item.user_id) +
                                      "-" +
                                      item.outboundMessagesCount
                                    }
                                    id={item.id}
                                    edge="end"
                                    aria-label="more"
                                    onClick={(event) =>
                                      openMoreMenu(event.currentTarget)
                                    }
                                    size={"small"}
                                  >
                                    <ExpandMore size="small" />
                                  </IconButton>
                                </ListItemSecondaryAction>
                              </ListItem>
                            </>
                          );
                      })
                    : ""}
                  {mergeDataLocalByGroups && mergeDataLocalByGroups.length > 14
                    ? map(
                        mergeDataLocalByGroups
                          .filter((group) => {
                            return group.id > 0 || group.id === null;
                          })
                          .slice(
                            listScrollGroups.initial,
                            listScrollGroups.amount
                          ),
                        (item, index) => {
                          return (
                            <>
                              <ListItem
                                button
                                key={item.id ? item.id : "" + item.phone}
                                classes={{
                                  root: selectedGroupContact
                                    ? selectedGroupContact == item.id
                                      ? classes.ListItemTextSelectedRoot
                                      : classes.ListItemTextRoot
                                    : selectedGroupPhone &&
                                      (!item.id || item?.id <= 0)
                                    ? selectedGroupPhone == item.fname
                                      ? classes.ListItemTextSelectedRoot
                                      : classes.ListItemTextRoot
                                    : classes.ListItemTextRoot,
                                }}
                                style={{ marginBottom: "25px" }}
                            
                                onClick={() => {
                              
                                  setSelectedGroup(item.group_id);
                                  setSelectedGroupContact(item.id);
                                  setSelectedGroupPhone(item.fname);
                                  setContact(null);
                                  updateUnreadCountByGroups(item);
                                }}
                              >
                                <ListItemAvatar>
                                  <Badge
                                    badgeContent={item.unread ? item.unread : 0}
                                    color="primary"
                                  >
                                    <Avatar
                                      style={{
                                        backgroundColor:
                                          item.smsMMSColor &&
                                          item.smsMMSColor != ""
                                            ? item.smsMMSColor
                                            : "#D7FDFF",
                                      }}
                                    >
                                      <GroupIcon />
                                    </Avatar>
                                  </Badge>
                                </ListItemAvatar>

                                <ListItemText
                                  classes={{
                                    primary: selectedGroupContact
                                      ? selectedGroupContact == item.id
                                        ? classes.ListItemTextSelectedText
                                        : classes.ListItemTextText
                                      : selectedGroupPhone &&
                                        (!item.id || item?.id <= 0)
                                      ? selectedGroupPhone == item.fname
                                        ? classes.ListItemTextSelectedText
                                        : classes.ListItemTextText
                                      : classes.ListItemTextText,
                                    secondary: classes.secundaryText,
                                  }}
                                  primary={
                                    item.fname && item.lname
                                      ? item.fname +
                                        " " +
                                        item.lname +
                                        " from " +
                                        (item.building
                                          ? item.building.substring(0, 3)
                                          : "none") +
                                        "." +
                                        " in " +
                                        item.group_name
                                      : item.phone + " in " + item.group_name
                                  }
                                  secondary={
                                    <>
                                      {
                                        <div
                                          style={{
                                            color: "black",
                                            fontStyle: "italic",
                                            textAlign: "justify",
                                          }}
                                        >
                                          {(item.lastMessageUser == 1
                                            ? "You: "
                                            : "") +
                                            (item.lastMessageBody
                                              ? trimDownToWord(
                                                  item.lastMessageBody,
                                                  50
                                                )
                                              : "")}
                                        </div>
                                      }
                                      <div>
                                        {item.user_id
                                          ? authUser.id != item.user_id
                                            ? "PTC: " + item.owner_name
                                            : ""
                                          : ""}{" "}
                                      </div>
                                    </>
                                  }
                                />

                                <ListItemSecondaryAction
                                  style={{
                                    fontSize: "12px",
                                    textAlign: "center",
                                  }}
                                >
                                  {item.lastMessageDate ? (
                                    moment(item.lastMessageDate).format(
                                      "MM-DD-YY"
                                    ) == moment().format("MM-DD-YY") ? (
                                      <strong>Today</strong>
                                    ) : moment(item.lastMessageDate).format(
                                        "MM-DD-YY"
                                      ) ==
                                      moment()
                                        .subtract(1, "days")
                                        .format("MM-DD-YY") ? (
                                      <strong>Yesterday</strong>
                                    ) : (
                                      moment(item.lastMessageDate).format(
                                        "MM-DD-YY"
                                      )
                                    )
                                  ) : (
                                    ""
                                  )}
                                  <br />

                                  {selectedGroup &&
                                    selectedGroup === item.group_id && (
                                      <IconButton
                                        name={
                                          item.phone +
                                          "-" +
                                          selectedGroup +
                                          "-" +
                                          item.outboundMessagesCount
                                        }
                                        id={item.id}
                                        edge="end"
                                        aria-label="more"
                                        onClick={(event) =>
                                          openMoreMenu(event.currentTarget)
                                        }
                                        size={"small"}
                                      >
                                        <ExpandMore size="small" />
                                      </IconButton>
                                    )}
                                </ListItemSecondaryAction>
                              </ListItem>
                            </>
                          );
                        }
                      )
                    : map(
                        mergeDataLocalByGroups.filter((group) => {
                          return group.id > 0 || group.id === null;
                        }),
                        (item, index) => {
                          return (
                            <>
                              <ListItem
                                button
                                key={item.id ? item.id : "" + item.phone}
                                classes={{
                                  root: selectedGroupContact
                                    ? selectedGroupContact == item.id
                                      ? classes.ListItemTextSelectedRoot
                                      : classes.ListItemTextRoot
                                    : selectedGroupPhone &&
                                      (!item.id || item?.id <= 0)
                                    ? selectedGroupPhone == item.fname
                                      ? classes.ListItemTextSelectedRoot
                                      : classes.ListItemTextRoot
                                    : classes.ListItemTextRoot,
                                }}
                                style={{ marginBottom: "25px" }}
                             
                                onClick={() => {
                         
                                  setSelectedGroup(item.group_id);
                                  setSelectedGroupContact(item.id);
                                  setSelectedGroupPhone(item.fname);
                                  setContact(null);
                                  updateUnreadCountByGroups(item);
                                }}
                              >
                                <ListItemAvatar>
                                  <Badge
                                    badgeContent={item.unread ? item.unread : 0}
                                    color="primary"
                                  >
                                    <Avatar
                                      style={{
                                        backgroundColor:
                                          item.smsMMSColor &&
                                          item.smsMMSColor != ""
                                            ? item.smsMMSColor
                                            : "#D7FDFF",
                                      }}
                                    >
                                      <GroupIcon />
                                    </Avatar>
                                  </Badge>
                                </ListItemAvatar>

                                <ListItemText
                                  classes={{
                                    primary: selectedGroupContact
                                      ? selectedGroupContact == item.id
                                        ? classes.ListItemTextSelectedText
                                        : classes.ListItemTextText
                                      : selectedGroupPhone &&
                                        (!item.id || item?.id <= 0)
                                      ? selectedGroupPhone == item.fname
                                        ? classes.ListItemTextSelectedText
                                        : classes.ListItemTextText
                                      : classes.ListItemTextText,
                                    secondary: classes.secundaryText,
                                  }}
                                  primary={
                                    item.fname && item.lname
                                      ? item.fname +
                                        " " +
                                        item.lname +
                                        " from " +
                                        (item.building
                                          ? item.building.substring(0, 3)
                                          : "none") +
                                        "." +
                                        " in " +
                                        item.group_name
                                      : item.phone + " in " + item.group_name
                                  }
                                  secondary={
                                    <>
                                      {
                                        <div
                                          style={{
                                            color: "black",
                                            fontStyle: "italic",
                                            textAlign: "justify",
                                          }}
                                        >
                                          {(item.lastMessageUser == 1
                                            ? "You: "
                                            : "") +
                                            (item.lastMessageBody
                                              ? trimDownToWord(
                                                  item.lastMessageBody,
                                                  50
                                                )
                                              : "")}
                                        </div>
                                      }
                                      <div>
                                        {item.user_id
                                          ? authUser.id != item.user_id
                                            ? "PTC: " + item.owner_name
                                            : ""
                                          : ""}{" "}
                                      </div>
                                    </>
                                  }
                                />

                                <ListItemSecondaryAction
                                  style={{
                                    fontSize: "12px",
                                    textAlign: "center",
                                  }}
                                >
                                  {item.lastMessageDate ? (
                                    moment(item.lastMessageDate).format(
                                      "MM-DD-YY"
                                    ) == moment().format("MM-DD-YY") ? (
                                      <strong>Today</strong>
                                    ) : moment(item.lastMessageDate).format(
                                        "MM-DD-YY"
                                      ) ==
                                      moment()
                                        .subtract(1, "days")
                                        .format("MM-DD-YY") ? (
                                      <strong>Yesterday</strong>
                                    ) : (
                                      moment(item.lastMessageDate).format(
                                        "MM-DD-YY"
                                      )
                                    )
                                  ) : (
                                    ""
                                  )}
                                  <br />

                                  {selectedGroup &&
                                    selectedGroup === item.group_id && (
                                      <IconButton
                                        name={
                                          item.phone +
                                          "-" +
                                          selectedGroup +
                                          "-" +
                                          item.outboundMessagesCount
                                        }
                                        id={item.id}
                                        edge="end"
                                        aria-label="more"
                                        onClick={(event) =>
                                          openMoreMenu(event.currentTarget)
                                        }
                                        size={"small"}
                                      >
                                        <ExpandMore size="small" />
                                      </IconButton>
                                    )}
                                </ListItemSecondaryAction>
                              </ListItem>
                            </>
                          );
                        }
                      )}
                </InfiniteScroll>{" "}
              </Box>
            )}
            {/* </TabPanel> */}
          </div>
        </List>

        <Menu
          id="more-menu"
          anchorEl={anchorElMoreMenu}
          keepMountd
          open={Boolean(anchorElMoreMenu)}
          TransitionComponent={Zoom}
          onClose={handleCloseMoreSetting}
          elevation={1}
          getContentAnchorEl={null}
          PaperProps={{
            style: {
              borderRadius: 10,
              borderColor: "rgba(180, 180, 180, 0.87)",
              borderWidth: "1px",
              borderStyle: "solid",
            },
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem
            onClick={() => {
              handleCloseMoreSetting(true);
              openContact();
            }}
            disabled={
              selectedItem <= 0 ||
              (authUser.is_salesman == 1 && userId != authUser.id)
            }
            key={"GotoContact"}
          >
            Go to Contact
          </MenuItem>
          {
            <MenuItem
              onClick={() => {
                handleCloseMoreSetting(true);
                mergeContact();
              }}
              disabled={searchMergeablePhone.length <= 1}
              key={"MergeContacts"}
            >
              Merge Contacts
            </MenuItem>
          }
          {
            <MenuItem
              onClick={() => {
                markasUnread(1);
              }}
              disabled={outboundMessagesCount && outboundMessagesCount == 0}
              key={"MarkasUnread"}
            >
              Mark as Unread
            </MenuItem>
          }
          {
            <MenuItem key={"setColor"}>
              <ListItemText style={{ textAlign: "center" }}>
                <CirclePicker
                  colors={[
                    "#FF6900",
                    "#7BDCB5",
                    "#9900EF",
                    "#8ED1FC",
                    "#FCB900",
                    "#BDBDBD",
                  ]}
                  onChange={(color, event) => {
                    setColor(color);
                  }}
                />
              </ListItemText>
            </MenuItem>
          }
        </Menu>

        <Draggable
          handle={'[class*="MuiDialog-root"]'}
          cancel={'[class*="input"]'}
        >
          <Dialog
            open={openPopup}
            classes={{ paper: classes.dialogWrapper }}
            PaperComponent={PaperComponent}
            maxWidth={"sm"}
            fullWidth
            disableEnforceFocus
            disableScrollLock
            hideBackdrop
          >
            <DialogTitle className={classes.dialogTitle2}>
              <Grid container>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  item
                  xs={11}
                >
                  <Typography variant="inherit">To: </Typography>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  item
                  xs={1}
                >
                  <Controls.IconButton
                    style={{ color: "white" }}
                    onClick={(event) => onCloseMessagePopup()}
                  >
                    <CloseIcon fontSize="small" />
                  </Controls.IconButton>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent>
              <NewSmsMmsForm onClose={onCloseMessagePopup} saveTo={saveTo} />
            </DialogContent>
          </Dialog>
        </Draggable>
      </div>
      <Wrapper>
        {contactDetail && (
          <Popup
            fullScreen
            title={`Contact ${contactDetail.fname} ${contactDetail.lname}`}
            openPopup={openContactPopup}
            onClose={oncloseContact}
          >
            <ContactDetails
              contact={contactDetail}
              onDetailClose={oncloseContact}
            />
          </Popup>
        )}
        {recordDetail && (
          <Popup
            fullScreen
            title={`Patient ${recordDetail.full_name}`}
            openPopup={openRecordPopup}
            onClose={oncloseRecord}
          >
            <RecordDetails record={recordDetail} onClose={oncloseRecord} />
          </Popup>
        )}
      </Wrapper>
    </>
  );
};

export default SmsMmsContactList;
